import { ItemSpecialCode } from "../models/models";
import { ApiResponse, request } from "./api";

export async function getListByItemId( id: number): Promise<ApiResponse<ItemSpecialCode[]>> {
    return await request<ItemSpecialCode[]>(`items/${id}/OzelKod`, "GET");
}

export async function add(
    model: ItemSpecialCode
): Promise<ApiResponse<ItemSpecialCode>> {
    return await request<ItemSpecialCode>(`items/OzelKod`, "POST", model);
}

export async function deleteItemSpecialCode(id: number, ozelKodAlaniId: number): Promise<ApiResponse<ItemSpecialCode[]>> {
    return await request<ItemSpecialCode[]>(
        `Malzeme/${id}/OzelKod/${ozelKodAlaniId}`,"DELETE");
}
