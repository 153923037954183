import { UserForLoginDto,AuthenticateResult,User} from "../models/models";


import { ApiResponse, request } from "./api";

export async function kullaniciDonemleriniAl(
    kullaniciAdi: string
): Promise<ApiResponse<User>> {
    return await request<User>(`users/${kullaniciAdi}/donemler`, "GET");
}

export async function getList(): Promise<ApiResponse<User[]>> {
    return await request<User[]>(`users/getList`,"GET");
}
export async function getUsersWithClaims(): Promise<ApiResponse<User[]>> {
    return await request<User[]>(`users/getUsersWithClaims`,"GET");
}

export async function add( model: User): Promise<ApiResponse<User>> {
    return await request<User>(`users/add`, "POST", model);
}

export async function deleteUser(id: number): Promise<ApiResponse<null>> {
    return await request<null>(`users/delete/${id}`, "DELETE");
}

export async function update( model: User): Promise<ApiResponse<null>> {
    return await request<null>(`users/update`, "PATCH", model);
}

export async function login( model: UserForLoginDto): Promise<ApiResponse<AuthenticateResult>> {
    const response = await request<AuthenticateResult>(`auth/login`,"POST",model);
    return response;
}



export async function logoutUI() {
    localStorage.removeItem("id");
    localStorage.removeItem("name");
    localStorage.removeItem("rules");
    localStorage.removeItem("firmNumber");
    localStorage.removeItem("periodNumber");
    localStorage.removeItem("token");
}

export function isUserLogin(): boolean {
    return localStorage.getItem("token") !== null;
}

export function loginUI(model: AuthenticateResult): void {
    localStorage.setItem("id", model.id.toString());
    localStorage.setItem("name", model.name);
    localStorage.setItem("rules", String(model.roles));
    localStorage.setItem("firmNumber", model.firmNumber.toString());
    localStorage.setItem("periodNumber", model.periodNumber.toString());
    localStorage.setItem("token", model.token);
}

export function getUserInfo(): AuthenticateResult | null {
    if (!isUserLogin()) return null;
    return {
        id: Number(localStorage.getItem("id")),
        name: String(localStorage.getItem("name")),
        roles: String(localStorage.getItem("rules")),
        firmNumber: Number(localStorage.getItem("firmNumber")),
        periodNumber: Number(localStorage.getItem("periodNumber")),
        token: String(localStorage.getItem("token")),
    };
}
