import { Tag, Button as AntsButton, Input, Menu, Tabs, Dropdown, Tooltip, message, DatePicker, Card, Table, Modal, Form, ConfigProvider, Pagination, Row, Col, Spin, Space, Select, Button, Drawer, Checkbox } from 'antd';
import { DownOutlined, AppstoreOutlined, UserOutlined } from '@ant-design/icons';
import Moment from 'react-moment';
import * as React from 'react';
import { OrderByType, ErpStatus, OrderFilter, OrderStatus, OrderDto, PaymentMethod } from '../../../../models/models'
import * as dbOrder from '../../../../api_ts/order';
import { OrderDetails } from './OrderDetails';
import { useEffect } from 'react';
import FormItem from 'antd/lib/form/FormItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIntl } from 'react-intl';
import TranslateJSX, { Translate } from '../../i18n/Translate';
import { initial_OrderFilter } from '../../../../models/initial_models'
import Currency from '../../components/Currency';


export interface Pagination {
    current: number,
    pageSize: number,
    defaultCurrent?: number
    total?: number
};

export const Orders: React.FC = () => {
    const intl = useIntl();
    const [flags, setFlags] = React.useState({ loading: false, orderDetailVisible: false });
    const [orders, setOrders] = React.useState<OrderDto[]>([]);
    const [orderId, setOrderId] = React.useState<number>(0);
    const [pagination, setPagination] = React.useState<Pagination>({ current: 1, pageSize: 10, defaultCurrent: 1 });
    const [orderForm] = Form.useForm();
    const [orderFilter, setOrderFilter] = React.useState<OrderFilter>(initial_OrderFilter);
    const [dateFilterDisabled, setDateFilterDisabled] = React.useState(true);
    let [selectedRow, setSelectedRow] = React.useState<any>([]);



    useEffect(() => {
        getAllOrders()
    }, []);
    useEffect(() => {
        // CsvData()
    }, [selectedRow]);


    const getAllOrders = () => {
        dbOrder.getListByFilter(orderFilter)
            .then(response => {
                if (response.ok) {
                    console.log(response.data)
                    setOrders(response.data.data);
                    setPagination({ current: response.data.pageNumber, total: response.data.totalCount, pageSize: response.data.pageSize })
                }
                else
                    console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }

    const searchToOrders = (fieldsValue: any) => {
        orderFilter.searchValue = fieldsValue["searchValue"];
        orderFilter.searchColumns = fieldsValue.searchColumns;
        orderFilter.startDate = fieldsValue['startDate'] == "" ? "" : fieldsValue['startDate'].format('YYYY-MM-DD');
        orderFilter.endDate = fieldsValue['endDate'] == "" ? "" : fieldsValue['endDate'].format('YYYY-MM-DD');
        getAllOrders();
    }

    const onChangeTable = (pagination: any, filters: any, sorter: any) => {
        console.log(filters);
        if (sorter.column != undefined) {
            orderFilter.columnName = sorter.field;
            orderFilter.orderByType = sorter.order == "ascend" ? OrderByType.Asc : OrderByType.Desc;
        }
        else {
            orderFilter.columnName = "";
            orderFilter.orderByType = OrderByType.Desc;
        }
        setOrderFilter({
            ...orderFilter,
            pageSize: pagination.pageSize,
            pageNumber: pagination.current,
            orderStatus: filters.orderStatus == null ? [] : filters.orderStatus,
            orderErpStatus: filters.erpStatus == null ? [] : filters.erpStatus,
        })
    }

    useEffect(() => {
        getAllOrders();
    }, [orderFilter])

    const showOrderDetail = (order: OrderDto) => {
        setOrderId(order.id);
        setFlags({ ...flags, orderDetailVisible: true });
    }

    const exportOrdersExcell = () => {
        fetch('/api/orders/export-excell', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + (localStorage.getItem("token") || ""),
            },
            body: JSON.stringify(selectedRow.map(x => x.id)),
        })
            .then(response => response.blob())
            .then(response => {
                const blob = new Blob([response], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const downloadUrl = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = downloadUrl;
                a.download = "file.xlsx";
                document.body.appendChild(a);
                a.click();
            })

            .catch((error) => {
                console.log('Error:', error);
            });
    }

    //TODO BU error mesajlarının tekrarı giderilecek
    const changeOrderStatus = async (durum: OrderStatus) => {
        if (selectedRow.length == 0) return;
        setFlags(({ ...flags, loading: true }));                               //Loading Aç
        let success: boolean = (await dbOrder.changeOrdersStatus(selectedRow.map(x => x.id), durum)).ok;
        if (success) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            getAllOrders();
            setSelectedRow([]);
            setFlags(({ ...flags, loading: false }));
        } else {
            message.success(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }
    //TODO BU error mesajlarının tekrarı giderilecek
    const erpTransfer = async () => {
        if (selectedRow.length == 0) return;
        setFlags({ ...flags, loading: true });
        let success: boolean = (await dbOrder.erpTransfer(selectedRow.map(x => x.id))).ok;
        if (success) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            getAllOrders();
            setSelectedRow([]);
            setFlags(({ ...flags, loading: false }));
        } else {
            message.success(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }


    const columns = [
        {
            key: 'orderNo',
            title: Translate("OrderNo"),
            dataIndex: 'orderNo',
        },
        {
            key: 'date',
            name: "date",
            dataIndex: 'date',
            title: Translate("Date"),
            sorter: true,
            render: (text: string, order: OrderDto) => <Moment format="DD.MM.YYYY HH:mm">{order.date_}</Moment>,
        },
        {
            key: 'clientDescription',
            title: Translate("Client"),
            dataIndex: 'clientDescription',
        },
        {
            title: Translate("Username"),
            dataIndex: "user",
            render: (_: string, order: OrderDto) => {
                if (order.clientUser === null) {
                    return <span><Tooltip placement="top" color="green" title={Translate("Salesman")}><Tag color={'green'}> {'SE'}</Tag>{`${order.salesman?.username}`}</Tooltip>
                    </span>
                } else if (order.salesman === null) {
                    return <span><Tooltip placement="top" color="green" title={Translate("ClientUser")}><Tag color={'blue'}> {'CK'}</Tag>{`${order.clientUser?.username}`}</Tooltip>
                    </span>
                }
            }
        },
        {
            key: 'subtotal',
            title: Translate('Subtotal'),
            dataIndex: 'subtotal',
            sorter: true,
            align: 'right' as const,
            render: (_: string, order: OrderDto) => <Currency value={order.subtotal} />

        },
        {
            key: 'priceWithVAT',
            title: Translate("PriceWithVAT"),
            dataIndex: 'priceWithVat',
            width: 120,
            align: 'right' as const,
            render: (_: string, order: OrderDto) => <Currency value={order.priceWithVat} />
        },
        {
            key: 'netAmount',
            title: Translate("NetAmount"),
            dataIndex: 'netAmount',
            align: 'right' as const,
            render: (_: string, order: OrderDto) => <Currency value={order.netAmount} />

        },
        {
            key: 'warehouse',
            title: Translate("Warehouse"),
            dataIndex: "warehouse",
            name: "warehouse",
        },
        {
            key: 'orderBonus',
            title: Translate("BonusPoint"),
            dataIndex: "bonusPoint",
            name: "orderBonus",
        },
        {
            key: 'paymentType',
            title: Translate("CollectionType"),
            dataIndex: "paymentType",
            name: "paymentType",
            render: (text: string, order: OrderDto) => Translate(`Payment.${PaymentMethod[order.paymentMethod]}`)
        },
        {
            key: 'orderStatus',
            title: Translate("Status"),
            dataIndex: 'orderStatus',
            width: 80,
            filteredValue: orderFilter.orderStatus.length > 0 ? orderFilter.orderStatus : null,
            filters: Object.keys(OrderStatus).filter(key => !/^\d+$/.test(key)).map(key => ({ text: Translate(key), value: OrderStatus[key as any] })),
            render: (text: string, order: OrderDto) => {
                switch (order.status) {
                    case OrderStatus.Confirmed:
                        return <Tag color={'green'}>{Translate("Confirmed")}</Tag>
                    case OrderStatus.WaitForApproval:
                        return <Tag color={'blue'}>{Translate("WaitForApproval")}</Tag>
                    case OrderStatus.Cancel:
                        return <Tag color={'red'}>{Translate("Cancel_noun")}</Tag>
                }
            }
        },
        {
            key: 'erpStatus',
            title: Translate("ERPStatus"),
            dataIndex: 'erpStatus',
            width: 80,
            filteredValue: orderFilter.orderErpStatus.length > 0 ? orderFilter.orderErpStatus : null,
            filters: Object.keys(ErpStatus).filter(key => !/^\d+$/.test(key)).map(key => ({ text: Translate(key), value: ErpStatus[key as any] })),
            render: (text: string, order: OrderDto) => {
                switch (order.erpStatus) {
                    case ErpStatus.NotTransferred:
                        return <Tag color={'blue'}>{Translate("NotTransferred")}</Tag>
                    case ErpStatus.Transferred:
                        return <Tag color={'green'}>{Translate("Transferred")}</Tag>
                }
            }
        },
        {
            key: 'idErp',
            title: Translate("ErpReference"),
            dataIndex: 'idErp',
            name: "idErp",
        },
        {
            key: '8',
            width: 50,
            render: (text: string, order: OrderDto) =>
                <>
                    <Tooltip placement="top" color="blue" title={Translate("OrderDetails")}>
                        <AntsButton icon={<AppstoreOutlined />} onClick={() => showOrderDetail(order)} />
                    </Tooltip>
                </>
        },
    ];


    const exportGroup = (
        <Button onClick={exportOrdersExcell} icon={<FontAwesomeIcon icon="file-excel" style={{ fontSize: "24px", color: "green" }} />}></Button>
    )

    const rowSelection = {
        selectedRowKeys: selectedRow.map(x => x.id),
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setSelectedRow(selectedRows)
        },
        // getCheckboxProps: (order: Siparis) => ({
        //     disabled: order.durum === SiparisDurumu.confirmed, // BU KOD DİSABLED YAPAR
        // }),
    };



    const orderStatusMenu = () => (
        <Menu>
            <Menu.Item key="0" onClick={() => changeOrderStatus(OrderStatus.Confirmed)}><a>{TranslateJSX("Confirm")}</a></Menu.Item>
            <Menu.Item key="1"><a onClick={() => changeOrderStatus(OrderStatus.Cancel)}>{TranslateJSX("Reject")}</a></Menu.Item>
        </Menu>
    );


    const header = (
        <Row style={{ height: "60px" }}>
            <Col span={5} >
                <Space>
                    <Dropdown overlay={orderStatusMenu} trigger={['click']}>
                        <AntsButton className="ant-dropdown-link"> {Translate("Status")}<DownOutlined /></AntsButton>
                    </Dropdown>
                    <AntsButton className="ant-dropdown-link" onClick={erpTransfer}>{Translate("ErpTransfer")}</AntsButton>
                </Space>
            </Col>
            <Col span={19}   >
                <Form

                    form={orderForm}
                    size="small"
                    onFinish={searchToOrders}
                >
                    <Row>
                        <Col span={22}>
                            <Row>
                                <Col style={{ height: '30px' }} span={24}>
                                    <Form.Item style={{ marginBottom: 0 }}>
                                        <Form.Item
                                            labelAlign='left'
                                            style={{ display: 'inline-block', marginRight: '5px' }}
                                            name="IsActiveDateFilter"
                                            initialValue={dateFilterDisabled}
                                            valuePropName="unchecked"
                                        >
                                            <Checkbox onChange={(e) => {
                                                if (e.target.checked)
                                                    setDateFilterDisabled(false)
                                                else {
                                                    setDateFilterDisabled(true)
                                                    orderForm.setFieldsValue({ startDate: "", endDate: "" })
                                                }
                                            }} ></Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            initialValue={initial_OrderFilter.startDate}
                                            name="startDate"
                                            style={{ display: 'inline-block', marginRight: '5px' }}
                                        >
                                            <DatePicker disabled={dateFilterDisabled} placeholder={Translate("StartDate")} />
                                        </Form.Item>

                                        <Form.Item
                                            initialValue={initial_OrderFilter.endDate}
                                            name="endDate"
                                            style={{ display: 'inline-block', marginRight: '5px' }}>
                                            <DatePicker disabled={dateFilterDisabled} placeholder={Translate("EndDate")} />
                                        </Form.Item>
                                        <FormItem
                                            name="searchColumns"
                                            initialValue={["OrderNo"]}
                                            style={{ display: 'inline-block', width: '150px', marginRight: '5px' }}
                                        >
                                            <Select
                                                size="small"
                                                mode="multiple"
                                                placeholder="Search Columns"
                                                maxTagCount="responsive"
                                            >
                                                {["ClientDescription", "OrderNo", "Salesman.Username", "ClientUser.Username"].map((x, i) =>
                                                    <Select.Option key={i} value={String(x)}>{Translate(x)} </Select.Option>
                                                )}
                                            </Select>
                                        </FormItem>
                                        <Form.Item
                                            name="searchValue"
                                            initialValue={orderFilter.searchValue}
                                            style={{ display: 'inline-block', width: "", marginRight: '5px' }}

                                        >
                                            <Input.Search

                                                name="searchValue"
                                                placeholder={Translate("Search")}
                                                allowClear
                                                enterButton={<Button
                                                    onKeyPress={event => event.key === 'Enter'}

                                                    style={{ background: "rgb(24 144 255)", color: "#fff" }} htmlType="submit">{Translate('Search')}
                                                </Button>}
                                                size="small"
                                            />

                                        </Form.Item>
                                    </Form.Item>
                                </Col>
                                <Row>
                                    <Col span={24}>


                                        {/* <Row>
                                                <Col span={10}> */}

                                        {/* </Col> */}
                                        {/* <Col span={10}> */}


                                        {/* </Row> */}

                                    </Col>
                                </Row>
                            </Row>


                        </Col>

                        <Col span={2}>
                            {exportGroup}
                        </Col>
                    </Row>
                </Form>
            </Col >
        </Row >

    );




    return (
        <div>
            <Tabs defaultActiveKey="1"
                tabBarStyle={{ marginBottom: "5px" }}
            >
                <Tabs.TabPane tab={<span><UserOutlined />{Translate("OrdersList")}</span>} key="1">
                    {header}
                    <div className="space-5"></div>
                    <Spin
                        size='large'
                        tip={Translate('Loading')}
                        spinning={flags.loading}>
                        <Table
                            bordered
                            rowKey="id"
                            size='small'
                            rowSelection={rowSelection}
                            columns={columns}
                            dataSource={orders}
                            pagination={pagination}
                            onChange={onChangeTable}
                        />
                    </Spin>
                </Tabs.TabPane>
            </Tabs>,
            <Drawer
                placement="right"
                width={1000}
                onClose={() => setFlags({ ...flags, orderDetailVisible: false })}
                visible={flags.orderDetailVisible}
            >
                <OrderDetails orderId={orderId} />
            </Drawer>

        </div >
    );
};
