import {  MailSendingConditions } from "../models/models";
import { ApiResponse, request } from "./api";

export async function getList(): Promise<ApiResponse<MailSendingConditions[]>> {
    return await request<MailSendingConditions[]>("mailSendingConditions/getList","GET");
}

export async function add(MailSendingConditions: MailSendingConditions): Promise<ApiResponse<MailSendingConditions>> {
    return await request<MailSendingConditions>("mailSendingConditions/add", "POST", MailSendingConditions);
}

export async function update(MailSendingConditions: MailSendingConditions): Promise<ApiResponse<MailSendingConditions>> {
    return await request<MailSendingConditions>("mailSendingConditions/update", "PATCH", MailSendingConditions);
}

export async function deleteMailSendingConditions(mailSendingCondition: MailSendingConditions): Promise<ApiResponse<null>> {
    return await request<null>(`mailSendingConditions`, "DELETE",mailSendingCondition);
}