import { useIntl } from 'react-intl';
import { SalesmanClients } from './SalesmanClients';
import React, { useState, useEffect } from 'react';
import * as dbSalesman from '../../../../api_ts/salesman';
import { ISalesman } from '../../../../models/models';
import { Salesman } from '../../../../models/initial_models';
import { useStateWithCallbackLazy as useStateCallback } from 'use-state-with-callback';
import { Button, Tabs, Table, message, Modal, Form, Input, Tooltip, Drawer } from 'antd';
import { AppstoreAddOutlined, UserOutlined, EditOutlined, DeleteOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Translate } from '../../i18n/Translate';

export const Salesmen: React.FC = () => {

    const intl = useIntl();
    const [salesmen, setSalesmen] = useState<ISalesman[]>([]);
    const [loading, setLoading] = useState(false);
    const [flags, setFlags] = useState({ salesmanModalVisible: false, operation: "" });
    const [salesmanForm] = Form.useForm();
    const formItemLayout = { labelCol: { span: 7 }, wrapperCol: { span: 17 } };
    const [salesmanClients_ModalVisible, setSalesmanClients_ModalVisible] = useState(false);
    const [salesman, setSalesman] = useState<ISalesman>(Salesman);


    useEffect(() => { getAllSalesmen() }, []);

    const fillSalesmanForm = (salesman: ISalesman) => { //TODO burası düzeltilecek salesman any tipi normal tipe dönüştürülüp confirmPassword'u veri tabanında açmak lazım mı sor !!.
        salesmanForm.setFieldsValue(salesman);
    }

    const assignClientToSalesman = (salesman: ISalesman) => {
        setSalesman(salesman);
        setSalesmanClients_ModalVisible(true);
    }

    const columns = [
        {
            title: Translate("Kod"),
            dataIndex: 'code',
        },
        {
            title: Translate("Name"),
            dataIndex: 'firstName',
        },
        {
            title: Translate("LastName"),
            dataIndex: 'lastName',
        },
        {
            title: Translate("E-mail"),
            dataIndex: 'email',
        },
        {
            title: Translate("Username"),
            dataIndex: 'username',
        },
        {
            title: Translate("Password"),
            dataIndex: 'password',
        },
        // {
        //     title: Translate("YetkiGrubu"),
        //     dataIndex: 'yetkiGrubu',
        //     render: (text: string, record: Salesman) => Translate(SalesmanYetkiGrubu[record.yetkiGrubu])
        // },
        {
            key: 'id',
            render: (text: string, salesman: ISalesman) =>
                <>
                    <Tooltip placement="top" color="blue" title={Translate("AssignClientToSalesman")}>
                        <Button icon={<AppstoreAddOutlined />} onClick={() => assignClientToSalesman(salesman)} />
                    </Tooltip>
                    <Tooltip placement="top" color="blue" title={Translate("Edit")}>
                        <Button icon={<EditOutlined />} onClick={() => {
                            salesmanForm.resetFields()
                            setFlags({ ...flags, salesmanModalVisible: true, operation: "Update" })
                            fillSalesmanForm(salesman)
                        }}
                        />
                    </Tooltip>
                    <Tooltip placement="top" color="red" title={Translate("Delete")}>
                        <Button icon={<DeleteOutlined />} onClick={() => deleteSalesman(salesman.id)} />
                    </Tooltip>
                </>
        },
    ];


    const addOrUpdateSalesman = async (salesman: ISalesman) => {
        if (salesman.id == 0)
            IsSuccessful(await dbSalesman.add(salesman));
        else
            IsSuccessful(await dbSalesman.update(salesman))
    }


    const deleteSalesman = async (salemanId: number) => {
        if ((await dbSalesman.deleteSalesman(salemanId)).ok) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }))
            getAllSalesmen();
        }
        else
            message.error(intl.formatMessage({ id: 'Operation.Error' }))
    }


    const getAllSalesmen = () => {
        return dbSalesman.getList()
            .then(response => {
                if (response.ok)
                    setSalesmen(response.data);
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }


    const IsSuccessful = (action: any) => {
        setLoading(true);
        if (action.ok) {
            getAllSalesmen()
                .then(() => {
                    salesmanForm.resetFields()
                    setFlags({ ...flags, salesmanModalVisible: false, operation: "Add" })
                    setLoading(false)
                })
        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }))
            setLoading(false)
        }
    }

    //#region COMPONENT DRİLİNG 
    const changeAllClientsPermission = async (e: CheckboxChangeEvent) => {
        const success = await dbSalesman.update({ ...salesman, allClientAuth: e.target.checked });
        if (success.ok) {
            getSalesmanById(salesman.id)
            getAllSalesmen();
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }

    const getSalesmanById = (salesmanId: number) => {
        dbSalesman.getById(salesmanId)
            .then(response => {
                if (response.ok) {
                    setSalesman(response.data);
                }
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }

    const header = (<Button type="primary" className="ml-auto mb-1" onClick={() => {
        salesmanForm.resetFields()
        setFlags({ ...flags, salesmanModalVisible: true, operation: "Add" })
    }}
        icon={<PlusSquareOutlined style={{ verticalAlign: 'middle', fontSize: '20px' }} />} >
        <span style={{ verticalAlign: 'middle' }} >{Translate("Add")}</span>
    </Button>)

    return (
        <Tabs
            defaultActiveKey="1"
            tabBarStyle={{ marginBottom: "3.5px" }}
        >
            <Tabs.TabPane tab={<span><UserOutlined />{Translate("SalesmanList")}</span>} key="1">
                <div className="site-card-border-less-wrapper">
                    <div className="d-flex">{header} </div>
                    <Table bordered size="small" columns={columns} dataSource={salesmen} />
                </div>

                <Modal
                    title={Translate("Salesman")}
                    visible={flags.salesmanModalVisible}
                    onCancel={() => setFlags({ ...flags, salesmanModalVisible: false, operation: "Add" })}
                    onOk={() => { salesmanForm.submit() }}
                    okText={Translate(flags.operation)}
                    cancelText={Translate("Cancel")}
                    confirmLoading={loading}
                >
                    <Form
                        form={salesmanForm}
                        layout={"horizontal"}
                        onFinish={addOrUpdateSalesman}
                        {...formItemLayout}
                        size={"small"}
                        labelAlign={"left"}
                    >
                        <Form.Item
                            name={"id"}
                            fieldKey={"id"}
                            hidden={true}
                            initialValue={0} >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={Translate("Code")}
                            name="code"
                            rules={[{ required: true, message: Translate("RequiredField") }]}
                        >
                            <Input placeholder={Translate("Code")} />
                        </Form.Item>
                        <Form.Item
                            label={Translate("Name")}
                            name="firstName"
                            rules={[{ required: true, message: Translate("RequiredField") }]}
                        >
                            <Input placeholder={Translate("Name")} />
                        </Form.Item>
                        <Form.Item
                            label={Translate("Surname")}
                            name="lastName"
                            rules={[{ required: true, message: Translate("RequiredField") }]}
                        >
                            <Input placeholder={Translate("Surname")} />
                        </Form.Item>
                        <Form.Item
                            label={Translate("Email")}
                            name="email"
                            rules={[{ required: true, message: Translate("RequiredField") }, { type: 'email', message: Translate("InvalidEmail") }]}
                        >
                            <Input placeholder={Translate("Email")} />
                        </Form.Item>
                        <Form.Item
                            label={Translate("Username")}
                            name="username"
                            rules={[{ required: true, message: Translate("Username") }]}
                        >
                            <Input placeholder={Translate("Username")} />
                        </Form.Item>

                        <Form.Item
                            label={Translate("Password")}
                            name="password"
                            hasFeedback
                            rules={[{ required: true, message: Translate("RequiredField") }]}
                        >
                            <Input.Password placeholder={Translate("Password")} />
                        </Form.Item>
                        <Form.Item
                            name="confirmPassword"
                            label={Translate("ConfirmPassword")}
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                { required: true, message: Translate("RequiredField") },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(Translate("PasswordNotMatch")));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder={Translate("Password")} />
                        </Form.Item>
                    </Form>
                </Modal>


                <Drawer
                    placement="right"
                    width={720}
                    onClose={() => setSalesmanClients_ModalVisible(false)}
                    visible={salesmanClients_ModalVisible}
                >
                    <SalesmanClients changeAllClientsPermission={changeAllClientsPermission} salesman={salesman} />
                </Drawer>



            </Tabs.TabPane>
        </Tabs >
    )


};