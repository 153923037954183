// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import Indicator from './Indicator';
import { Cart20Svg, Cross10Svg } from '../../svg';
import { removeFromCart, getCart } from '../../store/cart';
import { url } from '../../services/utils';

function IndicatorCart(props) {
    const { cart,
        removeFromCart
    } = props;
    let dropdown;
    let refIndicator = React.createRef();




    const cartItems = cart.cartItems.map((cartItem) => {
        let options;
        let image;

        if (cartItem.options) {
            options = (
                <ul className="dropcart__product-options">
                    {cartItem.options.map((option, index) => (
                        <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
                    ))}
                </ul>
            );
        }

        if (cartItem.product.images.length) {
            image = (
                <div className="product-image dropcart__product-image">
                    <Link to={url.product(cartItem.product)} className="product-image__body">
                        <img className="product-image__img" src={cartItem.product.images[0]} alt="" />
                    </Link>
                </div>
            );
        }

        const removeButton = (
            <AsyncAction
                action={() => removeFromCart(cartItem.id, cart.cartItems.length)}
                render={({ run, loading }) => {
                    const classes = classNames('dropcart__product-remove btn btn-light btn-sm btn-svg-icon', {
                        'btn-loading': loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes}>
                            <Cross10Svg />
                        </button>
                    );
                }}
            />
        );
        return (
            <div key={cartItem.id} className="dropcart__product">
                {image}
                <div className="dropcart__product-info">
                    <div className="dropcart__product-name">
                        <Link to={url.product(cartItem.product)}>{cartItem.product.name}</Link>
                    </div>
                    {options}
                    <div className="dropcart__product-meta">
                        <span className="dropcart__product-quantity">{cartItem.quantity}</span>
                        {' × '}
                        <span className="dropcart__product-price"><Currency value={cartItem.product.price} /></span>
                    </div>
                </div>
                {removeButton}
            </div>
        );
    });

    if (cart.quantity) {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__products-list">
                    {cartItems}
                </div>

                <div className="dropcart__totals">
                    <table>
                        <tbody>
                            {/* {totals} */}
                            <tr>
                                <th><FormattedMessage id="shop.indicator.cart.total" /></th>
                                <td><Currency value={cart.subtotal} /> + <FormattedMessage id="vat" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="dropcart__buttons">
                    <Link className="btn btn-secondary" to="/shop/cart" onClick={() => refIndicator.close()}><FormattedMessage id="shop.indicator.cart.viewCart" /></Link>
                    {/* <Link className="btn btn-primary" to="/shop/checkout">Checkout</Link> */}
                </div>
            </div>
        );
    } else {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__empty">
                    <FormattedMessage id="shop.emptyCart" />
                </div>
            </div>
        );
    }

    return (
        <Indicator ref={ref => refIndicator = ref} url="/shop/cart" dropdown={dropdown} value={cart.quantity} icon={<Cart20Svg />} />
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    removeFromCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);
