import { request } from "./request";
import {
    update_quantityOfCartItem,
    update_offerPricesOfCart,
    update_shippingAddress,
    update_billingAddress,
    update_paymentTypeOfCart
} from "./../../../helper/magic_string";



export async function getCart() {
    return await request(`carts/getCartByUser`, "GET");
}
export async function removeFromCart(itemId, cartItemCount) {
    return await request(`carts/${itemId}?cartItemCount=${cartItemCount}`, 'DELETE');
}

export async function addToCart(itemId, quantity) {
    const cartItem = { itemId, quantity }
    return await request(`carts/AddToCart`, 'POST', cartItem);
}

export async function updateCartItem(id, quantity) {
    return await request(`${update_quantityOfCartItem}`, 'POST', { id, quantity });
}
export async function updateOfferPricesOfCart(offerUnitPrice, cartItemId) {
    return await request(`${update_offerPricesOfCart}`, 'POST', { offerUnitPrice, id: cartItemId });
}
export async function updatePaymentTypeOfCart(cartId, paymentTypeId) {
    return await request(`${update_paymentTypeOfCart}?cartId=${cartId}&paymentTypeId=${paymentTypeId}`, 'GET');
}
export async function updateDeliveryTypeOfCart(cartId, deliveryTypeId) {
    return await request(`carts/UpdateDeliveryTypeOfCart?cartId=${cartId}&deliveryTypeId=${deliveryTypeId}`, 'GET');
}
export async function updateShippingAddressOfCart(cartId, shippingAddressId) {
    return await request(`${update_shippingAddress}?cartId=${cartId}&shippingAddressId=${shippingAddressId}`, 'GET');
}

export async function updateBillingOfCart(cartId, billingAddressId) {
    return await request(`${update_billingAddress}?cartId=${cartId}&billingAddressId=${billingAddressId}`, 'GET');
}