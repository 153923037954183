import {
    Tag, Button as AntsButton, Input, Popconfirm, Menu, Tabs, Dropdown, Tooltip, message, DatePicker, Table, Modal, Form,
    Pagination, Row, Col, Spin, Space, Select, Button, Drawer, Checkbox, InputNumber
} from 'antd';
import { PlusSquareOutlined, DeleteOutlined, EditOutlined, DownOutlined, AppstoreOutlined, UserOutlined } from '@ant-design/icons';
import Moment from 'react-moment';
import * as React from 'react';
import { OrderByType, ErpStatus, PaymentFilter, Payment, PaymentStatus, Bank, Client, PaymentMethod, PaymentType, Backoffice_PaymentDto } from '../../../../models/models'
import * as dbPayment from '../../../../api_ts/payment';
import * as dbBank from '../../../../api_ts/bank';
import * as dbClient from '../../../../api_ts/client';
import * as dbPaymentType from '../../../../api_ts/paymentType';
import { useEffect } from 'react';
import FormItem from 'antd/lib/form/FormItem';
import { useIntl } from 'react-intl';
import moment from 'antd/node_modules/moment';
import TranslateJSX, { Translate } from '../../i18n/Translate';
import { initial_CollectionFilter as initial_paymentFilter } from '../../../../models/initial_models';
import { PaymentDetails } from './PaymentDetails';
import '../../../../helper/helper_methods';
import Currency from '../../components/Currency';

export interface Pagination {
    current: number,
    pageSize: number,
    defaultCurrent?: number,
    total?: number
};
const Option = Select.Option;

export const Payments: React.FC = () => {
    const intl = useIntl();
    const [flags, setFlags] = React.useState({
        loading: false,
        operation: 'Add',
        paymentModalVisible: false,
        paymentDetailVisible: false,
        formItem_Bank_Visible: true,
    });
    const [payments, setPayments] = React.useState<Backoffice_PaymentDto[]>([]);
    const [paymentDetail, setPaymentDetail] = React.useState<Backoffice_PaymentDto>();
    const [banks, setBanks] = React.useState<Bank[]>([]);
    const [clients, setClients] = React.useState<Client[]>([]);
    const [paymentTypes, setPaymentTypes] = React.useState<PaymentType[]>([]);
    const [selectedBank, setSelectedBank] = React.useState<Bank>();
    const [newFicheNo, setNewFicheNo] = React.useState<string>("");
    const [pagination, setPagination] = React.useState<Pagination>({ current: 1, pageSize: 10, defaultCurrent: 1 });
    const [paymentForm] = Form.useForm();
    const [paymentFilterForm] = Form.useForm();
    const formItemLayout = { labelCol: { span: 7 }, wrapperCol: { span: 17 } };
    const [paymentFilter, setCollectionFilter] = React.useState<PaymentFilter>(initial_paymentFilter);
    const [dateFilterDisabled, setDateFilterDisabled] = React.useState(true);



    let [selectedRow, setSelectedRow] = React.useState<any>([]);

    useEffect(() => {
        getPayments()
        getClients();
        getPaymentTypes();
    }, []);

    useEffect(() => { paymentForm.setFieldsValue({ ficheNo: newFicheNo }); }, [newFicheNo]);



    const getPaymentTypes = () => {
        return dbPaymentType.getList()
            .then(response => {
                if (response.ok) {
                    debugger;
                    setPaymentTypes(response.data);
                }
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }

    const getClients = () => {
        dbClient.getList()
            .then(response => {
                if (response.ok)
                    setClients(response.data);
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }
    const getBankByPaymentMethod = (paymentMethod) => {
        return dbBank.getListByPaymentMethod(paymentMethod)
            .then(response => {
                if (response.ok)
                    setBanks(response.data);
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }

    const getPayments = () => {
        dbPayment.getListByFilter(paymentFilter)
            .then(response => {
                if (response.ok) {
                    debugger;
                    setPayments(response.data.data);
                    setPagination({ current: response.data.pageNumber, total: response.data.totalCount, pageSize: response.data.pageSize })
                }
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }

    const getNewFicheNo = (method: PaymentMethod) => {
        return dbPayment.newFicheNo(method)
            .then(response => {
                if (response.ok) {
                    setNewFicheNo(response.data[0]);
                }
                else console.log("Veri yok");
            })
            .catch(err => console.log(err.message));
    }

    const deleteCollection = async (collectionId: Number) => {
        if ((await dbPayment.deleteCollection(collectionId)).ok) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }))
            getPayments();
        }
        else
            message.error(intl.formatMessage({ id: 'Operation.Error' }))
    }

    const fillPaymentForm = (payment: any) => {
        payment.date_ = moment(payment.date);
        paymentForm.setFieldsValue(payment);
    }

    const addOrUpdateCollection = async (payment: Payment) => {

        let success: boolean = false;
        if (payment.id == 0) {
            success = (await dbPayment.add(payment)).ok;
        } else {
            success = (await dbPayment.update(payment)).ok;
        }
        if (success) {
            setFlags(({ ...flags, paymentModalVisible: false }))
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            getPayments();
        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }


    const searchToCollections = (fieldsValue: any) => {
        paymentFilter.searchValue = fieldsValue["searchValue"];
        paymentFilter.searchColumns = fieldsValue.searchColumns;
        paymentFilter.startDate = fieldsValue['startDate'] == "" ? "" : fieldsValue['startDate'].format('YYYY-MM-DD');
        paymentFilter.endDate = fieldsValue['endDate'] == "" ? "" : fieldsValue['endDate'].format('YYYY-MM-DD');
        getPayments();
    }

    const onChangeTable = (pagination: any, filters: any, sorter: any) => {
        console.log(filters);
        if (sorter.column != undefined) {
            paymentFilter.columnName = sorter.field;
            paymentFilter.orderByType = sorter.order == "ascend" ? OrderByType.Asc : OrderByType.Desc;
        }
        else {
            paymentFilter.columnName = "";
            paymentFilter.orderByType = OrderByType.Desc;
        }
        setCollectionFilter({
            ...paymentFilter,
            pageSize: pagination.pageSize,
            pageNumber: pagination.current,
            paymentStatus: filters.status == null ? [] : filters.status,
            erpStatus: filters.erpStatus == null ? [] : filters.erpStatus,
        })
    }

    useEffect(() => { getPayments(); }, [paymentFilter])

    const showPaymentDetail = (payment: Backoffice_PaymentDto) => {
        setPaymentDetail(payment);
        setFlags({ ...flags, paymentDetailVisible: true });
    }


    //TODO BU error mesajlarının tekrarı giderilecek
    const changeCollectionStatus = async (status: PaymentStatus) => {
        if (selectedRow.length == 0) return;
        setFlags(({ ...flags, loading: true }));                               //Loading Aç
        let success: boolean = (await dbPayment.changeStatus(selectedRow.map(x => x.id), status)).ok;
        if (success) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            getPayments();
            setSelectedRow([]);
            setFlags(({ ...flags, loading: false }));
        } else {
            message.success(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }

    const erpTransfer = async () => {
        if (selectedRow.length == 0) return;
        setFlags({ ...flags, loading: true });
        let success: boolean = (await dbPayment.erpTransfer(selectedRow.map(x => x.id))).ok;
        if (success) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            getPayments();
            setSelectedRow([]);
            setFlags(({ ...flags, loading: false }));
        } else {
            message.success(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }


    const columns = [
        {
            key: 'ficheNo',
            title: Translate("FicheNo"),
            dataIndex: 'ficheNo',
        },
        {
            key: 'date',
            dataIndex: 'date_',
            title: Translate("Date"),
            sorter: true,
            render: (_: string, payment: Backoffice_PaymentDto) => <Moment format="DD.MM.YYYY HH:mm">{payment.date_}</Moment>,
        },
        {
            key: 'clientCode',
            title: Translate("ClientCode"),
            dataIndex: 'clientCode',
        },
        {
            key: 'clientDescription',
            title: Translate("Client"),
            dataIndex: 'clientDescription',
        },
        {
            key: 'description',
            title: Translate("Description"),
            dataIndex: 'description',
        },
        {
            key: 'amount',
            title: Translate("Amount"),
            dataIndex: 'amount',
            render: (_: string, payment: Backoffice_PaymentDto) => <Currency value={payment.amount} />

        },
        {
            key: 'orderNo',
            title: Translate("OrderNo"),
            dataIndex: 'orderNo',
        },
        {
            key: 'paymentMethod',
            title: Translate("CollectionMethod"),
            dataIndex: 'paymentMethod',
            render: (_: string, payment: Backoffice_PaymentDto) => Translate("Payment." + PaymentMethod[payment.paymentMethod])
        },
        {
            title: Translate("Username"),
            dataIndex: "user",
            render: (_: string, payment: Backoffice_PaymentDto) => {
                if (payment.clientUserId === null) {
                    return <span><Tooltip placement="top" color="green" title={Translate("Salesman")}><Tag color={'green'}> {'SE'}</Tag>{`${payment.username}`}</Tooltip>
                    </span>
                } else if (payment.salesmanId === null) {
                    return <span><Tooltip placement="top" color="green" title={Translate("ClientUser")}><Tag color={'blue'}> {'CK'}</Tag>{`${payment.username}`}</Tooltip>
                    </span>
                }
            }
        },
        {
            key: 'provisionCode',
            title: Translate("ProvisionCode"),
            dataIndex: 'provisionCode',
        },
        {
            key: 'status',
            title: Translate("Status"),
            dataIndex: 'status',
            filteredValue: paymentFilter.paymentStatus.length > 0 ? paymentFilter.paymentStatus : null,
            filters: Object.keys(PaymentStatus).filter(key => !/^\d+$/.test(key)).map(key => ({ text: Translate(key), value: PaymentStatus[key as any] })),
            render: (text: string, payment: Backoffice_PaymentDto) => {
                switch (payment.status) {
                    case PaymentStatus.Confirmed:
                        return <Tag color={'green'}>{Translate("Confirmed")}</Tag>
                    case PaymentStatus.WaitForApproval:
                        return <Tag color={'blue'}>{Translate("WaitForApproval")}</Tag>
                    case PaymentStatus.Cancel:
                        return <Tag color={'red'}>{Translate("Cancel_noun")}</Tag>
                }
            }
        },
        {
            key: 'erpStatus',
            title: Translate("ERPStatus"),
            dataIndex: 'erpStatus',
            filteredValue: paymentFilter.erpStatus.length > 0 ? paymentFilter.erpStatus : null,
            filters: Object.keys(ErpStatus).filter(key => !/^\d+$/.test(key)).map(key => ({ text: Translate(key), value: ErpStatus[key as any] })),
            render: (text: string, payment: Backoffice_PaymentDto) => {
                switch (payment.erpStatus) {
                    case ErpStatus.NotTransferred:
                        return <Tag color={'blue'}>{Translate("NotTransferred")}</Tag>
                    case ErpStatus.Transferred:
                        return <Tag color={'green'}>{Translate("Transferred")}</Tag>
                }
            }
        },
        {
            key: 'idErp',
            title: Translate("ErpReference"),
            dataIndex: 'idErp',
            name: "idErp",
        },
        {
            key: '8',
            width: 115,
            render: (text: string, payment: Backoffice_PaymentDto) =>
                <>
                    <Tooltip placement="top" color="blue" title={Translate("Edit")}>
                        <Button icon={<EditOutlined />} onClick={() => {
                            let visible: boolean = true;
                            // let bankId = payment.bankId;
                            switch (payment.paymentMethod) {
                                case PaymentMethod.Remittance:
                                case PaymentMethod.CreditCard: visible = false;
                                    getBankByPaymentMethod(PaymentMethod.CreditCard)
                                    break;
                                case PaymentMethod.Cash: visible = true; break;
                            }
                            // collectionForm.resetFields()
                            setFlags({ ...flags, paymentModalVisible: true, operation: "Update", formItem_Bank_Visible: visible })
                            fillPaymentForm(payment)
                        }}
                        />
                    </Tooltip>
                    <Popconfirm
                        title={Translate("PopconfirmDelete")}
                        onConfirm={() => deleteCollection(payment.id)}
                        // onCancel={cancel}
                        okText={Translate("Yes")}
                        cancelText={Translate("Cancel")}

                    >
                        <Button icon={<DeleteOutlined />} />
                    </Popconfirm>
                    <Tooltip placement="top" color="blue" title={Translate("CollectionDetail")}>
                        <AntsButton icon={<AppstoreOutlined />} onClick={() => showPaymentDetail(payment)} />
                    </Tooltip>

                </>
        },
    ];

    const rowSelection = {
        selectedRowKeys: selectedRow.map(x => x.id),
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setSelectedRow(selectedRows)
        },
        // getCheckboxProps: (order: Siparis) => ({
        //     disabled: order.durum === SiparisDurumu.confirmed, // BU KOD DİSABLED YAPAR
        // }),
    };



    const orderStatusMenu = () => (
        <Menu>
            <Menu.Item key="0" onClick={() => changeCollectionStatus(PaymentStatus.Confirmed)}><a>{TranslateJSX("Confirm")}</a></Menu.Item>
            <Menu.Item key="1"><a onClick={() => changeCollectionStatus(PaymentStatus.Cancel)}>{TranslateJSX("Reject")}</a></Menu.Item>
        </Menu>
    );


    const header = (
        <Row style={{ height: "60px" }}>
            <Col lg={5} md={5} >
                <Space>
                    <Dropdown overlay={orderStatusMenu} trigger={['click']}>
                        <AntsButton className="ant-dropdown-link"> {Translate("Status")}<DownOutlined /></AntsButton>
                    </Dropdown>
                    <AntsButton className="ant-dropdown-link" onClick={erpTransfer} >{Translate("ErpTransfer")}</AntsButton>
                </Space>
            </Col>
            <Col lg={17} md={17} >
                <Form

                    form={paymentFilterForm}
                    size="small"
                    onFinish={searchToCollections}
                >
                    <Row>
                        <Col lg={24}>
                            <Row>
                                <Col style={{ height: '30px' }} span={24}>
                                    <Form.Item style={{ marginBottom: 0 }}>
                                        <Form.Item
                                            labelAlign='left'
                                            style={{ display: 'inline-block', marginRight: '5px' }}
                                            name="IsActiveDateFilter"
                                            initialValue={dateFilterDisabled}
                                            valuePropName="unchecked"
                                        >
                                            <Checkbox onChange={(e) => {
                                                if (e.target.checked)
                                                    setDateFilterDisabled(false)
                                                else {
                                                    setDateFilterDisabled(true)
                                                    paymentFilterForm.setFieldsValue({ startDate: "", endDate: "" })
                                                }
                                            }} ></Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            initialValue={initial_paymentFilter.startDate}
                                            name="startDate"
                                            style={{ display: 'inline-block', marginRight: '5px' }}
                                        >
                                            <DatePicker disabled={dateFilterDisabled} placeholder={Translate("StartDate")} />
                                        </Form.Item>

                                        <Form.Item
                                            initialValue={initial_paymentFilter.endDate}
                                            name="endDate"
                                            style={{ display: 'inline-block', marginRight: '5px' }}>
                                            <DatePicker disabled={dateFilterDisabled} placeholder={Translate("EndDate")} />
                                        </Form.Item>
                                        <FormItem
                                            name="searchColumns"
                                            initialValue={["FicheNo"]}
                                            style={{ display: 'inline-block', width: '150px', marginRight: '5px' }}
                                        >
                                            <Select
                                                size="small"
                                                mode="multiple"
                                                placeholder="Search Columns"
                                                maxTagCount="responsive"
                                            >
                                                {["FicheNo", "Description", "Client.Description"].map((x, i) =>
                                                    <Select.Option key={i} value={String(x)}>{Translate(x)} </Select.Option>
                                                )}
                                            </Select>
                                        </FormItem>
                                        <Form.Item
                                            name="searchValue"
                                            initialValue={paymentFilter.searchValue}
                                            style={{ display: 'inline-block', width: "", marginRight: '5px' }}

                                        >
                                            <Input.Search

                                                name="searchValue"
                                                placeholder={Translate("Search")}
                                                allowClear
                                                enterButton={<Button
                                                    onKeyPress={event => event.key === 'Enter'}

                                                    style={{ background: "rgb(24 144 255)", color: "#fff" }} htmlType="submit">{Translate('Search')}
                                                </Button>}
                                                size="small"
                                            />

                                        </Form.Item>
                                    </Form.Item>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Col >
            <Col lg={2} md={2}>
                <Button style={{ float: "right" }} type="primary" className="ml-auto mb-1" onClick={() => {
                    paymentForm.resetFields()
                    setFlags({ ...flags, paymentModalVisible: true, operation: "Add" })
                }}
                    icon={<PlusSquareOutlined style={{ verticalAlign: 'middle', fontSize: '20px' }} />} >
                    <span style={{ verticalAlign: 'middle' }} >{Translate("Add")}</span>
                </Button>
            </Col>

        </Row >

    );

    return (
        <div>
            <Tabs defaultActiveKey="1"
                tabBarStyle={{ marginBottom: "5px" }}
            >
                <Tabs.TabPane tab={<span><UserOutlined />{Translate("CollectionList")}</span>} key="1">
                    {header}
                    <div className="space-5"></div>
                    <Spin
                        size='large'
                        tip={Translate('Loading')}
                        spinning={flags.loading}>
                        <Table
                            bordered
                            rowKey="id"
                            size='small'
                            rowSelection={rowSelection}
                            columns={columns}
                            dataSource={payments}
                            pagination={pagination}
                            onChange={onChangeTable}
                        />
                    </Spin>

                </Tabs.TabPane>
            </Tabs>,


            <Drawer
                placement="right"
                width={1000}
                onClose={() => setFlags({ ...flags, paymentDetailVisible: false })}
                visible={flags.paymentDetailVisible}
            >
                <PaymentDetails payment={paymentDetail} />
            </Drawer>


            <Modal
                title={Translate("CollectionFiche")}
                width={600}
                visible={flags.paymentModalVisible}
                onCancel={() => setFlags(({ ...flags, paymentModalVisible: false }))}
                onOk={() => { paymentForm.submit() }}
                okText={Translate(flags.operation)}
                confirmLoading={flags.loading}
            >

                <Form
                    form={paymentForm}
                    id={"itemForm"}
                    layout={"horizontal"}
                    onFinish={addOrUpdateCollection}
                    {...formItemLayout}
                    size={"small"}
                    labelAlign={"left"}
                >
                    <Form.Item
                        name={"id"}
                        fieldKey={"id"}
                        hidden={true}
                        initialValue={0} >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={"paymentMethod"}
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                        label={Translate("CollectionMethod")}
                    >
                        <Select
                            onChange={(value) => {
                                let visible = flags.formItem_Bank_Visible;
                                if (flags.operation == "Add")  //Ekleme işlemi İse yeni bir FişNo oluştur
                                    getNewFicheNo(value as PaymentMethod);
                                else if (flags.operation == "Update") {
                                    const ficheNo = paymentForm.getFieldValue("ficheNo")
                                    switch (value) {
                                        case PaymentMethod.CreditCard: paymentForm.setFieldsValue({ ficheNo: String(ficheNo).substring(0, 3) + 'KK' + String(ficheNo).substring(3).match(/\d/g) });
                                            break;
                                        case PaymentMethod.Cash: paymentForm.setFieldsValue({ ficheNo: String(ficheNo).substring(0, 3) + 'P' + String(ficheNo).substring(3).match(/\d/g) }); break;
                                        case PaymentMethod.Remittance: paymentForm.setFieldsValue({ ficheNo: String(ficheNo).substring(0, 3) + 'H' + String(ficheNo).substring(3).match(/\d/g) }); break;
                                    }
                                }


                                switch (value) {
                                    case PaymentMethod.Remittance:
                                    case PaymentMethod.CreditCard:
                                        paymentForm.resetFields(["bankId", "iban", "provisionCode"])
                                        getBankByPaymentMethod(value)
                                        visible = false;
                                        break;
                                    case PaymentMethod.Cash: visible = true; break;
                                }
                                const collectionType = paymentTypes.find(x => x.method == value)
                                paymentForm.setFieldsValue({ collectionTypeId: collectionType?.id });

                                setFlags(flag => ({ ...flag, formItem_Bank_Visible: visible }))
                            }}
                            placeholder={Translate("PleaseSelect")}>
                            <Option key={1} value={PaymentMethod.Cash}>{`${Translate("Payment." + PaymentMethod[PaymentMethod.Cash])}`}</Option>
                            <Option key={2} value={PaymentMethod.Remittance}>{`${Translate("Payment." + PaymentMethod[PaymentMethod.Remittance])}`}</Option>
                            <Option key={3} value={PaymentMethod.CreditCard}>{`${Translate("Payment." + PaymentMethod[PaymentMethod.CreditCard])}`}</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name={"collectionTypeId"} hidden={true}  ><Input /></Form.Item>

                    <Form.Item
                        label={Translate("FicheNo")}
                        name="ficheNo"
                        rules={[{ required: true, message: Translate("RequiredField") }]}

                    >
                        <Input readOnly placeholder={Translate('FicheNo')} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("Date")}
                        initialValue={moment()}
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                        name="date_"
                    >
                        <DatePicker showTime placeholder={Translate("Date")} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("Amount")}
                        name="amount"
                        initialValue={0}
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <InputNumber placeholder={Translate('Amount')} min={0} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("Bank")}
                        name={"bankId"}
                        rules={[{ required: flags.formItem_Bank_Visible ? false : true, message: Translate("RequiredField") }]}
                        hidden={flags.formItem_Bank_Visible}
                    >
                        <Select
                            onChange={(value) => {
                                const bank = banks.find(x => x.id == value)
                                paymentForm.setFieldsValue({ iban: bank?.iban, bankDefination: bank?.name });
                            }}
                            placeholder={Translate("PleaseSelect")}>
                            {banks.map(x => (
                                <Select.Option key={x.id} value={x.id}>{`${x.name}`}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name={"bankDefination"} hidden={true} ><Input /></Form.Item>
                    <Form.Item
                        label={Translate("Iban")}
                        name="iban"
                        rules={[{ required: flags.formItem_Bank_Visible ? false : true, message: Translate("RequiredField") }]}
                        hidden={flags.formItem_Bank_Visible}
                    >
                        <Input readOnly placeholder={Translate('Iban')} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("ProvisionCode")}
                        name="provisionCode"
                        // rules={[{ required: true, message: Translate("RequiredField") }]}
                        hidden={flags.formItem_Bank_Visible}
                    >
                        <Input readOnly placeholder={Translate('ProvisionCode')} />
                    </Form.Item>

                    <Form.Item

                        label={Translate("Client")}
                        name={"clientId"}
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Select
                            showSearch
                            placeholder={Translate("PleaseSelect")}
                            optionFilterProp="children"
                            onChange={(value) => {
                                const client = clients.find(x => x.id == value)
                                paymentForm.setFieldsValue({ clientDescription: client?.description, clientCode: client?.code });
                            }}
                            filterOption={(input, option) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase().trim()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase().trim())
                            }
                        >
                            {clients.map(x => (
                                <Select.Option key={x.id} value={x.id}>{`${x.description}`}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name={"clientDescription"} hidden={true} ><Input /></Form.Item>
                    <Form.Item name={"clientCode"} hidden={true} ><Input /></Form.Item>

                    <Form.Item
                        label={Translate("Description")}
                        name="description"
                    >
                        <Input.TextArea rows={2} placeholder={Translate("Description")} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("DocumentNo")}
                        name="documentNo"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate('DocumentNo')} />
                    </Form.Item>
                    <Form.Item name={"status"} hidden={true} initialValue={PaymentStatus.WaitForApproval}><Input /></Form.Item>
                    <Form.Item name={"erpStatus"} hidden={true} initialValue={ErpStatus.NotTransferred} ><Input /></Form.Item>
                    <Form.Item name={"salesmanId"} hidden={true} > <Input /></Form.Item>
                    <Form.Item name={"clientUserId"} hidden={true} > <Input /></Form.Item>
                    <Form.Item name={"netahsilatGuid"} hidden={true} initialValue={""} > <Input /></Form.Item>

                </Form>
            </Modal>

        </div >
    );
};
