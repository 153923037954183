
import { UserType } from '../enum/enumB2B';
import { request } from './request';
import jwtDecode from 'jwt-decode';


//LOGİN İŞLEMLERİ HEM CLİENTUSER VE SALESMAN İÇİN BİRLİKTE YAPILMISTIR

export async function loginClient(user) {
    return await request(`auth/login/clientUser`, 'POST', user);
}
export async function loginFirstStepForSalesman(user) {
    return await request(`auth/firstStepForLogin/salesman`, 'POST', user);
}
export async function loginSalesman(user) {
    return await request(`auth/login/salesman/chooseClient`, 'POST', user);
}


export async function logoutUserB2B() {
    localStorage.removeItem('s_id');
    localStorage.removeItem('s_name');
    localStorage.removeItem('s_clientId');
    localStorage.removeItem('s_token');
    localStorage.removeItem('s_firmNumber');
    localStorage.removeItem('s_periodNumber');
    localStorage.removeItem('s_userType');

}


//Hem Cari Veya Salesman Tüm Degerleri ile aktif iken tüm sayfalar için Login olarak gözükür.
export function isUserLogin() {
    const clientId = Number(localStorage.getItem('s_clientId'))
    const token = localStorage.getItem('s_token')
    if ((token != null && clientId > 0)) {
        return true;
    }
    else {
        console.log("cari kullanıcı ve salesman giriş yapmadı")
        return false
    }
}


export function loginUI(jwtToken) {
    const result = jwtDecode(jwtToken.token);
    debugger;
    localStorage.setItem('s_id', result["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]);
    localStorage.setItem('s_name', result["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"]);
    localStorage.setItem('s_clientId', result.ClientId.toString());
    localStorage.setItem('s_userType', UserType[result.UserType]);
    localStorage.setItem('s_token', jwtToken.token);
    localStorage.setItem('s_firmNumber', result.FirmNumber.toString());
    localStorage.setItem('s_periodNumber', result.PeriodNumber.toString());
    localStorage.setItem('s_rolls', JSON.stringify(result.operationsClaims));

}

export function getUserInfo() {
    if (!(isUserLogin() || firstStepLoginSalesmanStatus())) {
        return null;
    } else {
        return {
            id: Number(localStorage.getItem('s_id')),
            name: String(localStorage.getItem('s_name')),
            clientId: Number(localStorage.getItem('s_clientId')),
            token: String(localStorage.getItem('s_token')),
            firmNumber: Number(localStorage.getItem('s_firmNumber')),
            periodNumber: Number(localStorage.getItem('s_periodNumber')),
            userType: Number(localStorage.getItem('s_userType'))
        }
    }
}

//BU METHODLAR SALESMAN'DA 2 Adımlı dogrulama oldugu için Kullanılmıstır.
//Kullanıcı tipi salesman ise ve clientId var ise o zaman birinci login olma adımını geçmiştir Users can see clientList
export function firstStepLoginSalesmanStatus() {
    const userType = localStorage.getItem('s_userType')
    const clientId = Number(localStorage.getItem('s_clientId'))
    if (userType == "0" && clientId == 0)  //0 Salesman demektir.
    {
        console.log("satış elemanı birinci girişi yaptı")
        return true;
    }
    else
        return false;
}

export function finallyStepLoginSalesmanStatus() {
    const userType = Number(localStorage.getItem('s_userType'))
    const clientId = Number(localStorage.getItem('s_clientId'))
    if (userType == UserType.Salesman && clientId > 0) {
        return true;
    }
    else
        return false;
}

export function getSalesmanInfo() {
    return {
        id: Number(localStorage.getItem('s_id')),
        name: String(localStorage.getItem('s_name')),
        firmNumber: Number(localStorage.getItem('s_firmNumber')),
        periodNumber: Number(localStorage.getItem('s_periodNumber')),
        userType: Number(localStorage.getItem('s_userType'))
    }
}




