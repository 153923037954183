import { ClientAddress } from "../models/models";
import { ApiResponse, request } from "./api";


export async function getListByClientId(clientId: number): Promise<ApiResponse<ClientAddress[]>> {
    return await request<ClientAddress[]>(`clientAddresses/getListByClientId/${clientId}`);
}

export async function add(model: ClientAddress): Promise<ApiResponse<ClientAddress>> {
    return await request<ClientAddress>(`clientAddresses/add`, "POST", model);
}

export async function deleteClientAddress(id: number): Promise<ApiResponse<ClientAddress>> {
    return await request<ClientAddress>(`clientAddresses/delete/${id}`, "DELETE");
}

export async function update(model: ClientAddress): Promise<ApiResponse<ClientAddress>> {
    return await request<ClientAddress>("clientAddresses/update", "PATCH", model);
}
