import { CURRENCY_CHANGE, CHANGE_DIGIT } from './currencyActionTypes';
import { getAllPeriodParameters as getAllParameters_API } from '../../api_js/parameters';


// eslint-disable-next-line import/prefer-default-export
export const currencyChange = (currency) => ({
    type: CURRENCY_CHANGE,
    currency,
});

export const changeDigit = (priceDecimalDigit) => ({
    type: CHANGE_DIGIT,
    priceDecimalDigit: priceDecimalDigit,
});



export const changeCurrencyDecimalPlaces = () => {
    return (dispatch) => getAllParameters_API()
        .then(response => {
            if (response.ok) {
                dispatch(changeDigit(response.data.priceDecimalDigit));
            } else {
                console.log(response.message);
            }
        })
        .catch(err => console.log(err));
}