import { CURRENCY_CHANGE, CHANGE_DIGIT } from "./currencyActionTypes";




const initialState = {
    code: "TL",
    symbol: "₺",
    name: "Turkish Lira",
    priceDecimalDigit: 2
};

export default function currencyReducer(state = initialState, action) {
    if (action.type === CURRENCY_CHANGE && state.code !== action.currency.code) {
        return JSON.parse(JSON.stringify(action.currency));
    } else if (action.type === CHANGE_DIGIT) {
        state.priceDecimalDigit = action.priceDecimalDigit;
        return state
    }
    return state;
}