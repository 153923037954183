import './Items.css';
import React, { useState, useEffect } from 'react';
import { ItemPrices } from '../itemPrices/ItemPrices';
import { ItemAmounts } from '../itemAmounts/ItemAmounts';
import { Images } from '../Images';
import { useIntl } from 'react-intl';
import TextArea from 'antd/lib/input/TextArea';
import { SpecialCodes } from '../ItemSpecialCodes';
import * as dbItem from '../../../../../api_ts/item';
import * as dbGroupCode from '../../../../../api_ts/groupCode';
import * as dbUnitSet from '../../../../../api_ts/unitSet';
import { Item, UnitSet, GroupCode, ItemWarehouse } from '../../../../../models/models';
import { tableColumnTextFilterConfig } from '../../../components/AntHelpers/TableHelpers';
import { Tabs, Modal, Table, Input, Button, Tooltip, message, Form, InputNumber, Popconfirm, Select, Checkbox } from 'antd';
import { ProfileOutlined, PlusSquareOutlined, ExperimentOutlined, FileImageOutlined, DollarOutlined, OneToOneOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Translate } from '../../../i18n/Translate';

export const Items: React.FC = () => {

    const intl = useIntl();
    const [flags, setFlags] = useState({ loading: false, itemModalVisible: false, operation: "None" });
    const [itemForm] = Form.useForm();
    const [items, setItems] = useState<Item[]>([]);
    const [unitSets, setUnitSets] = useState<UnitSet[]>([]);
    const [groupCodes, setGroupCodes] = useState<GroupCode[]>([]);
    const formItemLayout = { labelCol: { span: 7 }, wrapperCol: { span: 17 } };

    useEffect(() => {
        getAllItems()
    }, []);


    const [specialCodeVisible, setSpecialCodeVisible] = useState(false);
    const [itemPricesVisible, setItemPricesVisible] = useState(false);
    const [imagesVisible, setImagesVisible] = useState(false);
    const [amountsVisible, setAmountsVisible] = useState(false);

    const [itemIdSpecialCodes, setItemIdSpecialCodes] = useState<number>(0);
    const [itemIdPrices, setItemIdPrices] = useState<number>(0);
    const [itemIdImages, setItemIdImages] = useState<number>(0);
    const [itemIdAmounts, setItemIdAmounts] = useState<number>(0);



    const addOrUpdateItem = async (item: Item) => {
        let success: boolean = false;
        if (item.id === 0) {
            success = (await dbItem.add(item)).ok;
        } else {
            success = (await dbItem.update(item)).ok;
        }
        if (success) {
            setFlags(({ ...flags, itemModalVisible: false }))
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            getAllItems();
        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }

    const deleteItem = async (itemId: number) => {
        const response = await dbItem.deleteItem(itemId);

        if (response.ok) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            getAllItems();
        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }

    const fillItemForm = (item: Item) => {
        console.log(item)
        itemForm.resetFields();
        setFlags(({ ...flags, itemModalVisible: true, operation: "Update" }));
        itemForm.setFieldsValue(item);
    }

    const handleSpecialCodesButtonClick = (item: Item) => {
        setItemIdSpecialCodes(item.id);
        setSpecialCodeVisible(true);
    }

    const handleItemPricesButtonClick = (item: Item) => {
        setItemIdPrices(item.id);
        setItemPricesVisible(true);
    }

    const handleImagesButtonClick = (item: Item) => {
        setItemIdImages(item.id);
        setImagesVisible(true);
    }

    const handleAmountsButtonClick = (item: Item) => {
        setItemIdAmounts(item.id);
        setAmountsVisible(true);
    }


    const getAllItems = () => {
        dbItem.getListWithWarehouse()
            .then(response => {
                if (response.ok) {
                    setItems(response.data)
                }
                else
                    console.log(response.message)
            })
            .catch(err => console.log(err.message));

        dbUnitSet.getList()
            .then(response => {
                if (response.ok) setUnitSets(response.data);
                else
                    console.log(response.message)
            })
            .catch(err => console.log(err.message));

        dbGroupCode.getList()
            .then(response => {
                if (response.ok) setGroupCodes(response.data);
                else
                    console.log(response.message)
            })
            .catch(err => console.log(err.message));

        setItemPricesVisible(false);
        setSpecialCodeVisible(false);
    }

    // const itemOperationsMenu = (
    //     <Menu>
    //         <Menu.Item key="0">
    //             <a href="https://www.antgroup.com">1st menu item</a>
    //         </Menu.Item>
    //         <Menu.Item key="1">
    //             <a href="https://www.aliyun.com">2nd menu item</a>
    //         </Menu.Item>
    //         <Menu.Divider />
    //         <Menu.Item key="3">3rd menu item</Menu.Item>
    //     </Menu>
    // );

    const itemColumns = [
        {
            title: Translate("Code"),
            dataIndex: 'code',
            width: '10%',
            ...tableColumnTextFilterConfig<Item>(),
            onFilter: (value: any, item: Item) => item.code.toString().toLowerCase().includes(value.toString().toLowerCase())
        },
        {
            title: Translate("Description"),
            dataIndex: 'description',
            width: '10%',
            ...tableColumnTextFilterConfig<Item>(),
            onFilter: (value: any, item: Item) => item.description.toString().toLowerCase().includes(value.toString().toLowerCase())
        },
        {
            title: Translate("Description2"),
            dataIndex: 'description2',
            width: '10%',
            ...tableColumnTextFilterConfig<Item>(),
            onFilter: (value: any, item: Item) => item.description2.toString().toLowerCase().includes(value.toString().toLowerCase())
        },
        // {
        //     title: Translate("Description3"),
        //     dataIndex: 'description3',
        //     width: '10%',
        //     ...tableColumnTextFilterConfig<Item>(),
        //     onFilter: (value: any, item: Item) => item.description3.toString().toLowerCase().includes(value.toString().toLowerCase())
        // },
        {
            title: Translate("GroupCode"),
            width: '5%',
            render: (text: string, item: Item) => groupCodes.filter(x => x.id === item.groupCodeId).map(x => x.description),
        },
        {
            title: Translate("UnitSet"),
            width: '5%',
            render: (text: string, item: Item) => unitSets.filter(x => x.id === item.unitSetId).map(x => x.description)
        },
        {
            title: Translate("BonusPointFactor"),
            width: '5%',
            dataIndex: 'bonusPointFactor',
        },
        {
            title: Translate("MiktarFiili"),
            width: '5%',
            render: (text: string, item: Item) => item.itemWarehouses.filter(x => !!x.warehouse.isActive && x.warehouse.b2bStatus).reduce((acc: number, c: ItemWarehouse) => acc + c.miktarFiili, 0)
        },
        {
            title: Translate("MiktarSevkedilebilir"),
            width: '5%',
            render: (text: string, item: Item) => item.itemWarehouses.filter(x => !!x.warehouse.isActive && x.warehouse.b2bStatus).reduce((acc: number, c: ItemWarehouse) => acc + c.miktarSevkedilebilir, 0)
        },
        {
            title: Translate("MiktarGercek"),
            width: '5%',
            render: (text: string, item: Item) => item.itemWarehouses.filter(x => !!x.warehouse.isActive && x.warehouse.b2bStatus).reduce((acc: number, c: ItemWarehouse) => acc + c.miktarGercek, 0)
        },
        {
            title: Translate("Status"),
            width: '5%',
            render: (text: string, item: Item) => item.isActive ? "Aktif" : "Pasif",
            filters: [{ text: 'Aktif', value: true }, { text: 'Pasif', value: false }],
            onFilter: (value: any, item: Item) => item.isActive == value
        },
        {
            key: 'id',
            width: '10%',
            render: (text: string, malzeme: Item) =>
                <>
                    <Tooltip placement="top" color="blue" title={Translate("ItemAmounts")}>
                        <Button icon={<ExperimentOutlined />} onClick={() => handleAmountsButtonClick(malzeme)} />
                    </Tooltip>
                    <Tooltip placement="top" color="blue" title={Translate("Images")}>
                        <Button icon={<FileImageOutlined />} onClick={() => handleImagesButtonClick(malzeme)} />
                    </Tooltip>
                    <Tooltip placement="top" color="blue" title={Translate("Prices")}>
                        <Button icon={<DollarOutlined />} onClick={() => handleItemPricesButtonClick(malzeme)} />
                    </Tooltip>
                    <Tooltip placement="top" color="blue" title={Translate("SpecialCodes")}>
                        <Button icon={<OneToOneOutlined />} onClick={() => handleSpecialCodesButtonClick(malzeme)} />
                    </Tooltip>
                    <Tooltip placement="top" color="blue" title={Translate("Edit")}>
                        <Button icon={<EditOutlined />} onClick={() => fillItemForm(malzeme)} />
                    </Tooltip>
                    <Tooltip placement="top" color="red" title={Translate("Delete")}>
                        <Popconfirm
                            title="Silmek İstediğinize Emin Misiniz ?"
                            onConfirm={() => {
                                deleteItem(malzeme.id)
                            }}
                            okText={Translate("Yes")}
                            cancelText={Translate("No")}
                        >
                            <Button icon={<DeleteOutlined />}></Button>
                        </Popconfirm>
                    </Tooltip>
                </>
        }
    ];


    const header = (<Button type="primary" className="ml-auto mb-1" onClick={() => {
        if (flags.operation == "Update") {
            itemForm.resetFields();
        }
        setFlags({ ...flags, itemModalVisible: true, operation: "Add" })
    }}
        icon={<PlusSquareOutlined style={{ verticalAlign: 'middle', fontSize: '20px' }} />} >
        <span style={{ verticalAlign: 'middle' }} >{Translate("Add")}</span>
    </Button>)

    return (
        <>
            <Tabs
                defaultActiveKey="1"
                tabBarStyle={{ marginBottom: "3.5px" }}
            >
                <Tabs.TabPane tab={<span><ProfileOutlined />{Translate("Item")}</span>} key="1">
                    <div className="site-card-border-less-wrapper">
                        <div className="d-flex">{header} </div>
                        <Table bordered size="small" columns={itemColumns} dataSource={items} />
                    </div>
                </Tabs.TabPane>
            </Tabs>
            <Modal
                title="Malzeme"
                width={600}
                visible={flags.itemModalVisible}
                onCancel={() => setFlags(({ ...flags, itemModalVisible: false }))}
                onOk={() => { itemForm.submit() }}
                okText={Translate(flags.operation)}
                confirmLoading={flags.loading}
            >

                <Form
                    form={itemForm}
                    id={"itemForm"}
                    layout={"horizontal"}
                    onFinish={addOrUpdateItem}
                    {...formItemLayout}
                    size={"small"}
                    labelAlign={"left"}
                >
                    <Form.Item
                        name={"id"}
                        fieldKey={"id"}
                        hidden={true}
                        initialValue={0} >
                        <Input />
                    </Form.Item>
                    <Form.Item name={"idERP"} fieldKey={"idERP"} hidden={true}  > <Input /> </Form.Item>
                    <Form.Item
                        label={Translate("Code")}
                        name="code"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate("Code")} />
                    </Form.Item>

                    <Form.Item
                        label={Translate("Description")}
                        name="description"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <TextArea rows={2} placeholder={Translate("Description")} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("Description-2")}
                        name="description2"
                    >
                        <TextArea rows={2} placeholder={Translate("Description-2")} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("Description-3")}
                        name="description3"
                    >
                        <TextArea rows={2} placeholder={Translate("Description-3")} />
                    </Form.Item>
                    <Form.Item
                        name={"unitSetId"}
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                        label={Translate("UnitSet")}
                    >
                        <Select placeholder={Translate("PleaseSelect")}>
                            {unitSets.map(unitSet => (
                                <Select.Option key={unitSet.id} value={unitSet.id}>{`${unitSet.description}`}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={Translate("GroupCode")}
                        name={"groupCodeId"}
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Select placeholder={Translate("PleaseSelect")}>
                            {groupCodes.map(group => (
                                <Select.Option key={group.id} value={group.id}>{`${group.description}`}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={Translate("BonusPointFactor")}
                        name="bonusPointFactor"
                        initialValue={0}
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <InputNumber min={0}
                            placeholder={Translate('BonusPointFactor')}
                        // formatter={value => `${value}%`}
                        // parser={value => value.replace('%', '')}
                        />
                    </Form.Item>
                    <Form.Item
                        label={Translate("VAT")}
                        name="vat"
                        initialValue={0}
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <InputNumber<number>
                            placeholder={Translate('VAT')}
                            min={0}
                            max={18}
                            formatter={value => `${value}%`}
                            parser={value => Number(value?.replace('%', ''))}
                        />
                    </Form.Item>
                    <Form.Item
                        label={Translate("ReturnVAT")}
                        name="returnOfVAT"
                        initialValue={0}
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <InputNumber<number>
                            placeholder={Translate('ReturnVAT')}
                            min={0}
                            max={18}
                            formatter={value => `${value}%`}
                            parser={value => Number(value?.replace('%', ''))}
                        />
                    </Form.Item>

                    <Form.Item
                        label={Translate("SellVAT")}
                        name="sellVAT"
                        initialValue={0}
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <InputNumber<number>
                            placeholder={Translate('SellVAT')}
                            min={0}
                            max={18}
                            formatter={value => `${value}%`}
                            parser={value => Number(value?.replace('%', ''))}
                        />
                    </Form.Item>

                    <Form.Item
                        label={Translate("Surtax")}
                        name="surtax"
                        initialValue={0}
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <InputNumber min={0} placeholder={Translate('Surtax')} />
                    </Form.Item>

                    <Form.Item
                        label={Translate("Width")}
                        name="width"
                        initialValue={0}
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <InputNumber min={0} placeholder={Translate('Width')} />
                    </Form.Item>

                    <Form.Item
                        label={Translate("Height")}
                        name="height"
                        initialValue={0}
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <InputNumber min={0} placeholder={Translate('Height')} />
                    </Form.Item>

                    <Form.Item
                        label={Translate("Size")}
                        name="size"
                        initialValue={0}
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <InputNumber min={0} placeholder={Translate('Size')} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("Area")}
                        name="area"
                        initialValue={0}
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <InputNumber min={0} placeholder={Translate('Area')} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("GrossVolume")}
                        name="grossVolume"
                        initialValue={0}
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <InputNumber min={0} placeholder={Translate('GrossVolume')} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("NetVolume")}
                        name="netVolume"
                        initialValue={0}
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <InputNumber min={0} placeholder={Translate('NetVolume')} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("GrossWeight")}
                        name="grossWeight"
                        initialValue={0}
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <InputNumber min={0} placeholder={Translate('GrossWeight')} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("NetWeight")}
                        name="netWeight"
                        initialValue={0}
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <InputNumber min={0} placeholder={Translate('NetWeight')} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("BrandCode")}
                        name="brandCode"
                    >
                        <Input placeholder={Translate('BrandCode')} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("MainBarcode")}
                        name="mainBarcode"
                    >
                        <Input placeholder={Translate('MainBarcode')} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("Barcode2")}
                        name="Barcode2"
                    >
                        <Input placeholder={Translate("Barcode2")} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("Barcode3")}
                        name="Barcode3"
                    >
                        <Input placeholder={Translate('Barcode3')} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("MadeIn")}
                        name="madeIn"
                    >
                        <Input placeholder={Translate('MadeIn')} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("Active")}
                        name="isActive"
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Checkbox ></Checkbox>
                    </Form.Item>
                </Form>
            </Modal>


            <Modal
                centered visible={itemPricesVisible}
                footer={null}
                onCancel={() => setItemPricesVisible(false)}
                width={1000}>
                <ItemPrices malzemeId={itemIdPrices} />
            </Modal>
            <Modal centered visible={specialCodeVisible} onOk={() => setSpecialCodeVisible(false)} okText={Translate("Save")} onCancel={() => setSpecialCodeVisible(false)} cancelText={Translate("Cancel")} width={1000}>
                <SpecialCodes malzemeId={itemIdSpecialCodes} />
            </Modal>
            <Modal centered visible={imagesVisible} onOk={() => setImagesVisible(false)} okText={Translate("Save")} onCancel={() => setImagesVisible(false)} cancelText={Translate("Cancel")} width={1000}>
                <Images malzemeId={itemIdImages} />
            </Modal>
            <Modal centered visible={amountsVisible} okButtonProps={{ style: { display: 'none' } }} onCancel={() => setAmountsVisible(false)} cancelText={Translate("Cancel")} width={1000}>
                <ItemAmounts malzemeId={itemIdAmounts} />
            </Modal>
        </>
    );
};
