import * as React from 'react';
import { CariKullaniciYetkiGrubu, ClientUser } from '../../../../models/models';
import * as DbClientUser from '../../../../api_ts/clientUser';
import { useIntl } from 'react-intl';
import { Translate } from '../../i18n/Translate';
import { Tabs, Table, Input, Button, Tooltip, message, Card, Form } from 'antd';
import { EditOutlined, MailOutlined, UserOutlined, DeleteOutlined, FileDoneOutlined, FileAddTwoTone, LockOutlined } from '@ant-design/icons';


interface Props {
    clientId: number;
}

export const ClientUsers: React.FC<Props> = ({ clientId }) => {

    const intl = useIntl();
    const [clientUsers, setClientUsers] = React.useState<ClientUser[]>([]);
    const [clientUserForm] = Form.useForm();
    const formItemLayout = { labelCol: { span: 7 }, wrapperCol: { span: 17 } };
    const [flags, setFlags] = React.useState({ activeTabKey: "1", operation: "Add", title: "", loading: false });


    React.useEffect(() => {
        getAllClientUsers()
        resetOperation()
    }, [clientId]);


    const deleteClientUser = async (value: number) => {
        const response = await DbClientUser.deleteClientUser(value);
        if (response.ok) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            getAllClientUsers();
            resetOperation()
        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }
    const resetOperation = () => {
        clientUserForm.resetFields();
        setFlags({ ...flags, activeTabKey: "1", operation: "Add" })
    }

    const fillClientUserForm = (clientUser: ClientUser) => {
        clientUserForm.setFieldsValue(clientUser);
        clientUserForm.setFieldsValue({ confirmPassword: clientUser.password });//Burada confirmPassword Doldurduk
        setFlags({ ...flags, activeTabKey: "2", operation: "Update" })
    }

    const columns = [
        {
            title: Translate("Name"),
            dataIndex: 'firstName',
        },
        {
            title: Translate("LastName"),
            dataIndex: 'lastName',
        },
        {
            title: Translate("Email"),
            dataIndex: 'email',
        },
        {
            title: Translate("Username"),
            dataIndex: 'username',
        },
        {
            title: Translate("Password"),
            dataIndex: 'password',
        },
        // {
        //     title: Translate("YetkiGrubu"),
        //     dataIndex: 'yetkiGrubu',
        //     render: (text: string, record: CariKullanici) => Translate(CariKullaniciYetkiGrubu[record.yetkiGrubu])
        // },
        {
            key: 'x',
            width: 100,
            render: (text: string, record: ClientUser) =>
                <>
                    <Tooltip placement="top" color="blue" title={Translate("Edit")}>
                        <Button icon={<EditOutlined />} onClick={() => fillClientUserForm(record)} />
                    </Tooltip>
                    <Tooltip placement="top" color="red" title={Translate("Delete")}>
                        <Button icon={<DeleteOutlined />} onClick={() => deleteClientUser(record.id)} danger />
                    </Tooltip>
                </>
        },
    ];

    const addOrUpdateClientUser = async (clientUser: ClientUser) => {
        let success: boolean = false;
        if (clientUser.id === 0) {
            success = (await DbClientUser.add(clientUser)).ok;
        } else {
            success = (await DbClientUser.update(clientUser)).ok;
        }
        if (success) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            getAllClientUsers();
            resetOperation()
        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }


    const getAllClientUsers = () => {
        DbClientUser.GetListByClientId(clientId)
            .then(response => {
                if (response.ok)
                    setClientUsers(response.data);
                else
                    console.log(response.message)
            })
            .catch(err => console.log(err.message));
    }


    return (
        <>
            <Tabs defaultActiveKey="1" onTabClick={(e) => setFlags({ ...flags, activeTabKey: e })} activeKey={flags.activeTabKey} >
                <Tabs.TabPane tab={<span><FileDoneOutlined />{Translate("ClientUsers")}</span>} key="1">
                    <Table
                        bordered
                        size="small"
                        columns={columns}
                        dataSource={clientUsers}
                        pagination={{ pageSize: 7 }} scroll={{ y: 700 }} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span><FileAddTwoTone />{Translate(flags.operation)}</span>} key="2">
                    <Card
                        bordered={false}
                        size={'small'}
                        style={{ width: "50%" }}
                    >
                        <Form
                            form={clientUserForm}
                            id={"clientForm"}
                            layout={"horizontal"}
                            onFinish={addOrUpdateClientUser}
                            {...formItemLayout}
                            size={"small"}
                            labelAlign={"left"}
                        >
                            <Form.Item
                                name={"id"}
                                hidden={true}
                                initialValue={0} >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={"clientId"}
                                hidden={true}
                                initialValue={clientId} >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={Translate("Name")}
                                name="firstName"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input placeholder={Translate("Name")} />
                            </Form.Item>

                            <Form.Item
                                label={Translate("LastName")}
                                name="lastName"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input placeholder={Translate("LastName")} />
                            </Form.Item>
                            <Form.Item
                                label={Translate("Email")}
                                name="email"
                                rules={[{ required: true, message: Translate("RequiredField") }, { type: 'email', message: Translate("InvalidEmail") }]}
                            >
                                <Input placeholder={Translate("Email")} prefix={<MailOutlined />} />
                            </Form.Item>
                            < Form.Item
                                name={"username"}
                                label={Translate("Username")}
                                rules={[{ required: true, message: Translate("RequiredField") }]}

                            >
                                <Input placeholder={Translate("Username")} prefix={<UserOutlined />} />
                            </Form.Item>
                            <Form.Item
                                label={Translate("Password")}
                                name="password"
                                hasFeedback
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input.Password placeholder={Translate("Password")} prefix={<LockOutlined className="site-form-item-icon" />} />
                            </Form.Item>
                            <Form.Item
                                name="confirmPassword"
                                label={Translate("ConfirmPassword")}
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    { required: true, message: Translate("RequiredField") },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(Translate("PasswordNotMatch")));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder={Translate("Password")} prefix={<LockOutlined className="site-form-item-icon" />} />
                            </Form.Item>
                            <Form.Item {...{ wrapperCol: { offset: 7, span: 17 } }}>
                                <Button type="primary" className="mr-1" htmlType="submit">
                                    {Translate("Save")}
                                </Button>
                                <Button htmlType="button" onClick={resetOperation}>
                                    {Translate("Cancel")}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>


                    {/* <div className="form-group" style={{ marginBottom: '20px' }}>
                        <label>{Translate("YetkiGrubu")}</label>
                        {' '}
                        <Select
                            style={{ width: 200 }}
                            value={clientUser.yetkiGrubu}
                            onChange={value => setClientUser({ ...clientUser, yetkiGrubu: value })}
                        >
                            {Object.keys(CariKullaniciYetkiGrubu).filter(x => !/^\d+$/.test(x)).map(x => (
                                <Select.Option value={CariKullaniciYetkiGrubu[x as any]}>{x}</Select.Option>
                            ))}
                        </Select>
                    </div> */}

                </Tabs.TabPane>
            </Tabs>
        </>
    );
};
