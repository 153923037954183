import { CURRENT_STEP } from './generalActionTypes';

export default function wizardReducer(state = 0, action) {

    switch (action.type) {
        case CURRENT_STEP:
            return action.stepValue;
        default:
            return state;
    }
}