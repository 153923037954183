import {  MailTemplate } from "../models/models";
import { ApiResponse, request } from "./api";

export async function getList(): Promise<ApiResponse<MailTemplate[]>> {
    return await request<MailTemplate[]>("mailTemplates/getList","GET");
}

export async function add(MailTemplate: MailTemplate): Promise<ApiResponse<MailTemplate>> {
    return await request<MailTemplate>("mailTemplates/add", "POST", MailTemplate);
}

export async function update(MailTemplate: MailTemplate): Promise<ApiResponse<MailTemplate>> {
    return await request<MailTemplate>("mailTemplates/update", "PATCH", MailTemplate);
}

export async function deleteMailTemplate(id: number): Promise<ApiResponse<null>> {
    return await request<null>(`mailTemplates/delete/${id}`, "DELETE");
}