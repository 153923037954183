
import * as Icons from '@ant-design/icons';
import * as React from 'react';
import { Backoffice_PaymentDto } from '../../../../models/models'
import { Descriptions, Table, Tabs } from 'antd';
import { useIntl } from 'react-intl';
import { Translate } from '../../i18n/Translate';

interface Props {
    payment: Backoffice_PaymentDto | undefined;
}

export const PaymentDetails: React.FC<Props> = ({ payment }) => {

    const intl = useIntl();

    React.useEffect(() => {

    }, [payment]);

    return (
        <div>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab={<span><Icons.UserOutlined />{Translate("CollectionDetail")}</span>} key="1">
                    <div className="site-card-border-less-wrapper">

                        <Descriptions
                            labelStyle={{ fontWeight: "bold" }}
                            layout="vertical"
                            size="small"
                            bordered
                        >
                            <Descriptions.Item label="Banka">{payment?.bankName}</Descriptions.Item>
                            <Descriptions.Item label="İban">{payment?.iban}</Descriptions.Item>
                            {/* <Descriptions.Item label="Cari Kod">{payment?.clientCode}</Descriptions.Item> */}
                            {/* <Descriptions.Item label="Sipariş Numarası">{payment?.orderNo}</Descriptions.Item> */}
                            <Descriptions.Item label="Belge No">{payment?.documentNo}</Descriptions.Item>
                            <Descriptions.Item label="Bonus Puan">{payment?.bonus}</Descriptions.Item>

                        </Descriptions>
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};
