import * as React from 'react';
import { Link } from 'react-router-dom';
import Indicator from './Indicator';
import { Person20Svg } from '../../svg';
import * as authenticate from '../../api_js/authenticate';
import { withRouter } from 'react-router-dom'
import * as dbNetahsilat from '../../api_js/netahsilat';
import $ from "jquery"
import { FormattedMessage } from 'react-intl';

function IndicatorAccount(props) {

    const logout = async () => {
        authenticate.logoutUserB2B();
        return props.history.push("/account/login")
    }

    const payClientAccount = () => {
        dbNetahsilat.payClientAccount()
            .then(response => {
                if (response.ok) {
                    clientAccountPaymentForm(response.data.data, response.data.hash)
                } else {
                    console.log(response.message);
                }
            })
            .catch(err => console.log(err));
    }

    const clientAccountPaymentForm = (data, hash) => {
        let form = $("<form action='https://odeme.oguz.com.tr/hpm/json' method='post' id='paymentpage' name='paymentpage' enctype='application/x-www-form-urlencoded'></form>");
        let dataInput = $("<input/>", { type: 'hidden', id: 'DATA', name: 'Data', value: data })
        let hashInput = $(`<input  type='hidden' id='HASH' name='HASH' value="${hash}"/>`)
        form.append(dataInput);
        form.append(hashInput);
        $("body").append(form);
        document.forms['paymentpage'].submit();
    }



    React.useEffect(() => console.log("2"),

        // DbCari.ggggggg()
        //     .then(response => {
        //         if (response.ok) {
        //             setCurrents(response.data);
        //             setCurrent(response.data.length > 0 ? response.data[0].id : null);
        //             setCurrentName(response.data.length > 0 ? response.data[0].aciklama : null);
        //         } else console.log(response.message);
        //     })
        //     .catch(err => console.log(err.message)),
        []);


    // const giris = async () => {
    //     if (!current) {
    //         Ants.message.warn('Select current')
    //         return;
    //     }

    //     const response = await authenticate.kullaniciGirisiYap(model);
    //     if (response.ok) {
    //         setLSCurrentName(currentName);
    //     } else {
    //         Ants.message.error('Kullanıcı giriş başarısız');
    //     }
    // }
    const renderDropdown = () => {
        // if (!authenticate.kullaniciGirisiYapildiMi()) return (
        //     <div className="account-menu__form">
        //         <div className="account-menu__form-title"><FormattedMessage id="header.LogIntoYourAccount" /></div>
        //         <div className="form-group">
        //             <label htmlFor="header-signin-email" className="sr-only"><FormattedMessage id="header.Emailaddress" /></label>
        //             <input onChange={(e) => setUser(e.target.value)}
        //                 value={user}
        //                 id="header-signin-email"
        //                 type="text"
        //                 className="form-control form-control-sm"
        //                 placeholder="Email address"
        //             />
        //         </div>
        //         <div className="form-group">
        //             <label htmlFor="header-signin-password" className="sr-only"><FormattedMessage id="header.Password" /></label>
        //             <div className="account-menu__form-forgot">
        //                 <input onChange={(e) => setPassword(e.target.value)}
        //                     value={password}
        //                     id="header-signin-password"
        //                     type="password"
        //                     className="form-control form-control-sm"
        //                     placeholder="Password"
        //                 />
        //                 <Link to="/account/login" className="account-menu__form-forgot-link"><FormattedMessage id="header.Forgot" /></Link>
        //             </div>
        //         </div>
        //         <div className="form-group">
        //             <label htmlFor="header-signin-email" className="sr-only"><FormattedMessage id="header.Current" /></label>
        //             <select
        //                 className="form-control"
        //                 value={current}
        //                 onChange={e => setCurrent(parseInt(e.target.value))}
        //             >
        //                 {currents.map(x => <option value={x.id} key={x.id}>{x.kod} {x.aciklama}</option>)}
        //             </select>
        //         </div>

        //         <div className="form-group account-menu__form-button">
        //             <button onClick={giris} className="btn btn-primary btn-sm"><FormattedMessage id="accountPage.login" /></button>
        //         </div>
        //         <div className="account-menu__form-link">
        //             <Link to="/account/login"><FormattedMessage id="accountPage.CreateAnAccount" /></Link>
        //         </div>
        //     </div>
        // );

        return (
            <div className="account-menu__form">
                {/* <Link to="/account/dashboard" className="account-menu__user"> */}
                <div className="account-menu__user">
                    <div className="account-menu__user-avatar">
                        <img src="/images/avatars/avatar-3.jpg" alt="" />
                    </div>
                    <div className="account-menu__user-info">
                        <div className="account-menu__user-name">{authenticate.getUserInfo()?.name}</div>
                        {/* <div className="account-menu__user-email">{test}</div> */}
                    </div>
                </div>
                <div className="account-menu__divider" />
                <ul className="account-menu__links">
                    {/* <li><Link to="/account/profile">Edit Profile</Link></li> */}
                    <li><Link to="/account/client-extract">{<FormattedMessage id="extract" />}</Link></li>
                    <li><Link to="/salesman/choose-client">{<FormattedMessage id="clientList" />}</Link></li>
                    <li><Link to="#" onClick={payClientAccount}>{<FormattedMessage id="client-payment" />}</Link></li>


                    {/* <li><Link to="/account/addresses">Addresses</Link></li>
                <li><Link to="/account/password">Password</Link></li> */}
                </ul>
                <div className="account-menu__divider" />
                <ul className="account-menu__links">
                    <li>
                        <Link onClick={() => logout()}><FormattedMessage id="header.logout" />

                        </Link>

                    </li>
                </ul>
            </div>
        );
    }
    const dropdown = (
        <div className="account-menu">
            <div>{renderDropdown()}</div>
        </div>
    );

    return (
        <Indicator url="/account" dropdown={dropdown} icon={<Person20Svg />} />
    );
}
export default withRouter(IndicatorAccount)

