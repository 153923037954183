import { IntegrationHistory, Erp,IntegrationFilter } from "../models/models";
import { ApiResponse, request } from "./api";

export async function logoIntegration( erp:Erp,integrationFilter:IntegrationFilter): Promise<ApiResponse<null>>
 {       
    return await request<null>(`LogoIntegrations?Port=${erp.port}&FirmNumber=${erp.firmNumber}&PeriodNumber=${erp.periodNumber}`, "POST", integrationFilter);
 }

export async function addEntegrasyonInfo(model: Erp): Promise<ApiResponse<null>> {
  return await request<null>(`LogoIntegrations/Erp`, "POST", model);
}

export async function getEntegrasyonHistory(): Promise<ApiResponse<IntegrationHistory>> {
  return await request<IntegrationHistory>(`LogoIntegrations/ParametreEntegrasyonHistory`, "GET");
}




