import { Tabs, DatePicker, Modal, Table, Input, Button, Tooltip, message, Form, Row, Col, InputNumber, Spin, Popconfirm } from 'antd';
import { useIntl } from 'react-intl';
import { ProfileOutlined, PlusSquareOutlined, SwapOutlined, DeleteOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import * as React from 'react';
import { FirmAndPeriod, Erp } from '../../../../models/models';
import * as DbPeriod from '../../../../api_ts/firmAndPeriod';
import * as DbErp from '../../../../api_ts/erp';
import Moment from 'react-moment';
import TextArea from 'antd/lib/input/TextArea';
import { ApiResponse } from '../../../../api_ts/api';
import moment from 'antd/node_modules/moment';
import { useEffect, useState } from 'react';
import { Translate } from '../../i18n/Translate';


export const SalesConditions: React.FC = () => {

    const intl = useIntl();

    useEffect(() => {
    }, []);


    return (
        <>
            <h1>Satış Koşulları</h1>

        </>
    )
}


