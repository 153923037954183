import { LOCALES } from "../Locales";

export default {
    [LOCALES.TURKISH]: {
        // MENU

        "Menu.Records": "Kayıtlar",
        "Menu.Records.Orders": "Siparişler",
        "Menu.Records.Offers": "Teklifler",
        "Menu.Records.ClientCarts": "Sepetler",
        "Menu.Records.Collections": "Tahsilatlar",

        "Menu.Definitions": "Tanımlar ve Kartlar",
        "Menu.Definitions.Units": "Birimler",
        "Menu.Definitions.Warehouses": "Depolar",
        "Menu.Definitions.Salesmen": "Satış Elemanları",
        "Menu.Definitions.Items": "Malzemeler",
        "Menu.Definitions.Items.Items": "Malzemeler",
        "Menu.Definitions.Banks": "Bankalar",
        "Menu.Definitions.Items.SpecialCodes": "Özel Kodlar",
        "Menu.Definitions.Items.GroupCodes": "Grup Kodları",
        "Menu.Definitions.Clients": "Cariler",
        "Menu.Definitions.CollectionTypes": "Tahsilat Türü",
        "Menu.Definitions.Mail": "E-posta",
        "Menu.Definitions.SMS": "SMS",
        "Menu.Definitions.DeliveryTypes": "Lojistik",
        "Menu.Definitions.TermsOfSale": "Satış Koşulları",
        "Menu.Definitions.mailSendingConditions": "E-posta Gönderim Koşulları",
        "Menu.Definitions.SMSSendingConditions": "SMS Gönderim Koşulları",

        "Menu.SystemManagement": "Sistem Yönetimi",
        "Menu.SystemManagement.Firms": "Firma Tanımları",
        "Menu.SystemManagement.Firms.Firms": "Firmalar & Dönemler",
        "Menu.SystemManagement.Versions": "Versionlar",
        "Menu.SystemManagement.Firms.Parameters": "Parametreler",
        "Menu.SystemManagement.Firms.FirmLogo": "Firma Logosu",
        "Menu.SystemManagement.Firms.Address": "Adres",
        "Menu.SystemManagement.Firms.Telephone": "Telefon",
        "Menu.SystemManagement.ERP": "ERP Tanımları",
        "Menu.SystemManagement.ERP.Logo": "Logo",
        "Menu.SystemManagement.mailServer": "E-posta Sunucu Tanımları",
        "Menu.SystemManagement.SMSServer": "SMS Sunucu Tanımları",
        "Menu.SystemManagement.EPayment": "E-Tahsilat Tanımları",
        "Menu.SystemManagement.EPayment.NetPayment": "Net Tahsilat",
        "Menu.SystemManagement.Users": "Kullanıcılar",
        "Menu.Logout": "Çıkış",

        "Menu.Integrations": "Entegrasyonlar",
        "Menu.Integrations.LogoIntegration": "Logo Entegrasyon",
        "Menu.Integrations.VirtualPos":"Sanal Pos",
        "Payment.ClientAccount": "Cari Hesap",
        "Payment.Cash": "Nakit",
        "Payment.Remittance": "Havale",
        "Payment.CreditCard": "Kredi Kartı",
        "Payment.Cheque": "Çek",
        "Payment.Bill": "Senet",
        // COMMO
        MailSendingConditions: "E-posta Gönderim Koşulları",
        SMSSendingConditions: "SMS Gönderim Koşulları",
        MailProviders: "Mail Sağlayıcıları",
        MailProvider: "Mail Sağlayıcı",
        MailTitle: "Mail Başlığı",
        Mail: "Mail",
        SMS: "Sms",
        DeliveryType: "Teslimat Türü",
        Sender: "Gönderici",
        Add: "Ekle",
        Logout:"Çıkış",
        BackOffice: "Arka Ofis",
        Cancel_adj: "İptal Et",
        Cancel_noun: "İptal",
        CollectionMethod: "Tahsilat Tipi",
        id: "id",
        Close: "Kapat",
        CollectionBonusType: "Tahsilat Bonus Turu",
        Code: "Kod",
        Date: "Tarih",
        FicheNo: "Fiş No",
        DefaultLanguage: "Varsayılan Dil",
        PopconfirmDelete: "Bu Kaydı Silmek İstediginden Emin Misin?",
        IsDelete: "Silinsin mi?",
        Delete: "Sil",
        Description: "Açıklama",
        "Description-2": "Açıklama-2",
        "Description-3": "Açıklama-3",
        Update: "Güncelle",
        VirtualPosType:"SanalPos",
        VirtualPosCode:"SanalPos Kod",
        Edit: "Düzenle",
        "Key":"Anahtar",
        "URL":"URL",
        Home: "Ana Sayfa",
        Images: "Resimler",
        InUse: "Kullanımda",
        LastName: "Soyad",
        LineNo: "Satır No",
        Undefined: "Tanımsız",
        DocumentNo: "Belge No",
        Name: "Ad",
        Surname: "Soyad",
        Phone: "Telefon",
        "Operation.Error": "İşlemi tamamlanamadı",
        "Operation.Success": "İşlemi başarı ile tamamlandı",
        Operation_Success: "İşlemi başarı ile tamamlandı",
        Save: "Kaydet",
        mail: "Mail",
        VirtualPos:"Sanal Pos",
        "E-mail": "Mail",
        Active: "Aktif",
        Actions: "İşlemler",
        Languages: "Diller",
        Adress: "Adres",
        Explanation: "Açıklama",
        Quantity: "Miktar",
        ProvisionCode: "Provizyon Kodu",
        CollectionFiche: "Tahsilat Fişi",
        Search: "Ara",
        Reset: "Temizle",
        DecimalDigits: "Kuruş Hane Sayısı",
        YetkiGrubu: "Yetki Grubu",
        "Contact.Info": "İletişim Açıklama",
        "Contact.mail": "İletişim E-posta Adresi",
        "Contact.Phone": "İletişim Telefon Numarası",
        "Contact.Address": "İletişim Adres",
        DiscountRate: "İndirim Oranı",
        Default: "Varsayılan",
        Clients: "Cariler",
        Email: "E-Posta",
        Client: "Cari",
        Discount: "İndirim",
        "E-Commerce": "E-Ticaret",
        Filter: "Filtrele",
        Integration: "Entegrasyon",
        RequiredField: "Zorunlu Alan",
        PleaseSelect: "Lütfen Seçiniz",
        All: "Tümü",
        Start: "Başlangıç",
        End: "Bitiş",
        Cancel: "İptal",
        Loading: "Yükleniyor",
        Port: "Port",
        ProjectName: "Proje Adi",
        Server: "Sunucu",
        Operations: "İşlemler",
        Cart: "Sepet",
        Carts: "Sepetler",
        User: "Kullanıcı",
        Category: "Kategori",
        Version: "Versiyon",
        InvoicePayerType: "Fatura Mükellef Türü",
        Parameters: "Parametreler",
        PriceDecimalPlaces: "Fiyat Hane Sayısı",
        ItemAmountType: "Ürün Miktar Tipi",
        ErpTransfer: "Erp Aktar",
        Transferred: "Aktarıldı",
        NotTransferred: "Aktarılmadı",
        MailOperationGroup: "Mail İşlem Grubu",
        Order: "Sipariş",
        HideTransferredToErp: "Erp aktarılanları gösterme",
        Offer: "Teklif",
        Collection: "Tahsilat",
        Banks: "Bankalar",
        Bank: "Banka",
        Amount: "Tutar",
        Size: "Boyut",
        None: "Yok",
        EInvoice: "E-Fatura",
        EArchive: "E-Arşiv",
        MailTemplate: "Mail Şablonu",
        Header: "Üst Bilgi",
        Yes: "Evet",
        No: "Hayır",
        Footer: "Alt Bilgi",
        Content: "İçerik",
        ERPStatus: "Erp Durum",
        Subtotal: "Ara Toplam",
        OrderNo: "Siparis No",
        Salesman: "Satis Elemanı",
        Auth:"Yetki",
        Salesmen: "Satis Elemanları",
        InvalidEmail: "Geçersiz E-posta",
        PasswordNotMatch: "Şifre Eşleşmiyor",
        ConfirmPassword: "Şifre(Dogrula)",
        AssignClientToSalesman: "Satis Elemanına Cari Ata",
        SalesmanClients: "Satis Elemanı Cari Listesi",
        AllClientAuth: "Tüm Cari Yetkisi",
        ReleaseNotes: "Sürüm Notları",
        WaitForApproval: "Beklemede",
        PaymentBonusType: "Ödeme Bonus Turu",
        BonusPoint: "Bonus Puan",
        BonusPointAndDiscount: "Bonus Puan Ve İndirim",
        PaymentMethod: "Odeme Yontemi",
        PaymentMethods: "Odeme Yontemleri",
        GrossVolume: "Brüt Hacim",
        B2BWarehouse: "B2B Depolar",
        NetVolume: "Net Hacim",
        GrossWeight: "Brüt Agırlık",
        NetWeight: "Net Agırlık",
        BrandCode: "Marka Kodu",
        MainBarcode: "Ana Barkod",
        Barcode: "Barkod",
        Barcode2: "Barkod2",
        Barcode3: "Barkod3",
        MadeIn: "Menşei",
        Description2: "Aciklama-2",
        Description3: "Aciklama-3",
        E_InvoicePayer: "E-Fature Mükellef",
        E_IrsaliyeMukellef: "E-Irsaliye Mükellef",
        BankName: "Banka Adı",
        BankBranch: "Banka Şubesi",
        BranchNumber: "Şube Numarası",
        Iban: "İban",
        BankList: "Banka Listesi",
        ErpCode: "Erp Kod",
        B2BWarehouses: "B2B Depolar",
        // ENUMS
        YerelParaBirimi: "Yerel Para Birimi",
        DovizParaBirimi: "Döviz Para Birimi",
        Pending: "Bekliyor",
        Confirmed: "Onaylandı",
        Rejected: "Reddedildi",
        AllTime: "Tüm Zamanlar",
        Pend: "Beklet",
        Confirm: "Onayla",
        Reject: "Reddet",

        TL: "TL",
        USD: "Dolar",
        EUR: "Euro",


        // Login

        Login: "Giriş Yap",
        LoginFailed: "Bilgiler hatalı",
        Username: "Kullanıcı Adı",
        UsernameRequired: "Kullanıcı adı gereklidir",
        Password: "Parola",
        PasswordControl: "Parola (Kontrol)",
        PasswordRequired: "Parola gereklidir",

        //Warehouse
        Warehouse: "Depo",
        WarehouseDescription: "Depo Açıklaması",
        WarehouseCode: "Depo Kodu",
        Warehouses: "Depolar",
        WarehouseList: "Depo Listesi",
        OrderWarehouse: "Siparis Deposu",

        //Store
        Stores: "Depolar",
        Store: "Depo",
        StoresList: "Depo Listesi",
        AddStore: "Depo Ekle",
        EditStore: "Depo Düzenle",
        StoreCode: "Depo Kodu",

        // Units

        UnitSets: "Birim Setleri",
        AddUnitSet: "Birim Seti Ekle",
        EditUnitSet: "Birim Seti Düzenle",
        UnitSetCode: "Birim Seti Kodu",
        UnitSetDescription: "Birim Seti Açıklaması",
        Unit: "Birim",
        Units: "Birimler",
        AddUnit: "Birim Ekle",
        UnitCode: "Birim Kodu",
        UnitDescription: "Birim Açıklaması",
        UnitFact1: "Çarpan 1",
        UnitFact2: "Çarpan 2",

        // SpecialCodes

        SpecialCodeDomains: "Özel Kod Alanları",
        AddSpecialCodeDomain: "Özel Kod Alanı Ekle",
        EditSpecialCodeDomain: "Özel Kod Alanı Düzenle",
        SpecialCodeDomainName: "Özel Kod Alan Adı",
        SpecialCodes: "Özel Kodlar",
        SpecialCodesList: "Özel Kod Listesi",
        AddSpecialCode: "Özel Kod Ekle",
        EditSpecialCode: "Özel Kod Düzenle",
        SelectSpecialCode: "Özel Kod Seç",

        // GroupCodes

        GroupCodesList: "Grup Kodu Listesi",
        AddGroupCode: "Grup Kodu Ekle",
        EditGroupCode: "Grup Kodu Düzenle",
        GroupCode: "Grup Kodu",
        GroupCodeName: "Grup Kodu İsmi",
        SubGroupCode: "Alt Grup Kodu",
        ParentGroupCode: "Üst Grup Kodu",
        // Items
        Item: "Malzeme",
        Items: "Malzemeler",
        ItemsList: "Malzeme Listesi",
        AddItem: "Malzeme Ekle",
        EditItem: "Malzeme Düzenle",
        ItemAmounts: "Malzeme Miktarları",
        ToplamMiktarFiili: "Toplam Fiili Miktar",
        ToplamMiktarSevkedilebilir: "Toplam Sevkedilebilir Miktar",
        ToplamMiktarGercek: "Toplam Gerçek Miktar",
        MiktarFiili: "Fiili Miktar",
        MiktarSevkedilebilir: "Sevkedilebilir Miktar",
        MiktarGercek: "Gerçek Miktar",
        TaxOffice: "Vergi Dairesi",

        // Prices

        Prices: "Fiyatlar",
        AddPrice: "Fiyat Ekle",
        EditPrice: "Fiyat Düzenle",
        TaxIncluded: "KDV Dahil",
        PriceWithVAT: "KDV Dahil Tutar",
        CurrencyUnitPrice: "Döviz Birim Fiyat",
        CurrencyType: "Para Birimi Türü",
        UnitPrice: "Birim Fiyat",
        CurrencyUnit: "Para Birimi",
        IsActive: "Aktif Mi",

        // Orders

        OrdersList: "Sipariş Listesi",
        AddOrder: "Sipariş Ekle",
        EditOrder: "Sipariş Düzenle",
        ClientTitle: "Müşteri Ünvanı",
        Total: "Tutar",
        KDVOrani: "KDV Oranı",
        KDV: "KDV",
        NetTotal: "Net Tutar",
        Status: "Durum",
        ChangeStatus: "Durum Değiştir",
        OrderDetails: "Sipariş Detayı",
        OrderDetailsList: "Sipariş Detay Listesi",

        // Clients
        ClientCode: "Cari Kodu",
        ClientList: "Cari Listesi",
        AddClient: "Cari Ekle",
        EditClient: "Cari Düzenle",

        // ClientAddress

        ClientAddresses: "Cari Adresler",
        ClientAddressList: "Cari Adres Listesi",
        AddClientAddress: "Cari Adres Ekle",
        EditClientAddress: "Cari Adres Düzenle",
        Invoice: "Fatura",
        Shipment: "Sevkiyat",
        AddressDefinition: "Adres Tanımı",
        PriceDecimalDigit: "Kurus Hane Sayısı",
        // ClientUsers

        ClientUser: "Cari Kullanıcı",
        ClientUsers: "Cari Kullanıcılar",
        ClientUsersList: "Cari Kullanıcı Listesi",
        AddClientUser: "Cari Kullanıcı Ekle",
        EditClientUser: "Cari Kullanıcı Düzenle",

        // Salespeople

        SalesmanList: "Satış Elemanı Listesi",
        AddSalesman: "Satış Elemanı Ekle",
        EditSalesman: "Satış Elemanı Düzenle",
        SalesmanGroup: "Satış Temsilcisi Grubu",

        // Periods

        PeriodsList: "Dönem Listesi",
        AddPeriod: "Dönem Ekle",
        EditPeriod: "Dönem Düzenle",
        FirmNo: "Firma No",
        FirmName: "Firma Adı",
        PeriodNo: "Dönem No",
        PeriodNumber: "Dönem No",
        FirmNumber: "Firma No",
        PeriodName: "Dönem Adı",
        StartDate: "Başlangıç Tarih",
        EndDate: "Bitiş Tarih",
        TaxNumber: "Vergi Numarası",
        City: "İl",
        Town: "İlçe",
        ClientDescription: "Cari",
        Address: "Adres",
        Address2: "Adres-2",
        MsSqlServer: "MsSql Sunucu",
        MsSqlDatabase: "MsSql Veritabanı",
        MsSqlUserId: "MsSql Kullanıcı Adı",
        MsSqlPassword: "MsSql Parola",
        AddParametre: "Parametre Dönem Ekle",
        ErpList: "Erp Listesi",
        Passive: "Pasif",
        ErpReference: "ErpRef",
        NetAmount: "NetTutar",
        // Users

        UsersList: "Kullanıcı Listesi",
        AddUser: "Kullanıcı Ekle",
        EditUser: "Kullanıcı Düzenle",
        "Salesman.Username": "Satis Elemani",
        "ClientUser.Username": "Cari Kullanici",
        "Client.Description": "Cariler",

        //General
        CollectionDetail: "Tahsilat Detayı",
        Kod: "Kod",
        UnitSet: "Birim Seti",
        VAT: "KDV",
        ReturnVAT: "KDV İade",
        SellVAT: "KDV Satış",
        Surtax: "Ek Vergi",
        AnaBarkod: "Ana Barkod",
        Barkod: "Barkod",
        MarkaKodu: "Marka Kodu",
        Mensei: "Menşei",
        Width: "Genişlik",
        Height: "Yükseklik",
        Area: "Alan",
        BrutHacim: "Brüt Hacim",
        NetHacim: "Net Hacim",
        BrutAgirlik: "Brüt Ağırlık",
        NetAgirlik: "Net Ağırlık",

        // CollectionDefinitions
        CollectionTypes: "Tahsilat Türleri",
        CollectionType: "Tahsilat Türü",
        CollectionDefinition: "Tahsilat Tanımı",
        CollectionDefinitions: "Tahsilat Tanımları",
        Collections: "Tahsilatlar",
        CollectionList: "Tahsilat Listesi",
        AddCollection: "Tahsilat Ekle",
        CollectionDay: "Tahsilat Günü",
        CollectionEdit: "Tahsilat Düzenle",
        CollectionStatus: "Tahsilat Durum",
        PlanCode: "Plan Kodu",
        Kullanimda: "Kullanımda",
        KullanimDisinda: "Kullanım Dışında",
        BonusPointFactor: "BonusPuan Çarpanı",
        OrderBonusFactor: "Sipariş Bonus Çarpanı",
        //Logistic
        Host: "Host",
        //LogoIntegration
        LogoIntegration: "Logo Entegrasyon",
        ItemPriceCards: "Malzeme Fiyat Kartlar",
        "E-Store-Access": "E-Magaza Erişilebilir",
        "E-Job-Access": "E-İş Ortamı Erişilebilir",
        CategoryGroup: "Kategori Grubu",
        SequenceNumber: "Sıralama Numarası",
        PriorityRange: "Öncelik Aralığı",

        //SalesParameters
        ShowOutOfStockItem: "Stokta Olmayan Ürün Gösterimi",
        AutoOrderIntegration: "Otomatik Sipariş Entegrasyon",
        ShowCurrency: "Para Birimi Gösterimi",
        ShowItemsWithoutPrice: "Fiyatsız Ürün Gösterimi",
        OrderOutOfStockItem: "Stokta Olmayan Ürün Siparis Verilmesi",
    },
};
