import { FirmAndPeriod, PeriodParameter } from "../models/models";
import { isUserLogin } from "./user"
import { ApiResponse, request } from "./api";

export interface ListParams {
    satisElemaniKullanici?: string;
    satisElemaniParola?: string;
}


export async function getList(): Promise<ApiResponse<FirmAndPeriod[]>> {
    return await request<FirmAndPeriod[]>(`firmsAndPeriods/getList`,"GET");
}
export async function selectActivePeriod(id: number): Promise<ApiResponse<null>> {
    return await request<null>(`firmsAndPeriods/selectActivePeriod/${id}`, "GET");
}

export async function add(model: FirmAndPeriod): Promise<ApiResponse<any>> {
    return await request<any>(`firmsAndPeriods/add`, "POST", model);
}

export async function deleteFirmAndPeriod(id: number): Promise<ApiResponse<any>> {
    return await request<any>(`firmsAndPeriods/delete/${id}`, "DELETE");
}

export async function update(model: FirmAndPeriod): Promise<ApiResponse<any>> {
    return await request<any>("firmsAndPeriods/update", "PATCH", model);
}




export async function parametreDonemDuzenle(
    model: PeriodParameter
): Promise<ApiResponse<PeriodParameter>> {
    return await request<PeriodParameter>("Donem/Parametreler", "PATCH", model);
}

export async function donemParametreleriniAl(): Promise<
    ApiResponse<PeriodParameter>
> {
    const response = await request<PeriodParameter>("Donem/Parametreler");
    if (response.ok) parametreDonemAlKaydet(response.data);
    return response;
}


export function parametreDonemAlKaydet(model: PeriodParameter): void {
    localStorage.setItem("s_paramsPeriod", JSON.stringify(model));
}

export function parametreDonemAlLocalStorage(): PeriodParameter | null {
    const value = localStorage.getItem("s_paramsPeriod");

    if (!isUserLogin() || !value)
        return {
            phoneNumber: "",
            address: "",
            email: "",
            description: "",
            priceDecimalDigit: 0,
            defaultLanguage: "TURKISH",
            defaultCurrency: 0,
            autoOrderIntegration:false
        };

    return JSON.parse(value);
}
