import {  DeliveryType } from "../models/models";
import { ApiResponse, request } from "./api";

export async function getList(): Promise<ApiResponse<DeliveryType[]>> {
    return await request<DeliveryType[]>("deliveryTypes/getList","GET");
}

export async function add(DeliveryType: DeliveryType): Promise<ApiResponse<DeliveryType>> {
    return await request<DeliveryType>("deliveryTypes/add", "POST", DeliveryType);
}

export async function update(DeliveryType: DeliveryType): Promise<ApiResponse<DeliveryType>> {
    return await request<DeliveryType>("deliveryTypes/update", "PATCH", DeliveryType);
}

export async function deleteDeliveryType(id: number): Promise<ApiResponse<null>> {
    return await request<null>(`deliveryTypes/delete/${id}`, "DELETE");
}