// react
import React, { Component } from "react";
// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { FormattedMessage } from "react-intl";
import { ListGroup } from "react-bootstrap";
import MediaQuery from 'react-responsive'

// application
import AsyncAction from "../shared/AsyncAction";
import ShopPageCartPriceSummary from "./ShopPageCartPriceSummary";
import Wizard from "../shared/Wizard";

import {
    getCart,
    updateShippingAddressOfCart,
    updateBillingAddressOfCart,
    updateDeliveryTypeOfCart,
} from "../../store/cart";
import { currentStep } from "../../store/general";

import * as dbClientAddress from "../../api_js/clientAddress";
import * as authenticate from "../../api_js/authenticate";
import * as dbDeliveryType from "../../api_js/deliveryType";
import ClientHeader from '../shared/ClientHeader';

// data stubs
import theme from "../../data/theme";
import { OperationType } from "../../enum/enumB2B";

class ShopPageOrderShipping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryTypes: [],
            clientAddresses: [],
            validationDelivery: false
        };
    }

    getClientAddresses() {
        const clientId = authenticate.getUserInfo().clientId;
        dbClientAddress.getListByClientId(clientId)
            .then((response) => {
                if (response.ok) {
                    this.setState({ clientAddresses: response.data });
                    this.setState({ clientAddressListCount: response.data.length })
                } else console.log(response.message);
            })
            .catch((err) => console.log(err.message));
    }
    getDeliveryTypes() {
        dbDeliveryType.getList()
            .then((response) => {
                if (response.ok) {
                    this.setState({ deliveryTypes: response.data });
                } else alert(response.message);
            })
            .catch((err) => alert(err.message));
    }


    componentDidMount() {
        this.getClientAddresses();
        this.getDeliveryTypes();
        this.props.currentStep(0);
        this.validationDelivery(this.props.cart)
    }

    validationDelivery(cart) {
        if (cart.shippingAddressId != null && cart.billingAddressId != null && cart.deliveryTypeId != null) {
            this.setState({ validationDelivery: true })
        } else {
            this.setState({ validationDelivery: false })
        }
    }


    updateWindowDimensions() {
        //TODO RESPONSİVE TASARIM İÇİN SİLİNECEK
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    renderDeliveryType() {
        const { updateDeliveryTypeOfCart, cart } = this.props;
        const { deliveryTypes } = this.state;
        const deliveryTypeList = deliveryTypes.map((deliveryType) => (
            <AsyncAction
                action={() =>
                    updateDeliveryTypeOfCart(cart.cartId, deliveryType.id).then((response) => this.validationDelivery(response.cart))
                }
                render={({ run, loading }) => {
                    const classes = classNames({
                        "btn-loading": loading,
                    });

                    const active = cart.deliveryTypeId == deliveryType.id;
                    return (
                        <>
                            <MediaQuery minWidth={769}>
                                <div className={`col-3 ${classes}`} onClick={run}>
                                    <div
                                        className={`card cart-order-parameters ${active && "g-select-order-parameters"
                                            }`}
                                    >
                                        <div className={"mt-2 ml-2 mr-2 spod_group_icon"}>
                                            <i class={active && "fas fa-check-circle icon spod-select-order-parameters__icon"} ></i>
                                            <i class="fas fa-ellipsis-v  float-right"></i>
                                        </div>
                                        <div
                                            class="d-flex"
                                            style={{ minHeight: "75px" }}
                                        >
                                            <table className="spod-adress-style  m-auto text-center">
                                                <tr>
                                                    <td>
                                                        <FormattedMessage
                                                            id={`${deliveryType.description}`}
                                                        />
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </MediaQuery>
                            <MediaQuery maxWidth={768}>
                                <ListGroup.Item
                                    className={`g-select-order-parameters-line ${classes} ${active && "g-select-order-parameters"}`}
                                    onClick={run}
                                >
                                    <FormattedMessage id={`${deliveryType.description}`} />
                                </ListGroup.Item>
                            </MediaQuery>
                        </>)
                }}
            />
        ));

        return (<><MediaQuery minWidth={769} children={<div className="row">{deliveryTypeList}</div>} />
            <MediaQuery maxWidth={768} children={<ListGroup>{deliveryTypeList}</ListGroup>} />  </>)
    }


    renderShippingAddress() {
        const { cart, updateShippingAddressOfCart } = this.props;
        const { clientAddresses, width } = this.state;

        const billingAddresses = clientAddresses.map((x, index) => (
            <AsyncAction
                action={() =>
                    updateShippingAddressOfCart(cart.cartId, x.id).then((x) => this.validationDelivery(x.cart))
                }
                render={({ run, loading }) => {
                    const classes = classNames({
                        "btn-loading": loading,
                    });
                    debugger;
                    const active = cart.shippingAddressId == x.id;

                    return (
                        <>
                            <MediaQuery minWidth={769}>
                                {/* TODO TEKRAR ALGORİTMA KURULACAK <div className={`col-3 ${classes} ${!(index<numberOfVisibleAdress) && "d-none" }`} onClick={run}> */}
                                <div className={`col-3 ${classes}`} onClick={run}>
                                    <div className={`card cart-order-parameters  ${active && "g-select-order-parameters"}`}>
                                        <div className={"mt-2 ml-2 mr-2 spod_group_icon"}>
                                            <i class={active && "fas fa-check-circle icon spod-select-order-parameters__icon"} ></i>
                                            <i class="fas fa-ellipsis-v float-right"></i>
                                        </div>
                                        <div class="d-flex" style={{ minHeight: "75px" }}>
                                            <table className="spod-adress-style m-auto text-center">
                                                <tr>
                                                    <td>
                                                        {x.address.substring(0, 40) + "..."}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <bold>
                                                            {x.town}/{x.city}
                                                        </bold>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </MediaQuery>
                            {/* {
                                   TODO Yapılacak 
                                 (DeliveryAddresses=== index+1) &&
                                   <div className="col-3">
                                   <div onClick={()=>{
                                        this.setState({numberOfVisibleDeliveryAddress: showCount ,showAll_DeliveryAddress:!showAll})
                                   }}>
                                     <span>{<FormattedMessage id={showAll? "hide":"showAll"} />}</span>
                                   </div>
                                   </div>
                               } */}
                            <MediaQuery maxWidth={768}>
                                <ListGroup.Item
                                    className={`g-select-order-parameters-line ${classes} ${active && "g-select-order-parameters"
                                        }`}
                                    onClick={run}
                                >{`
                         ${x.address.substring(0, 70) + "..."} ${x.city}/${x.town
                                    }`}</ListGroup.Item>
                            </MediaQuery>
                        </>
                    )
                }}
            />
        ));

        return (<><MediaQuery minWidth={769} children={<div className="row">{billingAddresses}</div>} />
            <MediaQuery maxWidth={768} children={<ListGroup>{billingAddresses}</ListGroup>} />  </>)
    }



    renderBillingAddress() {
        const { cart, updateBillingAddressOfCart } = this.props;
        const { clientAddresses, width } = this.state;

        const invoiceAddresses = clientAddresses.map((x, index) => (
            <AsyncAction
                action={() =>
                    updateBillingAddressOfCart(cart.cartId, x.id).then((item) => this.validationDelivery(item.cart))
                }
                render={({ run, loading }) => {
                    const classes = classNames({
                        "btn-loading": loading,
                    });
                    const active = cart.billingAddressId == x.id;

                    return (
                        <>
                            <MediaQuery minWidth={769}>
                                {/* TODO TEKRAR ALGORİTMA KURULACAK <div className={`col-3 ${classes} ${!(index<numberOfVisibleAdress) && "d-none" }`} onClick={run}> */}
                                <div className={`col-3 ${classes}`} onClick={run}>
                                    <div className={`card cart-order-parameters ${active && "g-select-order-parameters"}`}>
                                        <div className={"mt-2 ml-2 mr-2 spod_group_icon"}>
                                            <i class={active && "fas fa-check-circle icon spod-select-order-parameters__icon"} ></i>
                                            <i class="fas fa-ellipsis-v  float-right"></i>
                                        </div>
                                        <div class="d-flex" style={{ minHeight: "75px" }}>
                                            <table className="spod-adress-style  m-auto text-center">
                                                <tr>
                                                    <td>
                                                        {x.address.substring(0, 40) + "..."}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <bold>
                                                            {x.town}/{x.city}
                                                        </bold>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </MediaQuery>
                            {/* {
                                   TODO Yapılacak 
                                 (DeliveryAddresses=== index+1) &&
                                   <div className="col-3">
                                   <div onClick={()=>{
                                        this.setState({numberOfVisibleDeliveryAddress: showCount ,showAll_DeliveryAddress:!showAll})
                                   }}>
                                     <span>{<FormattedMessage id={showAll? "hide":"showAll"} />}</span>
                                   </div>
                                   </div>
                               } */}
                            <MediaQuery maxWidth={768}>
                                <ListGroup.Item
                                    className={`g-select-order-parameters-line ${classes} ${active && "g-select-order-parameters"
                                        }`}
                                    onClick={run}
                                >{`
                         ${x.address.substring(0, 70) + "..."} ${x.city}/${x.town}`}</ListGroup.Item>
                            </MediaQuery>
                        </>
                    );
                }}
            />
        ));
        return (<><MediaQuery minWidth={769} children={<div className="row">{invoiceAddresses}</div>} />
            <MediaQuery maxWidth={768} children={<ListGroup>{invoiceAddresses}</ListGroup>} />  </>)
    }

    render() {
        const { cart } = this.props;
        const breadcrumb = [
            { title: <FormattedMessage id="home" />, url: "" },
            { title: <FormattedMessage id="shopCart.shoppingCart" />, url: "" },
        ];

        return (
            <React.Fragment>
                <div className={"spod-parent-container"}>
                    <div className={"spod-header"}>

                    </div>
                    <div className="spod-content" >
                        <Wizard
                            captions={[
                                <FormattedMessage id="delivery" />,
                                <FormattedMessage id="collection" />,
                                <FormattedMessage id="summary" />,
                            ]}
                        />
                        <ClientHeader />
                        <Helmet>
                            <title>{`${theme.name}`}</title>
                        </Helmet>
                        <div className="spc-group-box-shadow">
                            <h6>Teslimat Yöntemi</h6>
                            {this.renderDeliveryType()}
                        </div>

                        <div className="space mt-3"></div>
                        <div className="spc-group-box-shadow">
                            <h6>Teslimat Adresi</h6>
                            {this.renderShippingAddress()}
                        </div>

                        <div className="space mt-3"></div>
                        <div className="spc-group-box-shadow" >
                            <h6>Fatura Adresi</h6>
                            {this.renderBillingAddress()}
                        </div>
                    </div>
                    <ShopPageCartPriceSummary
                        btnInfo={{
                            validation: this.state.validationDelivery,
                            btnName: "proceedToCheckout",
                            to: "/order/payment",
                            operationType: OperationType.Link
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    getCart,
    updateShippingAddressOfCart,
    updateBillingAddressOfCart,
    updateDeliveryTypeOfCart,
    currentStep,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageOrderShipping);
