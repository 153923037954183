import { PaymentType } from "../models/models";
import { ApiResponse, request } from "./api";
import { 
      paymentTypes_add,
      paymentTypes_getList,
      paymentTypes_delete,
      paymentTypes_update,
        } from "../helper/magic_string";

export async function getList(): Promise<ApiResponse<PaymentType[]>> {
    return await request<PaymentType[]>(`${paymentTypes_getList}`, "GET");
}
export async function add(paymentType: PaymentType): Promise<ApiResponse<PaymentType>> {
    return await request<PaymentType>(`${paymentTypes_add}`, "POST", paymentType);
}
export async function deletePaymentType(id: number): Promise<ApiResponse<null>> {
    return await request<null>(`${paymentTypes_delete}/${id}`, "DELETE");
}
export async function update(model: PaymentType): Promise<ApiResponse<PaymentType>> {
    return await request<PaymentType>(`${paymentTypes_update}`, "PATCH", model);
}
