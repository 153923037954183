import { GroupCode } from "../models/models";
import { ApiResponse, request } from "./api";

export async function getList(): Promise<ApiResponse<GroupCode[]>> {
    return await request<GroupCode[]>("groupCodes/getList");
}

export async function deleteGroupCode(id: number): Promise<ApiResponse<null>> {
    return await request<null>(`groupCodes/delete/${id}`, "DELETE");
}

export async function add( model: GroupCode): Promise<ApiResponse<GroupCode>> {
    return await request<GroupCode>("groupCodes/add", "POST", model);
}

export async function update( model: GroupCode): Promise<ApiResponse<GroupCode>> {
    return await request<GroupCode>("groupCodes/update", "PATCH", model);
}
