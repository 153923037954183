import { LOCALE_CHANGE, LOCALE_ANT_DESIGN_CHANGE } from './localeActionTypes';

// eslint-disable-next-line import/prefer-default-export
export const localeChange = (locale) => ({
    type: LOCALE_CHANGE,
    locale,
});

export const changeAntDesignLocale = (ant_locale) => ({
    type: LOCALE_ANT_DESIGN_CHANGE,
    ant_locale,
});
