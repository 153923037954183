// react
import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';

// application
import SocialLinks from '../shared/SocialLinks';

export default function FooterNewsletter() {
    return (
        <div className="site-footer__widget footer-newsletter">
            <h5 className="footer-newsletter__title"><FormattedMessage id="footer.newsletter" /></h5>
            <div className="footer-newsletter__text">
                <FormattedMessage id="footer.newsletter.info" />
            </div>

            <form action="" className="footer-newsletter__form">
                <label className="sr-only" htmlFor="footer-newsletter-address"><FormattedMessage id="footer.newsletter.emailAddress" /></label>
                <input
                    type="text"
                    className="footer-newsletter__form-input form-control"
                    id="footer-newsletter-address"
                    placeholder="Email Address..."
                />
                <button type="submit" className="footer-newsletter__form-button btn btn-primary"><FormattedMessage id="footer.newsletter.subscribe" /></button>
            </form>

            <div className="footer-newsletter__text footer-newsletter__text--social">
                <FormattedMessage id="footer.newsletter.follow" />
            </div>

            <SocialLinks className="footer-newsletter__social-links" shape="circle" />
        </div>
    );
}
