import { SpecialCodeArea, SpecialCode } from "../models/models";
import { ApiResponse, request } from "./api";

export async function getList(): Promise< ApiResponse<SpecialCodeArea[]>> {
    return await request<SpecialCodeArea[]>("specialCodeAreas/getList");
}

export async function add( model: SpecialCodeArea): Promise<ApiResponse<SpecialCodeArea>> {
    return await request<SpecialCodeArea>(`specialCodeAreas/add`, "POST", model);
}

export async function deleteSpecialCodeArea(id: number): Promise<ApiResponse<null>> {
    return await request<null>(`specialCodeAreas/delete/${id}`, "DELETE");
}

export async function update(model: SpecialCodeArea): Promise<ApiResponse<null>> {
    return await request<null>(`specialCodeAreas/update`, "PATCH", model);
}


