import { FirmParameter, PeriodParameter, SalesParameter } from "../models/models";
import { ApiResponse, request } from "./api";


export async function getPeriodParameters(): Promise<ApiResponse<PeriodParameter>> {
  return await request<PeriodParameter>("parameters/periodParameters");
}
export async function addOrUpdatePeriodParameters(periodParameter: PeriodParameter): Promise<ApiResponse<PeriodParameter>> {
    return await request<PeriodParameter>("parameters/addOrUpdatePeriodParameters", "PATCH", periodParameter);
}
export async function getSalesParameters(): Promise<ApiResponse<SalesParameter>> {
  return await request<SalesParameter>("parameters/salesParameters");
}
export async function updateSalesParameters(salesParameter: SalesParameter): Promise<ApiResponse<SalesParameter>> {
    return await request<SalesParameter>("parameters/addOrUpdateSalesParameters", "POST", salesParameter);
}
export async function getFirmParameters(): Promise<ApiResponse<FirmParameter>> {
  return await request<FirmParameter>("parameters/firmParameters");
}
export async function updateFirmParameters(firmParameters:FirmParameter): Promise<ApiResponse<FirmParameter>> {
  return await request<FirmParameter>("parameters/addOrUpdateFirmParameters","POST",firmParameters);
}


// TODO BU METOTLAR BİLİNMİYOR
export async function parametreDonemDuzenle( model: PeriodParameter): Promise<ApiResponse<PeriodParameter>> {
    return await request<PeriodParameter>("Donem/Parametreler", "PATCH", model);
}

export async function getAllB2BPeriodParameters(): Promise<ApiResponse<PeriodParameter>> {
  return await request<PeriodParameter>("B2BParameters/PeriodParameters");
}

