import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive'
import Currency from "../shared/Currency";
import moment from 'moment'
import { Table, Form, DatePicker, Input, Row, Col, Button } from 'antd';
import theme from '../../data/theme';
import { getExtractReportByFilter_API, getAccountSummaryByFilter_API } from '../../api_js/client';

import { SortType } from '../../enum/enumB2B';
import { currencySymbol } from '../../../../helper/helper_methods';

import React from 'react'
import $ from "jquery"

export default function AccountPageClientExtract() {
  const intl = useIntl();
  const dateFormat = "DD.MM.YYYY";
  const [extracts, setExtracts] = React.useState([]);
  const [accountSummariesByDate, setAccountSummariesByDate] = React.useState([]);
  const [accountSummaries, setAccountSummaries] = React.useState([]);

  const [extractFilter, setExtractFilter] = React.useState(
    { startDate: moment().add(-1, 'M'), endDate: moment(), searchValue: "", dateSortType: SortType.Desc });
  const mobile = useMediaQuery({ query: '(max-width:425px)' })
  const tablet = useMediaQuery({ query: '(max-width:768px)' })

  React.useEffect(() => {
    getClientExtractsByFilter()
    getAccountSummaries()
  }, [extractFilter]);
  React.useEffect(() => { getAccountSummaries() }, []);

  const getAccountSummaries = () => {
    getAccountSummaryByFilter_API({ startDate: "", endDate: "" })
      .then((response) => {
        if (response.ok) {
          setAccountSummaries(response.data)
        } else console.log(response.message);
      })
      .catch((err) => console.log(err.message));
  }


  const getClientExtractsByFilter = () => {
    const filter = MM_DD_YYYY(extractFilter)
    getExtractReportByFilter_API(filter)
      .then((response) => {
        if (response.ok) {
          setExtracts(response.data.ekstreRaporSatirlari)
          setAccountSummariesByDate(response.data.cariHesapOzeti)
        } else console.log(response.message);
      })
      .catch((err) => console.log(err.message));
  }


  const searhToExtract = (filter) => {
    setExtractFilter(extractFilter => ({
      ...extractFilter,
      startDate: filter.startDate,
      endDate: filter.endDate,
      searchValue: filter.searchValue
    }))
  }

  //(bu method tarihi MM_DD_YYYY formatına çevirir) ve extra
  const MM_DD_YYYY = (filterItem) => {
    const filter = {
      searchValue: filterItem.searchValue,
      startDate: moment(filterItem.startDate).format("YYYY-MM-DD"),
      endDate: moment(filterItem.endDate).format("YYYY-MM-DD"),
      dateSortType: filterItem.dateSortType
    }
    return filter;
  }






  const columns = [
    // {
    //     title: <FormattedMessage id="clientName"  />, 
    //     dataIndex: 'carI_ADI', 
    // },
    // {

    //     title: <FormattedMessage id="description"  />, 
    //     dataIndex: 'aciklama', 
    //     width:"120px"
    // },
    // {
    //   title: <FormattedMessage id="module"  />, 
    //   dataIndex: 'modul',
    //   width:"100px"
    // },
    {
      title: <FormattedMessage id="ficheNo" />,
      dataIndex: 'fiS_NO',
      width: "100px"
    },
    {
      title: <FormattedMessage id="ficheType" />,
      dataIndex: 'fiS_TURU',
      width: "100px"
    },
    {
      title: <FormattedMessage id="date" />,
      dataIndex: 'tarih',
      width: "60px",
      render: (text, record) => moment(new Date(record.tarih)).format('DD.MM.YYYY'),
      sorter: true,
      defaultSortOrder: 'descend',
      // onHeaderCell: (column) => {
      //   return {
      //     onClick: () => {setExtractFilter(extractFilter => ({...extractFilter,sortType:SortType.Asc }))}
      //   }
      // },
    },
    {
      title: <FormattedMessage id="dept" />,
      dataIndex: 'borc',
      align: 'right',
      width: "117px",
      render: (text, record) => <Currency value={record.borc} />,
    },
    {
      title: <FormattedMessage id="paid" />,
      dataIndex: 'alacak',
      width: "117px",
      align: 'right',
      render: (text, record) => <Currency value={record.alacak} />
    }
    ,
    {
      title: <FormattedMessage id="balance" />,
      dataIndex: 'bakiye',
      align: 'right',
      width: "117px",
      render: (text, record) => <><Currency value={record.bakiye} /> {record.bakiyE_TIPI}</>
    }
  ]



  const onChangeTable = (pagination, filters, sorter) => {

    setExtractFilter(ef => ({ ...ef, dateSortType: sorter.order == "ascend" ? SortType.Asc : sorter.order == "descend" ? SortType.Desc : SortType.None }))

  }

  const mobileTableAccountSummariesByDate = (
    <div className="card">
      <div className="card-header p-0">
        <h6>TARİHE GÖRE HESAP ÖZETİ</h6>
      </div>
      <table className="account-summary-mobil-table">
        {
          accountSummariesByDate.map(x =>
            <>
              <tbody >
                <tr  >
                  <td style={{ width: "80px" }} className="text-center pt-2" rowSpan="4">{x.isleM_DOVIZ_TIPI}</td>
                </tr>
                <tr  >
                  <td className="pt-2" >Borc:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{<Currency currency={{ symbol: currencySymbol(x.isleM_DOVIZ_TIPI_NUMARASI) }} value={x.borc} />}</td>
                </tr>
                <tr  >
                  <td>Alacak:&nbsp;&nbsp;{<Currency currency={{ symbol: currencySymbol(x.isleM_DOVIZ_TIPI_NUMARASI) }} value={x.alacak} />}</td>
                </tr >
                <tr  >
                  <td>Bakiye:&nbsp;&nbsp;{<Currency currency={{ symbol: currencySymbol(x.isleM_DOVIZ_TIPI_NUMARASI) }} value={x.bakiye} />}{x.bakiyE_TIPI}</td>
                </tr  >
              </tbody>
              <tr class="tr-separator " style={{ height: "0.5rem", borderBottom: "1px solid #6e777f" }} colspan="2">
              </tr>
            </>
          )
        }
      </table>
      {/* <div className="card-divider" />
                  <div className="card-footer">
                  </div> */}
    </div>
  )



  const mobileTableAccountSummary = (
    <div className="card">
      <div className="card-header p-0">
        <h6>HESAP ÖZETİ</h6>
      </div>
      <table className="account-summary-mobil-table">
        {
          accountSummaries.map(x =>
            <>
              <tbody >
                <tr  >
                  <td style={{ width: "80px" }} className="text-center pt-2" rowSpan="4">{x.isleM_DOVIZ_TIPI}</td>
                </tr>
                <tr  >
                  <td className="pt-2" >Borc:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{<Currency currency={{ symbol: currencySymbol(x.isleM_DOVIZ_TIPI_NUMARASI) }} value={x.borc} />}</td>
                </tr>
                <tr  >
                  <td>Alacak:&nbsp;&nbsp;{<Currency currency={{ symbol: currencySymbol(x.isleM_DOVIZ_TIPI_NUMARASI) }} value={x.alacak} />}</td>
                </tr  >
                <tr  >
                  <td>Bakiye:&nbsp;&nbsp;{<Currency currency={{ symbol: currencySymbol(x.isleM_DOVIZ_TIPI_NUMARASI) }} value={x.bakiye} />}{x.bakiyE_TIPI}</td>
                </tr  >
              </tbody>
              <tr class="tr-separator " style={{ height: "0.5rem", borderBottom: "1px solid #6e777f" }} colspan="2">
              </tr>
            </>
          )
        }
      </table>
    </div>)

  const desktopTableAccountSummary = (
    <div className="card border-top-0">

      <div className="card-header p-0 text-center border-0">
        <h6>GENEL HESAP ÖZETİ</h6>
      </div>
      <div className="card-divider" />
      <div className="card-table">
        <div className="table-responsive-sm">
          <table className="client-account-summary-desktop-table">
            <thead>
              <tr>
                <th>DÖVİZ TÜRÜ</th>
                <th>BORC</th>
                <th>ALACAK</th>
                <th>BAKIYE</th>
                {/* <th>YEREL BAKIYE</th> */}
              </tr>
            </thead>
            <tbody>
              {
                accountSummaries.map(x =>
                  <tr>
                    <td>{x.isleM_DOVIZ_TIPI}</td>
                    <td>{<Currency value={x.borc} />}</td>
                    <td>{<Currency value={x.alacak} />}</td>
                    <td>{<Currency value={x.bakiye} />}{x.bakiyE_TIPI}</td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
      </div>

    </div>
  )

  const filterBar = (
    <Form
      layout="horizontal"
      size="small"
      onFinish={searhToExtract}
    >
      <Row >
        <Col style={{ height: "37px" }} lg={9} md={11} sm={24} xs={24}>
          <Form.Item
            name="startDate"
            style={{ display: 'inline-block', width: 'calc(50%)' }}
            rules={[{ required: true, message: intl.formatMessage({ id: "requiredField" }) }]}
            initialValue={moment(extractFilter.startDate, dateFormat)}
          >
            <DatePicker
              size="middle"
              style={{ width: "98%" }}
              placeholder={intl.formatMessage({ id: "accountPage.StartDate" })}
              defaultValue={moment(extractFilter.startDate, dateFormat)}
              format={dateFormat}
            />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 0 }}
            name="endDate"
            rules={[{ required: true, message: intl.formatMessage({ id: "requiredField" }) }]}
            style={{ display: 'inline-block', width: 'calc(50%)' }}
            initialValue={moment(extractFilter.endDate, dateFormat)}
          >
            <DatePicker
              size="middle"
              style={{ width: "98%" }}
              defaultValue={moment(extractFilter.endDate, dateFormat)}
              format={dateFormat}
              placeholder={intl.formatMessage({ id: "accountPage.EndDate" })} />
          </Form.Item>
        </Col>
        <Col style={{ height: "37px" }} lg={15} md={13} sm={24} xs={24}>
          <Form.Item
            name="searchValue"
            initialValue=""
          >
            <Input.Search
              name="searchValue"
              placeholder={intl.formatMessage({ id: "accountPage.ficheNo_or_ficheType_or_description" })}
              allowClear
              enterButton={<Button style={{ background: "#cc3333", color: "#fff" }} htmlType="submit">{intl.formatMessage({ id: "search" })}</Button>}
              size="middle"
            />

          </Form.Item>
        </Col>
      </Row>
    </Form>)





  const mobileTableExtract = (
    <div className="mobil-extract-report">
      <table class="component-mobil-table">
        {extracts.map((x, index) =>
          <tr>
            <td>
              <table style={{ width: "100%" }}>
                <tr key={index}>
                  <td style={{ width: "25%" }} > FisNo: {x.fiS_NO}</td>
                  <td style={{ width: "20%", textAlign: "center" }}>{moment(x.Tarih).format('DD.MM.YYYY')}</td>
                  <td style={{ width: "29%" }} colSpan={2}>{x.fiS_TURU}</td>
                </tr>
                <tr>
                  <td style={{ width: "91px", textAlign: "left" }}>{x.alacak == 0 ?
                    <span style={{ color: 'red' }}><Currency value={x.borc} />(B)</span> :
                    <span style={{ color: 'green' }}><Currency value={x.alacak} />(A)</span>}</td>
                  <td style={{ textAlign: "right" }} colSpan={2}>Bakiye: <Currency value={x.bakiye} />{x.bakiyE_TIPI}</td>
                  <td rowSpan={2} style={{ width: "25px", textAlign: "right" }} ><span className="component-mobil-icon"><i className="fas fa-chevron-right"></i></span></td>
                </tr>
                {/* <tr>
          <td colSpan="3">Aciklama: {x.aciklama}</td> 
          </tr> */}
              </table>
            </td>
          </tr>
        )
        }
      </table>
    </div>
  )


  const accountSummariesByDateHTML = (accountSummariesByDate.map(x => <tr style={{ border: "1px solid #f0f0f0" }}>
    <td className="p-3">
      {<Currency currency={{ symbol: currencySymbol(x.isleM_DOVIZ_TIPI_NUMARASI) }} value={x.borc} />}
    </td>
    <td className="p-3">
      {<Currency currency={{ symbol: currencySymbol(x.isleM_DOVIZ_TIPI_NUMARASI) }} value={x.alacak} />}
    </td>
    <td className="p-3">
      {<Currency currency={{ symbol: currencySymbol(x.isleM_DOVIZ_TIPI_NUMARASI) }} value={x.bakiye} />}
    </td>
  </tr>)
  )

  const desktopTableExtract = (<div className="card border-0">

    <div className="card-header p-1 text-center border-0">
      <h6><FormattedMessage id="accountPage.clientExtract.Upper" defaultMessage="Code" /></h6>
      {/* {desktopTableAccountSummary} */}
    </div>
    <div className="card-divider" />
    <div className="card-table">
      <Table

        style={{ height: "100%" }}
        id="client-extract-table"
        size="small"
        columns={columns}
        pagination={{ pageSize: 20 }}
        onChange={onChangeTable}
        // expandable={{
        // expandedRowRender: record => <p style={{ margin: 0 }}>{record.Miktar}</p>,
        // rowExpandable: record => record.Miktar !== 'Not Expandable',
        // }}
        // scroll={extracts.length>0 && { x: 1200 } }
        dataSource={extracts}
      />
    </div>
    <div className="card-footer p-0">
      <table>
        <th className="p-3">BORÇ</th>
        <th className="p-3">ALACAK</th>
        <th className="p-3">BAKİYE</th>
        <tbody>
          {accountSummariesByDateHTML}
        </tbody>
      </table>
    </div>
  </div>
  )


  return (
    <>
      <Helmet>
        <title>{`Ekstre`}</title>
      </Helmet>
      {filterBar}

      {tablet ? mobileTableAccountSummary : desktopTableAccountSummary}
      {tablet ? mobileTableAccountSummariesByDate : <></>}
      {tablet ? mobileTableExtract : desktopTableExtract}

    </>
  );

}

