import React, { useEffect, useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { Menu, Button, Descriptions, Layout as Ants_Layout, Breadcrumb, Dropdown, Avatar, Divider, ConfigProvider, } from 'antd';
import Title from 'antd/lib/typography/Title';
import { UserOutlined, ProfileOutlined, MenuUnfoldOutlined, LogoutOutlined, MenuFoldOutlined, AlignCenterOutlined, SwapOutlined } from '@ant-design/icons';
import { I18nProvider, LOCALES } from '../i18n';
import './Layout.css';
import * as DbDonem from '../../../api_ts/firmAndPeriod';
import * as dbVersionInfo from '../../../api_ts/versionInfo';
import * as dbUser from '../../../api_ts/user';

import { VersionInfo } from '../../../../src/models/models';
import { FormattedMessage, useIntl } from 'react-intl';
import 'moment/locale/tr';
import tr_TR from 'antd/lib/locale/tr_TR';
import en_US from 'antd/lib/locale/en_US';
import de_DE from 'antd/lib/locale/de_DE';

const { SubMenu } = Menu;
const { Sider, Content, Footer, Header } = Ants_Layout;


export const Layout: React.FC = (props) => {
    const parameters = DbDonem.parametreDonemAlLocalStorage();
    const user = dbUser.getUserInfo();
    const [version, setVersion] = useState<VersionInfo>();

    const [locale, setLocale] = React.useState({ I18: LOCALES.TURKISH, flag: <></>, antDesigner: tr_TR });

    const [collapsed, setCollapsed] = useState(false);
    const path = useLocation().pathname;

    interface MenuItemKey {
        path: string;
        key: string;
    }
    useEffect(() => {
        getCurrentVersion()
        setDefaultLocales(parameters?.defaultLanguage)
    }, [])

    const setDefaultLocales = (lang) => {
        switch (lang) {
            case 'TURKISH':
                setLocale({ I18: LOCALES.TURKISH, flag: trFlag, antDesigner: tr_TR })
                break;
            case 'ENGLISH':
                setLocale({ I18: LOCALES.ENGLISH, flag: enFlag, antDesigner: en_US })
                break;
            case 'GERMAN':
                setLocale({ I18: LOCALES.GERMAN, flag: deFlag, antDesigner: de_DE })
                break;
        }
        localStorage.setItem('lang', lang);
    }

    const getCurrentVersion = () => {
        return dbVersionInfo.getCurrentVersion()
            .then(response => {
                if (response.ok) {
                    setVersion(response.data);
                }
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }


    const keys: MenuItemKey[] = [];

    const clientKey = keys.find(x => x.path === path);
    const keyPaths = clientKey?.key.split('.') ?? [];
    let selectedKeys: string[] | undefined = clientKey ? [clientKey.key] : undefined;
    let openKeys: string[] = [];

    for (let i = 0; i < keyPaths.length - 2; i++) {
        openKeys.push(keyPaths.slice(0, i + 2).join("."));
    }

    if (path === '/' || path === '/BackOffice') {
        selectedKeys = undefined;
        openKeys = [];
    }

    // @ts-ignore

    // preloader
    setTimeout(() => {
        const preloader = document.querySelector('.site-preloader');

        if (!preloader) return;

        preloader.addEventListener('transitionend', (event: any) => {
            if (event.propertyName === 'opacity') {
                preloader?.parentNode?.removeChild(preloader);
            }
        });
        preloader.classList.add('site-preloader__fade');
    }, 500);


    const toggle = () => {
        setCollapsed(!collapsed);
    };

    const languages = (
        <Menu>
            <Menu.Item>
                <span onClick={() => {
                    setDefaultLocales('TURKISH')
                }}>Türkçe</span>
            </Menu.Item>
            <Menu.Item>
                <span onClick={() => {
                    setDefaultLocales('ENGLISH')
                }}>English</span>
            </Menu.Item>
            <Menu.Item>
                <span onClick={() => {
                    setDefaultLocales('GERMAN')
                }}>German</span>
            </Menu.Item>
        </Menu>
    );

    const userHtml = (
        <div style={{ backgroundColor: '#ffff', padding: 15, minWidth: 150 }} >
            <span>
                <Avatar icon={<UserOutlined />} />
                <span> <Descriptions.Item label="UserName">{dbUser.getUserInfo()?.name}</Descriptions.Item></span>
            </span>
            <Divider />
            <Link to="/backoffice/Logout">
                <span>{<FormattedMessage id="Menu.Logout" />}</span>
            </Link>
        </div>
    );

    useEffect(() => {
        const width: string = '400px';
        const elements = document.getElementsByClassName('backoffice-menu');

        if (elements.length === 0) return;

        const el = elements[0] as HTMLElement;
        el.style.flexBasis = 'auto';
        el.style.maxWidth = width;
        el.style.width = 'inherit';
    });
    //TODO SVG'leri ICON'a çevirirmisin.
    const deFlag = (<><svg style={{ borderRadius: "50%", display: "inline", marginRight: '5px' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" id="flag-icons-de" viewBox="0 0 512 512">
        <path fill="#ffce00" d="M0 341.3h512V512H0z" />
        <path d="M0 0h512v170.7H0z" />
        <path fill="#d00" d="M0 170.7h512v170.6H0z" />
    </svg>
        <span>German</span>
    </>);
    const trFlag = (<>
        <svg style={{ display: "inline", marginRight: '5px' }} xmlns="http://www.w3.org/2000/svg" id="turkey" width="20" height="20" viewBox="0 0 20 20">
            <circle id="Oval" cx="10" cy="10" r="10" fill="#d80027" />
            <desc>Flag of Tr</desc>
            <g id="Group" transform="translate(2.773 6.484)">
                <path id="Path" d="M1.33,0,2.15,1.131,3.479.7l-.822,1.13.82,1.131L2.149,2.528l-.822,1.13V2.26L0,1.828,1.329,1.4Z" transform="translate(5.487 1.687)" fill="#f0f0f0" />
                <path id="Path-2" data-name="Path" d="M4.565,6.3A2.826,2.826,0,1,1,5.909.992a3.478,3.478,0,1,0,0,4.973A2.813,2.813,0,0,1,4.565,6.3Z" transform="translate(0.013 0.037)" fill="#f0f0f0" />
            </g>
        </svg>
        <span>Türkçe</span>
    </>)
    const enFlag = (<>
        <svg style={{ borderRadius: "50%", display: "inline", background: "white", marginRight: '5px' }} xmlns="http://www.w3.org/2000/svg" version="1.0" width="20" height="20" viewBox="0 0 640.000000 640.000000" preserveAspectRatio="xMidYMid meet">
            <circle id="Oval" cx="10" cy="10" r="10" fill="#d80027" />
            <desc>Flag of En</desc>
            <g fill='red' transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)" stroke="none" >
                <path d="M2950 6389 c-58 -4 -120 -11 -137 -14 l-33 -6 0 -1374 0 -1375 -1374 0 -1374 0 -6 -22 c-3 -13 -11 -107 -18 -210 -9 -146 -9 -230 0 -375 7 -104 15 -198 18 -210 l6 -23 1374 0 1374 0 0 -1374 0 -1374 23 -6 c12 -3 106 -11 209 -18 146 -9 230 -9 375 0 104 7 198 15 211 18 l22 6 0 1374 0 1374 1374 0 1374 0 6 23 c3 12 11 106 18 209 9 146 9 230 0 375 -7 104 -15 198 -18 211 l-6 22 -1374 0 -1374 0 0 1374 0 1374 -27 6 c-108 23 -442 30 -643 15z"
                />
                <path fill='#012269' d="M4040 5462 l0 -827 552 552 c529 529 552 553 533 568 -11 9 -87 61 -169 115 -237 160 -547 306 -833 393 l-83 25 0 -826z"
                />
                <path fill='#012269' d="M2195 6236 c-277 -94 -528 -216 -758 -370 -78 -53 -151 -102 -162 -111 -19 -15 4 -39 533 -568 l552 -552 0 823 c0 776 -1 822 -17 822 -10 0 -76 -20 -148 -44z" />
                <path d="M4647 5042 l-607 -607 0 -195 0 -195 711 710 710 710 -78 73 c-43 40 -90 82 -104 92 l-25 19 -607 -607z" />
                <path d="M855 5376 c-43 -47 -84 -94 -91 -104 -11 -17 43 -74 594 -625 l608 -607 192 2 192 3 -708 708 -709 709 -78 -86z" />
                <path fill='#012269' d="M613 5082 c-200 -276 -375 -628 -476 -959 l-25 -83 826 0 827 0 -550 550 c-302 303 -552 550 -555 550 -3 0 -24 -26 -47 -58z" />
                <path fill='#012269' d="M5185 4590 l-550 -550 827 0 826 0 -25 83 c-85 276 -211 552 -359 784 -80 125 -156 232 -164 233 -3 0 -253 -247 -555 -550z" />
                <path fill='#012269' d="M137 2278 c92 -301 228 -588 397 -841 53 -78 102 -151 111 -162 15 -19 39 4 568 533 l552 552 -827 0 -826 0 25 -82z" />
                <path d="M4754 1651 c391 -391 714 -708 718 -705 5 3 47 49 94 101 l85 97 -608 608 -608 608 -195 0 -195 0 709 -709z" />
                <path fill='#012269' d="M5187 1808 c529 -529 553 -552 568 -533 9 11 58 84 111 162 169 253 305 540 397 841 l25 82 -826 0 -827 0 552 -552z" />
                <path d="M1647 1642 l-709 -709 86 -78 c47 -43 94 -84 104 -91 17 -11 74 43 625 594 l607 608 -2 192 -3 192 -708 -708z" />
                <path fill='#012269' d="M1805 1210 c-526 -526 -549 -550 -530 -565 11 -9 84 -58 162 -111 236 -158 487 -279 773 -374 l145 -48 3 415 c1 228 1 599 0 824 l-3 409 -550 -550z" />
                <path fill='#012269' d="M4040 938 l0 -826 83 25 c286 87 596 233 833 393 82 54 158 106 169 115 19 15 -4 39 -533 568 l-552 552 0 -827z" />
            </g>
        </svg>
        <span>English</span>
    </>
    )

    return (
        <I18nProvider locale={locale.I18}>
            <ConfigProvider locale={locale.antDesigner}>
                <Ants_Layout className="" style={{ minHeight: "100%" }}  >
                    <Sider
                        // className="backoffice-menu"
                        theme="dark"
                        trigger={null}
                        collapsible
                        collapsed={collapsed}

                    >
                        <div className="logo"
                            style={{
                                height: "32px",
                                margin: "16px",
                                background: " rgba(255, 255, 255, 0.3)",
                                textAlign: "center",
                            }}
                        >
                            <span style={{
                                fontSize: "1.5rem",
                                color: "white",
                                marginTop: "4px",
                                display: collapsed ? "none" : "inline-block",
                                transition: "all 0.2s"
                            }}>
                                RESMAK
                            </span>
                        </div>
                        <Menu
                            theme='dark'
                            mode="inline"
                            style={{ border: "none" }}
                            defaultOpenKeys={openKeys}
                            defaultSelectedKeys={selectedKeys}
                        >
                            {/* {menus.map(x => renderMenuGroupOrItem(x, 'Menu.'))} */}

                            <SubMenu
                                icon={<ProfileOutlined size={3} />} key={"Menu.Records"}
                                title={<FormattedMessage id={"Menu.Records"} />}>
                                <Menu.Item key={"Menu.Records.Orders"}>
                                    <Link to={'/BackOffice/Orders'}>
                                        {<FormattedMessage id={"Menu.Records.Orders"} />}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={"Menu.Records.Collections"}>
                                    <Link to={'/BackOffice/Collections'}>
                                        {<FormattedMessage id={"Menu.Records.Collections"} />}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={"Menu.Records.Offers"}>
                                    <Link to={'/BackOffice'}>
                                        {<FormattedMessage id={"Menu.Records.Offers"} />}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={"Menu.Records.ClientCarts"}>
                                    <Link to={'/BackOffice/Carts'}>
                                        {<FormattedMessage id={"Menu.Records.ClientCarts"} />}
                                    </Link>
                                </Menu.Item>
                            </SubMenu>
                            <SubMenu
                                icon={<UserOutlined />} key={"Menu.Definitions"}
                                title={<FormattedMessage id={"Menu.Definitions"} />}>
                                <Menu.Item key={"Menu.Definitions.Units"}>
                                    <Link to={'/BackOffice/Units'}>
                                        {<FormattedMessage id={"Menu.Definitions.Units"} />}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={"Menu.Definitions.Warehouses"}>
                                    <Link to={'/BackOffice/Warehouses'}>
                                        {<FormattedMessage id={"Menu.Definitions.Warehouses"} />}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={"Menu.Definitions.Salesmen"}>
                                    <Link to={'/BackOffice/Salesmen'}>
                                        {<FormattedMessage id={"Menu.Definitions.Salesmen"} />}
                                    </Link>
                                </Menu.Item>
                                <SubMenu
                                    key={"Menu.Definitions.Items"}
                                    title={<FormattedMessage id={"Menu.Definitions.Items"} />}
                                >
                                    <Menu.Item key={"Menu.Definitions.Items.Items"}>
                                        <Link to={'/BackOffice/Items'}>
                                            {<FormattedMessage id={"Menu.Definitions.Items.Items"} />}
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key={"Menu.Definitions.Items.SpecialCodes"}>
                                        <Link to={'/BackOffice/SpecialCodes'}>
                                            {<FormattedMessage id={"Menu.Definitions.Items.SpecialCodes"} />}
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key={"Menu.Definitions.Items.GroupCodes"}>
                                        <Link to={'/BackOffice/GroupCodes'}>
                                            {<FormattedMessage id={"Menu.Definitions.Items.GroupCodes"} />}
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>
                                <Menu.Item key={"Menu.Definitions.Clients"}>
                                    <Link to={'/BackOffice/Clients'}>
                                        {<FormattedMessage id={"Menu.Definitions.Clients"} />}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={"Menu.Definitions.CollectionTypes"}>
                                    <Link to={'/BackOffice/CollectionTypes'}>
                                        {<FormattedMessage id={"Menu.Definitions.CollectionTypes"} />}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={"Menu.Definitions.DeliveryTypes"}>
                                    <Link to={'/BackOffice/DeliveryTypes'}>
                                        {<FormattedMessage id={"Menu.Definitions.DeliveryTypes"} />}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={"Menu.Definitions.Banks"}>
                                    <Link to={'/BackOffice/Banks'}>
                                        {<FormattedMessage id={"Menu.Definitions.Banks"} />}
                                    </Link>

                                </Menu.Item>  <Menu.Item key={"Menu.Definitions.TermsOfSale"}>
                                    <Link to={'/BackOffice/SalesConditions'}>
                                        {<FormattedMessage id={"Menu.Definitions.TermsOfSale"} />}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={"Menu.Definitions.Mail"}>
                                    <Link to={'/BackOffice/MailProviders'}>
                                        {<FormattedMessage id={"Menu.Definitions.Mail"} />}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={"Menu.Definitions.SMS"}>
                                    <Link to={'/BackOffice'}>
                                        {<FormattedMessage id={"Menu.Definitions.SMS"} />}
                                    </Link>
                                </Menu.Item>
                            </SubMenu>

                            {user?.roles == "Administrator" &&
                                <SubMenu
                                    key={"Menu.SystemManagement"}
                                    icon={<AlignCenterOutlined />}
                                    title={<FormattedMessage id={"Menu.SystemManagement"} />}
                                >
                                    <SubMenu
                                        key={"Menu.SystemManagement.Firms"}
                                        title={<FormattedMessage id={"Menu.SystemManagement.Firms"} />}
                                    >
                                        <Menu.Item key={"Menu.SystemManagement.Firms.Firms"}>
                                            <Link to={'/BackOffice/Periods'}>
                                                {<FormattedMessage id={"Menu.SystemManagement.Firms.Firms"} />}
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key={"Menu.SystemManagement.Firms.Parameters"}>
                                            <Link to={'/BackOffice/Parameters'}>
                                                {<FormattedMessage id={"Menu.SystemManagement.Firms.Parameters"} />}
                                            </Link>
                                        </Menu.Item>
                                    </SubMenu>
                                    <Menu.Item key={"Menu.SystemManagement.Versions"}>
                                        <Link to={'/BackOffice/Versions'}>
                                            {<FormattedMessage id={"Menu.SystemManagement.Versions"} />}
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key={"Menu.SystemManagement.Users"}>
                                        <Link to={'/BackOffice/Users'}>
                                            {<FormattedMessage id={"Menu.SystemManagement.Users"} />}
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>
                            }

                            <SubMenu
                                key={"Menu.Integrations"}
                                icon={<SwapOutlined />}
                                title={<FormattedMessage id={"Menu.Integrations"} />}
                            >
                                <Menu.Item key={"Menu.Integrations.LogoIntegration"}>
                                    <Link to={'/BackOffice/LogoIntegration'}>
                                        {<FormattedMessage id={"Menu.Integrations.LogoIntegration"} />}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={"Menu.Integrations.VirtualPos"}>
                                    <Link to={'/BackOffice/VirtualPos'}>
                                        {<FormattedMessage id={"Menu.Integrations.VirtualPos"} />}
                                    </Link>
                                </Menu.Item>
                            </SubMenu>
                            <Menu.Item
                                icon={<LogoutOutlined />}
                                key={"Menu.Logout"}
                            >
                                <Link to={'/BackOffice/Logout'}>
                                    {<FormattedMessage id={"Logout"} />}
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </Sider>
                    <Ants_Layout className="site-layout" >
                        <Header className="site-layout-background" style={{ lineHeight: 0, padding: 3, backgroundColor: '', color: 'white' }}>
                            <div style={{ position: "relative", float: "right", height: "100%" }}>
                                <div className="header_avatar_pozisiyon">
                                    <div style={{ flex: "0 0 50%" }}>
                                        <Dropdown overlay={languages} arrow>
                                            <div className="langs">
                                                <div className="lang">
                                                    {locale.flag}
                                                </div>
                                            </div>
                                        </Dropdown>
                                    </div>
                                    <div style={{ flex: "1 1 50%" }}>
                                        <Dropdown overlay={userHtml} className="header_avatar" arrow>
                                            <Avatar size={35} icon={<UserOutlined style={{ fontSize: "25px", verticalAlign: "middle" }} />} />
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <span className="triger_box1">
                                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                    className: 'trigger',
                                    onClick: toggle,
                                })}
                                {/* <span style={{
                                    position: "absolute",
                                    textShadow: "2px 2px 5px #cf2327",
                                    fontSize: "2rem",
                                    color: "white",
                                    top: "34px"
                                }}>
                                    Resmak
                                </span> */}
                            </span>
                        </Header>
                        <Content >
                            {/* <Title style={{ margin: 10 }} level={3}>
                                <Breadcrumb.Item>{<FormattedMessage id="BackOffice" />}</Breadcrumb.Item>
                            </Title> */}
                            <div className='content-layout'  >
                                {props.children}
                            </div>
                        </Content>
                        <Footer>
                            {<FormattedMessage id="FirmName" />}: <strong>{user?.firmNumber}</strong>&nbsp;&nbsp;
                            {<FormattedMessage id="PeriodName" />}: <strong>{user?.periodNumber}</strong>&nbsp;&nbsp;
                            {<FormattedMessage id="Version" />}: <strong>{version?.name}</strong>
                        </Footer>
                    </Ants_Layout>
                </Ants_Layout>
            </ConfigProvider>
        </I18nProvider >
    )
};
