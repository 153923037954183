import { ClientUser } from "../models/models";
import { ApiResponse, request } from "./api";

export async function GetListByClientId(clientId): Promise<ApiResponse<ClientUser[]>> {
    return await request<ClientUser[]>(`clientUsers/getListByClientId?clientId=${clientId}`);
}
export async function add(model: ClientUser): Promise<ApiResponse<ClientUser>> {
    return await request<ClientUser>(`clientUsers/add`, "POST", model);
}
export async function deleteClientUser(id: number): Promise<ApiResponse<ClientUser>> {
    return await request<ClientUser>(`clientUsers/delete/${id}`, "DELETE");
}
export async function update( model: ClientUser): Promise<ApiResponse<ClientUser>> {
    return await request<ClientUser>("clientUsers/update", "PATCH", model);
}
