import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'antd/dist/antd.css';
import './App.css';
import { Layout } from '././modules/BackOffice/layouts/Layout';
import { Users } from '././modules/BackOffice/views/user/Users';
import { Login } from '././modules/BackOffice/views/login/Login';
import { Logout } from '././modules/BackOffice/views/logout/Logout';
import { Home } from '././modules/BackOffice/views/home/Home';
import { Units } from '././modules/BackOffice/views/unit/Units';
import { SpecialCodes } from '././modules/BackOffice/views/specialCode/SpecialCodes';
import { GroupCodes } from '././modules/BackOffice/views/groupCode/GroupCodes';
import { Items } from './modules/BackOffice/views/Item/Item/Items';
import { Orders } from '././modules/BackOffice/views/order/Orders';
import { Periods } from './modules/BackOffice/views/period/FirmsAndPeriods';
import { Clients } from './modules/BackOffice/views/client/Clients';
import { Salesmen } from './modules/BackOffice/views/salesman/Salesmen';
import { Warehouses } from '././modules/BackOffice/views/warehouse/Warehouses';
import { Parameters } from '././modules/BackOffice/views/parameter/Parameters';
import { Carts } from '././modules/BackOffice/views/cart/Carts';
import { PaymentTypes } from './modules/BackOffice/views/paymentTypes/PaymentTypes';
import { LogoIntegration } from './modules/BackOffice/views/Integration/LogoIntegration';
import { Versions as Versions } from './modules/BackOffice/views/version/VersionInfo';
import B2BApp from './modules/B2B';
import { MailProviders } from '././modules/BackOffice/views/mailProvider/MailProviders';
import { DeliveryTypes } from './modules/BackOffice/views/deliveryType/DeliveryTypes';
import { Banks } from './modules/BackOffice/views/bank/Banks';
import { Payments } from './modules/BackOffice/views/payment/Payments';
import * as dbUser from './api_ts/user';
import { VirtualPos } from './modules/BackOffice/views/Integration/VirtualPos/VirtualPos';
import { SalesConditions } from './modules/BackOffice/views/salesConditions/SalesConditions';


function App() {
    return (
        <Router>
            <Switch >
                <Route path="/backOffice/login" exact component={() => <Login />} />
                <Route path="/backOffice/Logout" exact render={() => <Logout />} />
                <Route path="/backOffice" exact component={() => dbUser.isUserLogin() ? <Layout><Home /></Layout> : <Login />} />
                <Route path="/backOffice/warehouses" exact component={() => dbUser.isUserLogin() ? <Layout><Warehouses /></Layout> : <Login />} />
                <Route path="/backOffice/units" exact component={() => dbUser.isUserLogin() ? <Layout><Units /></Layout> : <Login />} />
                <Route path="/backOffice/specialCodes" exact component={() => dbUser.isUserLogin() ? <Layout><SpecialCodes /></Layout> : <Login />} />
                <Route path="/backOffice/groupCodes" exact component={() => dbUser.isUserLogin() ? <Layout><GroupCodes /></Layout> : <Login />} />
                <Route path="/backOffice/items" exact component={() => dbUser.isUserLogin() ? <Layout><Items /></Layout> : <Login />} />
                <Route path="/backOffice/orders" exact component={() => dbUser.isUserLogin() ? <Layout><Orders /></Layout> : <Login />} />
                <Route path="/backOffice/users" exact component={() => dbUser.isUserLogin() ? <Layout><Users /></Layout> : <Login />} />
                <Route path="/backOffice/periods" exact component={() => dbUser.isUserLogin() ? <Layout><Periods /></Layout> : <Login />} />
                <Route path="/backOffice/clients" exact component={() => dbUser.isUserLogin() ? <Layout><Clients /></Layout> : <Login />} />
                <Route path="/backOffice/salesmen" exact component={() => dbUser.isUserLogin() ? <Layout><Salesmen /></Layout> : <Login />} />
                <Route path="/backOffice/parameters" exact component={() => dbUser.isUserLogin() ? <Layout><Parameters /></Layout> : <Login />} />
                <Route path="/backOffice/versions" exact component={() => dbUser.isUserLogin() ? <Layout><Versions /></Layout> : <Login />} />
                <Route path="/backOffice/collections" exact component={() => dbUser.isUserLogin() ? <Layout><Payments /></Layout> : <Login />} />
                <Route path="/backOffice/parameters" exact component={() => dbUser.isUserLogin() ? <Layout><Parameters /></Layout> : <Login />} />
                <Route path="/backOffice/carts" exact component={() => dbUser.isUserLogin() ? <Layout><Carts /></Layout> : <Login />} />
                <Route path="/backOffice/banks" exact component={() => dbUser.isUserLogin() ? <Layout><Banks /></Layout> : <Login />} />
                <Route path="/backOffice/salesConditions" exact component={() => dbUser.isUserLogin() ? <Layout><SalesConditions /></Layout> : <Login />} />
                <Route path="/backOffice/mailProviders" exact component={() => dbUser.isUserLogin() ? <Layout><MailProviders /></Layout> : <Login />} />
                <Route path="/backOffice/deliveryTypes" exact component={() => dbUser.isUserLogin() ? <Layout><DeliveryTypes /></Layout> : <Login />} />
                <Route path="/backOffice/collectionTypes" exact component={() => dbUser.isUserLogin() ? <Layout><PaymentTypes /></Layout> : <Login />} />
                <Route path="/backOffice/logoIntegration" exact component={() => dbUser.isUserLogin() ? <Layout><LogoIntegration /></Layout> : <Login />} />
                <Route path="/backOffice/virtualPos" exact component={() => dbUser.isUserLogin() ? <Layout><VirtualPos /></Layout> : <Login />} />

                <Route path="/" component={() => <B2BApp />} />
            </Switch>
        </Router>
    );
}

export default App;
