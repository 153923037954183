import React, { useEffect, useState } from 'react';
import { PaymentType, PaymentMethod } from '../../../../models/models';
import * as DbCollectionType from '../../../../api_ts/paymentType';
import { Tabs, Modal, Table, Input, Button, Tooltip, message, Form, InputNumber, Select, Checkbox } from 'antd';
import { PlusSquareOutlined, EditOutlined, DeleteOutlined, ProfileOutlined } from '@ant-design/icons';
import { useStateWithCallbackLazy as useStateCallback } from 'use-state-with-callback';
import { useIntl } from 'react-intl';
import { Translate } from '../../i18n/Translate';

export const PaymentTypes: React.FC = () => {

    const intl = useIntl();
    const [paymentTypeForm] = Form.useForm();
    const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>([]);
    const [flags, setFlags] = useState({ collectionTypeModalVisible: false, operation: "" });
    const [loading, setLoading] = useStateCallback(false);

    useEffect(() => {
        getPaymentTypes();
    }, []);


    const deletePaymentType = async (collectionTypeId: number) => {
        if ((await DbCollectionType.deletePaymentType(collectionTypeId)).ok) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }))
            getPaymentTypes();
        }
        else
            message.error(intl.formatMessage({ id: 'Operation.Error' }))
    }

    const fillPaymentTypeForm = (collectionType: PaymentType) => {
        paymentTypeForm.setFieldsValue(collectionType)
    }

    const addOrUpdateCollectionType = async (model: PaymentType) => {

        if (model.id === 0) {
            IsSuccessful((await DbCollectionType.add(model)));
        } else {
            IsSuccessful((await DbCollectionType.update(model)));
        }
    }

    const IsSuccessful = (action: any) => {
        setLoading(true, () => {
            if (action.ok) {
                getPaymentTypes()
                    .then(() => {
                        paymentTypeForm.resetFields()
                        setFlags({ ...flags, collectionTypeModalVisible: false })
                        setLoading(false, () => message.success(intl.formatMessage({ id: 'Operation.Success' })))
                    })
            } else {
                message.error(intl.formatMessage({ id: 'Operation.Error' }))
            }
        })
    }

    const getPaymentTypes = () => {
        return DbCollectionType.getList()
            .then(response => {
                if (response.ok)
                    setPaymentTypes(response.data);
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }


    const collectionColumns = [
        {
            title: Translate("Description"),
            dataIndex: 'description',
        },
        {
            title: Translate("CollectionType"),
            dataIndex: 'method',
            key: 'id',
            render: (text: string, paymentType: PaymentType) => {
                return (Translate(`Payment.${PaymentMethod[paymentType.method]}`));
            }
        },
        {
            title: Translate("CollectionDay"),
            dataIndex: 'collectionDay',
        },
        {
            title: Translate("PlanCode"),
            dataIndex: 'planCode',
        },
        {
            title: Translate("OrderBonusFactor"),
            dataIndex: 'orderBonusFactor',
        },
        {
            title: Translate("DiscountRate"),
            dataIndex: 'discountRate',
        },
        // {
        //     title: Translate("Status"),
        //     key: 'id',
        //     dataIndex: 'isActive',
        //     render: (text: string, record: CollectionType) => (
        //         Translate(record.isActive === true ? 'Active' : 'Passive')
        //     )
        // },
        {
            key: 'id',
            width: 100,
            render: (text: string, collection: PaymentType) =>
                <>
                    <Tooltip placement="top" color="blue" title={Translate("Edit")}>
                        <Button icon={<EditOutlined />} onClick={() => {
                            paymentTypeForm.resetFields();
                            setFlags(({ ...flags, collectionTypeModalVisible: true, operation: "Update" }))
                            fillPaymentTypeForm(collection)
                        }
                        } />
                    </Tooltip>
                    {/* <Tooltip placement="top" color="red" title={Translate("Delete")}>
                        <Button icon={<DeleteOutlined />} onClick={() => deleteCollectionType(collection.id)} />
                    </Tooltip> */}
                </>
        },
    ];

    const header = (<Button type="primary" className="ml-auto mb-1" onClick={() => {
        paymentTypeForm.resetFields()
        setFlags({ ...flags, collectionTypeModalVisible: true, operation: "Add" })
    }}
        icon={<PlusSquareOutlined style={{ verticalAlign: 'middle', fontSize: '20px' }} />} >
        <span style={{ verticalAlign: 'middle' }} >{Translate("Add")}</span>
    </Button>)

    const formItemLayout = { labelCol: { span: 7 }, wrapperCol: { span: 17 } };
    return (
        <>
            <Tabs
                defaultActiveKey="1"
                tabBarStyle={{ marginBottom: "3.5px" }}
            >
                <Tabs.TabPane tab={<span><ProfileOutlined />{Translate("CollectionTypes")}</span>} key="1">

                    <div className="site-card-border-less-wrapper">
                        {/* <div className="d-flex">{header} </div> */}
                        <Table bordered size='small' columns={collectionColumns} dataSource={paymentTypes} />
                    </div>
                    <Modal
                        title={Translate("CollectionType")}
                        visible={flags.collectionTypeModalVisible}
                        onCancel={() => setFlags(({ ...flags, collectionTypeModalVisible: false }))}
                        onOk={() => { paymentTypeForm.submit() }}
                        okText={Translate(flags.operation)}
                        cancelText={Translate("Cancel")}
                        confirmLoading={loading}
                    >

                        <Form
                            form={paymentTypeForm}
                            id={"itemForm"}
                            layout={"horizontal"}
                            onFinish={addOrUpdateCollectionType}
                            {...formItemLayout}
                            size={"small"}
                            labelAlign={"left"}
                        >
                            <Form.Item
                                name={"id"}
                                fieldKey={"id"}
                                hidden={true}
                                initialValue={0} >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={Translate("Description")}
                                name="description"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input placeholder={Translate("Description")} />
                            </Form.Item>
                            <Form.Item
                                name={"method"}
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                                label={Translate("CollectionType")}
                            >
                                <Select disabled={true} placeholder={Translate('PleaseSelect')}>
                                    {Object.keys(PaymentMethod).filter(key => !/^\d+$/.test(key)).map(key =>
                                        <Select.Option value={PaymentMethod[key as any]}>{Translate("Payment." + key)}</Select.Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={Translate("CollectionDay")}
                                name="collectionDay"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <InputNumber placeholder={Translate("CollectionDay")} />
                            </Form.Item>
                            <Form.Item
                                label={Translate("PlanCode")}
                                name="planCode"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input placeholder={Translate("PlanCode")} />
                            </Form.Item>
                            <Form.Item
                                label={Translate("OrderBonusFactor")}
                                name="orderBonusFactor"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <InputNumber placeholder={Translate("OrderBonusFactor")} />
                            </Form.Item>
                            <Form.Item
                                label={Translate("DiscountRate")}
                                name="discountRate"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <InputNumber<number>
                                    placeholder={Translate('SellVAT')}
                                    min={0}
                                    max={100}
                                    formatter={value => `${value}%`}
                                    parser={value => Number(value?.replace('%', ''))}
                                />
                            </Form.Item>

                            {/* <Form.Item
                                label={Translate("IsActive")}
                                name={"isActive"}
                                valuePropName="checked"
                                initialValue={true}
                            >
                                <Checkbox ></Checkbox>
                            </Form.Item> */}
                        </Form>
                    </Modal>
                </Tabs.TabPane>
            </Tabs >
        </>
    );
};
