import { getWindowsSize } from "../store/responsive";
import * as authenticate from "./authenticate";
import { IsJsonString } from "../../../helper/helper_methods";

/**
 * @param uri {string} `/api/`den sonraki istek adresi
 * @param method {'GET' | 'POST' | 'PATCH' | 'DELETE'} request metodu
 */
export async function request(uri, method, body) {
    let token = localStorage.getItem('s_token');

    const response = await fetch(`/api/${uri}`, {
        method: method || 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'Bearer ' + (token || '')
        },
        body: body ? JSON.stringify(body) : undefined
    });

    if (!response.ok) {
        if (response.status === 401) {
            authenticate.logoutUserB2B();
            window.location.reload();
        }
        return { ok: false, status: response.status, message: response.statusText };
    }
    const text = await response.text();
    if (text == null) {
        return { ok: true, data: null };
    } else {
        if (IsJsonString(text)) {
            const data = JSON.parse(text);
            return { ok: true, data: data, text };
        } else {
            return { ok: true, text };
        }
    }
}
