import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Form, Select, message, Input, Button } from 'antd';
import * as dbUser from '../../../../api_ts/user';
import { FirmAndPeriod, UserForLoginDto, AuthenticateResult } from '../../../../models/models';
import * as dbFirmAndPeriod from '../../../../api_ts/firmAndPeriod';
import './Login.css';
import jwtDecode from 'jwt-decode';

const { Option } = Select;

export const Login: React.FC = (props: any) => {
    // preloader
    setTimeout(() => {
        const preloader = document.querySelector('.site-preloader');

        if (!preloader) return;

        preloader.addEventListener('transitionend', (event: any) => {
            if (event.propertyName === 'opacity') {
                preloader?.parentNode?.removeChild(preloader);
            }
        });
        preloader.classList.add('site-preloader__fade');
    }, 500);
    const [firmsAndPeriods, setFirmsAndPeriods] = React.useState<FirmAndPeriod[]>([]);
    const [periods, setPeriods] = React.useState<FirmAndPeriod[]>([]);
    const [isLoginUI, setLoginUI] = React.useState<boolean>(false);
    const [loginForm] = Form.useForm();


    React.useEffect(() => {
        dbFirmAndPeriod.getList()
            .then(response => {
                if (response.ok) {
                    setFirmsAndPeriods(response.data);
                } else {
                    console.log(response.message);
                }
            })
            .catch(err => console.log(err.message));
    }, []);


    const login = async (userDto: UserForLoginDto) => {
        const response = await dbUser.login(userDto);
        if (response.ok) {
            const decodedToken = jwtDecode<any>(response.data.token);
            const authenticateResult: AuthenticateResult = {
                id: decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"],
                name: decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"],
                roles: decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"],
                firmNumber: decodedToken["FirmNumber"],
                periodNumber: decodedToken["PeriodNumber"],
                token: response.data.token
            }
            // dispatch(loginAuthenticate(authenticateResult));
            dbUser.loginUI(authenticateResult);
            setLoginUI(dbUser.isUserLogin())
        } else {
            message.error("Giriş Yapılamadı");
        }
    }

    const loginFormHtml = (<div>
        <div className="container-login100">
            <div className="wrap-login100 p-t-50 p-b-90">
                <span className="login100-form-title p-b-51 mb-4">
                    <img src="/images/resmak106x26.jpg" alt="" />
                </span>
                <Form
                    form={loginForm}
                    layout={"horizontal"}
                    onFinish={login}
                    // {...formItemLayout}
                    size={"small"}
                    labelAlign={"left"}
                >
                    <Form.Item
                        name={"username"}
                        style={{ marginBottom: "10px" }}
                        rules={[{ required: true, message: "Bu Alan Gereklidir" }]}
                    >
                        <Input size={"large"} placeholder={"Kullanıcı Adı"} />
                    </Form.Item>
                    <Form.Item
                        name={"password"}
                        style={{ marginBottom: "10px" }}
                        rules={[{ required: true, message: "Bu Alan Gereklidir" }]}
                    >
                        <Input type={"password"} size={"large"} placeholder={"Şifre"} />

                    </Form.Item>
                    < Form.Item
                        name={"firmNumber"}
                        rules={[{ required: true, message: "Bu Alan Gereklidir" }]}
                        style={{ marginBottom: "10px" }}
                    //initialValue={firmsAndPeriods[0]?.firmNo ?? 0} 
                    >
                        <Select
                            placeholder={"Firma"}
                            size={"large"}
                            onChange={(value) => {
                                setPeriods(firmsAndPeriods.filter(x => x.firmNumber == value))
                            }}
                        >
                            {firmsAndPeriods.map(x => <Option value={x.firmNumber}>{x.firmNumber}{": "}{x.firmName}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name={"periodNumber"}
                        rules={[{ required: true, message: "Bu Alan Gereklidir" }]}
                        style={{ marginBottom: "10px" }}
                    >
                        <Select
                            size={"large"}
                            placeholder={"Dönem"}
                        >
                            {periods.map(x => <Option value={x.periodNumber}>{x.periodNumber}{": "}{x.periodName}</Option>)}

                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <div className="wrap-input100  m-b-16" id="margine">
                            <Button htmlType='submit' className="login100-form-btn" >
                                Giriş Yap
                            </Button>
                        </div>
                    </Form.Item>
                </Form>

            </div >
        </div >
    </div >)

    return (
        // <I18nProvider locale={locale_I18n}>
        isLoginUI ? <Redirect to="/BackOffice" /> : loginFormHtml
        // </I18nProvider>
    );

};




