// react
import React from 'react';

// third-party
import classNames from 'classnames';
import {
    Link,
    matchPath,
    Redirect,
    Switch,
    Route,
} from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';

// pages
import AccountPageAddresses from './AccountPageAddresses';
import AccountPageDashboard from './AccountPageDashboard';
import AccountPageEditAddress from './AccountPageEditAddress';
import AccountPageOrderDetails from './AccountPageOrderDetails';
import AccountPageOrders from './AccountPageOrders';
import AccountPagePassword from './AccountPagePassword';
import AccountPageProfile from './AccountPageProfile';
import AccountPageClientExtract from './AccountPageClientExtract';
import AccountPageClientAccountSummary from './AccountPageClientAccountSummary';

import ClientHeader from '../shared/ClientHeader';

import { FormattedMessage } from 'react-intl';

export default function AccountLayout(props) {
    const { match, location } = props;

    const breadcrumb = [
        { title: <FormattedMessage id="accountPage.home" defaultMessage="Ana Sayfa" />, url: '' },
        { title: <FormattedMessage id="accountPage.myAccount" defaultMessage="Hesabım" />, url: '' },
    ];

    const links = [
        { title: <FormattedMessage id="accountPage.clientExtract" />, url: 'client-extract' },
        { title: <FormattedMessage id="accountPage.clientAccountSummary" />, url: 'client-account-summary' },
        { title: <FormattedMessage id="accountPage.clientPayment" />, url: 'client-payment' },

        // { title: <FormattedMessage id="accountPage.dashboard" defaultMessage="Gösterge Paneli" />, url: 'dashboard' },
        // { title: <FormattedMessage id="accountPage.editProfile" defaultMessage="Profili Düzenle" />, url: 'profile' },
        // { title: <FormattedMessage id="accountPage.orderHistory" defaultMessage="Sipariş Geçmişi" />, url: 'orders' },
        // { title: <FormattedMessage id="accountPage.orderDetails" defaultMessage="Profili Düzenle" />, url: 'orders/5' },
        // { title: <FormattedMessage id="accountPage.addresses" defaultMessage="Adresler" />, url: 'addresses' },
        // { title: <FormattedMessage id="accountPage.editAddress" defaultMessage="Adresi düzelt" />, url: 'addresses/5' },
        // { title: <FormattedMessage id="header.password" defaultMessage="Parola" />, url: 'password' },
        // { title: <FormattedMessage id="header.logout" defaultMessage="Çıkış yap" />, url: 'login' },
    ].map((link) => {
        const url = `${match.url}/${link.url}`;
        const isActive = matchPath(location.pathname, { path: url, exact: true });
        const classes = classNames('account-nav__item', {
            'account-nav__item--active': isActive,
        });

        return (
            <li key={link.url} className={classes}>
                <Link to={url}>{link.title}</Link>
            </li>
        );
    });

    return (
        <React.Fragment>
            <PageHeader header={<FormattedMessage id="accountPage.myAccount" />} breadcrumb={breadcrumb} />

            <div className="block">
                < div className="container" >
                    <div className="row">
                        <div className="col-12 col-lg-2 d-flex">
                            <div className="account-nav flex-grow-1">
                                <h4 className="account-nav__title"><FormattedMessage id="accountPage.Navigation" defaultMessage="Navigasyon" /></h4>
                                <ul>{links}</ul>
                            </div>
                        </div>

                        <div className="col-12 col-lg-10  mt-lg-0">
                            <ClientHeader />
                            <Switch>
                                <Redirect exact from={match.path} to={`${match.path}/dashboard`} />
                                <Route exact path={`${match.path}/client-extract`} component={AccountPageClientExtract} />
                                <Route exact path={`${match.path}/client-account-summary`} component={AccountPageClientAccountSummary} />
                                <Route exact path={`${match.path}/dashboard`} component={AccountPageDashboard} />
                                <Route exact path={`${match.path}/profile`} component={AccountPageProfile} />
                                <Route exact path={`${match.path}/orders`} component={AccountPageOrders} />
                                <Route exact path={`${match.path}/orders/:orderId`} component={AccountPageOrderDetails} />
                                <Route exact path={`${match.path}/addresses`} component={AccountPageAddresses} />
                                <Route exact path={`${match.path}/addresses/:addressId`} component={AccountPageEditAddress} />
                                <Route exact path={`${match.path}/password`} component={AccountPagePassword} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
