import { payment_add, payment_changeStatus, payment_delete, payment_erpTransfer, payment_getListByFilter, payment_newFicheNo, payment_update } from "../helper/magic_string";
import { Payment, PaymentFilter, PaymentMethod, PaymentStatus, PagedResult, Backoffice_PaymentDto } from "../models/models";
import { ApiResponse,request } from "./api";


export async function add(collection: Payment): Promise<ApiResponse<Payment>> {
    return await request<Payment>(payment_add, "POST", collection);
}
export async function update(model: Payment): Promise<ApiResponse<Payment>> {
    return await request<Payment>(payment_update, "POST", model);
}
export async function deleteCollection(id: Number): Promise<ApiResponse<null>> {
    return await request<null>(`${payment_delete}/${id}`, "DELETE");
}

export async function erpTransfer(paymentsId:  number[]): Promise<ApiResponse<null>> {
    return await request<null>(`${payment_erpTransfer}`, "POST",paymentsId);
}

export async function getListByFilter( filter: PaymentFilter): Promise<ApiResponse<PagedResult<Backoffice_PaymentDto>>> {
    let params = new URLSearchParams();
    params.append('orderByType', String(filter.orderByType));
    params.append('startDate', String(filter.startDate));
    params.append('endDate', String(filter.endDate));
    params.append('searchValue', String(filter.searchValue));
    params.append('pageNumber', String(filter.pageNumber));
    params.append('pageSize', String(filter.pageSize));
    filter.searchColumns.forEach(x => {params.append('searchColumns', x.toString());});
    filter.paymentStatus.forEach(x => {params.append('paymentStatus', x.toString());});
    filter.erpStatus.forEach(x => {params.append('erpStatus', x.toString());});
    return await request<PagedResult<Backoffice_PaymentDto>>(`${payment_getListByFilter}?${params}`,'GET')
}

export async function changeStatus(collectionsId:  number[], collectionStatus: PaymentStatus): Promise<ApiResponse<null>> {

    return await request<null>(`${payment_changeStatus}?&status=${collectionStatus}`, "PATCH",collectionsId);
}

export async function newFicheNo(method: PaymentMethod): Promise<ApiResponse<string[]>> {
    return await request<string[]>(`${payment_newFicheNo}?method=${method}`, "GET");
}

