import * as React from 'react';
import { Input, Button, Form, Select, Layout, Tooltip, Table } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { Translate } from '../../../i18n/Translate';



const { Content } = Layout;
const { Option } = Select;
interface Props {
    netahsilatUrl: string;
    netahsilatHashKey: string
    addOrUpdateNetahsilat: (netahsilat) => {}
    deleteNetahsilat: (netahsilat) => {}
}

export const Netahsilat: React.FC<Props> = ({ netahsilatUrl, netahsilatHashKey, addOrUpdateNetahsilat, deleteNetahsilat }) => {
    const intl = useIntl();
    const [netahsilatParameterForm] = Form.useForm();


    const columns = [
        {
            key: "url",
            title: Translate("Url"),
            dataIndex: 'netahsilatUrl',
        },
        {
            key: 'hashKey',
            title: Translate("HashKey"),
            dataIndex: 'netahsilatHashKey',
        },
        {
            key: 'id',
            width: 200,
            render: (text: string, netahsilat: any) =>
                <>
                    <Tooltip placement="top" color="blue" title={Translate("Edit")}>
                        <Button icon={<EditOutlined />} onClick={() => {
                            netahsilatParameterForm.setFieldsValue(netahsilat)
                        }} />
                    </Tooltip>
                    <Tooltip placement="top" color="red" title={Translate("Delete")}>
                        <Button icon={<DeleteOutlined />} onClick={() => {
                            deleteNetahsilat(netahsilat)
                            netahsilatParameterForm.resetFields();
                        }} />
                    </Tooltip>
                </>
        },
    ];




    return (
        <div>
            <Content>
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={netahsilatParameterForm}
                    onFinish={(netahsilat) => {
                        addOrUpdateNetahsilat(netahsilat)
                        netahsilatParameterForm.resetFields();
                    }
                    }
                    autoComplete="off"
                >
                    <Form.Item
                        label={Translate("URL")}
                        name="netahsilatUrl"
                        rules={[{ required: true, message: 'Please input your URL!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={Translate("Key")}
                        name="netahsilatHashKey"
                        rules={[{ required: true, message: 'Please input your Key!' }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            {Translate("Save")}
                        </Button>
                    </Form.Item>
                </Form>

                <Table pagination={false} bordered size="small" columns={columns} dataSource={[{ netahsilatUrl, netahsilatHashKey }]} />

            </Content>



        </div >
    );

}
