// react
import React, { Component } from "react";
// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { FormattedMessage } from "react-intl";
import { ListGroup } from "react-bootstrap";
import MediaQuery from 'react-responsive'
import Currency from "../shared/Currency";

// application
import { CollectionBonusType, PaymentMethod } from "../../../../models/models";
import AsyncAction from "../shared/AsyncAction";
import ShopPageCartPriceSummary from "./ShopPageCartPriceSummary";
import Wizard from "../shared/Wizard";
import * as dbPaymentType from "../../api_js/paymentType";
import { getCart, updatePaymentTypeOfCart, } from "../../store/cart";
import { currentStep } from "../../store/general";
import ClientHeader from '../shared/ClientHeader';

// data stubs
import theme from "../../data/theme";
import { OperationType } from "../../enum/enumB2B";

class ShopPageOrderPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientAdresses: [],
            paymentTypes: [],
            validationPaymentType: false
        };
    }

    componentDidMount() {
        this.getPaymentTypes();
        this.props.currentStep(1);
        this.validationPaymentType(this.props.cart);
    }

    getPaymentTypes() {
        dbPaymentType.getPaymentTypeDtos()
            .then((response) => {
                if (response.ok) {
                    this.setState({ paymentTypes: response.data });
                } else console.log(response.message);
            })
            .catch((err) => console.log(err.message));
    }





    updateWindowDimensions() {
        //TODO RESPONSİVE TASARIM İÇİN SİLİNECEK
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    validationPaymentType(cart) {
        if (cart.paymentTypeId != null) {
            this.setState({ validationPaymentType: true })
        } else {
            this.setState({ validationPaymentType: false })
        }
    }

    renderCollectionTypes() {
        const { updatePaymentTypeOfCart, cart } = this.props;
        const { paymentTypes } = this.state;
        console.log(paymentTypes);
        const paymentTypeList = paymentTypes.map((x) => (
            <AsyncAction
                action={() => updatePaymentTypeOfCart(cart.cartId, x.paymentTypeId).then(x => this.validationPaymentType(x.cart))}
                render={({ run, loading }) => {
                    const classes = classNames({
                        "btn-loading": loading,
                    });
                    const active = cart.paymentTypeId == x.paymentTypeId;
                    return (
                        <>
                            <MediaQuery minWidth={769}>
                                <div className={`col-3 ${classes} pt-2 pb-2`} onClick={run}>
                                    <div
                                        className={`card cart-order-parameters ${active && "g-select-order-parameters"
                                            }`}
                                    >
                                        <div className={"mt-2 ml-2 mr-2 spod_group_icon"}>
                                            <i class={active && "fas fa-check-circle icon spod-select-order-parameters__icon"} ></i>
                                            <i class="fas fa-ellipsis-v float-right"></i>
                                        </div>
                                        <div
                                            class="d-flex"
                                            style={{ minHeight: "75px" }}
                                        >
                                            <table className="spoc-adress-style m-auto text-center">
                                                <tr>
                                                    <td>
                                                        <bold>{<FormattedMessage id={`payment.${PaymentMethod[x.paymentMethod]}`} />}</bold>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "} <Currency value={x.subtotal} /> {`+ KDV`}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {x.collectionBonusType != CollectionBonusType.BonusPoint && `Kazancınız :`}
                                                        <Currency value={x.collectionTypeDiscount} />
                                                        {``} {" "}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </MediaQuery>
                            <MediaQuery maxWidth={768}>
                                <ListGroup.Item
                                    className={`g-select-order-parameters-line ${classes} ${active && "g-select-order-parameters"}`}
                                    onClick={run}
                                >
                                    <>
                                        <FormattedMessage id={`payment.${PaymentMethod[x.paymentMethod]}`} />
                                        {` : `} <Currency value={x.subtotal} />
                                        {` + KDV     :  
                                        ${x.collectionBonusType != CollectionBonusType.BonusPoint && "Kazancınız :"}`}
                                        <Currency value={x.collectionTypeDiscount} />
                                    </>

                                </ListGroup.Item>
                            </MediaQuery>
                        </>
                    )
                }}
            />
        ));
        return (<><MediaQuery minWidth={769} children={<div className="row">{paymentTypeList}</div>} />
            <MediaQuery maxWidth={768} children={<ListGroup>{paymentTypeList}</ListGroup>} />  </>)
    }




    render() {
        const breadcrumb = [
            { title: <FormattedMessage id="home" />, url: "" },
            { title: <FormattedMessage id="shopCart.shoppingCart" />, url: "" },
        ];

        return (
            <React.Fragment>
                <div className={"spod-parent-container"}>
                    <div className={"spod-header"}>

                    </div>
                    <div className="spod-content">
                        <Wizard
                            captions={[
                                <FormattedMessage id="delivery" />,
                                <FormattedMessage id="collection" />,
                                <FormattedMessage id="summary" />,
                            ]}
                        />
                        <ClientHeader />
                        <Helmet>
                            <title>{`${theme.name}`}</title>
                        </Helmet>
                        <div className="spc-group-box-shadow">
                            <h6>Tahsilat Türü </h6>
                            {this.renderCollectionTypes()}
                        </div>
                    </div>
                    <ShopPageCartPriceSummary
                        btnInfo={{
                            validation: this.state.validationPaymentType,
                            btnName: "proceedToCheckout",
                            operationType: OperationType.PaymentPage,
                        }}
                    />
                </div>
            </React.Fragment >
        );
    }
}


const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    getCart,
    updatePaymentTypeOfCart,
    currentStep,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageOrderPayment);
