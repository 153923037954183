import { Client } from "../models/models";
import { ApiResponse, request } from "./api";

export async function getList(): Promise<ApiResponse<Client[]>> {
    return await request<Client[]>("clients/getList");
}

export async function add(model: Client): Promise<ApiResponse<Client>> {
    return await request<Client>(`clients/add`, "POST", model);
}

export async function deleteClient(id: number): Promise<ApiResponse<Client>> {
    return await request<Client>(`clients/delete/${id}`, "DELETE");
}

export async function update(model: Client): Promise<ApiResponse<Client>> {
    return await request<Client>("clients/update", "POST", model);
}




export async function getAllClientCarts(): Promise<ApiResponse<Client[]>> {
    return await request<Client[]>("Cart");
}

// export async function cariSepetAl(id: number): Promise<ApiResponse<SepetDTO>> {
//     return await request<SepetDTO>(`Cari/${id}/Sepet`);
// }
