import { ISalesman, OrderByType,IOrderDetailDto, PaymentMethod } from "./models";


export const initial_OrderFilter = {
    columnName: "",
    searchValue: "",
    searchColumns: [],
    orderByType: OrderByType.Desc,
    pageNumber: 1,
    pageSize: 10,
    startDate: "",
    endDate: "",
    orderStatus: [0,2],
    orderErpStatus:[]
}


export const orderLine={
    name:"",
    image:"",
    lineTotalPrice:0,
    unitPrice:0,
    quantity:0
}


export const initial_OrderSuccess= {
        orderNo:"",
        date_:new Date(),
        userFullName:"", 
        subtotal:0,
        netTotal:0,
        priceWithVat:0,
        discount:0,
        paymentMethod:PaymentMethod.Cash,
        orderLines :[],
        billingAddress: { id:0,description:"", phone:"",phone2:"",email:"",city:"", town:"",address:"",postcode:"",taxNr:"",taxOffice:""},
        shippingAddress:{ id:0,receiverName:"", phone:"",phone2:"",email:"",city:"", town:"",address:"",postcode:"",taxNr:"",taxOffice:""},
        deliveryType : {name:"",description:""}
}
export const initial_ClientAccountPaymentSuccess= {
        ficheNo: "",
        date_ :new Date(),
        clientDescription: "",
        clientCode :"",
        description: "",
        amount:0 ,
        paymentMethod:PaymentMethod.CreditCard
}

export const initial_CollectionFilter = {
    columnName: "",
    searchValue: "",
    searchColumns: [],
    orderByType: OrderByType.Desc,
    pageNumber: 1,
    pageSize: 10,
    startDate: "",
    endDate: "",
    paymentStatus: [0,2],
    erpStatus:[]
}

export let Salesman:ISalesman = {
    code:"",
    firstName:"",
    lastName:"",
    username:"",
    email:"",
    password:"",
    confirmPassword:"",
    id:0,
    idERP:undefined,
    cariler:[],
    parameters:"",
    yetkiGrubu:0,
    allClientAuth:false
 }



 export let initial_OrderDetailDto:IOrderDetailDto = {
     orderId: 0,
     billingAddress: {
        Id: 0,
        phone: "",
        phone2: "",
        email: "",
        city: "",
        town: "",
        address: "",
        postCode: "",
        taxNr: "",
        taxOffice: "",
        description: "",
        order:undefined
     },
     shippingAddress: {
        Id: 0,
        receiverName: "",
        receiverTC: "",
        phone: "",
        phone2: "",
        email: "",
        city: "",
        town: "",
        address: "",
        postCode: "",
        description: "",
        taxNr: "",
        taxOffice: "",
        code: "",
        order:undefined
     },
     items: []
 }


 