// react
import * as React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';
import * as DbParameters from '../../api_js/parameters';

export default function FooterContacts(props) {

    const [periodParams, setPeriodParams] = React.useState();

    React.useEffect(() => {
        getAllPeriodParameters()
    }, []);


    const getAllPeriodParameters = () => {
        DbParameters.getAllPeriodParameters()
            .then(response => {
                if (response.ok) {
                    setPeriodParams(response.data);
                    console.log(response.data)
                }
                else alert(response.message);
            })
            .catch(err => alert(err.message));
    }

    return (
        <div className="site-footer__widget footer-contacts">
            <h5 className="footer-contacts__title"><FormattedMessage id="footer.contactUs" /></h5>

            <div className="footer-contacts__text">
                {periodParams?.description}
            </div>

            <ul className="footer-contacts__contacts">
                <li>
                    <i className="footer-contacts__icon fas fa-globe-americas" />
                    {periodParams?.address}
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-envelope" />
                    {periodParams?.email}
                </li>
                <li>
                    <i className="footer-contacts__icon fas fa-mobile-alt" />
                    {periodParams?.phoneNumber}
                </li>
            </ul>
        </div>
    );
}
