
import * as Icons from '@ant-design/icons';
import * as React from 'react';
import { IOrderLineItem, IOrderDetailDto, } from '../../../../models/models'
import { Descriptions, Table, Tabs } from 'antd';
import { useIntl } from 'react-intl';
import { Translate } from '../../i18n/Translate';
import Currency from '../../components/Currency';
import * as dbOrder from '../../../../api_ts/order';
import { initial_OrderDetailDto } from '../../../../models/initial_models';

interface Props {
    orderId: number;
}

export const OrderDetails: React.FC<Props> = ({ orderId }) => {
    const intl = useIntl();

    React.useEffect(() => {
        getOrderDetailDto()
    }, [orderId]);

    const [orderDetail, setOrderDetail] = React.useState<IOrderDetailDto>(initial_OrderDetailDto);

    const getOrderDetailDto = () => {
        dbOrder.getOrderDetailDtoByOrderId(orderId)
            .then(response => {
                if (response.ok) {
                    debugger;
                    setOrderDetail(response.data);
                }
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }



    const columns = [
        {
            title: Translate("Code"),
            key: 'id',
            dataIndex: 'code',
            width: 100,
            // render: (text: string, orderLine: BackOffice_OrderLineItem) => orderLine.item.code
        },
        {
            title: Translate("Description"),
            key: 'id',
            dataIndex: 'description',
            width: 400,
            // render: (text: string, orderLine: BackOffice_OrderLineItem) => orderLine.item.description
        },
        {
            title: Translate("Description2"),
            key: 'id',
            dataIndex: 'description2',
            width: 100,
            // render: (text: string, orderLine: BackOffice_OrderLineItem) => orderLine.item.description2
        },
        {
            title: Translate("Quantity"),
            dataIndex: 'quantity',
            width: 50,
        },
        {
            title: Translate("Unit"),
            key: 'id',
            dataIndex: 'unit',
            width: 100,
            render: (text: string, orderLine: IOrderLineItem) => `${orderLine.unitCode} ${orderLine.unitDescription}`
        },
        {
            title: Translate("UnitPrice"),
            dataIndex: 'unitPrice',
            width: 100,
            align: 'right' as const,
            render: (_: string, orderLine: IOrderLineItem) => <Currency value={orderLine.unitPrice} />
        },
        // {
        //     title: Translate("Total"),
        //     dataIndex: 'kdvMatrahi',
        //     width: 100,
        // },
        {
            title: Translate("KDVOrani"),
            dataIndex: 'vat',
            width: 50,
            render: (text: string, orderLine: IOrderLineItem) => `${orderLine.vat}%`
        },
        {
            title: Translate("KDV"),
            dataIndex: 'priceWithVAT',
            width: 100,
            align: 'right' as const,
            render: (_: string, orderLine: IOrderLineItem) => <Currency value={orderLine.priceWithVAT} />

        },
        {
            title: Translate("Subtotal"),
            dataIndex: 'subtotal',
            width: 100,
            align: 'right' as const,
            render: (_: string, orderLine: IOrderLineItem) => <Currency value={orderLine.subtotal} />
        },
    ];


    return (
        <div>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab={<span><Icons.UserOutlined />{Translate("OrderDetailsList")}</span>} key="1">
                    <div className="site-card-border-less-wrapper">
                        <Table
                            bordered
                            size='small'
                            columns={columns}
                            dataSource={orderDetail.items} />

                        <Descriptions
                            labelStyle={{ fontWeight: "bold" }}
                            layout="vertical"
                            size="small"
                            bordered
                        >
                            <Descriptions.Item label="Fatura Adresi">
                                {` ${orderDetail.billingAddress.description}
                                   ${orderDetail.billingAddress.address}
                                   ${orderDetail.billingAddress?.postCode ?? ""}
                                   ${orderDetail.billingAddress.email}
                                   ${orderDetail.billingAddress.phone}
                                   ${orderDetail.billingAddress.phone2}
                                   `}
                                < br />
                                {` ${orderDetail.billingAddress.city}/${orderDetail.billingAddress.town}`}
                            </Descriptions.Item>
                            <Descriptions.Item label="Teslimat Adresi">
                                {` ${orderDetail.shippingAddress.description}
                                   ${orderDetail.shippingAddress.receiverName}
                                   ${orderDetail.shippingAddress.receiverTC}
                                   ${orderDetail.shippingAddress.address}
                                   ${orderDetail.shippingAddress?.postCode ?? ""}
                                   ${orderDetail.shippingAddress.email}
                                   ${orderDetail.shippingAddress.phone}
                                   ${orderDetail.shippingAddress.phone2} `}
                                < br />
                                {` ${orderDetail.shippingAddress.city}/${orderDetail.shippingAddress.town}`}
                            </Descriptions.Item>

                        </Descriptions>
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};
