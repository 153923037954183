import { RESET_CART, UPDATE_CART } from './cartActionTypes';

/**
 * @param {array} items
 * @param {object} product
 * @param {array} options
 * @return {number}
 */


function updateCart(state, cart) {
    return {
        ...state,
        cartId: cart.cartId,
        priceWithVAT: cart.priceWithVAT,
        paymentTypeId: cart.paymentTypeId,
        shippingAddressId: cart.shippingAddressId,
        billingAddressId: cart.billingAddressId,
        paymentMethod: cart.paymentMethod,
        deliveryTypeId: cart.deliveryTypeId,
        lastItemId: state.lastItemId + 1,
        quantity: cart.totalItemCount,
        cartItems: cart.cartItems,
        subtotal: cart.subtotal,
        bonus: cart.bonus,
        paymentTypeDiscount: cart.paymentTypeDiscount,
        netAmount: cart.netAmount,
    };
}



const initialState = {
    cartId: 0,
    lastItemId: 0,
    paymentTypeId: null,
    deliveryTypeId: null,
    shippingAddressId: null,
    billingAddressId: null,
    paymentMethod: null,
    quantity: 0,
    cartItems: [],
    priceWithVAT: 0,
    subtotal: 0,
    collectionTypeDiscount: 0,
    netAmount: 0,
    bonusPoint: 0
};

//TODO https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store?page=1&tab=votes#tab-top
export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_CART:
            return updateCart(state, action.cart);
        case RESET_CART:
            return initialState
        default:
            return state;
    }
}
