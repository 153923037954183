// react
import React from "react";



interface Props {
    value: number;
}
export default function Currency(props: Props) {
    const { value } = props;

    const result = new Intl.NumberFormat('tr-TR', { minimumFractionDigits: 2 }).format(value)
    https://aligoren.com/javascript-currency-formatting/
    // const result = new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', minimumFractionDigits: 2 }).format(value)

    return <React.Fragment>{`${result}`}</React.Fragment>;
}

