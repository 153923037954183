import { Client, ISalesman } from "../models/models";
import { ApiResponse, request } from "./api";

export async function getList(): Promise<
ApiResponse<ISalesman[]>> {
    return await request<ISalesman[]>("salesmen/getList");
}

export async function getById(id: number): Promise<ApiResponse<ISalesman>> {
    return await request<ISalesman>(`salesmen/getById/${id}`);
}

export async function add(model: ISalesman): Promise<ApiResponse<ISalesman>> {
    return await request<ISalesman>(`salesmen/add`, "POST", model);
}

export async function deleteSalesman(id: number): Promise<ApiResponse<null>> {
    return await request<null>(`salesmen/delete/${id}`, "DELETE");
}

export async function update(model: ISalesman): Promise<ApiResponse<null>> {
    return await request<null>(`salesmen/update`, "PATCH", model);
}

export async function getClientsBySalesmanId(salesmanId: number): Promise<ApiResponse<Client[]>> {
    return await request<Client[]>(`salesmen/getClientsOfSalemanById/${salesmanId}`,"GET");
}

export async function assignClientToSalesman(salesmanId: number,clientId: number): Promise<ApiResponse<null>> {
    return await request<null>(`salesmen/addSalesmanClient?salesmanId=${salesmanId}&clientId=${clientId}`,"GET");
}

export async function deleteClientToSalesman(salesmanId: number,clientId: number): Promise<ApiResponse<null>> {
     return await request<null>(`salesmen/deleteSalesmanClient?salesmanId=${salesmanId}&clientId=${clientId}`,"DELETE");
}



//B2B KULLANIM



