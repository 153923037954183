import { BackOfficeCartDetailDTO, BackOfficeCartDTO } from "../models/models";
import { ApiResponse, request } from "./api";


export async function getAllClientCarts(): Promise<ApiResponse<BackOfficeCartDTO[]>> {
    return await request<BackOfficeCartDTO[]>("carts/ClientsCarts");
}

export async function getCartDetail(cartId:number): Promise<ApiResponse<BackOfficeCartDetailDTO>> {
    return await request<BackOfficeCartDetailDTO>(`carts/ClientCartDetail/${cartId}`);
}




