import {  MailProvider } from "../models/models";
import { ApiResponse, request } from "./api";

export async function getList(): Promise<ApiResponse<MailProvider[]>> {
    return await request<MailProvider[]>("mailProviders/getList","GET");
}

export async function add(mailProvider: MailProvider): Promise<ApiResponse<MailProvider>> {
    return await request<MailProvider>("mailProviders/add", "POST", mailProvider);
}

export async function update(mailProvider: MailProvider): Promise<ApiResponse<MailProvider>> {
    return await request<MailProvider>("mailProviders/update", "PATCH", mailProvider);
}

export async function deleteMailProvider(id: number): Promise<ApiResponse<null>> {
    return await request<null>(`mailProviders/delete/${id}`, "DELETE");
}