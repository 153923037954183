import { ItemPrice, SpecialCode } from "../models/models";
import { ApiResponse, request } from "./api";

export async function add(model: ItemPrice): Promise<ApiResponse<ItemPrice>> {
    return await request<ItemPrice>(`itemPrices/add`, "POST", model);
}
export async function deleteItemPrice(id: number): Promise<ApiResponse<null>> {
    return await request<null>(`itemPrices/delete/${id}`, "DELETE");
}
export async function update(model: ItemPrice): Promise<ApiResponse<null>> {
    return await request<null>(`itemPrices/update`, "PATCH", model);
}
export async function getListByItemId(id: number): Promise<ApiResponse<ItemPrice[]>> {
    return await request<ItemPrice[]>(`items/${id}/Fiyatlar`, "GET");
}

export async function getItemPriceById_API(id: number): Promise<ApiResponse<ItemPrice>> {
    return await request<ItemPrice>(`itemPrices/${id}/Fiyatlar`, "GET");
}

