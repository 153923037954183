import { useIntl } from 'react-intl';
import React, { useState, useEffect } from 'react';
import { MailProvider } from '../../../../models/models';
import * as DbMailProvider from '../../../../api_ts/mailProvider';
import { Button, Tabs, Table, message, Modal, Form, InputNumber, Input, Tooltip } from 'antd';
import { PlusSquareOutlined, UserOutlined, EditOutlined, DeleteOutlined, EyeTwoTone, EyeInvisibleOutlined, BgColorsOutlined, GoldOutlined } from '@ant-design/icons';
import { useStateWithCallbackLazy as useStateCallback } from 'use-state-with-callback';
import { MailSendingConditions } from './MailSendingConditions';
import { MailTemplates } from './MailTemplates';
import { Translate } from '../../i18n/Translate';


const { TabPane } = Tabs;

export const MailProviders: React.FC = () => {

    const intl = useIntl();
    const [mailProviders, setMailProviders] = useState<MailProvider[]>([]);
    const [loading, setLoading] = useStateCallback(false);
    const [flags, setFlags] = useState({ mailProviderModalVisible: false, operation: "" });
    const [mailProviderForm] = Form.useForm();


    useEffect(() => {
        getAllMailProviders()
    }, [])

    const columns = [
        {
            key: 'description',
            dataIndex: 'description',
            title: Translate("Description"),
        },
        {
            key: 'title',
            title: Translate("MailTitle"),
            dataIndex: 'title'
        },
        {
            key: 'host',
            title: Translate("Host"),
            dataIndex: 'host'
        },
        {
            key: 'port',
            title: Translate("Port"),
            dataIndex: 'port'
        },
        {
            key: 'sender',
            title: Translate("Sender"),
            dataIndex: 'sender'
        },
        {
            key: 'id',
            width: 100,
            render: (text: string, mailProvider: MailProvider) =>
                <>
                    <Tooltip placement="top" color="blue" title={Translate("Edit")}>
                        <Button icon={<EditOutlined />} onClick={() => {
                            mailProviderForm.resetFields()
                            setFlags({ ...flags, mailProviderModalVisible: true, operation: "Update" })
                            fillMailProviderForm(mailProvider)
                        }
                        } />
                    </Tooltip>
                    <Tooltip placement="top" color="red" title={Translate("Delete")}>
                        <Button icon={<DeleteOutlined />} onClick={() => deleteMailProvider(mailProvider.id)} />
                    </Tooltip>
                </>
        },
    ];

    const getAllMailProviders = () => {
        return DbMailProvider.getList()
            .then(response => {
                if (response.ok) {
                    setMailProviders(response.data);
                }
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }


    const addOrUpdateMailProvider = async (mailProvider: MailProvider) => {
        if (mailProvider.id == 0)
            IsSuccessful(await DbMailProvider.add(mailProvider))
        else
            IsSuccessful(await DbMailProvider.update(mailProvider))

    }


    const deleteMailProvider = async (mailProviderId: number) => {
        if ((await DbMailProvider.deleteMailProvider(mailProviderId)).ok) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }))
            getAllMailProviders();
        }
        else
            message.error(intl.formatMessage({ id: 'Operation.Error' }))
    }


    const IsSuccessful = (action: any) => {
        setLoading(true, () => {
            if (action.ok) {
                getAllMailProviders()
                    .then(() => {
                        mailProviderForm.resetFields()
                        setFlags({ ...flags, mailProviderModalVisible: false, operation: "Add" })
                        setLoading(false, () => message.success(intl.formatMessage({ id: 'Operation.Success' })))
                    })
            } else {
                message.error(intl.formatMessage({ id: 'Operation.Error' }))
            }
        })
    }


    const fillMailProviderForm = (mailProvider: MailProvider) => {
        mailProviderForm.setFieldsValue(mailProvider);
    }

    const header = (<Button type="primary" className="ml-auto mb-1" onClick={() => {
        mailProviderForm.resetFields()
        setFlags({ ...flags, mailProviderModalVisible: true, operation: "Add" })
    }}
        icon={<PlusSquareOutlined style={{ verticalAlign: 'middle', fontSize: '20px' }} />} >
        <span style={{ verticalAlign: 'middle' }} >{Translate("Add")}</span>
    </Button>)

    const formItemLayout = { labelCol: { span: 7 }, wrapperCol: { span: 17 } };

    return (
        <div>
            <Tabs
                defaultActiveKey="1"
                tabBarStyle={{ marginBottom: "3.5px" }}
            >
                <TabPane tab={<span><UserOutlined />{Translate("MailProviders")}</span>} key="1">
                    <div className="site-card-border-less-wrapper">
                        <div className="d-flex">{header} </div>
                        <Table bordered size="small" columns={columns} dataSource={mailProviders} />
                    </div>
                </TabPane>
                <TabPane tab={<span><GoldOutlined />{Translate("MailSendingConditions")}</span>} key="2">
                    <MailSendingConditions mailProviders={mailProviders} />
                </TabPane>
                <TabPane tab={<span><BgColorsOutlined /> {Translate("MailTemplate")}</span>} key="3">
                    <MailTemplates />
                </TabPane>



            </Tabs>
            <Modal
                title={Translate("MailProvider")}
                visible={flags.mailProviderModalVisible}
                onCancel={() => setFlags({ ...flags, mailProviderModalVisible: false, operation: "Add" })}
                onOk={() => { mailProviderForm.submit() }}
                okText={Translate(flags.operation)}
                cancelText={Translate("Cancel")}
                confirmLoading={loading}
            >
                <Form
                    form={mailProviderForm}
                    layout={"horizontal"}
                    onFinish={addOrUpdateMailProvider}
                    {...formItemLayout}
                    size={"small"}
                    labelAlign={"left"}
                >
                    <Form.Item
                        name={"id"}
                        fieldKey={"id"}
                        hidden={true}
                        initialValue={0} >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={Translate("Description")}
                        name="description"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate("Description")} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("Host")}
                        name="host"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate("Host")} />
                    </Form.Item>

                    <Form.Item
                        label={Translate("Sender")}
                        name="sender"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate("Sender")} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("Password")}
                        name="password"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input.Password
                            placeholder={Translate("Password")}
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>


                    <Form.Item
                        label={Translate("Port")}
                        name="port"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <InputNumber placeholder={Translate('Port')} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("MailTitle")}
                        name="title"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate('MailTitle')} />
                    </Form.Item>

                </Form>
            </Modal>
        </div >
    );


}
