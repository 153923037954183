import { LOCALES } from '../Locales';

export default {
    [LOCALES.GERMAN]: {
        // MENU

        "Menu.Records": "Records",
        "Menu.Records.Orders": "Orders",
        "Menu.Records.Payments": "Payments",
        "Menu.Records.Offers": "Offers",
        "Menu.Records.ClientCarts": "Carts",
        "Menu.Records.Email": "E-mail",
        "Menu.Records.SMS": "SMS",

        "Menu.Definitions": "Definitions",
        "Menu.Definitions.Units": "Units",
        "Menu.Definitions.Warehouses": "Geschäfts",
        "Menu.Definitions.Salespeople": "Salespeople",
        "Menu.Definitions.Products": "Products",
        "Menu.Definitions.Products.Products": "Products",
        "Menu.Definitions.Products.SpecialCodes": "Special Codes",
        "Menu.Definitions.Products.GroupCodes": "Group Codes",
        "Menu.Definitions.Clients": "Clients",
        "Menu.Definitions.Collections": "Sammlungen",
        "Menu.Definitions.CollectType": "Sammeltyp",
        // "Menu.Definitions.Payments.CreditCard": "Credit Card",
        // "Menu.Definitions.Payments.Transfer": "Transfer",
        // "Menu.Definitions.Payments.Check": "Check",
        // "Menu.Definitions.Payments.PromissoryNote": "Promissory Note",
        "Menu.Definitions.Logistics": "Logistics",
        "Menu.Definitions.TermsOfSale": "Terms Of Sale",
        "Menu.Definitions.EmailSendingConditions": "E-mail Sending Conditions",
        "Menu.Definitions.SMSSendingConditions": "SMS Sending Conditions",

        "Menu.SystemManagement": "System Management",
        "Menu.SystemManagement.Firms": "Firm Definitions",
        "Menu.SystemManagement.Firms.Firms": "Firms & Periods",
        "Menu.SystemManagement.Firms.Parameters": "Parameters",
        "Menu.SystemManagement.Firms.FirmLogo": "Firm Logo",
        "Menu.SystemManagement.Firms.Address": "Address",
        "Menu.SystemManagement.Firms.Telephone": "Telephone",
        "Menu.SystemManagement.ERP": "ERP Definitions",
        "Menu.SystemManagement.ERP.Logo": "Logo",
        "Menu.SystemManagement.EmailServer": "E-mail Server Definitions",
        "Menu.SystemManagement.SMSServer": "SMS Server Definitions",
        "Menu.SystemManagement.EPayment": "E-Payment Definitions",
        "Menu.SystemManagement.EPayment.NetPayment": "Net Payment",
        "Menu.SystemManagement.Users": "Users",

        "Menu.Integrations": "Integrations",
        "Menu.Integrations.LogoIntegration": "Logo Integration",


        // COMMON

        "Add": "Hinzufügen",
        "BackOffice": "Back Office",
        "Cancel": "Cancel",
        "Close": "Close",
        "Code": "Code",
        "Date": "Date",
        "DefaultLanguage": "Default Language",
        "Delete": "Löschen",
        "Update":"Aktualisieren",
        "Description": "Beschreibung",
        "Description-2": "Beschreibung-2",
        "Description-3": "Beschreibung-3",
        "Edit": "Bearbeiten",
        "Home": "Home",
        "Images": "Images",
        "InUse": "In Benutzung",
        "LastName": "Nachname",
        "LineNo": "Linie Nr",
        "Name": "Name",
        "Phone": "Phone",
        "Operation.Success": "Der Vorgang wurde erfolgreich abgeschlossen",
        "Operation.Error": "Der Vorgang konnte nicht abgeschlossen werden",
        "Save": "Save",
        "Email": "E-mail",
        "E-mail": "E-mail",
        "Active": "Active",
        "Actions": "Aktionen",
        "Languages": "Languages",
        "Adress": "Ein Kleid",
        "Explanation": "Erläuterung",
        "Quantity": "Quantity",
        "Search": "Suche",
        "Reset": "Zurücksetzen",
        "DecimalDigits": "Decimal Digits",
        "Contact.Info": "Contact Info",
        "Contact.Email": "Contact Email",
        "Contact.Phone": "Contact Phone",
        "Contact.Address": "Contact Address",
        "Default": "Standard",
        "DiscountRate": "Diskontsatz",
        "Clients":"Aktuelle",
        "Client":"Aktuelle",
        "E-Commerce":"E-Commerce",
        "Filter":"Filter",
        "Integration":"Integration",
        "RequiredField":"Pflichtfeld",
        "PleaseSelect":"Bitte Auswählen",
        "All":"Alle",
        "Start":"Start",
        "End":"Ende",
        "Loading":"Wird Geladen",
        "Port":"Port",
        "ProjectName":"Projekt Name",
        "Server":"Server",
        "Cart":"Wagen",
        "Carts":"Wagen",
        "User":"Nutzer",
        "Category":"Kategorie",
        "Version":"Ausführung",
        "Parameters":"Parameters",
        "PriceDecimalPlaces":"Preis Nachkommastellen",
        // ENUMS

        "YerelParaBirimi": "Landeswährung",
        "DovizParaBirimi": "Währungseinheit",

        "Pending": "steht aus",
        "Confirmed": "Bestätigt",
        "Rejected": "Abgelehnt",

        "Pend": "Ausstehend",
        "Confirm": "Bestätigen",
        "Reject": "Ablehnen",

        "TL": "TL",
        "USD": "USD",
        "EUR": "UERO",

        "TahsilatTuru.CariHesap": "Cari Hesap",
        "TahsilatTuru.KrediKarti": "Kredi Kartı",
        "TahsilatTuru.Havale": "Havale",
        "TahsilatTuru.Cek": "Çek",
        "TahsilatTuru.Senet": "Senet",

        // Login

        "Login": "Einloggen",
        "LoginFailed": "Login fehlgeschlagen",
        "Username": "Nutzername",
        "UsernameRequired": "Benutzername erforderlich",
        "Password": "Passwort",
        "PasswordControl": "Password (Control)",
        "PasswordRequired": "Passwort erforderlich",

        //Stores
        "Warehouse":"Geschäft",
        "Warehouses":"Geschäfts",
        "WarehouseList":"Geschäftsliste",
        "Store": "Geschäft",
        "StoresList": "Geschäftsliste",
        "AddStore": "Store hinzufügen",
        "EditStore": "Store bearbeiten",
        "StoreCode": "Store Code",

        // UNITS

        "UnitSets": "Einheitensätze",
        "AddUnitSet": "Einheitensatz hinzufügen",
        "EditUnitSet": "Einheitensatz bearbeiten",
        "UnitSetCode": "Geräte-Set-Code",
        "UnitSetDescription": "Gerätesatz Beschreibung",
        "Unit": "Einheiten",
        "Units": "Einheiten",
        "AddUnit": "Einheit hinzufügen",
        "UnitCode": "Gerätecode",
        "UnitDescription": "Gerätebeschreibung",
        "UnitFact1": "Fabrik 1",
        "UnitFact2": "Fabrik 2",

        // SpecialCodes

        "SpecialCodeDomains": "Special Code Domains",
        "AddSpecialCodeDomain": "Add Special Code Domain",
        "EditSpecialCodeDomain": "Edit Special Code Domain",
        "SpecialCodeDomainName": "Spezieller Code Domain Name",
        "SpecialCodes": "Spezielle Codes",
        "SpecialCodesList": "Liste der speziellen Codes",
        "AddSpecialCode": "Fügen Sie einen speziellen Code hinzu",
        "EditSpecialCode": "Sondercode bearbeiten",
        "SelectSpecialCode": "Select Special Code",

        // GroupCodes

        "GroupCodesList": "Liste der Gruppencodes",
        "AddGroupCode": "Gruppencode hinzufügen",
        "EditGroupCode": "Gruppencode bearbeiten",
        "GroupCode": "Gruppencode",
        "SubGroupCode": "Unter Gruppen Code",
        "ParentGroupCode": "Eltern Gruppen Code",


        // Items
        "Item":"Artikel",
        "Items":"Artikel",
        "ItemsList": "Artikelliste",
        "AddItem": "Artikel hinzufügen",
        "EditItem": "Objekt bearbeiten",
        "ItemAmounts": "Item Amounts",
        "ToplamMiktarFiili": "Toplam Fiili Miktar",
        "ToplamMiktarSevkedilebilir": "Toplam Sevkedilebilir Miktar",
        "ToplamMiktarGercek": "Toplam Gerçek Miktar",
        "MiktarFiili": "Fiili Miktar",
        "MiktarSevkedilebilir": "Sevkedilebilir Miktar",
        "MiktarGercek": "Gerçek Miktar",

        // Prices

        "Prices": "Preise",
        "AddPrice": "Preis hinzufügen",
        "EditPrice": "Preis bearbeiten",
        "TaxIncluded": "Steuern inklusive",
        "UnitPriceCurrency": "Stückpreiswährung",
        "CurrencyType": "Währungstyp",
        "UnitPrice": "Stückpreis",
        "Currency": "Währung",
        "VATIncluded": "Inkl. MwSt",
        "IsActive": "Ist Aktiv",

        // Orders

        "OrdersList": "Bestellliste",
        "AddOrder": "Bestellung hinzufügen",
        "EditOrder": "Reihenfolge bearbeiten",
        "ClientTitle": "Client Title",
        "Total": "Total",
        "KDVOrani": "KDV Oranı",
        "KDV": "KDV",
        "NetTotal": "Net Total",
        "Status": "Status",
        "ChangeStatus": "Change Status",
        "OrderDetails": "Order Details",
        "OrderDetailsList": "Order Details List",

        // Clients
        "ClientCode":"Strom Code",
        "ClientsList": "Stromliste",
        "AddClient": "Strom hinzufügen",
        "EditClient": "Aktuell bearbeiten",
        "AllCurrrentsPermission": "All Currrents Permission",

        // ClientAddress

        "ClientAddresses": "Stromadresse",
        "ClientAddressList": "Aktuelle Adressliste",
        "AddClientAddress": "Adresse aktuell hinzufügen",
        "EditClientAddress": "Adresse aktuell bearbeiten ",
        "Fatura": "Rechnung",
        "Sevkiyat": "Sendung",
        "AddressDefinition": "Adressdefinition",

        // ClientUsers

        "ClientUsers": "Derzeitige Nutzer",
        "ClientUsersList": "Liste der aktuellen Benutzer",
        "AddClientUser": "Aktuellen Benutzer hinzufügen",
        "EditClientUser": "Aktuellen Benutzer bearbeiten",

        // Salespeople

        "Salesman": "Salespeople",
        "SalespeopleList": "Salespeople List",
        "AddSalesman": "Add Salesman",
        "EditSalesman": "Edit Salesman",
        "SalesmanGroup":"Verkäufer Gruppe",


        // Periods

        "PeriodsList": "Periodenliste",
        "AddPeriod": "Punkt hinzufügen",
        "EditPeriod": "Bearbeitungszeitraum",
        "FirmNo": "Firma Nr",
        "FirmName": "Firmenname",
        "PeriodNo": "Zeitraum Nr",
        "PeriodName": "Periodenname",
        "StartingDate": "Anfangsdatum",
        "EndDate": "Endtermin",
        "TaxAdministration": "Steuerverwaltung",
        "TaxNumber": "Steuernummer",
        "City": "Provinz",
        "Town": "Bezirk",
        "Address": "Addresse",
        "Address-2": "Addresse-2",
        "MsSqlServer": "MsSql Server",
        "MsSqlDatabase": "MsSql Database",
        "MsSqlUserId": "MsSql User Id",
        "MsSqlPassword": "MsSql Password",
        "AddParametre": "Add Parametre Period",
        "ErpList":"Erp Liste",
        // Users

        "UsersList": "Benutzerliste",
        "AddUser": "Benutzer hinzufügen",
        "EditUser": "Benutzer bearbeiten",

        //General

        "Kod": "Code",
        "UnitSet": "Gerätesatz",
        "ReturnVAT": "MwSt.-Rückerstattung",
        "SellVAT": "MwSt. Verkauf",
        "Surtax": "Zusätzliche Steuer",
        "AnaBarkod": "Haupt-Barcode",
        "Barkod": "Barcode",
        "MarkaKodu": "Markencode",
        "Mensei": "Mensei",
        "En": "Die meisten",
        "Boy": "hoch",
        "Yukseklik": "Höhe",
        "Alan": "Bereich",
        "BrutHacim": "Brutto-Volumen",
        "NetHacim": "Netto-Volumen",
        "BrutAgirlik": "Bruttogewicht",
        "NetAgirlik": "Nettogewicht",

        // CollectionDefinitions

        "CollectionList": "Sammlungsliste",
        "AddCollection": "Sammlung hinzufügen",
        "CollectionEdit": "Sammlung bearbeiten",
        "CollectionType": "Sammlungsart",
        "CollectionStatus": "Sammlungsstatus",
        "CollectionDay": "Abholtag",
        "PlanCode": "Planen Sie den Code",
        "Kullanimda": "In Benutzung",
        "KullanimDisinda": "Außer Betrieb",

        //Lojistik

        "LogisticsList": "Logistik Auflisten",
        "AddLogistics": "Logistik Hinzufügen",
        "EditLogistics": "Logistik Bearbeiten",
        "ShipmentType": "Sendungsart",
        "ShipmentStatus": "Sendungsstatus",
        "ShipmentDay": "Sendungstag",

       //LogoDBSynchronization
       "LogoIntegration":"Logo Integration",
       "E-Store-Access":"E-Store Access",
       "CategoryGroup":"Kategorie Gruppe",
       "SequenceNumber":"Sequenz Nummer",
       "PriorityRange":"Prioritätsbereich",
       "E-Job-Access":"E-Job-Zugriff",
       
    }
}
