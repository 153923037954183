import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Currency from '../shared/Currency';
import { Check100Svg } from '../../svg';
import * as dbOrder from "../../api_js/order";
import * as helper from "../../../../helper/helper_methods";
import { FormattedMessage, useIntl } from "react-intl";
import theme from '../../data/theme';
import { url } from '../../services/utils';
import { initial_OrderSuccess } from '../../../../models/initial_models';
import moment from 'moment'
import { PaymentMethod } from '../../../../models/models';

export default function ShopPageOrderSuccess() {

    const intl = useIntl();
    const params = helper.getUriParams("id");
    const [order, setOrder] = React.useState(initial_OrderSuccess);

    React.useEffect(() => {
        getOrderById()
    }, [])

    const getOrderById = () => {
        dbOrder.getCompletedOrderById(params)
            .then((response) => {
                if (response.ok) {
                    setOrder(response.data)
                } else console.log(response.message);
            })
            .catch((err) => console.log(err.message));
    }

    const items = order.orderLines.map((item) => {
        const options = (item.options || []).map((option) => (
            <li className="order-list__options-item">
                <span className="order-list__options-label">
                    {option.label}
                    {': '}
                </span>
                <span className="order-list__options-value">{option.value}</span>
            </li>
        ));

        return (
            <tr>
                <td className="order-list__column-image">
                    <div className="product-image">
                        <Link to={url.product(item)} className="product-image__body">
                            <img className="product-image__img" src={item.image} alt="" />
                        </Link>
                    </div>
                </td>
                <td className="order-list__column-product">
                    <Link to={url.product(item)}>{item.name}</Link>
                    {options.length > 0 && (
                        <div className="order-list__options">
                            <ul className="order-list__options-list">
                                {options}
                            </ul>
                        </div>
                    )}
                </td>
                <td className="order-list__column-quantity" data-title={intl.formatMessage({ id: "quantity" })}>{item.quantity}</td>
                <td className="order-list__column-total"><Currency value={item.lineTotalPrice} /></td>
            </tr>
        );
    });

    // const additionalLines = order.additionalLines.map((line) => (
    //     <tr>
    //         <th className="order-list__column-label" colSpan="3">{line.label}</th>
    //         {/* <td className="order-list__column-total"><Currency value={line.total} /></td> */}
    //     </tr>
    // ));

    return (
        <div className="block order-success">
            <Helmet>
                <title>{`Order Success — ${theme.name}`}</title>
            </Helmet>

            <div className="container">
                <div className="order-success__body">
                    <div className="order-success__header">
                        <Check100Svg className="order-success__icon" />
                        <h1 className="order-success__title"><FormattedMessage id="thanks" /></h1>
                        <div className="order-success__subtitle"> <FormattedMessage id="your-order-has-been-received" /> </div>
                        <div className="order-success__actions">
                            <Link to="/" className="btn btn-xs btn-secondary"><FormattedMessage id="homepage" /></Link>
                        </div>
                    </div>

                    <div className="order-success__meta">
                        <ul className="order-success__meta-list">
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">{<FormattedMessage id="orderNo" />}</span>
                                <span className="order-success__meta-value">{`${order.orderNo}`}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">{<FormattedMessage id="orderDate" />}</span>
                                <span className="order-success__meta-value">{moment(order.date_).format("DD.MM.YYYY h:mm:ss")}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">{<FormattedMessage id="total" />}</span>
                                <span className="order-success__meta-value"><Currency value={order.netTotal} /></span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">{<FormattedMessage id="paymentMethod" />}</span>
                                <span className="order-success__meta-value">{<FormattedMessage id={`payment.${PaymentMethod[order.paymentMethod]}`} />}</span>
                            </li>
                        </ul>
                    </div>

                    <div className="card">
                        <div className="order-list">
                            <table>
                                <thead className="order-list__header">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="2">{<FormattedMessage id="product" />}</th>
                                        <th className="order-list__column-quantity">{<FormattedMessage id="quantity" />}</th>
                                        <th className="order-list__column-total">{<FormattedMessage id="total" />}</th>
                                    </tr>
                                </thead>
                                <tbody className="order-list__products">
                                    {items}
                                </tbody>
                                {/* {additionalLines.length > 0 && (
                                    <tbody className="order-list__subtotals">
                                        <tr>
                                            <th className="order-list__column-label" colSpan="3">Subtotal</th>
                                            <td className="order-list__column-total"><Currency value={order.subtotal} /></td>
                                        </tr>
                                        {additionalLines}
                                    </tbody>
                                )} */}
                                <tfoot className="order-list__footer">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="3">{<FormattedMessage id="total" />}</th>
                                        <td className="order-list__column-total"><Currency value={order.netTotal} /></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                    <div className="row mt-3 no-gutters mx-n2">
                        <div className="col-sm-6 col-12 px-2">
                            <div className="card address-card">
                                <div className="address-card__body">
                                    <div className="address-card__badge address-card__badge--muted">
                                        <FormattedMessage id="shippingAddress" />
                                    </div>
                                    <div className="address-card__name">
                                        {`${order.shippingAddress.receiverName}`}
                                    </div>
                                    <div className="address-card__row">
                                        {/* {order.shippingAddress.country}
                                        <br /> */}
                                        {`${order.shippingAddress.postcode}, ${order.shippingAddress.city}`}
                                        <br />
                                        {order.shippingAddress.address}
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title">{<FormattedMessage id="phoneNumber" />}</div>
                                        <div className="address-card__row-content">{order.shippingAddress.phone}</div>
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title">{order.billingAddress.email}</div>
                                        <div className="address-card__row-content">{order.shippingAddress.email}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
                            <div className="card address-card">
                                <div className="address-card__body">
                                    <div className="address-card__badge address-card__badge--muted">
                                        <FormattedMessage id="billingAddress" />
                                    </div>
                                    <div className="address-card__name">
                                        {/* {`${order.billingAddress.receiverName}`} */}
                                    </div>
                                    <div className="address-card__row">
                                        {/* {order.billingAddress.country}
                                        <br /> */}
                                        {`${order.billingAddress.postcode}  ${order.billingAddress.city}`}
                                        <br />
                                        {order.billingAddress.address}
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title">{<FormattedMessage id="phoneNumber" />}</div>
                                        <div className="address-card__row-content">{order.billingAddress.phone}</div>
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title">{<FormattedMessage id="email" />}</div>
                                        <div className="address-card__row-content">{order.billingAddress.email}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
