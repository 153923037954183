import { request } from './request';


export async function payOrderWithCreditCard() {
    return await request("netahsilat/pay/order/anonymouse-client", 'GET');
}
export async function payClientAccount() {
    return await request("netahsilat/pay/client-account/anonymouse-client", 'GET');
}

export async function getNetahsilatParameter() {
    return await request("netahsilat/getNetahsilatParameter", "GET");
}