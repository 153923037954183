import { CURRENT_STEP } from './generalActionTypes';



export const currentStep = (stepValue) => ({
    type: CURRENT_STEP,
    stepValue: stepValue,
});


