import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Currency from '../shared/Currency';
import { Check100Svg } from '../../svg';
import * as dbPayment from "../../api_js/payment";
import * as helper from "../../../../helper/helper_methods";
import { initial_ClientAccountPaymentSuccess } from '../../../../models/initial_models';
import { FormattedMessage, useIntl } from "react-intl";
import theme from '../../data/theme';
import moment from 'moment'
import { PaymentMethod } from '../../../../models/models';

export default function ClientAccountPaymentSuccess() {

    const intl = useIntl();
    const params = helper.getUriParams("id");
    const [payment, setPayment] = React.useState(initial_ClientAccountPaymentSuccess);

    React.useEffect(() => {
        getPaymentSuccess()
    }, [])

    const getPaymentSuccess = () => {
        dbPayment.getClientAccountPaymentSuccess(params)
            .then((response) => {
                if (response.ok) {
                    setPayment(response.data)
                } else console.log(response.message);
            })
            .catch((err) => console.log(err.message));
    }


    // const additionalLines = order.additionalLines.map((line) => (
    //     <tr>
    //         <th className="order-list__column-label" colSpan="3">{line.label}</th>
    //         {/* <td className="order-list__column-total"><Currency value={line.total} /></td> */}
    //     </tr>
    // ));

    return (
        <div className="block order-success">
            <Helmet>
                <title>{`Order Success — ${theme.name}`}</title>
            </Helmet>

            <div className="container">
                <div className="order-success__body">
                    <div className="order-success__header">
                        <Check100Svg className="order-success__icon" />
                        <h1 className="order-success__title"><FormattedMessage id="thanks" /></h1>
                        <div className="order-success__subtitle"> <FormattedMessage id="your-payment-has-been-made" /> </div>
                        <div className="order-success__actions">
                            <Link to="/" className="btn btn-xs btn-secondary"><FormattedMessage id="homepage" /></Link>
                        </div>
                    </div>

                    <div className="order-success__meta">
                        <ul className="order-success__meta-list">
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">{<FormattedMessage id="collectionFicheNo" />}</span>
                                <span className="order-success__meta-value">{`${payment.ficheNo}`}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">{<FormattedMessage id="paymentDate" />}</span>
                                <span className="order-success__meta-value">{moment(payment.date_).format("DD.MM.YYYY h:mm:ss")}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">{<FormattedMessage id="total" />}</span>
                                <span className="order-success__meta-value"><Currency value={payment.amount} /></span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">{<FormattedMessage id="paymentMethod" />}</span>
                                <span className="order-success__meta-value">{<FormattedMessage id={`payment.${PaymentMethod[payment.paymentMethod]}`} />}</span>
                            </li>
                        </ul>
                    </div>

                    <div className="order-list">
                        <table>
                            <tfoot className="order-list__footer">
                                <tr>
                                    <th className="order-list__column-label" colSpan="3">{<FormattedMessage id="customerInfo" />}</th>
                                    <td className="order-list__column-total">{payment.clientDescription}</td>
                                </tr>
                            </tfoot>
                            <tfoot className="order-list__footer">
                                <tr>
                                    <th className="order-list__column-label" colSpan="3">{<FormattedMessage id="total" />}</th>
                                    <td className="order-list__column-total"><Currency value={payment.amount} /></td>
                                </tr>
                            </tfoot>
                            <tfoot className="order-list__footer">
                                <tr>
                                    <th className="order-list__column-label" colSpan="3">{<FormattedMessage id="description" />}</th>
                                    <td className="order-list__column-total">{payment.description}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
