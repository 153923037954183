import { Unit } from "../models/models";
import { ApiResponse, request } from "./api";

export async function getList(): Promise<ApiResponse<Unit[]>> {
    return await request<Unit[]>("units/getList");
}
export async function test(params:string): Promise<ApiResponse<Unit[]>> {
    return await request<Unit[]>(`Test?value=${params}`,"GET");
}
export async function add(model: Unit): Promise<ApiResponse<Unit>> {
    return await request<Unit>(`units/add`, "POST", model);
}
export async function deleteUnit(model: Unit): Promise<ApiResponse<Unit>> {
    return await request<Unit>(`units/delete/${model.id}`, "DELETE");
}


