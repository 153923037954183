import { LOCALE_ANT_DESIGN_CHANGE } from "./localeActionTypes";
import tr_TR from 'antd/lib/locale/tr_TR';


export default function localeAntDesignReducer(state = tr_TR, action) {
    if (action.type === LOCALE_ANT_DESIGN_CHANGE) {
        return action.ant_locale;
    }
    return state;
}
