//-----------------------------BANK---------------------------------------------------
const banks=`banks`
export const bank_getList = `${banks}/getList`;
export const bank_add = `${banks}/add`;
export const bank_delete = `${banks}/delete`;
export const bank_update= `${banks}/update`;
export const bankListByCollectionMethod= `${banks}/getListByPaymentMethod`;
//-----------------------------PAYMENT---------------------------------------------------
const payments=`payments`
export const payment_add= `${payments}/add`;
export const payment_delete= `${payments}/delete`;
export const payment_erpTransfer= `${payments}/erp-transfer`;
export const payment_update= `${payments}/update`;
export const payment_changeStatus= `${payments}/ChangeStatus`;
export const payment_getListByFilter= `${payments}/getListByFilter`;
export const payment_newFicheNo= `${payments}/newFicheNo`;

const paymentTypes=`paymentTypes`
export const paymentTypes_getPaymentTypesDto= `${paymentTypes}/paymentTypeDto`;
export const paymentTypes_getList= `${paymentTypes}/getList`;
export const paymentTypes_update= `${paymentTypes}/update`;
export const paymentTypes_add= `${paymentTypes}/add`;
export const paymentTypes_delete= `${paymentTypes}/delete`;


const carts=`carts`
export const update_quantityOfCartItem= `${carts}/UpdateQuantityOfCartItem`;
export const update_offerPricesOfCart= `${carts}/UpdateOfferPricesOfCart`;
export const update_shippingAddress= `${carts}/UpdateShippingAddressOfCart`;
export const update_billingAddress= `${carts}/UpdateBillingAddressOfCart`;
export const update_paymentTypeOfCart= `${carts}/UpdatePaymentTypeOfCart`;


