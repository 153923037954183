import { LOCALES } from "../Locales";

export default {
    [LOCALES.ENGLISH]: {
        // MENU

        "Menu.Records": "Records",
        "Menu.Records.Orders": "Orders",
        "Menu.Records.Payments": "Payments",
        "Menu.Records.Offers": "Offers",
        "Menu.Records.ClientCarts": "Carts",
        "Menu.Records.Email": "E-mail",
        "Menu.Records.SMS": "SMS",

        "Menu.Definitions": "Definitions",
        "Menu.Definitions.Units": "Units",
        "Menu.Definitions.Stores": "Stores",
        "Menu.Definitions.Salespeople": "Salespeople",
        "Menu.Definitions.Products": "Products",
        "Menu.Definitions.Products.Products": "Products",
        "Menu.Definitions.Products.SpecialCodes": "Special Codes",
        "Menu.Definitions.Products.GroupCodes": "Group Codes",
        "Menu.Definitions.Clients": "Clients",
        "Menu.Definitions.Collections": "Collections",
        "Menu.Definitions.CollectType": "Collect Type",

        // "Menu.Definitions.Payments.CreditCard": "Credit Card",
        // "Menu.Definitions.Payments.Transfer": "Transfer",
        // "Menu.Definitions.Payments.Check": "Check",
        // "Menu.Definitions.Payments.PromissoryNote": "Promissory Note",
        "Menu.Definitions.Logistics": "Logistics",
        "Menu.Definitions.TermsOfSale": "Terms Of Sale",
        "Menu.Definitions.EmailSendingConditions": "E-mail Sending Conditions",
        "Menu.Definitions.SMSSendingConditions": "SMS Sending Conditions",

        "Menu.SystemManagement": "System Management",
        "Menu.SystemManagement.Firms": "Firm Definitions",
        "Menu.SystemManagement.Firms.Firms": "Firms & Periods",
        "Menu.SystemManagement.Firms.Parameters": "Parameters",
        "Menu.SystemManagement.Firms.FirmLogo": "Firm Logo",
        "Menu.SystemManagement.Firms.Address": "Address",
        "Menu.SystemManagement.Firms.Telephone": "Telephone",
        "Menu.SystemManagement.ERP": "ERP Definitions",
        "Menu.SystemManagement.ERP.Logo": "Logo",
        "Menu.SystemManagement.EmailServer": "E-mail Server Definitions",
        "Menu.SystemManagement.SMSServer": "SMS Server Definitions",
        "Menu.SystemManagement.EPayment": "E-Payment Definitions",
        "Menu.SystemManagement.EPayment.NetPayment": "Net Payment",
        "Menu.SystemManagement.Users": "Users",
        "Menu.SystemManagement.DataBaseSynchronization":
            "DataBase Synchronization",
        "Menu.SystemManagement.LogoDBSynchronization": "LogoDB Synchronization",

        "Menu.Integrations": "Integrations",
        "Menu.Integrations.LogoIntegration": "Logo Integration",

        "Menu.Logout": "Logout",

        // COMMON

        Add: "Add",
        BackOffice: "Back Office",
        Cancel: "Cancel",
        Close: "Close",
        Code: "Code",
        Date: "Date",
        Update: "Update",
        DefaultLanguage: "Default Language",
        Delete: "Delete",
        Description: "Description",
        "Description-2": "Description-2",
        "Description-3": "Description-3",
        Edit: "Edit",
        Home: "Home",
        Images: "Images",
        InUse: "In Use",
        LastName: "Last Name",
        LineNo: "Line No",
        Name: "Name",
        Phone: "Phone",
        "Operation.Success": "The operation completed successfully",
        "Operation.Error": "The operation could not complete",
        Save: "Save",
        Email: "E-mail",
        "E-mail": "E-mail",
        Active: "Active",
        Actions: "Actions",
        Languages: "Languages",
        Address: "Address",
        "Address-2": "Address-2",
        Explanation: "Explanation",
        Quantity: "Quantity",
        Search: "Search",
        Reset: "Reset",
        DecimalDigits: "Decimal Digits",
        "Contact.Info": "Contact Info",
        "Contact.Email": "Contact Email",
        "Contact.Phone": "Contact Phone",
        "Contact.Address": "Contact Address",
        Default: "Default",
        DiscountRate: "Discount Rate",
        Clients: "Clients",
        Client: "Client",
        "E-Commerce": "E-Commerce",
        Filter: "Filter",
        Integration: "Integration",
        RequiredField: "Required Field",
        PleaseSelect: "Please Select",
        All: "All",
        Start: "Start",
        End: "End",
        Loading: "Loading",
        Port: "Port",
        ProjectName: "Project Name",
        Server: "Server",
        Operations: "Operations",
        Cart: "Cart",
        Carts: "Carts",
        User: "User",
        Category: "Kategori",
        Version: "Version",
        Parameters: "Parameters",
        PriceDecimalPlaces: "Price Decimal Places",
        // ENUMS

        YerelParaBirimi: "Local Currency",
        DovizParaBirimi: "Currency Unit",

        Pending: "Pending",
        Confirmed: "Confirmed",
        Rejected: "Rejected",

        Pend: "Pend",
        Confirm: "Confirm",
        Reject: "Reject",

        TL: "TL",
        USD: "USD",
        EUR: "EURO",

        "TahsilatTuru.CariHesap": "Cari Hesap",
        "TahsilatTuru.KrediKarti": "Kredi Kartı",
        "TahsilatTuru.Havale": "Havale",
        "TahsilatTuru.Cek": "Çek",
        "TahsilatTuru.Senet": "Senet",

        // Login

        Login: "Login",
        LoginFailed: "Login failed",
        Username: "Username",
        UsernameRequired: "Username required",
        Password: "Password",
        PasswordControl: "Password (Control)",
        PasswordRequired: "Password required",

        //Stores
        Warehouse: "Warehouse",
        Warehouses: "Warehouses",
        WarehouseList: "Warehouse List",
        Stores: "Stores",
        Store: "Depo",
        StoresList: "Stores List",
        AddStore: "Add Store",
        EditStore: "Edit Store",
        StoreCode: "Store Code",

        // UNITS

        UnitSets: "Unit Sets",
        AddUnitSet: "AddUnit Set",
        EditUnitSet: "EditUnit Set",
        UnitSetCode: "Unit Set Code",
        UnitSetDescription: "Unit Set Description",
        Unit: "Unit",
        Units: "Units",
        AddUnit: "Add Unit",
        UnitCode: "Unit Code",
        UnitDescription: "Unit Description",
        UnitFact1: "Factory 1",
        UnitFact2: "Factory 2",

        // SpecialCodes

        SpecialCodeDomains: "Special Code Domains",
        AddSpecialCodeDomain: "Add Special Code Domain",
        EditSpecialCodeDomain: "Edit Special Code Domain",
        SpecialCodeDomainName: "Special Code Domain Name",
        SpecialCodes: "Special Codes",
        SpecialCodesList: "Special Codes List",
        AddSpecialCode: "Add Special Code",
        EditSpecialCode: "Edit Special Code",
        SelectSpecialCode: "Select Special Code",

        // GroupCodes

        GroupCodesList: "Group Codes List",
        AddGroupCode: "Add Group Code",
        EditGroupCode: "Edit Group Code",
        GroupCodeName: "Group Code Name",
        GroupCode: "Group Code",
        SubGroupCode: "Sub Group Code",
        ParentGroupCode: "Parent Group Code",

        // Items
        Item: "Item",
        Items: "Items",
        ItemsList: "Items List",
        AddItem: "Add Item",
        EditItem: "Edit Item",
        ItemAmounts: "Item Amounts",
        ToplamMiktarFiili: "Toplam Fiili Miktar",
        ToplamMiktarSevkedilebilir: "Toplam Sevkedilebilir Miktar",
        ToplamMiktarGercek: "Toplam Gerçek Miktar",
        MiktarFiili: "Fiili Miktar",
        MiktarSevkedilebilir: "Sevkedilebilir Miktar",
        MiktarGercek: "Gerçek Miktar",

        // Prices

        Prices: "Prices",
        AddPrice: "Add Price",
        EditPrice: "Edit Price",
        TaxIncluded: "Tax Included",
        UnitPriceCurrency: "Unit Price Currency",
        CurrencyType: "Currency Type",
        UnitPrice: "Unit Price",
        Currency: "Currency",
        VATIncluded: "VAT Included",
        IsActive: "Is Active",

        // Orders

        OrdersList: "Orders List",
        AddOrder: "Add Order",
        EditOrder: "Edit Order",
        ClientTitle: "Client Title",
        Total: "Total",
        KDVOrani: "KDV Oranı",
        KDV: "KDV",
        NetTotal: "Net Total",
        Status: "Status",
        ChangeStatus: "Change Status",
        OrderDetails: "Order Details",
        OrderDetailsList: "Order Details List",

        // Clients
        ClientCode: "Client Code",
        ClientsList: "Clients List",
        AddClient: "Add Client",
        EditClient: "Edit Client",
        AllCurrrentsPermission: "All Currrents Permission",

        // ClientAddress

        ClientAddresses: "Clients Addres",
        ClientAddressList: "Client Address List",
        AddClientAddress: "Add Client Address",
        EditClientAddress: "Edit Client Address",
        Fatura: "Invoice",
        Sevkiyat: "Shipment",
        AddressDefinition: "Address Definition",

        // ClientUsers

        ClientUsers: "Client Users",
        ClientUsersList: "Client Users List",
        AddClientUser: "Add Client User",
        EditClientUser: "Edit Client User",

        // Salespeople

        Salesman: "Salesman",
        SalespeopleList: "Salespeople List",
        AddSalesman: "Add Salesman",
        EditSalesman: "Edit Salesman",
        SalesmanGroup: "Salesman Group",

        // Periods

        PeriodsList: "Periods List",
        AddPeriod: "Add Period",
        EditPeriod: "Edit Period",
        FirmNo: "Firma No",
        FirmName: "Firma Name",
        PeriodNo: "Period No",
        PeriodName: "Period Name",
        StartingDate: "Starting Date",
        EndDate: "End Date",
        TaxAdministration: "Tax Administration",
        TaxNumber: "Tax Number",
        City: "City",
        Town: "Town",
        MsSqlServer: "MsSql Server",
        MsSqlDatabase: "MsSql Database",
        MsSqlUserId: "MsSql User Id",
        MsSqlPassword: "MsSql Password",
        AddParametre: "Add Parametre Period",
        ErpList: "Erp List",

        // Users

        UsersList: "Users List",
        AddUser: "Add User",
        EditUser: "Edit User",

        //General

        Kod: "Code",
        UnitSet: "Unit Set",
        VAT: "VAT",
        ReturnVAT: "Return VAT",
        SellVAT: "Sell VAT",
        Surtax: "Surtax",
        AnaBarkod: "Main Barcode",
        Barkod: "Barcode",
        MarkaKodu: "Trademark Code",
        Mensei: "Origin",
        En: "Width",
        Boy: "Depth",
        Yukseklik: "Height",
        Alan: "Area",
        BrutHacim: "Gross Volume",
        NetHacim: "Net Volume",
        BrutAgirlik: "Gross Weight",
        NetAgirlik: "Net Weight",

        // CollectionDefinitions

        CollectionList: "Collection List",
        AddCollection: "Add Collection",
        CollectionEdit: "Edit Collection",
        CollectionType: "Collection Type",
        CollectionStatus: "Collection Status",
        CollectionDay: "Collection Day",
        PlanCode: "Plan Code",
        Kullanimda: "In Use",
        KullanimDisinda: "Out of Use",
        //Lojistik
        LogisticsList: "Logistics List",
        AddLogistics: "Add Logistics",
        EditLogistics: "Edit Logistics",
        ShipmentType: "Shipment Type",
        ShipmentStatus: "Shipment Status",
        ShipmentDay: "Shipment Day",

        //LogoIntegration
        LogoIntegration: "Logo Integration",
        ItemPriceCards: "Item Price Cards",
        "E-Store-Access": "E-Store Access",
        CategoryGroup: "Category Group",
        SequenceNumber: "Sequence Number",
        PriorityRange: "Priority Range",
        "E-Job-Access": "E-Job Access",
    },
};
