import { Erp } from "../models/models";
import { ApiResponse, request } from "./api";


  export async function addOrUpdate(model: Erp): Promise<ApiResponse<Erp>> {
   return await request<Erp>(`erp/addOrUpdate`, "POST", model);
    }
  // export async function getList(): Promise<ApiResponse<Erp[]>> {
  //   return await request<Erp[]>(`erp/getList`, "GET" );
  // }
  export async function getById(id:number): Promise<ApiResponse<Erp>> {
      return await request<Erp>(`erp/getById/${id}`, "GET" );
  }

  export async function getByFirmAndPeriodNumber(): Promise<ApiResponse<Erp>> {
    return await request<Erp>(`erp/getByFirmAndPeriodNumber`, "GET" );
}

  export async function deleteErp(model: Erp): Promise<ApiResponse<Erp>> {
    return await request<Erp>(`erp/delete`, "DELETE",model );
  }
