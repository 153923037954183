export default [
    {
        title: '₺ TL',
        currency: {
            code: 'TL',
            symbol: '₺',
            name: 'Turkish Lira',
            decimalSeparator: ',',
            groupSeparator: '.',
        },
    },
    {
        title: '€ Euro',
        currency: {
            code: 'EUR',
            symbol: '€',
            name: 'Euro',
            decimalSeparator: '.',
            groupSeparator: ',',
        },
    },
    {
        title: '£ Pound Sterling',
        currency: {
            code: 'GBP',
            symbol: '£',
            name: 'Pound Sterling',
            decimalSeparator: '.',
            groupSeparator: ',',
        },
    },
    {
        title: '$ US Dollar',
        currency: {
            code: 'USD',
            symbol: '$',
            name: 'US Dollar',
            decimalSeparator: '.',
            groupSeparator: ',',
        },
    },
    {
        title: '₽ Russian Ruble',
        currency: {
            code: 'RUB',
            symbol: '₽',
            name: 'Russian Ruble',
            decimalSeparator: '.',
            groupSeparator: ',',
        },
    },
];
