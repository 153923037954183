import React, { useState, useEffect } from 'react';
import { DeliveryType as Model_DeliveryType } from '../../../../models/models';
import * as DbDeliveryType from '../../../../api_ts/deliveryType';
import { Button, Tabs, Table, message, Modal, Form, InputNumber, Input, Tooltip } from 'antd';
import { PlusSquareOutlined, UserOutlined, EditOutlined, DeleteOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { useStateWithCallbackLazy as useStateCallback } from 'use-state-with-callback';
import { useIntl } from 'react-intl';
import { Translate } from '../../i18n/Translate';


const { TabPane } = Tabs;

export const DeliveryTypes: React.FC = () => {
    const intl = useIntl();

    const [deliveryTypes, setDeliveryTypes] = useState<Model_DeliveryType[]>([]);
    const [loading, setLoading] = useStateCallback(false);
    const [flags, setFlags] = useState({ deliveryTypeModalVisible: false, operationTitle: "" });
    const [deliveryTypeForm] = Form.useForm();


    useEffect(() => {
        getAllDeliveryTypes()
    }, [])

    const columns = [
        {
            key: 'description',
            dataIndex: 'description',
            title: Translate("Description"),
        },
        {
            key: 'name',
            title: Translate("DeliveryType"),
            dataIndex: 'name'
        },
        {
            key: 'id',
            width: 100,
            render: (text: string, deliveryType: Model_DeliveryType) =>
                <>
                    <Tooltip placement="top" color="blue" title={Translate("Edit")}>
                        <Button icon={<EditOutlined />} onClick={() => {
                            deliveryTypeForm.resetFields()
                            setFlags({ ...flags, deliveryTypeModalVisible: true, operationTitle: "Update" })
                            filldeliveryTypeForm(deliveryType)
                        }
                        } />
                    </Tooltip>
                    <Tooltip placement="top" color="red" title={Translate("Delete")}>
                        <Button icon={<DeleteOutlined />} onClick={() => deletedeliveryType(deliveryType.id)} />
                    </Tooltip>
                </>
        },

    ];

    const getAllDeliveryTypes = () => {
        return DbDeliveryType.getList()
            .then(response => {
                if (response.ok) {
                    setDeliveryTypes(response.data);
                }
                else
                    console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }


    const addOrUpdatedeliveryType = async (deliveryType: Model_DeliveryType) => {
        if (deliveryType.id == 0)
            IsSuccessful(await DbDeliveryType.add(deliveryType))
        else
            IsSuccessful(await DbDeliveryType.update(deliveryType))
    }


    const deletedeliveryType = async (deliveryTypeId: number) => {
        if ((await DbDeliveryType.deleteDeliveryType(deliveryTypeId)).ok) {
            message.success(intl.formatMessage({ id: "Operation.Success" }))
            getAllDeliveryTypes();
        }
        else
            message.error(intl.formatMessage({ id: "Operation.Error" }))
    }


    const IsSuccessful = (action: any) => {
        setLoading(true, () => {
            if (action.ok) {
                getAllDeliveryTypes()
                    .then(() => {
                        deliveryTypeForm.resetFields()
                        setFlags({ ...flags, deliveryTypeModalVisible: false, operationTitle: "Add" })
                        setLoading(false, () => message.success(intl.formatMessage({ id: "Operation.Success" })))
                    })
            } else {
                message.error(intl.formatMessage({ id: "Operation.Error" }))
            }
        })
    }


    const filldeliveryTypeForm = (deliveryType: Model_DeliveryType) => {
        deliveryTypeForm.setFieldsValue(deliveryType);
    }

    const header = (<Button type="primary" onClick={() => {
        deliveryTypeForm.resetFields()
        setFlags({ ...flags, deliveryTypeModalVisible: true, operationTitle: "Add" })
    }}
        icon={<PlusSquareOutlined style={{ verticalAlign: 'middle', fontSize: '20px' }} />} >
        <span style={{ verticalAlign: 'middle' }} >{Translate("Add")}</span>
    </Button>)

    const formItemLayout = { labelCol: { span: 7 }, wrapperCol: { span: 17 } };

    return (
        <div>
            <Tabs
                defaultActiveKey="1"
                tabBarExtraContent={header}
            >
                <TabPane
                    tab={<span><UserOutlined />{Translate("DeliveryType")}</span>} key="1">
                    <div className="site-card-border-less-wrapper">
                        <Table bordered size="small" columns={columns} dataSource={deliveryTypes} />
                    </div>
                </TabPane>
            </Tabs>
            <Modal
                title={Translate("DeliveryType")}
                visible={flags.deliveryTypeModalVisible}
                onCancel={() => setFlags({ ...flags, deliveryTypeModalVisible: false, operationTitle: "Add" })}
                onOk={() => { deliveryTypeForm.submit() }}
                okText={Translate(flags.operationTitle)}
                cancelText={Translate("Cancel")}
                confirmLoading={loading}
            >
                <Form
                    form={deliveryTypeForm}
                    layout={"horizontal"}
                    onFinish={addOrUpdatedeliveryType}
                    {...formItemLayout}
                    size={"small"}
                    labelAlign={"left"}
                >

                    <Form.Item
                        name={"id"}
                        fieldKey={"id"}
                        hidden={true}
                        initialValue={0} >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={Translate("Description")}
                        name="description"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate("Description")} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("DeliveryType")}
                        name="name"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate("DeliveryType")} />
                    </Form.Item>

                </Form>
            </Modal>
        </div>
    );


}
