import * as Icons from '@ant-design/icons';
import * as Ants from 'antd';
import * as React from 'react';
import * as DbOzelKodAlani from '../../../../api_ts/specialCodeArea';
import * as dbSpecialCode from '../../../../api_ts/specialCode'
import * as Models from '../../../../models/models';
import { useIntl } from 'react-intl';
import { Translate } from '../../i18n/Translate';

const { TabPane } = Ants.Tabs;

export const SpecialCodes: React.FC<{}> = () => {

    const intl = useIntl();
    const [ozelKodAlaniId, setOzelKodAlaniId] = React.useState<number>(0);
    const [ozelKodiId, setOzelKodiId] = React.useState<number>(0);
    const [ozelKodAlanId, setOzelKodAlanId] = React.useState<number>(0);
    const [visible, setVisible] = React.useState(false);
    const [data, setData] = React.useState<Models.SpecialCodeArea[]>([]);
    const [ozelkodlar, setOzelKodlar] = React.useState<Models.SpecialCode[]>([]);
    const [aciklama, setAciklama] = React.useState<string>('');
    const [alanAciklama, setAlanAciklama] = React.useState<string>('');
    const [aktifKey, setAktifKey] = React.useState<string>('1');
    const [kod, setKod] = React.useState<string>('');

    const ozelKodlariAl = async (ozelKodAlaniId: number) => {
        setOzelKodAlaniId(ozelKodAlaniId);

        dbSpecialCode.getListBySpecialCodeAreaId(ozelKodAlaniId)
            .then(response => {
                if (response.ok)
                    setOzelKodlar(response.data);
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }

    const eklecode = async () => {
        if (ozelKodAlanId === 0 && aciklama !== '' && kod !== '') {
            const model: Models.SpecialCode = {
                id: ozelKodAlanId,
                description: aciklama,
                code: kod,
                ozelKodAlaniId: ozelKodAlaniId,
                isActive: true,
                items: null!,
                ozelKodAlani: null!
            }

            const response = await dbSpecialCode.add(model);

            if (response.ok) {
                Ants.message.success(intl.formatMessage({ id: 'Operation.Success' }));
                yenile();
            } else {
                Ants.message.error(intl.formatMessage({ id: 'Operation.Error' }));
            }
        }
        else if (ozelKodAlanId !== 0 && aciklama !== '' && kod !== '') {
            const model: Models.SpecialCode = {
                id: ozelKodAlanId,
                description: aciklama,
                code: kod,
                ozelKodAlaniId: ozelKodAlaniId,
                isActive: true,
                items: null!,
                ozelKodAlani: null!
            }

            const response = await dbSpecialCode.update(model);

            if (response.ok) {
                Ants.message.success(intl.formatMessage({ id: 'Operation.Success' }));
                yenile();
            } else {
                Ants.message.error(intl.formatMessage({ id: 'Operation.Error' }));
            }
        }

    }

    const ekle = async () => {

        if (ozelKodiId === 0 && alanAciklama !== '') {
            const model: Models.SpecialCodeArea = {
                id: ozelKodiId,
                aciklama: alanAciklama,
                alanNo: 5,
                ozelKodlar: [],
            }

            const response = await DbOzelKodAlani.add(model)
            if (response.ok) {
                Ants.message.success(intl.formatMessage({ id: 'Operation.Success' }));
                yenile();
            } else {
                Ants.message.error(intl.formatMessage({ id: 'Operation.Error' }));
            }
        }
        else if (ozelKodiId !== 0 && alanAciklama !== '') {
            const model: Models.SpecialCodeArea = {
                id: ozelKodiId,
                aciklama: alanAciklama,
                alanNo: 5,
                ozelKodlar: null!,
            }
            const response = await DbOzelKodAlani.update(model);
            if (response.ok) {
                Ants.message.success(intl.formatMessage({ id: 'Operation.Success' }));
                yenile();
            } else {
                Ants.message.error(intl.formatMessage({ id: 'Operation.Error' }));
            }
        }

    }

    const ozelkodalanSil = async (id: number) => {
        const response = await DbOzelKodAlani.deleteSpecialCodeArea(id);
        if (response.ok) {
            Ants.message.success(intl.formatMessage({ id: 'Operation.Success' }));
            yenile();
        } else {
            Ants.message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }

    const ozelkodalanDuzenle = async (ozelKod: Models.SpecialCodeArea) => {
        setAlanAciklama(ozelKod.aciklama);
        setOzelKodiId(ozelKod.id);
        setAktifKey("2");
    }

    const ozelkodicerikSil = async (id: number) => {
        const response = await dbSpecialCode.deleteSpecialCode(id);

        if (response.ok) {
            Ants.message.success(intl.formatMessage({ id: 'Operation.Success' }));
            yenile();
        } else {
            Ants.message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }

    const ozelkodicerikDuzenle = async (ozelKod: Models.SpecialCode) => {
        setAciklama(ozelKod.description);
        setKod(ozelKod.code);
        setOzelKodAlanId(ozelKod.id);
    }

    function yenile() {
        DbOzelKodAlani.getList()
            .then(response => {
                if (response.ok) setData(response.data);
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));

        ozelKodlariAl(ozelKodAlaniId);
        setAlanAciklama('');
        setOzelKodiId(0);
        setAciklama('');
        setKod('');
        setOzelKodAlanId(0);
        setAktifKey('1');
    }
    const renderCancelEditButton = () => {
        if (ozelKodiId > 0) return (
            <Ants.Button style={{ width: '100px', float: 'left' }} type="ghost" onClick={yenile}>{Translate("Cancel")}</ Ants.Button >
        );

        return null;
    }
    React.useEffect(yenile, []);
    const columns = [
        {
            title: Translate("SpecialCodeDomainName"),
            dataIndex: 'aciklama',
            width: '75%',
        },
        {
            key: 'id',
            width: '25%',
            render: (text: any, record: Models.SpecialCodeArea) =>
                <>
                    <Ants.Tooltip placement="top" color="blue" title={Translate("SpecialCodes")}>
                        <Ants.Button icon={<Icons.AppstoreAddOutlined />} onClick={() => { setVisible(true); ozelKodlariAl(record.id) }} />
                    </Ants.Tooltip>
                    <Ants.Tooltip placement="top" color="blue" title={Translate("Edit")}>
                        <Ants.Button icon={<Icons.EditOutlined />} onClick={() => ozelkodalanDuzenle(record)} />
                    </Ants.Tooltip>
                    <Ants.Tooltip placement="top" color="red" title={Translate("Delete")}>
                        <Ants.Button icon={<Icons.DeleteOutlined />} onClick={() => ozelkodalanSil(record.id)} />
                    </Ants.Tooltip>
                </>
        },
    ];

    const column = [
        {
            title: Translate("Aciklama"),
            width: '70%',
            dataIndex: 'aciklama',
            key: 'id',
            editable: true,
        },
        {
            key: 'id',
            width: '30%',
            render: (text: any, record: Models.SpecialCode) =>
                <>
                    <Ants.Tooltip placement="top" color="blue" title={Translate("Edit")}>
                        <Ants.Button icon={<Icons.EditOutlined />} onClick={() => ozelkodicerikDuzenle(record)} />
                    </Ants.Tooltip>
                    <Ants.Tooltip placement="top" color="red" title={Translate("Delete")}>
                        <Ants.Button icon={<Icons.DeleteOutlined />} onClick={() => ozelkodicerikSil(record.id)} />
                    </Ants.Tooltip>
                </>
        },
    ];
    return (
        <>
            <Ants.Tabs defaultActiveKey="1" onTabClick={(e) => setAktifKey(e)} activeKey={aktifKey} >
                <TabPane tab={<span><Icons.ProfileOutlined />{Translate("SpecialCodeDomains")}</span>} key="1">
                    <div className="site-card-border-less-wrapper">
                        <Ants.Card title={Translate("SpecialCodeDomains")} bordered={false} style={{ width: '95%' }}>
                            <Ants.Table columns={columns} dataSource={data} pagination={{ pageSize: 7 }} scroll={{ y: 700 }} />
                        </Ants.Card>
                    </div>
                </TabPane>
                <TabPane tab={<span><Icons.FileAddOutlined />{Translate(ozelKodiId ? "EditSpecialCodeDomain" : "AddSpecialCodeDomain")}</span>} key="2">
                    <span >
                        <div className="site-card-border-less-wrapper">
                            <Ants.Card title={Translate(ozelKodiId ? "EditSpecialCodeDomain" : "AddSpecialCodeDomain")} bordered={false} style={{ width: '100%' }}>
                                <div style={{ width: '50%' }}>
                                    <div className="form-group" style={{ marginBottom: '20px' }}>
                                        <label htmlFor="SpecialCodes">{Translate("SpecialCodeDomainName")}</label>
                                        <Ants.Input
                                            size="large"
                                            value={alanAciklama}
                                            onChange={(e) => setAlanAciklama(e.target.value)}
                                            placeholder={Translate('SpecialCodeDomainName')}
                                            prefix={<Icons.UserOutlined />}
                                        />
                                    </div>
                                    <div style={{ marginBottom: '20px' }}>
                                        <Ants.Button style={{ width: '100px', float: 'left' }} type="ghost" onClick={() => ekle()}>{Translate("Save")}</Ants.Button>
                                        <div>{renderCancelEditButton()}</div>
                                    </div>
                                </div>
                            </Ants.Card>
                        </div>
                    </span>
                </TabPane>
            </Ants.Tabs>

            <Ants.Modal title={Translate('SpecialCodes')} centered visible={visible} okText={Translate("Save")} cancelText={Translate("Cancel")} onOk={() => setVisible(false)} onCancel={() => setVisible(false)} width={800}>

                <div style={{ width: '50%', float: 'right', marginBottom: '191px' }}>

                    <div className="form-group" style={{ marginBottom: '20px' }}>
                        <label htmlFor="SpecialCodes">{Translate("LineNo")}</label>
                        <Ants.Input size="large" value={kod} onChange={(e) => setKod(e.target.value)} placeholder={Translate('LineNo')} prefix={<Icons.UserOutlined />} />
                    </div>

                    <div className="form-group" style={{ marginBottom: '20px' }}>
                        <label htmlFor="SpecialCodes">{Translate("SpecialCodeDomainName")}</label>
                        <Ants.Input size="large" value={aciklama} onChange={(e) => setAciklama(e.target.value)} placeholder={Translate('SpecialCodeDomainName')} prefix={<Icons.UserOutlined />} />
                    </div>

                    <div style={{ marginBottom: '20px', width: '100%', float: 'right' }}>
                        <Ants.Button type="ghost" onClick={() => eklecode()}>{Translate("Add")}</Ants.Button>
                    </div>

                </div>

                <div style={{ width: '50%', float: 'left', marginBottom: '300px' }}>
                    <Ants.Table columns={column} dataSource={ozelkodlar} pagination={{ pageSize: 4 }} scroll={{ y: 700 }} />
                </div>

            </Ants.Modal>

        </>
    );
};
