import { bank_add, bank_getList, bankListByCollectionMethod, bank_update, bank_delete } from "../helper/magic_string";
import { Bank, PaymentMethod } from "../models/models";
import { ApiResponse, request } from "./api";

export async function getList(): Promise<ApiResponse<Bank[]>> {
    return await request<Bank[]>(bank_getList);
}

export async function add(model: Bank): Promise<ApiResponse<Bank>> {
    return await request<Bank>(bank_add, "POST", model);
}
export async function deleteBank(id: number): Promise<ApiResponse<Bank>> {
    return await request<Bank>(`${bank_delete}/${id}`, "DELETE");
}
export async function update(model: Bank): Promise<ApiResponse<Bank>> {
    return await request<Bank>(bank_update, "PATCH", model);
}
export async function getListByPaymentMethod(paymentMethod:PaymentMethod): Promise<ApiResponse<Bank[]>> {
    let params = new URLSearchParams();
    params.append('paymentMethod', String(paymentMethod));

    return await request<Bank[]>(`${bankListByCollectionMethod}?${params}`);
}




