import React, { Fragment } from 'react';
import { IntlProvider } from 'react-intl';
import messages from './messages';
import { LOCALES } from './Locales';

const Provider = ({ children, locale = LOCALES.ENGLISH }: any) => (
    <IntlProvider locale={locale} textComponent={Fragment} messages={messages[locale]}>
        {children}
    </IntlProvider>
);

export default Provider;
