import { GET_SALES_PARAMETERS } from "./parameterActionTypes";




const initialState = {
    paymentBonusType: null
};



export default function salesParametersReducer(state = initialState, action) {
    if (action.type === GET_SALES_PARAMETERS) {
        return action.salesParameter
    }
    return state;
}