import * as React from 'react';
import * as Ants from 'antd';
import * as Models from '../../../../models/models';
import * as DbOzelKodAlan from '../../../../api_ts/specialCodeArea';
import * as DbOzelKod from '../../../../api_ts/specialCode';
import * as DbItemSpecialCode from '../../../../api_ts/itemSpecialCode';
import * as Icons from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { Translate } from '../../i18n/Translate';

interface Props {
    malzemeId: number;
}

interface SelectListValueState {
    ozelKodAlanId: number;
    ozelKodId: number;
}

const { Option } = Ants.Select;

export const SpecialCodes: React.FC<Props> = ({ malzemeId }) => {
    const intl = useIntl();

    const [ozelKodlar, setOzelKodlar] = React.useState<Models.SpecialCode[]>([]);
    const [ozelKodAlanlari, setOzelkodAlanlari] = React.useState<Models.SpecialCodeArea[]>([]);
    const [malzemeOzelKodlari, setMalzemeOzelKodlari] = React.useState<Models.ItemSpecialCode[]>([]);


    const onChange = async (ozelKodAlanId: number, ozelKodId?: any) => {
        let success: boolean = false;
        if (ozelKodId) {
            success = (await DbItemSpecialCode.add({
                itemId: malzemeId,
                ozelKodAlaniId: ozelKodAlanId,
                specialCodeId: ozelKodId,
                ozelKodAlani: null!
            })).ok;

            if (success) {
                Ants.message.success(intl.formatMessage({ id: 'Operation.Success' }));

                yenile();
            } else {
                Ants.message.error(intl.formatMessage({ id: 'Operation.Error' }));
            }
        } else {
            const response = await DbItemSpecialCode.deleteItemSpecialCode(malzemeId, ozelKodAlanId);

            if (response.ok) {
                Ants.message.success(intl.formatMessage({ id: 'Operation.Success' }));

                yenile();
            } else {
                Ants.message.error(intl.formatMessage({ id: 'Operation.Error' }));
            }
        }
    }

    const malzemeOzelKodIdAl = (ozelKodAlaniId: number): number | null => {
        const ozelKodlar = malzemeOzelKodlari.filter(x => x.ozelKodAlaniId === ozelKodAlaniId);

        if (ozelKodlar.length > 0) return ozelKodlar[0].specialCodeId;

        return null;
    }

    const column = [
        {
            title: Translate("Description"),
            width: '30%',
            dataIndex: 'description',
            key: 'id',
        },
        {
            title: Translate("SpecialCodes"),
            key: 'id',
            render: (text: any, record: Models.SpecialCodeArea) =>
                <>
                    {<Ants.Select style={{ width: 200 }} value={malzemeOzelKodIdAl(record.id) || Translate('SelectSpecialCode')} onChange={ozelKodId => onChange(record.id, ozelKodId)}>
                        {ozelKodlar.filter(x => x.ozelKodAlaniId === record.id).map(x => <Option value={x.id} label={x.description}>
                            <div className="demo-option-label-item">
                                {x.description}
                            </div>
                        </Option>)}
                    </Ants.Select>
                    }
                </>
        },
        {
            title: Translate("Actions"),
            key: 'id',
            render: (text: any, record: Models.SpecialCodeArea) =>
                <Ants.Tooltip placement="top" color="red" title={Translate("Delete")}>
                    <Ants.Button className="units_button" icon={<Icons.DeleteOutlined />} onClick={() => malzemeOzelKodSill(record.id)} />
                </Ants.Tooltip>
        },
    ];

    const malzemeOzelKodSill = async (ozelKodAlanId: number) => {
        const response = await DbItemSpecialCode.deleteItemSpecialCode(malzemeId, ozelKodAlanId);
        if (response.ok) {
            Ants.message.success(intl.formatMessage({ id: 'Operation.Success' }));

            yenile();
        } else {
            Ants.message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }

    function yenile() {
        DbItemSpecialCode.getListByItemId(malzemeId)
            .then(response => {
                if (response.ok) {
                    setMalzemeOzelKodlari(response.data);
                } else console.log(response.message);
            })
            .catch(err => console.log(err.message));

        DbOzelKodAlan.getList()
            .then(response => {
                if (response.ok) setOzelkodAlanlari(response.data);
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));

        DbOzelKod.getList()
            .then(response => {
                if (response.ok) setOzelKodlar(response.data);
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }

    React.useEffect(yenile, [malzemeId]);

    return (
        <>
            <Ants.Card title={Translate("SpecialCodes")} bordered={false} style={{ width: '100%' }}>
                <Ants.Table columns={column} dataSource={ozelKodAlanlari} pagination={{ pageSize: 4 }} scroll={{ y: 700 }} />
            </Ants.Card>
        </>
    );
};
