import { string } from "prop-types";

// ..\..\ApplicationCore\DTO\DosyaDTO.cs
export interface DosyaDTO {
    ad: string;
    tur: string;
    boyut: number;
    veri: any[];
}

// ..\..\ApplicationCore\DTO\KullaniciTuru .cs
export enum UserType {
    Salesman = 0,
    ClientUser =1 ,
}

// ..\..\ApplicationCore\DTO\MalzemeBulDTO.cs
export interface MalzemeBulDTO {
    id: number;
    code: string;
    name: string;
    sku: string;
    slug: string;
    quantity: number;
    unit: string;
    price: number;
    compareAtPrice: number;
    images: string[];
    badges: string[];
    rating: number;
    reviews: number;
    availability: boolean;
    brand?: string;
    categories: string[];
    attributes: string[];
    customFields: any;
}

// ..\..\ApplicationCore\DTO\MalzemeBulFiltreModel.cs
export interface MalzemeBulFiltreModel {
    page: number;
    limit: number;
    filterCategory?: string;
    price?: string;
    sort?: string;
}

// ..\..\ApplicationCore\DTO\MalzemeBulSonucDTO.cs
export interface MalzemeBulSonucDTO {
    page: number;
    limit: number;
    sort: string;
    total: number;
    pages: number;
    from: number;
    to: number;
    items: MalzemeBulDTO[];
    filters: Filter[];
}

// ..\..\ApplicationCore\DTO\MalzemeDTO.cs
export interface B2B_CartItemDTO {
    id: number;
    code: string;
    name: string;
    sku: string;
    slug: string;
    price: number;
    compareAtPrice: number;
    images: string[];
    badges: string[];
    rating: number;
    reviews: number;
    availability: boolean;
    brand?: string;
    categories: string[];
    attributes: string[];
    customFields: any;
}

// ..\..\ApplicationCore\DTO\SepetDTO.cs
export interface B2B_CartDTO {
    malzemeAdedi: number;
    malzemeler: B2B_SepetMalzemeDTO[];
    ekstraSatirlar: B2B_SepetEkstraSatirDTO[];
    altToplamTutar: number;
    netToplamTutar: number;
}

export interface B2B_SepetEkstraSatirDTO {
    tur: SepetEkstraSatirTuru;
    baslik: string;
    tutar: number;
}

export interface B2B_SepeteMalzemeEkleDTO {
    malzemeId: number;
    miktar: number;
}

// ..\..\ApplicationCore\DTO\SepetDTO.cs
export interface BackOfficeCartDTO {
    id: number;
    createDate: Date;
    clientUserFullName: string;
    salesmanFullName: string;
    clientName: string;
    clientCode: string;
    cartDeail: BackOfficeCartDetailDTO;
}

export interface BackOfficeCartDTO {
    id: number;
    createDate: Date;
    clientUserFullName: string;
    salesmanFullName: string;
    clientName: string;
    clientCode: string;
    cartDeail: BackOfficeCartDetailDTO;
}

export interface Backoffice_PaymentDto
    {
         id :number
         idERP? :number
         ficheNo :string
         date_ :Date
         clientId:number
         clientDescription :string
         clientCode :string
         description :string
         amount :number
         orderNo :string
         paymentMethod :PaymentMethod
         provisionCode :string
         status :PaymentStatus
         erpStatus :ErpStatus
         bankName :string
         bankId:number;
         iban :string
         documentNo :string
         salesmanId? :number
         clientUserId? :number
         bonus :number
         username:string
    }

export interface BackOfficeCartDetailDTO {
    cartItems: BackOfficeCartItemDTO[];
    totalPrice: number;
    totalQuantity: number;
}
export interface BackOfficeCartItemDTO {
    itemId: number;
    unitPrice: number;
    categoryName: string;
    vat: number;
    quantity: number;
    code: string;
    description: string;
}

// ..\..\ApplicationCore\DTO\SepetEkstraSatirTuru.cs
export enum SepetEkstraSatirTuru {
    KDV = 0,
    Kargo = 1,
}

// ..\..\ApplicationCore\DTO\SepeteMalzemeEkleDTO.cs
export interface SepeteMalzemeEkleDTO {
    malzemeId: number;
    miktar: number;
}

// ..\..\ApplicationCore\DTO\SepetMalzemeDTO.cs
export interface B2B_SepetMalzemeDTO {
    id: number;
    product: B2B_CartItemDTO;
    options: any[];
    price: number;
    quantity: number;
    total: number;
}

// ..\..\ApplicationCore\Filters\CategoryFilterItem.cs
export interface CategoryFilterItem {
    id: number;
    name: string;
    slug: string;
    image?: any;
    items: number;
    customFields?: any;
    parent?: CategoryFilterItem;
    children: CategoryFilterItem[];
}

// ..\..\ApplicationCore\Filters\Filter.cs
export interface Filter {
    type: string;
    slug: string;
    name: string;
    value?: any;
    items?: any;
}

// ..\..\ApplicationCore\Filters\OrderBy.cs
export enum OrderByType {
    Asc = 0,
    Desc = 1,
}

// ..\..\ApplicationCore\Filters\OrderFilter.cs
export interface OrderFilter extends PaginationParameter {
    orderByType?: OrderByType;
    startDate?: string;
    endDate?: string;
    searchValue?: string;
    columnName?: string;
    searchColumns:string[];
    orderStatus:number[];
    orderErpStatus:number[];
}

export interface PaymentFilter extends PaginationParameter {
    orderByType?: OrderByType;
    startDate?: string;
    endDate?: string;
    searchValue?: string;
    columnName?: string;
    searchColumns:string[];
    paymentStatus:number[];
    erpStatus:number[];
}

// ..\..\ApplicationCore\Filters\Pagination\PagedResult.cs
export interface PagedResult<T> {
    pageNumber: number;
    totalCount: number;
    maxPageCount: number;
    pageSize: number;
    data: T[];
}

// ..\..\ApplicationCore\Filters\Pagination\PaginationParameter.cs
export interface PaginationParameter {
    pageSize: number;
    pageNumber: number;
}

// ..\..\ApplicationCore\Models\Birim.cs
export interface Unit extends IERPEntity {
    id: number;
    idERP?: number;
    unitSetId: number;
    code: string;
    description: string;
    convertFactor1: number;
    convertFactor2: number;
    lineNr: number;
    isMainUnit: boolean;
    isActive: boolean;
}

// ..\..\ApplicationCore\Models\BirimSeti.cs
export interface UnitSet extends IERPEntity {
    id: number;
    idERP?: number;
    code: string;
    description: string;
    isActive: boolean;
    units: Unit[];
}

// ..\..\ApplicationCore\Models\Cari.cs
export interface Client extends IERPEntity {
    id: number;
    idERP?: number;
    code: string;
    description: string;
    description2: string;
    isActive: boolean;
    adresler: ClientAddress[];
    clientUsers: ClientUser[];
    e_InvoicePayer:E_InvoicePayer
    salesmanClient: SalesmanClient[];
}

// ..\..\ApplicationCore\Models\CariAdres.cs
export interface ClientAddress extends IERPEntity {
    id: number;
    idERP?: number;
    clientId: number;
    addressType: ClientAddressType;
    city: string;
    town: string;
    address: string;
    address2: string;
    taxNr: string;
    taxOffice: string;
    client: Client;
}

// ..\..\ApplicationCore\Models\CariAdresTanimi.cs
export enum ClientAddressType {
    Invoice = 0,
    Shipment = 1,
}

// ..\..\ApplicationCore\Models\CariKullanici.cs
export interface ClientUser extends IEntity {
    id: number;
    clientId: number;
    firstName: string;
    lastName: string;
    username: string;
    password: string;
    email: string;
    authorityGroup: CariKullaniciYetkiGrubu;
    client: Client;
}

// ..\..\ApplicationCore\Models\CariKullaniciYetkiGrubu.cs
export enum CariKullaniciYetkiGrubu {
    Master = 0,
}

// ..\..\ApplicationCore\Models\Depo.cs
export interface Warehouse extends IERPEntity {
    id: number;
    idERP?: number;
    code: string;
    isActive: boolean;
    description: string;
    b2bStatus:boolean;
    itemWarehouses: ItemWarehouse[];
}

export interface FirmAndPeriod extends IEntity { // GÜNCEL
    id: number;
    firmNumber: number;
    firmName: string;
    taxNo: string;
    taxOffice: string;
    city: string;
    town: string;
    address: string;
    periodNumber: number;
    periodName: string;
    startDate: string;
    finishDate: string;
    isActive: boolean;
    erp: Erp;
}

export interface VersionInfo{
    id: number;
    name:string;
    releaseNotes:string;
    date:Date;
}

// ..\..\ApplicationCore\Models\Dosya.cs
export interface ImageFile {
    id: number;
    fileName: string;
    extension: string;
    type: string;
    size: number;
    location: string;
    url: string;
}

// ..\..\ApplicationCore\Models\DovizKuru.cs
export interface ExchangeRate extends IEntity {
    id: number;
    date: string;
    currencyType: CurrencyType;
    exchange: number;
}

// ..\..\ApplicationCore\Models\DovizTuru.cs
export enum CurrencyType {
    tl = 0,
    TL = 160,
    USD = 1,
    EUR = 20,
}
export enum AmountType {
    miktarFiili = 0,
    miktarSevkedilebilir = 1,
    miktarGercek = 2,
}
// ..\..\ApplicationCore\Models\Erp.cs
export interface Erp {
    id?: number;
    firmAndPeriod?: FirmAndPeriod;
    erpName?: string;
    port?: string;
    firmNumber?: number;
    periodNumber?: number;
}

// ..\..\ApplicationCore\Models\GrupKodu.cs
export interface GroupCode extends IERPEntity {
    id: number;
    idERP?: number;
    parentGroupCodeId?: number;
    description: string;
    code: string;
    subGroupCodes: GroupCode[];
}

// ..\..\ApplicationCore\Models\IEntity.cs
export interface IEntity {
    id: number;
}

// ..\..\ApplicationCore\Models\IERPEntity.cs
export interface IERPEntity extends IEntity {
    idERP?: number;
}

// ..\..\ApplicationCore\Models\Kullanici.cs
export interface User extends IEntity {
    id: number;
    firstName: string;
    lastName: string;
    username: string;
    password: string;
    email?: string;
    phoneNumber?: string;
    parameters: string;
    isActive: boolean;
    userPeriods: KullaniciDonem[];
    userOperationClaims: UserOperationClaim[];
}

export interface UserOperationClaim {
    id: number;
    userId: number;
    operationClaimId: number;
    user: User;
    operationClaim: OperationClaim;
}

export interface KullaniciDonem {
    userId: number;
    periodId: number;
    isDefault: boolean;
    user: User;
    period: FirmAndPeriod;
}

export interface OperationClaim {
    id: number;
    name: string;
    userOperationClaims: UserOperationClaim[];
}


// ..\..\ApplicationCore\Models\Malzeme.cs
export interface Item extends IERPEntity {
    id: number;
    idERP?: number;
    unitSetId: number;
    groupCodeId?: number;
    code: string;
    description: string;
    description2: string;
    description3: string;
    vat: number;
    returnOfVAT: number;
    sellVAT: number;
    surtax: number;
    width: number;
    size: number;
    height: number;
    area: number;
    grossVolume: number;
    netVolume: number;
    grossWeight: number;
    netWeight: number;
    brandCode: string;
    mainBarcode: string;
    barcode2: string;
    barcode3: string;
    madeIn: string;
    bonusPointFactor:number;
    EXTACCESSFLAGS:number;
    isActive: boolean;
    specialCodeId:number;
    unitSet: UnitSet;
    groupCode?: GroupCode;
    units: ItemUnit[];
    itemWarehouses: ItemWarehouse[];
    itemSpecialCodes: ItemSpecialCode[];
    itemPrices: ItemPrice[];
    itemPictures: ItemPicture[];
    orderLines: OrderLine[];
    cartItems: OrderLine[];
}





// NAVIGATIONS





// ..\..\ApplicationCore\Models\MalzemeBirim.cs
export interface ItemUnit extends IERPEntity {
    id: number;
    idERP?: number;
    itemId: number;
    unitId: number;
    convertFactor1: number;
    convertFactor2: number;
    unit: Unit;
}

// ..\..\ApplicationCore\Models\MalzemeDepo.cs
export interface ItemWarehouse {
    itemId: number;
    warehouseId: number;
    unitId: number;
    miktarFiili: number;
    miktarSevkedilebilir: number;
    miktarGercek: number;
    item: Item;
    warehouse: Warehouse;
    unit: Unit;
}

// ..\..\ApplicationCore\Models\MalzemeFiyat.cs
export interface ItemPrice extends IERPEntity {
    id: number;
    idERP?: number;
    itemId: number;
    unitId: number;
    currencyUnit: CurrencyUnit;
    unitPrice: number;
    currencyType: CurrencyType;
    currencyUnitPrice: number;
    isVATIncluded: boolean;
    startDate: string;
    endDate: string;
    isActive: boolean;
    unit: Unit;
    modifiedDate:Date;
}

// ..\..\ApplicationCore\Models\MalzemeOzelKod.cs
export interface ItemSpecialCode {
    itemId: number;
    ozelKodAlaniId: number;
    specialCodeId: number;
    ozelKodAlani: SpecialCodeArea;
}

// ..\..\ApplicationCore\Models\MalzemeResim.cs
export interface ItemPicture {
    itemId: number;
    fileId: number;
    idERP?: number;
    code: string;
    item: Item;
    file: ImageFile;
}

// ..\..\ApplicationCore\Models\OzelKod.cs
export interface SpecialCode extends IERPEntity {
    id: number;
    idERP?: number;
    ozelKodAlaniId: number;
    code: string;
    description: string;
    isActive: boolean;
    ozelKodAlani: SpecialCodeArea;
    items: Item[];
}

// ..\..\ApplicationCore\Models\OzelKodAlani.cs
export interface SpecialCodeArea extends IERPEntity {
    id: number;
    idERP?: number;
    alanNo: number;
    aciklama: string;
    ozelKodlar: SpecialCode[];
}

// ..\..\ApplicationCore\Models\ParaBirimi.cs
export enum CurrencyUnit {
    YerelParaBirimi = 0,
    DovizParaBirimi = 1,
}

// ..\..\ApplicationCore\Models\Parametre.cs
export interface Parameter extends IEntity {
    id: number;
    key: string;
    value: string;
}

// ..\..\ApplicationCore\Models\ParametreDonem.cs
export interface PeriodParameter {
    phoneNumber: string;
    email: string;
    address: string;
    description: string;
    priceDecimalDigit: number;
    defaultLanguage: string;
    defaultCurrency: number;
    autoOrderIntegration:boolean;
}

// ..\..\ApplicationCore\Models\ParametreEntegrasyon.cs
export interface IntegrationHistory {
    itemGroupColumnName: string;
    e_JobItem: boolean;
    itemPriceGroupCode: string | null | undefined | string[];
    startPriorityNr: number;
    endPriorityNr: number;
    startOrderNr: number;
    endOrderNr: number;
    e_JobItemPriceCard: boolean;
    e_JobCurrent: boolean;
    salesmanGroupCode: string;
    isSubmitItem: boolean;
    isSubmitItemPrice: boolean;
    isSubmitCurrent: boolean;
}

export interface DonemEntegrasyon {
    id: number;
    cariIslemTarih: Date;
    malzemeIslemTarih: Date;
    malzemeFiyatIslemTarih: Date;
    donem: FirmAndPeriod;
}

// ..\..\ApplicationCore\Models\ParametreKullanici.cs
export interface ParametreKullanici {
    kullaniciParametreTest: number;
}

// ..\..\ApplicationCore\Models\ParametreMaster.cs
export interface ParametreMaster {
    masterParametreTest: string;
}


// ..\..\ApplicationCore\Models\SatisElemani.cs
export interface ISalesman extends IERPEntity {
    id: number;
    idERP?: number;
    code: string;
    firstName: string;
    lastName: string;
    username: string;
    password: string;
    confirmPassword:string;
    email: string;
    parameters: string;
    allClientAuth: boolean;
    yetkiGrubu: SatisElemaniYetkiGrubu;
    cariler: SalesmanClient[];
}

 export enum E_InvoicePayer
{
    No = 0,
    Yes = 1,
}


export interface FirmParameter {
    e_InvoicePayer :E_InvoicePayer;
}
export interface Bank {
    id :number;
    idERP :number;
    code :string;
    name :string;
    branch :string;
    branchNumber :string;
    iban :string;
    erpCode :string;
    virtualPosCode:string;
    virtualPosType:VirtualPosType;
    collectionMethodGroup:PaymentMethod;
    isActive:Boolean;
}

// ..\..\ApplicationCore\Models\SatisElemaniCari.cs
export interface SalesmanClient {
    salesmanId: number;
    clientId: number;
    salesman: ISalesman;
    client: Client;
}

// ..\..\ApplicationCore\Models\SatisElemaniYetkiGrubu.cs
export enum SatisElemaniYetkiGrubu {
    Master = 0,
}

// ..\..\ApplicationCore\Models\SepetMalzeme.cs
export interface SepetMalzeme extends IEntity {
    id: number;
    tarih: string;
    cariId: number;
    itemId: number;
    miktar: number;
    cari: Client;
    item: Item;
}



// ..\..\ApplicationCore\Models\Siparis.cs
export interface Order extends IERPEntity {
    id: number;
    idERP?: number;
    orderNo:string;
    date_: string;
    warehouseId: number;
    erpStatus: ErpStatus;
    status: OrderStatus;
    clientId?: number;
    salesmanId?: number;
    clientUserId?: number;
    clientDescription: string;
    currencyUnit: CurrencyUnit;
    currencyType: CurrencyType;
    exchangeRate: number;
    collectionTypeId:number;
    subtotal:number;
    netAmount: number;
    priceWithVAT: number;
    collectionTypeDiscount: number;
    warehouse: Warehouse;
    client?: Client;
    salesman?: ISalesman;
    clientUser?: ClientUser;
    billingAddress:OrderBillingAddress;
    shippingAddress:OrderShippingAddress;
    collectionType : PaymentType;
    orderLines: OrderLine[];
    orderBonus:OrderBonus;
}

export interface OrderBonus{
    id :number;
    idERP :number;
    date :Date;
    campaignCode :string;
    orderNo :string;
    clientId :number;
    clientCode :string;
    salesmanId? :number;
    bonusPoint:number;
    clientUserId? :number;
    orderBonusOperationType :OrderBonusOperationType;
    status :OrderBonusStatus;
    clientBonusDetail :ClientBonusDetail;
    salesman :ISalesman;
    clientUser :ClientUser;
    order :Order;
}

export interface  ClientBonusDetail
    {
         clientId :number
         totalBonusPoint :number
         client :Client
        clientBonuses :OrderBonus[]
    }

export interface OrderBillingAddress{
     Id :number;
     phone :string;
     phone2 :string;
     email :string;
     city :string;
     town :string;
     address :string;
     postCode :string;
     taxNr :string;
     taxOffice :string;
     description :string;
     order: Order |undefined;
}


export interface OrderShippingAddress {
     Id:number;
     receiverName :string;
     receiverTC :string;
     phone :string;
     phone2 :string;
     email :string;
     city :string;
     town :string;
     address :string;
     postCode :string;
     description :string;
     taxNr :string;
     taxOffice :string;
     code :string;
     order: Order|undefined;
}


// ..\..\ApplicationCore\Models\SiparisDurumu.cs

export enum OrderBonusStatus {
    WaitForApproval = 0,
    Cancel = 1,
    Confirmed = 2,
}
export enum VirtualPosType {
    Netahsilat = 0,
}
export enum OrderBonusOperationType
{
    In = 0,
    Out,
}
// ..\..\ApplicationCore\Models\SiparisERPDurum.cs
export enum ErpStatus {
    NotTransferred = 0,
    Transferred = 1,
}
export enum OrderStatus {
    WaitForApproval = 0,
    Cancel = 1,
    Confirmed = 2,
}
export enum PaymentStatus {
    WaitForApproval = 0,
    Cancel = 1,
    Confirmed = 2,
}
// ..\..\ApplicationCore\Models\SiparisSatir.cs
export interface OrderLine extends IERPEntity {
    id: number;
    idERP?: number;
    orderId: number;
    itemId: number;
    unitId: number;
    convertFactor1: number;
    convertFactor2: number;
    quantity: number;
    currencyUnit: CurrencyUnit;
    currencyType: CurrencyType;
    unitPrice: number;
    currencyUnitPrice: number;
    kdvMatrahi: number;
    vat: number;
    priceWithVAT: number;
    netAmount: number;
    lineNr: number;
    subtotal:number;
    item: Item;
    unit: Unit;
    order:Order;
}

export interface PaymentType extends IERPEntity {
    id: number;
    idERP?: number;
    method: PaymentMethod;
    description: string;
    collectionDay: number;
    planCode: string;
    discountRate: number;
    orderBonusFactor: number;
    isActive: boolean;
}


export interface Payment {
     id:Number; 
     idERP?:Number; 
     bankId:Number; 
     clientId:Number; 
     salesmanId?:Number; 
     clientUserId?:Number; 
     paymentTypeId:Number; 
     date_:Date; 
     ficheNo:String;
     clientCode:String;
     clientDescription:String;
     paymentMethod:PaymentMethod;
     documentNo:String;
     provisionCode:String;
     vaultNo:String;
     iban:String;
     bankName:String;
     orderNo:String;
     description:String;
     netahsilatGuid:string;
     amount:number; 
     bonusPoint:Number; 
     status:PaymentStatus;
     erpStatus:ErpStatus;
     client:Client; 
     bank:Bank;
     salesman :ISalesman;
     clientUser: ClientUser 
     paymentType:PaymentType; 
}










// ..\..\ApplicationCore\Models\TahsilatTuru.cs
export enum PaymentMethod {
    ClientAccount = 0, //CariHesap
    Cash, // Nakit
    CreditCard, //Kredi Kart
    Remittance, //Havale
    Cheque, //Çek
    Bill, //Senet
}


// ..\Models\AuthenticateRequest.cs
export interface UserForLoginDto {
    username: string;
    password: string;
    firmNumber: number;
    periodNumber: number;
}

// ..\Models\AuthenticateResponse.cs
export interface AuthenticateResult {
    id: number;
    name: string;
    firmNumber: number;
    periodNumber:number;
    token: string;
    roles:string;
}   

// ..\Models\CurrentUserAuthenticateRequest.cs
export interface CurrentUserAuthenticateRequest {
    username: string;
    password: string;
    firmaNo: number;
    donemNo: number;
    cariId: number;
}

// ..\Models\CurrentUserAuthenticateResponse.cs
export interface CurrentUserAuthenticateResponse {
    id: number;
    firstName: string;
    lastName: string;
    username: string;
    token: string;
    firmaNo: number;
    donemNo: number;
    cari: string;
    cariId: number;
}

// ..\Models\SalesmanAuthenticateRequest.cs
export interface SalesmanAuthenticateRequest {
    username: string;
    password: string;
    firmaNo: number;
    donemNo: number;
    cariId: number;
}

// ..\Models\SalesmanAuthenticateResponse.cs
export interface SalesmanAuthenticateResponse {
    id: number;
    firstName: string;
    lastName: string;
    username: string;
    token: string;
    firmaNo: number;
    donemNo: number;
    cariId: number;
}

// ..\Models\SalesmanAuthenticateResponse.cs
export interface SalesParameter {
    showOutOfStockItem: boolean;
    orderOutOfStockItem: boolean;
    showItemsWithoutPrice: boolean;
    showCurrency: boolean;
    autoOrderIntegration: boolean;
    amountType: AmountType;
    orderWarehouseId: number;
    orderWarehouseName: string;
    collectionBonusType:CollectionBonusType
}
export interface VirtualPosParameter {
  virtualPosType:VirtualPosType;
  netahsilatUrl:string;
  netahsilatHashKey:string;
}
export enum CollectionBonusType {
    BonusPoint = 0,
    Discount = 1,
    BonusPointAndDiscount = 2
  }

export enum PRCLIST_PTYPE {
    SatinAlmaFiyatKarti = 1,
    SatisFiyatKart = 2
  }

export interface LG_FFF_SPECODES {
    logicalref: number;
    codetype: number;
    specodetype: number;
    specode: string;
    definitioN_: string;
    spetyp1: number;
    spetyp2: number;
    spetyp3: number;
    spetyp4: number;
    spetyp5: number;
    submit :boolean
}

export interface CurrentFilter { //Current = Cari
    salesmanGroupCode:string;
    EXTACCESSFLAGS: number;
    submit :boolean;
}

export interface IntegrationFilter {
    itemPriceCardFilter?: ItemPriceCardFilter;
    currentFilter?: CurrentFilter;
    itemFilter?: ItemFilter;
}

export interface ItemFilter {
    EXTACCESSFLAGS:number;
    itemGroupColumnName:string;
    submit :boolean
}

export interface ItemPriceCardFilter {
    EXTACCESSFLAGS:number;
    groupCodes: string[];
    startPriortyNr: number;
    startOrderNr: number;
    endPriortyNr: number;
    endOrderNr: number;
    submit :boolean;
    PRCLIST_PTYPE: PRCLIST_PTYPE;
}

export interface LgDonem {
    Id?: number;
    numara?: number;
    firmaNumarasi?: number;
    baslangicTarihi?: Date;
    bitisTarihi?: Date;
    aktif?:boolean
}


export interface MailProvider {
    id:number;
    port: number;
    host: string;
    sender: string;
    password: string;
    description: string;
    title: string;
}

export interface MailTemplate {
    id:number;
    description: string;
    content: string;
    header: string;
    footer: string;
    html: string;
}

export interface MailSendingConditions {
    mailProviderId:number;
    mailOperationGroup: MailOperationGroup;
    mailTemplateId: number;
    mailTemplate: MailTemplate;
    mailProvider :MailProvider;
}

export enum MailOperationGroup {
    Order = 0,
    Collection = 1,
    Offer = 2,
}

export interface DeliveryType {
    id:number;
    description: string;
    name: string;
}


export interface IOrderDetailDto {
    orderId :number;
    billingAddress : OrderBillingAddress;
    shippingAddress: OrderShippingAddress;
    items : IOrderLineItem[];
}

 
export interface IOrderLineItem {
  quantity:number;
  currencyUnit: CurrencyUnit
  currencyType: CurrencyType
  unitPrice:number
  currencyUnitPrice:number
  kDVMatrahi:number
  vat:number
  priceWithVAT:number
  subtotal:number
  lineNr:number
  code:string
  description:string
  description2:string
  unitDescription:string
  unitCode:string
}
export interface OrderDto
{
      id:number;
      idERP :string;
      orderNo: string;
      date_:Date 
      clientDescription :string;
      subtotal :number;
      salesman:ISalesman;
      clientUser:ClientUser;
      priceWithVat :number;
      netAmount :number;
      warehouse :string;
      bonusPoint :number; 
      paymentMethod:PaymentMethod 
      status :OrderStatus
      erpStatus :ErpStatus
}
