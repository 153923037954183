import * as React from 'react';
import { useIntl } from 'react-intl';
import { Modal, Table, Card, Input, Button, Tooltip, message, Row, Col } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Unit, UnitSet } from '../../../../models/models';
import * as DbBirimSeti from '../../../../api_ts/unitSet';
import { tableColumnTextFilterConfig } from '../../components/AntHelpers/TableHelpers';
import { Translate } from '../../i18n/Translate';


export const Units: React.FC = () => {
    const intl = useIntl();
    const [unitSetModalVisible, setUnitSetModalVisible] = React.useState(false);
    const [indexBirimSeti, setIndexBirimSeti] = React.useState<number>(0);
    const [indexBirim, setIndexBirim] = React.useState<number>(0);
    // const [searchUnit, setSearchUnit] = React.useState({ searchText: "", searchedColumn: "" });



    const [birimSetleri, setBirimSetleri] = React.useState<UnitSet[]>([
        {
            id: 0,
            code: "",
            description: "",
            isActive: true,
            units: [
                {
                    id: 0,
                    unitSetId: 0,
                    code: "",
                    description: "",
                    convertFactor1: 1,
                    convertFactor2: 1,
                    lineNr: 1,
                    isMainUnit: true,
                    isActive: true
                }
            ]
        }
    ]);

    function yenile() {
        DbBirimSeti.getList()
            .then(response => {
                if (response.ok)
                    setBirimSetleri(response.data);
            })
            .catch(err => console.log(err.message));
    }

    React.useEffect(yenile, []);

    const saveUnitSet = async () => {
        const birimSeti = birimSetleri[indexBirimSeti];

        const response = birimSeti.id === 0
            ? await DbBirimSeti.add(birimSeti)
            : await DbBirimSeti.update(birimSeti);

        if (response.ok) {
            message.success(intl.formatMessage({ id: "Operation.Success" }));
            yenile();
        } else {
            message.error(intl.formatMessage({ id: "Operation.Error" }));
        }
    }

    const deleteUnitSet = async (birimSetiId: number) => {
        const response = await DbBirimSeti.deleteUnitSet(birimSetiId);
        if (response.ok) {
            message.success(intl.formatMessage({ id: "Operation.Success" }));
            yenile();
        } else {
            message.error(intl.formatMessage({ id: "Operation.Error" }));
        }
    }

    const handleUnitSetEditButton = (index: number) => {
        setIndexBirimSeti(index);
        setIndexBirim(0);
        setUnitSetModalVisible(true);
    }

    const handleUnitDeleteButton = (index: number) => {
        const yeniBirimSetleri = [...birimSetleri];
        yeniBirimSetleri[indexBirimSeti] = { ...yeniBirimSetleri[indexBirimSeti] };
        yeniBirimSetleri[indexBirimSeti].units = [...yeniBirimSetleri[indexBirimSeti].units];
        yeniBirimSetleri[indexBirimSeti].units.splice(index, 1);

        setBirimSetleri(yeniBirimSetleri);
        setIndexBirim(Math.min(index, yeniBirimSetleri[indexBirimSeti].units.length - 1));
    }

    const columnsUnitSets = [
        {
            key: 'code',
            dataIndex: 'code',
            title: Translate("Code"),
            render: (text: string) => text,
            ...tableColumnTextFilterConfig<UnitSet>(),
            onFilter: (value: any, record: UnitSet) => {
                return record.code
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
            },
        },
        {
            title: Translate("Description"),
            dataIndex: 'description',
            render: (text: string) => text,
            ...tableColumnTextFilterConfig<UnitSet>(),
            onFilter: (value: any, record: UnitSet) => {
                return record.description
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
            },
        },
        {
            key: 'x',
            dataIndex: '',
            width: 100,
            render: (_: string, record: UnitSet, index: number) =>
                <>
                    <Tooltip placement="top" color="blue" title={Translate("Edit")}>
                        <Button icon={<EditOutlined />} onClick={() => handleUnitSetEditButton(index)} />
                    </Tooltip>
                    <Tooltip placement="top" color="red" title={Translate("Delete")}>
                        <Button danger icon={<DeleteOutlined />} onClick={() => deleteUnitSet(record.id)} />
                    </Tooltip>
                </>
        },
    ];

    const columnsUnits = [
        {
            title: Translate("Units"),
            dataIndex: 'code',
            key: 'id',
            render: (text: string, record: Unit, index: number) =>
                <div
                    style={{
                        cursor: 'pointer',
                        fontWeight: indexBirim === index ? 'bold' : 'normal',
                        fontStyle: text === '' ? 'italic' : 'normal'
                    }}
                    onClick={() => setIndexBirim(index)}
                >
                    {text || Translate('UnitCode')}
                </div>
        },
        {
            key: 'x',
            dataIndex: '',
            width: 50,
            render: (text: string, record: Unit, index: number) =>
                birimSetleri[indexBirimSeti].units.length <= 1 ? null : (
                    <Tooltip placement="top" color="red" title={Translate("Delete")}>
                        <Button danger icon={<DeleteOutlined />} onClick={() => handleUnitDeleteButton(index)} />
                    </Tooltip>
                )
        }
    ];

    const addNewUnit = () => {
        const birimSeti = birimSetleri[indexBirimSeti];
        const countBirimler = birimSeti.units.length;

        if (birimSeti.units[countBirimler - 1].code === '') return;

        const yeniBirimSetleri = [...birimSetleri];
        yeniBirimSetleri[indexBirimSeti].units = [...yeniBirimSetleri[indexBirimSeti].units];
        yeniBirimSetleri[indexBirimSeti].units.push({
            id: 0,
            unitSetId: 0,
            code: "",
            description: "",
            convertFactor1: 1,
            convertFactor2: 1,
            lineNr: yeniBirimSetleri[indexBirimSeti].units.length,
            isMainUnit: false,
            isActive: true
        });

        setBirimSetleri(yeniBirimSetleri);
        setIndexBirim(yeniBirimSetleri[indexBirimSeti].units.length - 1);
    }

    const setEditingUnitSet = function (callback: (birimSeti: UnitSet) => void): void {
        const yeniBirimSetleri = [...birimSetleri];
        yeniBirimSetleri[indexBirimSeti] = { ...birimSetleri[indexBirimSeti] }

        callback(yeniBirimSetleri[indexBirimSeti]);

        setBirimSetleri(yeniBirimSetleri);
    }

    const setEditingUnit = function (callback: (birim: Unit) => void): void {
        const yeniBirimSetleri = [...birimSetleri];
        yeniBirimSetleri[indexBirimSeti] = { ...birimSetleri[indexBirimSeti] }
        yeniBirimSetleri[indexBirimSeti].units = [...yeniBirimSetleri[indexBirimSeti].units];
        yeniBirimSetleri[indexBirimSeti].units[indexBirim] = { ...yeniBirimSetleri[indexBirimSeti].units[indexBirim] };

        callback(yeniBirimSetleri[indexBirimSeti].units[indexBirim]);

        setBirimSetleri(yeniBirimSetleri);
    }

    return (
        <>
            <Card
                title={Translate("UnitSets")}
                bordered={false}
                extra={
                    <Button onClick={() => {
                        let lastIndex = birimSetleri.length - 1;

                        if (birimSetleri.length === 0 || birimSetleri[birimSetleri.length - 1].id !== 0) {
                            const yeniBirimSetleri = [
                                ...birimSetleri,
                                {
                                    id: 0,
                                    code: '',
                                    description: '',
                                    isActive: true,
                                    units: [
                                        {
                                            id: 0,
                                            unitSetId: 0,
                                            code: "",
                                            description: "",
                                            convertFactor1: 1,
                                            convertFactor2: 1,
                                            lineNr: 1,
                                            isMainUnit: true,
                                            isActive: true
                                        }
                                    ]
                                }
                            ];

                            lastIndex++;

                            setBirimSetleri(yeniBirimSetleri);
                        }

                        setIndexBirimSeti(lastIndex);
                        setIndexBirim(0);
                        setUnitSetModalVisible(true);
                    }}
                    >
                        {Translate("AddUnitSet")}
                    </Button>
                }
            >
                <Table bordered size="small" columns={columnsUnitSets} dataSource={birimSetleri.filter(x => x.id > 0)} scroll={{ y: 700 }} />
            </Card>
            <Modal
                centered
                title={Translate(birimSetleri[indexBirimSeti]?.id === 0 ? "AddUnitSet" : "EditUnitSet")}
                visible={unitSetModalVisible}
                okText={Translate("Save")}
                cancelText={Translate("Cancel")}
                onOk={saveUnitSet}
                onCancel={() => setUnitSetModalVisible(false)}
            >
                <div className="form-group" style={{ marginBottom: '20px' }}>
                    <label htmlFor="UnitSetCode">{Translate("UnitSetCode")}</label>
                    <Row gutter={[14, 14]}>
                        <Col xs={24}>
                            <Input
                                size="large"
                                placeholder={Translate('UnitSetCode')}
                                value={birimSetleri[indexBirimSeti]?.code}
                                onChange={e => setEditingUnitSet(x => x.code = e.target.value)}
                            />
                        </Col>
                    </Row>
                </div>
                <div className="form-group" style={{ marginBottom: '20px' }}>
                    <label htmlFor="UnitSetDescription">{Translate("UnitSetDescription")}</label>
                    <Row gutter={[14, 14]}>
                        <Col xs={24}>
                            <Input
                                size="large"
                                placeholder={Translate('UnitSetDescription')}
                                value={birimSetleri[indexBirimSeti]?.description}
                                onChange={e => setEditingUnitSet(x => x.description = e.target.value)}
                            />
                        </Col>
                    </Row>
                </div>
                <Row gutter={[14, 14]}>
                    <Col xs={10}>
                        <Table columns={columnsUnits} dataSource={birimSetleri[indexBirimSeti]?.units} pagination={false} size="small"></Table>
                    </Col>
                    <Col xs={14}>
                        <div className="form-group" style={{ marginBottom: '20px' }}>
                            <label htmlFor="UnitCode">{Translate("UnitCode")}</label>
                            <Row gutter={[14, 14]}>
                                <Col xs={24}>
                                    <Input
                                        size="large"
                                        placeholder={Translate('UnitCode')}
                                        value={birimSetleri[indexBirimSeti]?.units[indexBirim]?.code}
                                        onChange={e => setEditingUnit(x => x.code = e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <Row gutter={[14, 14]}>
                            <Col xs={24}>
                                <Input
                                    size="large"
                                    placeholder={Translate('UnitDescription')}
                                    value={birimSetleri[indexBirimSeti]?.units[indexBirim]?.description}
                                    onChange={e => setEditingUnit(x => x.description = e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row gutter={14}>
                            <Col xs={12}>{birimSetleri[indexBirimSeti]?.units[indexBirim]?.convertFactor1} {birimSetleri[indexBirimSeti]?.units[indexBirim]?.code}</Col>
                            <Col xs={12}>{birimSetleri[indexBirimSeti]?.units[indexBirim]?.convertFactor2} {birimSetleri[indexBirimSeti]?.units[0]?.code}</Col>
                        </Row>
                        <Row gutter={[14, 14]}>
                            <Col xs={12}>
                                <Input
                                    size="large"
                                    placeholder={Translate('UnitFact1')}
                                    value={birimSetleri[indexBirimSeti]?.units[indexBirim]?.convertFactor1}
                                    onChange={e => setEditingUnit(x => x.convertFactor1 = parseInt(e.target.value))}
                                    disabled={indexBirim === 0}
                                />
                            </Col>
                            <Col xs={12}>
                                <Input
                                    size="large"
                                    placeholder={Translate('UnitFact2')}
                                    value={birimSetleri[indexBirimSeti]?.units[indexBirim]?.convertFactor2}
                                    onChange={e => setEditingUnit(x => x.convertFactor2 = parseInt(e.target.value))}
                                    disabled={indexBirim === 0}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Tooltip placement="top" color="green" title={Translate("AddUnit")}>
                    <Button type="dashed" onClick={addNewUnit}>+</Button>
                </Tooltip>
            </Modal>


        </>
    );
}
