import { useIntl } from 'react-intl';
import React, { useState, useEffect } from 'react';
import { MailTemplate } from '../../../../models/models';
import * as DbMailTemplate from '../../../../api_ts/mailTemplate';
import { Button, Tabs, Table, message, Modal, Form, InputNumber, Input, Tooltip } from 'antd';
import { PlusSquareOutlined, UserOutlined, EditOutlined, DeleteOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { useStateWithCallbackLazy as useStateCallback } from 'use-state-with-callback';
import { MailSendingConditions } from './MailSendingConditions';
import { Translate } from '../../i18n/Translate';


const { TabPane } = Tabs;

export const MailTemplates: React.FC = () => {

    const intl = useIntl();
    const [mailTemplates, setMailTemplates] = useState<MailTemplate[]>([]);
    const [loading, setLoading] = useStateCallback(false);
    const [flags, setFlags] = useState({ mailTemplateModalVisible: false, operation: "" });
    const [mailTemplateForm] = Form.useForm();
    const formItemLayout = { labelCol: { span: 7 }, wrapperCol: { span: 17 } };


    useEffect(() => {
        getAllMailTemplates()
    }, [])

    const columns = [
        {
            key: 'description',
            dataIndex: 'description',
            title: Translate("Description"),
        },
        {
            key: 'header',
            title: Translate("Header"),
            dataIndex: 'header'
        },
        {
            key: 'content',
            title: Translate("Content"),
            dataIndex: 'content'
        },
        {
            key: 'footer',
            title: Translate("Footer"),
            dataIndex: 'footer'
        },
        {
            key: 'id',
            width: 100,
            render: (text: string, mailTemplate: MailTemplate) =>
                <>
                    <Tooltip placement="top" color="blue" title={Translate("Edit")}>
                        <Button icon={<EditOutlined />} onClick={() => {
                            mailTemplateForm.resetFields()
                            setFlags({ ...flags, mailTemplateModalVisible: true, operation: "Update" })
                            fillMailTemplateForm(mailTemplate)
                        }
                        } />
                    </Tooltip>
                    <Tooltip placement="top" color="red" title={Translate("Delete")}>
                        <Button icon={<DeleteOutlined />} onClick={() => deleteMailTemplate(mailTemplate.id)} />
                    </Tooltip>
                </>
        },

    ];

    const getAllMailTemplates = () => {
        return DbMailTemplate.getList()
            .then(response => {
                if (response.ok) {
                    setMailTemplates(response.data);
                }
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }


    const addOrUpdateMailTemplate = async (MailTemplate: MailTemplate) => {
        if (MailTemplate.id == 0)
            IsSuccessful(await DbMailTemplate.add(MailTemplate));
        else
            IsSuccessful(await DbMailTemplate.update(MailTemplate))
    }


    const deleteMailTemplate = async (MailTemplateId: number) => {
        if ((await DbMailTemplate.deleteMailTemplate(MailTemplateId)).ok) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }))
            getAllMailTemplates();
        }
        else
            message.error(intl.formatMessage({ id: 'Operation.Error' }))
    }


    const IsSuccessful = (action: any) => {
        setLoading(true, () => {
            if (action.ok) {
                getAllMailTemplates()
                    .then(() => {
                        mailTemplateForm.resetFields()
                        setFlags({ ...flags, mailTemplateModalVisible: false, operation: "Add" })
                        setLoading(false, () => message.success(intl.formatMessage({ id: 'Operation.Success' })))
                    })
            } else {
                message.error(intl.formatMessage({ id: 'Operation.Error' }))
            }
        })
    }


    const fillMailTemplateForm = (MailTemplate: MailTemplate) => {
        mailTemplateForm.setFieldsValue(MailTemplate);
    }

    const header = (<Button type="primary" className="ml-auto mb-1" onClick={() => {
        mailTemplateForm.resetFields()
        setFlags({ ...flags, mailTemplateModalVisible: true, operation: "Add" })
    }}
        icon={<PlusSquareOutlined style={{ verticalAlign: 'middle', fontSize: '20px' }} />} >
        <span style={{ verticalAlign: 'middle' }} >{Translate("Add")}</span>
    </Button>)


    return (
        <>
            <div className="site-card-border-less-wrapper">
                <div className="d-flex">{header} </div>
                <Table bordered size="small" columns={columns} dataSource={mailTemplates} />
            </div>

            <Modal
                title={Translate("MailTemplate")}
                visible={flags.mailTemplateModalVisible}
                onCancel={() => setFlags({ ...flags, mailTemplateModalVisible: false, operation: "Add" })}
                onOk={() => { mailTemplateForm.submit() }}
                okText={Translate(flags.operation)}
                cancelText={Translate("Cancel")}
                confirmLoading={loading}
            >
                <Form
                    form={mailTemplateForm}
                    layout={"horizontal"}
                    onFinish={addOrUpdateMailTemplate}
                    {...formItemLayout}
                    size={"small"}
                    labelAlign={"left"}
                >
                    <Form.Item
                        name={"id"}
                        fieldKey={"id"}
                        hidden={true}
                        initialValue={0} >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={Translate("Description")}
                        name="description"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate("Description")} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("Header")}
                        name="header"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate("Header")} />

                    </Form.Item>
                    <Form.Item
                        label={Translate("Content")}
                        name="content"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate("Content")} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("Footer")}
                        name="footer"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate("Footer")} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );


}
