// react
import React from 'react';
import tr_TR from 'antd/lib/locale/tr_TR';
 import en_US from 'antd/lib/locale/en_US';
// import de_DE from 'antd/lib/locale/de_DE';
// third-party
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// application
import Dropdown from './Dropdown';
import { localeChange } from '../../store/locale';
import { changeAntDesignLocale } from '../../store/locale';


function DropdownLanguage(props) {
    const { locale, localeChange: changeLocale ,changeAntDesignLocale } = props;

    const languages = [
        {
            title: 'Türkçe',
            locale: 'tr',
            code: 'TR',
            icon: '/images/languages/language-1.png',
            icon_srcset: '/images/languages/language-1.png 1x, /images/languages/language-1@2x.png 2x',
        },
        {
            title: 'English',
            locale: 'en',
            code: 'EN',
            icon: '/images/languages/language-1.png',
            icon_srcset: '/images/languages/language-1.png 1x, /images/languages/language-1@2x.png 2x',
        },
        // {
        //     title: 'RTL',
        //     locale: 'ar',
        //     code: 'RTL',
        //     icon: '/images/languages/language-3.png',
        //     icon_srcset: '/images/languages/language-3.png 1x, /images/languages/language-3@2x.png 2x',
        // },
    ];

    const language = languages.find((x) => x.locale === locale);

    const title = (
        <React.Fragment>
            <FormattedMessage id="topbar.language" defaultMessage="Language" />
            {': '}
            <span className="topbar__item-value">{language.code}</span>
        </React.Fragment>
    );

    return (
        <Dropdown
            title={title}
            withIcons
            items={languages}
            onClick={(item) => {
                changeLocale(item.locale)
                switch (item.code) {
                    case "TR":changeAntDesignLocale(tr_TR)
                    case "EN":changeAntDesignLocale(en_US)
                        break;
                    default:
                        break;
                }
            }}
        />
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

const mapDispatchToProps = {
    localeChange,
    changeAntDesignLocale,
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownLanguage);
