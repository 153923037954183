import * as React from 'react';
import { Tabs, Card, Switch, Checkbox, Button, message, Form, Row, Col, Space, Select, InputNumber, Spin, Layout, Descriptions, } from 'antd';
import { DatabaseOutlined, SwapOutlined } from '@ant-design/icons';
import * as DbEntegrasyon from '../../../../../api_ts/IntegrationInfo';
import * as DbLogoMalzemeFiyat from '../../../../../api_ts/logoItemPrice';
import * as dbErp from '../../../../../api_ts/erp';
import { Erp, IntegrationHistory, IntegrationFilter, LG_FFF_SPECODES, PRCLIST_PTYPE, VirtualPosType, VirtualPosParameter } from '../../../../../models/models';
import { useIntl } from 'react-intl';
import { Translate } from '../../../i18n/Translate';
import { Netahsilat } from './Netahsilat';
import * as dbVirtualPos from '../../../../../api_ts/virtualPos';



const { Content } = Layout;
const { Option } = Select;

export const VirtualPos: React.FC = () => {
    const intl = useIntl();
    const [integrationForm] = Form.useForm();
    const [showVirtualPosType, setVirtualPosType] = React.useState<VirtualPosType>();
    const [salesmanGroupCodes, setSalesmanGroupCodes] = React.useState<string[]>([]);
    const [itemPriceGroupCodes, setItemPriceGroupCodes] = React.useState<LG_FFF_SPECODES[]>([]);
    const [virtualPosParameter, setVirtualPosParameter] = React.useState<VirtualPosParameter>();


    React.useEffect(() => {
        getVirtualPosParameter()
    }, []);

    const getVirtualPosParameter = async () => {
        return dbVirtualPos.getVirtualPosParameter()
            .then(response => {
                if (response.ok)
                    setVirtualPosParameter(response.data);
                else
                    console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }
    const selectIntegrationVirtualPos = () => {
        switch (virtualPosParameter?.virtualPosType) {
            case VirtualPosType.Netahsilat:
                return <Netahsilat
                    netahsilatHashKey={virtualPosParameter?.netahsilatHashKey}
                    netahsilatUrl={virtualPosParameter?.netahsilatUrl}
                    addOrUpdateNetahsilat={addOrUpdateVirtualPos}
                    deleteNetahsilat={deleteVirtualPos}
                />
            default: break;
        }
    }

    const addOrUpdateVirtualPos = async (virtualPos) => {
        if ((await dbVirtualPos.addOrUpdateVirtualPosParameter(virtualPos)).ok) {
            getVirtualPosParameter();
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }

    const deleteVirtualPos = async (virtualPos) => {
        const response = await dbVirtualPos.deleteVirtualPosParameter(virtualPos);
        if (response.ok) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            getVirtualPosParameter();
        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }

    return (
        <div>
            <Tabs>
                <Tabs.TabPane key="1" tab={<span><DatabaseOutlined />{Translate("VirtualPos")}</span>}>
                    <Content>
                        <Row>
                            <Col>
                                <Select
                                    placeholder={Translate("PleaseSelect")}
                                    onChange={(value: any) => {
                                        setVirtualPosType(value)
                                    }}
                                    value={virtualPosParameter?.virtualPosType}
                                >
                                    {Object.keys(VirtualPosType).filter(key => !/^\d+$/.test(key)).map(key =>
                                        <Option value={VirtualPosType[key as any]}>{Translate(key)}</Option>)}
                                </Select>
                            </Col>
                            <Col md={7}>
                                {selectIntegrationVirtualPos()}
                            </Col>
                        </Row>
                    </Content>
                </Tabs.TabPane>
            </Tabs >

        </div >
    );

}
