import { GET_WINDOWS_SIZE } from './responsiveActionTypes';

const initialState = { width: 0, height: 0 };

export default function responsiveReducer(state = initialState, action) {
    switch (action.type) {
        case GET_WINDOWS_SIZE:
            return { width: window.innerWidth, height: window.innerHeight }
        default:
            return state;
    }
}
