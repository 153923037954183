import { request } from './request';


export async function getList() {
    return await request('clients/getList');
}
export async function getById(clientId) {
    return await request(`clients/getById?clientId=${clientId}`);
}

export async function getClientWithClientAdressesById(clientId) {
    return await request(`clients/getClientWithClientAdressesById?clientId=${clientId}`);
}

export async function getListBySalesmanId(salesmanId) {
    return await request(`salesmen/getClientsOfSalemanById/${salesmanId}`, 'GET');
}

export async function getExtractReportByFilter_API(filter) {
    return await request(`LogoReports/clients-extract?startDate=${filter.startDate}&endDate=${filter.endDate}&searchValue=${filter.searchValue}&sortType=${filter.dateSortType}`, 'GET');
}

export async function getAccountSummaryByFilter_API(filter) {
    return await request(`LogoReports/clients-account-summary?startDate=${filter.startDate}&endDate=${filter.endDate}`, 'GET');
}


