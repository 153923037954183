import React from 'react';

import * as dbOrder from "../../api_js/order";
import * as helper from "../../../../helper/helper_methods";
import { FormattedMessage, useIntl } from "react-intl";
import { initial_OrderSuccess } from '../../../../models/initial_models';
import '../../../B2B/css/errorPage.css'


export default function ShopPageNetahsilatError() {

    const intl = useIntl();
    const params = helper.getUriParams("id");
    const [error, setError] = React.useState(initial_OrderSuccess);

    React.useEffect(() => {
        // getOrderById()
    }, [])

    // const getOrderById = () => {
    //     dbOrder.getCompletedOrderById(params)
    //         .then((response) => {
    //             if (response.ok) {
    //                 setOrder(response.data)
    //             } else alert(response.message);
    //         })
    //         .catch((err) => alert(err.message));
    // }


    return (
        <div id="notfound">
            <div class="notfound">
                <div class="notfound-bg">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <h1>oops!</h1>
                <h2>Error 404 : Page Not Found</h2>
                <a href="#">go back</a>
                <div class="notfound-social">
                    <a href="#"><i class="fa fa-facebook"></i></a>
                    <a href="#"><i class="fa fa-twitter"></i></a>
                    <a href="#"><i class="fa fa-pinterest"></i></a>
                    <a href="#"><i class="fa fa-google-plus"></i></a>
                </div>
            </div>
        </div>
    );
}
