import { useIntl } from 'react-intl';
import * as React from 'react';
import { Bank, PaymentMethod, VirtualPosType } from '../../../../models/models';
import * as DbBank from '../../../../api_ts/bank';
import { PlusSquareOutlined, DeleteOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import { Tabs, Modal, Table, Input, Button, Tooltip, message, Form, Checkbox, Select } from 'antd';
import { useStateWithCallbackLazy as useStateCallback } from 'use-state-with-callback';
import { useEffect, useState } from 'react';
import { Translate } from '../../i18n/Translate';

const Option = Select;
export const Banks: React.FC = () => {

    const intl = useIntl();
    const [banks, setBanks] = useState<Bank[]>([]);
    const [loading, setLoading] = useStateCallback(false);
    const [flags, setFlags] = useState({ bankModalVisible: false, operation: "" });
    const [bankForm] = Form.useForm();

    useEffect(() => {
        getAllBanks()
    }, [])

    const getAllBanks = () => {
        return DbBank.getList()
            .then(response => {
                if (response.ok)
                    setBanks(response.data);
                else
                    console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }

    const deleteBank = async (value: number) => {
        const response = await DbBank.deleteBank(value);
        if (response.ok) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            getAllBanks();
        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }

    const addOrUpdateBank = async (Bank: Bank) => {
        if (Bank.id == 0)
            IsSuccessful(await DbBank.add(Bank));
        else
            IsSuccessful(await DbBank.update(Bank))
    }

    const fillBankForm = (Bank: Bank) => {
        bankForm.setFieldsValue(Bank);
    }

    const IsSuccessful = (action: any) => {
        setLoading(true, () => {
            if (action.ok) {
                getAllBanks()
                    .then(() => {
                        bankForm.resetFields()
                        setFlags({ ...flags, bankModalVisible: false, operation: "Add" })
                        setLoading(false, () => message.success(intl.formatMessage({ id: 'Operation.Success' })))
                    })
            } else {
                message.error(intl.formatMessage({ id: 'Operation.Error' }))
            }
        })
    }

    const columns = [
        {
            key: "code",
            title: Translate("Code"),
            dataIndex: 'code',
        },
        {
            key: 'name',
            title: Translate("BankName"),
            dataIndex: 'name',
        },
        {
            key: 'branch',
            title: Translate("BankBranch"),
            dataIndex: 'branch',
        },
        {
            key: 'branchNumber',
            title: Translate("BranchNumber"),
            dataIndex: 'branchNumber',
        },
        {
            key: 'iban',
            title: Translate("Iban"),
            dataIndex: 'iban',
        },
        {
            key: 'erpCode',
            title: Translate("ErpCode"),
            dataIndex: 'erpCode',
        },
        {
            title: Translate("CollectionMethod"),
            dataIndex: 'collectionMethodGroup',
            key: 'id',
            render: (text: string, bank: Bank) => {
                return (Translate(`Payment.${PaymentMethod[bank.collectionMethodGroup]}`));
            }
        },
        {
            title: Translate("VirtualPosCode"),
            dataIndex: 'virtualPosCode',
            key: 'id',
        },
        {
            title: Translate("VirtualPosType"),
            dataIndex: 'virtualPosType',
            key: 'id',
            render: (text: string, bank: Bank) => {
                if (bank.virtualPosType != null)
                    return (Translate(`${VirtualPosType[bank.virtualPosType]}`));
            }
        },
        {
            key: 'isActive',
            title: Translate("Status"),
            dataIndex: 'isActive',
            render: (text: string, record: Bank) => record.isActive ? "Aktif" : "Pasif",
        },
        {
            key: 'id',
            width: 100,
            render: (text: string, Bank: Bank) =>
                <>
                    <Tooltip placement="top" color="blue" title={Translate("Edit")}>
                        <Button icon={<EditOutlined />} onClick={() => {
                            bankForm.resetFields()
                            setFlags({ ...flags, bankModalVisible: true, operation: "Update" })
                            fillBankForm(Bank)
                        }
                        } />
                    </Tooltip>
                    <Tooltip placement="top" color="red" title={Translate("Delete")}>
                        <Button icon={<DeleteOutlined />} onClick={() => deleteBank(Bank.id)} />
                    </Tooltip>
                </>
        },
    ];



    const header = (<Button type="primary" className="ml-auto mb-1" onClick={() => {
        bankForm.resetFields()
        setFlags({ ...flags, bankModalVisible: true, operation: "Add" })
    }}
        icon={<PlusSquareOutlined style={{ verticalAlign: 'middle', fontSize: '20px' }} />} >
        <span style={{ verticalAlign: 'middle' }} >{Translate("Add")}</span>
    </Button>)

    const formItemLayout = { labelCol: { span: 7 }, wrapperCol: { span: 17 } };

    return (
        <>
            <Tabs
                defaultActiveKey="1"
                tabBarStyle={{ marginBottom: "3.5px" }}
            >
                <Tabs.TabPane tabKey="1"
                    tab={<span><UserOutlined />{Translate("Banks")}</span>} key="1">
                    <div className="site-card-border-less-wrapper">
                        <div className="d-flex">{header} </div>
                        <Table bordered size="small" columns={columns} dataSource={banks} />
                    </div>
                </Tabs.TabPane>


            </Tabs>
            <Modal
                title={Translate("Bank")}
                visible={flags.bankModalVisible}
                onCancel={() => setFlags({ ...flags, bankModalVisible: false, operation: "Add" })}
                onOk={() => { bankForm.submit() }}
                okText={Translate(flags.operation)}
                cancelText={Translate("Cancel_noun")}
                confirmLoading={loading}
            >
                <Form
                    form={bankForm}
                    layout={"horizontal"}
                    onFinish={addOrUpdateBank}
                    {...formItemLayout}
                    size={"small"}
                    labelAlign={"left"}
                >
                    <Form.Item
                        name={"id"}
                        fieldKey={"id"}
                        hidden={true}
                        initialValue={0} >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={Translate("Code")}
                        name="code"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate("Code")} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("CollectionType")}
                        name="collectionMethodGroup"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Select
                            placeholder={Translate("PleaseSelect")}>
                            <Option key={2} value={PaymentMethod.Remittance}>{`${Translate("Payment." + PaymentMethod[PaymentMethod.Remittance])}`}</Option>
                            <Option key={3} value={PaymentMethod.CreditCard}>{`${Translate("Payment." + PaymentMethod[PaymentMethod.CreditCard])}`}</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={Translate("BankName")}
                        name="name"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate("BankName")} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("BankBranch")}
                        name="branch"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate("BankBranch")} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("BranchNumber")}
                        name="branchNumber"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate("BranchNumber")} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("Iban")}
                        name="iban"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate("Iban")} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("VirtualPosType")}
                        name="virtualPosType"
                    >
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("VirtualPosCode")}
                        name="virtualPosCode"
                    >
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("IsActive")}
                        name="isActive"
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Checkbox></Checkbox>
                    </Form.Item>

                </Form>
            </Modal>
        </>
    );
};

