// react
import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';

// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';
import ToTop from './ToTop';
import * as dbVersionInfo from '../../api_js/versionInfo';


export default function Footer() {

    const [versionInfo, setVersionInfo] = React.useState();

    React.useEffect(() => {
        getCurrentVersion()
    }, [])

    const getCurrentVersion = () => {                 //TODO RESMAK BACKOFFİCE ve RESMAK B2B için bir redux'a state oluşturup ikisi içinde kullanılmalı hızlı olsun diye bu şekilde çektim verileri düzeltilecek.
        return dbVersionInfo.getCurrentVersion()
            .then(response => {
                if (response.ok) {
                    setVersionInfo(response.data);
                }
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }

    const informationLinks = [
        { title: <FormattedMessage id="footer.information.aboutUs" />, url: '' },
        { title: <FormattedMessage id="footer.information.deliveryInformation" />, url: '' },
        { title: <FormattedMessage id="footer.information.privacyPolicy" />, url: '' },
        { title: <FormattedMessage id="footer.information.brands" />, url: '' },
        { title: <FormattedMessage id="footer.information.contactUs" />, url: '' },
        { title: <FormattedMessage id="footer.information.returns" />, url: '' },
        { title: <FormattedMessage id="footer.information.siteMap" />, url: '' },
    ];

    const accountLinks = [
        { title: <FormattedMessage id="footer.myAccount.storeLocation" />, url: '' },
        { title: <FormattedMessage id="footer.myAccount.orderHistory" />, url: '' },
        { title: <FormattedMessage id="footer.myAccount.wishList" />, url: '' },
        { title: <FormattedMessage id="footer.myAccount.newsletter" />, url: '' },
        { title: <FormattedMessage id="footer.myAccount.specials" />, url: '' },
        { title: <FormattedMessage id="footer.myAccount.giftCertificates" />, url: '' },
        { title: <FormattedMessage id="footer.myAccount.affiliate" />, url: '' },
    ];

    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <FooterContacts />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title={<FormattedMessage id="footer.information" />} items={informationLinks} />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title={<FormattedMessage id="footer.myAccount" />} items={accountLinks} />
                        </div>
                        <div className="col-12 col-md-12 col-lg-4">
                            <FooterNewsletter />
                        </div>
                    </div>
                </div>

                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        RESMAK  {versionInfo?.name}
                    </div>
                    <div className="site-footer__payments">
                        <img src="images/payments.png" alt="" />
                    </div>
                </div>
            </div>
            <ToTop />
        </div>
    );
}
