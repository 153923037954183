import React, { Component, useEffect } from 'react'
import { connect } from "react-redux";
import { getCart } from "../store/cart";
import { getAllSalesParameters } from "../store/parameter";
import { changeCurrencyDecimalPlaces } from '../store/currency';

function UpdateShopParameters(props) {

    useEffect(() => {
        const { getCart, changeCurrencyDecimalPlaces, getAllSalesParameters } = props;
        changeCurrencyDecimalPlaces()
        getAllSalesParameters()
        getCart()
    }, []);

    return (
        <>
            {props.children}
        </>
    )
}


const mapStateToProps = (state) => ({
    cart: state.cart,
    salesParameter: state.salesParameter
});

const mapDispatchToProps = {
    getCart,
    changeCurrencyDecimalPlaces,
    getAllSalesParameters
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateShopParameters);
