import {  VersionInfo } from "../models/models";
import { ApiResponse, request } from "./api";

export async function getList(): Promise<ApiResponse<VersionInfo[]>> {
    return await request<VersionInfo[]>("versionInfo/getList","GET");
}

export async function add(VersionHistory: VersionInfo): Promise<ApiResponse<VersionInfo>> {
    return await request<VersionInfo>("versionInfo/add", "POST", VersionHistory);
}

export async function update(VersionHistory: VersionInfo): Promise<ApiResponse<VersionInfo>> {
    return await request<VersionInfo>("versionInfo/update", "POST", VersionHistory);
}

export async function deleteVersionInfo(id: number): Promise<ApiResponse<null>> {
    return await request<null>(`versionInfo/delete/${id}`, "DELETE");
}

export async function getCurrentVersion(): Promise<ApiResponse<VersionInfo>> {
    return await request<VersionInfo>(`versionInfo/currentVersionInfo`, "GET");
}