// application
import trMessages from './messages/tr.json';
import enMessages from './messages/en.json';
import rtlMessages from './messages/rtl.json';

export default {
    tr: {
        messages: trMessages,
        direction: 'ltr',
    },
    en: {
        messages: enMessages,
        direction: 'ltr',
    },
    ar: {
        messages: rtlMessages,
        direction: 'rtl',
    },
};
