import { useIntl } from 'react-intl';
import React, { useState, useEffect } from 'react';
import { VersionInfo } from '../../../../models/models';
import * as dbVersionInfo from '../../../../api_ts/versionInfo';
import { Button, Tabs, message, Modal, Form, Input, Collapse } from 'antd';
import { PlusSquareOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useStateWithCallbackLazy as useStateCallback } from 'use-state-with-callback';
import { Translate } from '../../i18n/Translate';


const { TabPane } = Tabs;

export const Versions: React.FC = () => {

    const intl = useIntl();
    const [versionsInfo, setVersionsInfo] = useState<VersionInfo[]>([]);
    const [loading, setLoading] = useStateCallback(false);
    const [flags, setFlags] = useState({ versionHistoryModalVisible: false, operation: "" });
    const [versionHistoryForm] = Form.useForm();
    const formItemLayout = { labelCol: { span: 6 }, wrapperCol: { span: 18 } };


    useEffect(() => { getAllVersions() }, [])


    const getAllVersions = () => {
        return dbVersionInfo.getList()
            .then(response => {
                if (response.ok) {
                    debugger;
                    setVersionsInfo(response.data);
                }
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }


    const addOrUpdateVersionHistory = async (versionHistory: VersionInfo) => {
        if (versionHistory.id == 0)
            IsSuccessful(await dbVersionInfo.add(versionHistory));
        else
            IsSuccessful(await dbVersionInfo.update(versionHistory))
    }


    const deleteVersionHistory = async (VersionHistoryId: number) => {
        if ((await dbVersionInfo.deleteVersionInfo(VersionHistoryId)).ok) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }))
            getAllVersions();
        }
        else
            message.error(intl.formatMessage({ id: 'Operation.Error' }))
    }


    const IsSuccessful = (action: any) => {
        setLoading(true, () => {
            if (action.ok)
                getAllVersions()
                    .then(() => {
                        versionHistoryForm.resetFields()
                        setFlags({ ...flags, versionHistoryModalVisible: false, operation: "Add" })
                        setLoading(false, () => message.success(intl.formatMessage({ id: 'Operation.Success' })))
                    })
            else
                message.error(intl.formatMessage({ id: 'Operation.Error' }))

        })
    }


    const fillVersionHistoryForm = (VersionHistory: VersionInfo) => {
        versionHistoryForm.setFieldsValue(VersionHistory);
    }

    const header = (<Button type="primary" className="ml-auto mb-1" onClick={() => {
        versionHistoryForm.resetFields()
        setFlags({ ...flags, versionHistoryModalVisible: true, operation: "Add" })
    }}
        icon={<PlusSquareOutlined style={{ verticalAlign: 'middle', fontSize: '20px' }} />} >
        <span style={{ verticalAlign: 'middle' }} >{Translate("Add")}</span>
    </Button>)


    return (
        <>
            <div className="site-card-border-less-wrapper">
                <div className="d-flex">{header} </div>
                {versionsInfo.map((version, index) => (
                    <>
                        <Collapse  >
                            <Collapse.Panel extra={
                                <>
                                    <EditOutlined style={{ position: "absolute", fontSize: "24px", top: "11px", right: "45px", }}
                                        onClick={event => {
                                            event.stopPropagation();
                                            versionHistoryForm.resetFields()
                                            setFlags({ ...flags, versionHistoryModalVisible: true, operation: "Update" })
                                            fillVersionHistoryForm(version)
                                        }}
                                    />
                                    <DeleteOutlined style={{ position: "absolute", fontSize: "24px", top: "11px", right: "12px", }}
                                        onClick={event => {
                                            event.stopPropagation();
                                            deleteVersionHistory(version.id)
                                        }}
                                    />
                                </>
                            } header={version.name} key={1}>

                                <pre>{version.releaseNotes}</pre> </Collapse.Panel>
                        </Collapse>
                    </>
                ))}


            </div>

            <Modal
                width={600}
                title={Translate("Version")}
                visible={flags.versionHistoryModalVisible}
                onCancel={() => setFlags({ ...flags, versionHistoryModalVisible: false, operation: "Add" })}
                onOk={() => { versionHistoryForm.submit() }}
                okText={Translate(flags.operation)}
                cancelText={Translate("Cancel")}
                confirmLoading={loading}
            >
                <Form
                    form={versionHistoryForm}
                    layout={"horizontal"}
                    onFinish={addOrUpdateVersionHistory}
                    {...formItemLayout}
                    size={"small"}
                    labelAlign={"left"}
                >
                    <Form.Item
                        name={"id"}
                        fieldKey={"id"}
                        hidden={true}
                        initialValue={0} >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={Translate("Version")}
                        name="name"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate("Version")} />

                    </Form.Item>
                    <Form.Item
                        label={Translate("ReleaseNotes")}
                        name="releaseNotes"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input.TextArea rows={12} placeholder={Translate("ReleaseNotes")} />
                    </Form.Item>


                </Form>
            </Modal>
        </>
    );


}
