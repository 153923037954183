import { toast } from 'react-toastify';
import { GET_CLIENT_BY_ID } from './clientActionTypes';
import * as dbClient from '../../api_js/client';
// import {
//   addToCart_API
// } from '../../../../api/cart';



export function getByIdSuccess(client) {
    return { type: GET_CLIENT_BY_ID, client };
}

export function getById(clientId) {
    return (dispatch) => dbClient.getById(clientId)
        .then(response => {
            if (response.ok) {
                dispatch(getByIdSuccess(response.data));
            } else {
                console.log(response.message);
            }
        })
        .catch(err => console.log(err));
}
