import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import Currency from "../shared/Currency";

// import { validate } from "../../../../helper/helper_methods";

import AsyncAction from "../shared/AsyncAction";
import InputNumber from "../shared/InputNumber";
import ShopPageCartPriceSummary from "./ShopPageCartPriceSummary";
import ClientHeader from '../../components/shared/ClientHeader';
import { removeFromCart, updateCartItem, getCart, updateOfferPricesOfCart } from "../../store/cart";
import { Cross12Svg } from "../../svg";
import { url } from "../../services/utils";
import { Switch, Space, Input } from 'antd';
import { FormattedMessage, useIntl } from "react-intl";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Inputmask from "inputmask";
import theme from "../../data/theme";
import { OperationType } from "../../enum/enumB2B";
import $ from "jquery"


class ShopPageCart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quantityInputs: [],
            isActiveOfferSwitch: false,
            financialGoal: '',
        };
    }
    //timer identifier
    //time in ms (5 seconds)

    componentDidMount() {
        const { cart } = this.props;



        this.setState({ quantityInputs: cart.cartItems });

    }




    componentDidUpdate(prevProps) {
        const { cart } = this.props;
        if (cart !== prevProps.cart) {
            this.setState({ quantityInputs: cart.cartItems })
        }
        if (this.state.isActiveOfferSwitch === true) {
            Inputmask({
                alias: 'currency',
                groupSeparator: '.',
                radixPoint: ",",
                autoGroup: true,
                digits: 2,
                digitsOptional: false,
                placeholder: '0,00',
                showMaskOnFocus: true
            }).mask(document.getElementsByClassName("currencyMusk"));
        }
    }

    handleChangeQuantity = (itemId, quantity) => {
        this.props.updateCartItem(itemId, quantity)
    };


    renderItems() {

        const { cart, removeFromCart, updateOfferPricesOfCart, updateCartItem } = this.props;

        return cart.cartItems.map((item) => {
            let image;
            let options;
            if (item.product.images.length > 0) {
                image = (
                    <div className="product-image">
                        <Link
                            to={url.product(item.product)}
                            className="product-image__body"
                        >
                            <img
                                className="product-image__img"
                                src={item.product.images[0]}
                                alt=""
                            />
                        </Link>
                    </div>
                );
            }

            if (item.options.length > 0) {
                options = (
                    <ul className="cart-table__options">
                        {item.options.map((option, index) => (
                            <li
                                key={index}
                            >{`${option.optionTitle}: ${option.valueTitle}`}</li>
                        ))}
                    </ul>
                );
            }

            const removeButton = (
                <AsyncAction
                    action={() => removeFromCart(item.id, cart.cartItems.length)}
                    render={({ run, loading }) => {
                        const classes = classNames(
                            "btn btn-light btn-sm btn-svg-icon",
                            {
                                "btn-loading": loading,
                            }
                        );

                        return (
                            <button
                                type="button"
                                onClick={run}
                                className={classes}
                            >
                                <Cross12Svg />
                            </button>
                        );
                    }}
                />
            );


            return (
                <tr key={item.id} className="cart-table__row">
                    <td className="cart-table__column cart-table__column--image">
                        {image}
                    </td>
                    <td className="cart-table__column cart-table__column--product">
                        <Link
                            to={url.product(item.product)}
                            className="cart-table__product-name"
                        >
                            {item.product.name}
                        </Link>
                        {options}
                    </td>
                    {
                        this.state.isActiveOfferSwitch &&
                        <td className="cart-table__column cart-table__column--offer "
                            data-title="Teklif"
                        >
                            <input
                                className="form-control"
                                class={"currencyMusk"}
                                onBlur={(e) => {
                                    updateOfferPricesOfCart(e.target.value.split(".").join("").split(",").join("."), item.id)
                                }}
                                defaultValue={item.offerUnitPrice.toString().split(".").join(",")}
                            />
                        </td>
                    }
                    <td
                        className="cart-table__column cart-table__column--price"
                        data-title="Fiyat"
                    >
                        {/*<FormattedMessage id="shopCart.price" />*/}
                        <Currency value={item.product.price} />
                    </td>
                    <td
                        className="cart-table__column cart-table__column--quantity"
                        data-title="Adet"
                    >
                        {/*<FormattedMessage id="shopCart.quantity" />*/}
                        <AsyncAction
                            action={() => updateCartItem(item.id, this.state.quantityInputs.find(x => x.id == item.id)?.quantity)}
                            render={({ run, loading }) => {
                                const classes = classNames(
                                    {
                                        "loading": loading,
                                    }
                                );

                                return (
                                    <div className="input-number">
                                        <input
                                            className={`${classes} input-number form-control input-number__input onlyNumber`}
                                            type="text"
                                            id={item.id}
                                            min={0}
                                            pattern="[0-9]*"
                                            value={this.state.quantityInputs.find(x => x.id == item.id)?.quantity}
                                            onChange={(e) => {
                                                this.setState({
                                                    quantityInputs: this.state.quantityInputs.map(input => input.id === item.id ? { ...input, quantity: parseFloat(e.target.value) } : input)
                                                })
                                            }}
                                            onBlur={run}
                                        />
                                        {/* this.handleChangeQuantity(item.id, item.quantity + 1)} */}
                                        < div className="input-number__add" onMouseDown={(e) => {
                                            this.handleChangeQuantity(item.id, item.quantity + 1)
                                        }
                                        }
                                        />
                                        <div className="input-number__sub" onMouseDown={(e) => {
                                            this.handleChangeQuantity(item.id, item.quantity - 1)
                                        }}
                                        />
                                    </div>
                                );
                            }}
                        />


                    </td>

                    {
                        this.state.isActiveOfferSwitch &&
                        <td
                            className="cart-table__column cart-table__column--total"
                            data-title={"T.Toplam"}
                        >
                            {/*<FormattedMessage id="shopCart.total" />*/}
                            <Currency value={item.offerLineSumPrice} />
                        </td>
                    }
                    <td
                        className="cart-table__column cart-table__column--total"
                        data-title={"Toplam"}
                    >
                        {/*<FormattedMessage id="shopCart.total" />*/}
                        <Currency value={item.lineTotalPrice} />
                    </td>
                    <td className="cart-table__column cart-table__column--remove">
                        {removeButton}
                    </td>
                </tr >
            );
        });
    }

    renderCart() {
        const { updateCartItem } = this.props;
        const { cartItem } = this.state;

        const updateCartButton = (
            <AsyncAction
                action={() => updateCartItem(cartItem).then(() => this.setState({ cartItem: [] }))}
                render={({ run, loading }) => {
                    const classes = classNames(
                        "btn btn-primary cart__update-button",
                        {
                            "btn-loading": loading,
                        }
                    );

                    return (
                        <button
                            type="button"
                            onClick={run}
                            className={classes}
                            disabled={!this.cartNeedUpdate()}
                        >
                            <FormattedMessage id="shopCart.updateCart" />
                        </button>
                    );
                }}
            />
        );

        return (

            <div className="cart block">
                <div className={"spc-parent-container"}>
                    <div className="spc-content">
                        <ClientHeader />
                        <div className="space-5" />
                        <label><FormattedMessage id="offer" /> : </label>
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={this.isActiveOfferSwitch}
                            onChange={(value) => {
                                this.setState({ isActiveOfferSwitch: value })


                            }}
                        />
                        <div className="space-5" />
                        <table className="cart__table cart-table">
                            <thead className="cart-table__head">
                                <tr className="cart-table__row">
                                    <th className="cart-table__column cart-table__column--image">
                                        <FormattedMessage id="shopCart.image" />
                                    </th>
                                    <th className="cart-table__column cart-table__column--product">
                                        <FormattedMessage id="shopCart.product" />
                                    </th>
                                    {
                                        this.state.isActiveOfferSwitch && <th className="cart-table__column cart-table__column--offer">
                                            <FormattedMessage id="offer" />
                                        </th>
                                    }
                                    <th className="cart-table__column cart-table__column--price">
                                        <FormattedMessage id="shopCart.price" />
                                    </th>
                                    <th className="cart-table__column cart-table__column--quantity">
                                        <FormattedMessage id="shopCart.quantity" />
                                    </th>
                                    {
                                        this.state.isActiveOfferSwitch && <th className="cart-table__column cart-table__column--total">
                                            <FormattedMessage id="t.total" />
                                        </th>
                                    }
                                    <th className="cart-table__column cart-table__column--total">
                                        <FormattedMessage id="total" />
                                    </th>
                                    <th
                                        className="cart-table__column cart-table__column--remove"
                                        aria-label="Remove"
                                    />
                                </tr>
                            </thead>
                            <tbody className="cart-table__body">
                                {this.renderItems()}
                            </tbody>
                        </table>
                        <div className="cart__actions">
                            <form className="cart__coupon-form">
                                <label
                                    htmlFor="input-coupon-code"
                                    className="sr-only"
                                >
                                    <FormattedMessage id="shopCart.password" />
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="input-coupon-code" /* placeholder={intl.formatMessage({ id: "shopCart.couponCode" })}*/
                                />
                                <button type="submit" className="btn btn-primary">
                                    <FormattedMessage id="shopCart.applyCoupon" />
                                </button>
                            </form>
                            <div className="cart__buttons">
                                <Link to="/" className="btn btn-light">
                                    <FormattedMessage id="shopCart.continueShopping" />
                                </Link>
                                {/* {updateCartButton} */}
                            </div>
                        </div>


                    </div>
                    <ShopPageCartPriceSummary
                        btnInfo={{
                            validation: true,
                            to: "/order/delivery",
                            btnName: "proceedToCheckout",
                            operationType: OperationType.Link
                        }}
                    />

                </div>
            </div >
        );
    }

    render() {
        const { cart } = this.props;
        const breadcrumb = [
            { title: <FormattedMessage id="home" />, url: "" },
            { title: <FormattedMessage id="shopCart.shoppingCart" />, url: "" },
        ];

        let content;

        if (cart.quantity) {
            content = this.renderCart();
        } else {
            content = (
                <div className="block block-empty">
                    <div className="container">
                        <div className="block-empty__body">
                            <div className="block-empty__message">
                                <FormattedMessage id="shopCart.yourShoppingCartIsEmpty" />
                            </div>
                            <div className="block-empty__actions">
                                <Link to="/" className="btn btn-primary btn-sm">
                                    <FormattedMessage id="shopCart.continue" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`${theme.name}`}</title>
                </Helmet>


                {/* 
                <PageHeader
                    header={<FormattedMessage id="shop.title" />}
                    breadcrumb={breadcrumb}
                /> */}

                {content}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    removeFromCart,
    updateCartItem,
    updateOfferPricesOfCart,
    getCart
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCart);
