import React, { useEffect } from "react";
import * as dbContract from "../../api_js/contract";
import DOMPurify from 'dompurify';


export default function ShopPagePreliminaryInfoForm(props) {

    const [preliminaryInfoForm, setPreliminaryInfoForm] = React.useState();
    const { cartId } = props;

    useEffect(() => {
        getPreliminaryInfoForm()
    }, [])

    const getPreliminaryInfoForm = () => {
        dbContract.getPreliminaryInfoFormByCartId(cartId)
            .then(response => {
                if (response.ok) {
                    setPreliminaryInfoForm(response.data.preliminaryInfoForm_Value)
                } else {
                    console.log(response.message);
                }
            })
            .catch(err => console.log(err));
    }


    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(preliminaryInfoForm) }}></div>
        </>
    );
}



