import { GET_CLIENT_BY_ID } from './clientActionTypes';

/**
 * @param {array} items
 * @param {object} product
 * @param {array} options
 * @return {number}
 */

const initialState = {
    id: 0,
    idErp: 0,
    code: "",
    Description: "",
    Description2: "",
    E_InvoicePayer: null,
    IsActive: true,
    EXTACCESSFLAGS: null,

};

//TODO https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store?page=1&tab=votes#tab-top
export default function clientReducer(state = initialState, action) {
    switch (action.type) {
        case GET_CLIENT_BY_ID:
            return action.client;
        default:
            return state;
    }
}
