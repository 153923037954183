import { useIntl } from 'react-intl';
import * as React from 'react';
import { Warehouse } from '../../../../models/models';
import * as dbWarehouse from '../../../../api_ts/warehouse';
import { PlusSquareOutlined, DeleteOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import { Tabs, Modal, Table, Input, Button, Tooltip, message, Form, Checkbox } from 'antd';
import { useStateWithCallbackLazy as useStateCallback } from 'use-state-with-callback';
import { useEffect, useState } from 'react';
import { Translate } from '../../i18n/Translate';


export const Warehouses: React.FC = () => {

    const intl = useIntl();
    const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
    const [loading, setLoading] = useStateCallback(false);
    const [flags, setFlags] = useState({ warehouseModalVisible: false, operation: "" });
    const [warehouseForm] = Form.useForm();

    useEffect(() => {
        getAllWarehouses()
    }, [])

    const getAllWarehouses = () => {
        return dbWarehouse.getList()
            .then(response => {
                if (response.ok) setWarehouses(response.data);
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }

    const deleteWarehouse = async (value: number) => {
        const response = await dbWarehouse.deleteWarehouse(value);
        if (response.ok) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            getAllWarehouses();
        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }

    const addOrUpdateWarehouse = async (warehouse: Warehouse) => {
        if (warehouse.id == 0)
            IsSuccessful(await dbWarehouse.add(warehouse));
        else
            IsSuccessful(await dbWarehouse.update(warehouse))
    }

    const fillWarehouseForm = (warehouse: Warehouse) => {
        warehouseForm.setFieldsValue(warehouse);
    }
    const IsSuccessful = (action: any) => {
        setLoading(true, () => {
            if (action.ok) {
                getAllWarehouses()
                    .then(() => {
                        warehouseForm.resetFields()
                        setFlags({ ...flags, warehouseModalVisible: false, operation: "Add" })
                        setLoading(false, () => message.success(intl.formatMessage({ id: 'Operation.Success' })))
                    })
            } else {
                message.error(intl.formatMessage({ id: 'Operation.Error' }))
            }
        })
    }


    const columns = [
        {
            key: "code",
            title: Translate("Code"),
            dataIndex: 'code',
        },
        {
            key: 'description',
            title: Translate("Description"),
            dataIndex: 'description',
        },
        {
            title: Translate("Status"),
            dataIndex: 'isActive',
            render: (text: string, record: Warehouse) => record.isActive ? "Aktif" : "Pasif",
        },
        {
            key: 'id',
            width: 100,
            render: (text: string, warehouse: Warehouse) =>
                <>
                    <Tooltip placement="top" color="blue" title={Translate("Edit")}>
                        <Button icon={<EditOutlined />} onClick={() => {
                            warehouseForm.resetFields()
                            setFlags({ ...flags, warehouseModalVisible: true, operation: "Update" })
                            fillWarehouseForm(warehouse)
                        }
                        } />
                    </Tooltip>
                    <Tooltip placement="top" color="red" title={Translate("Delete")}>
                        <Button icon={<DeleteOutlined />} onClick={() => deleteWarehouse(warehouse.id)} />
                    </Tooltip>
                </>
        },
    ];





    const header = (<Button type="primary" className="ml-auto mb-1" onClick={() => {
        warehouseForm.resetFields()
        setFlags({ ...flags, warehouseModalVisible: true, operation: "Add" })
    }}
        icon={<PlusSquareOutlined style={{ verticalAlign: 'middle', fontSize: '20px' }} />} >
        <span style={{ verticalAlign: 'middle' }} >{Translate("Add")}</span>
    </Button>)

    const formItemLayout = { labelCol: { span: 7 }, wrapperCol: { span: 17 } };

    return (
        <>
            <Tabs
                defaultActiveKey="1"
                tabBarStyle={{ marginBottom: "3.5px" }}
            >
                <Tabs.TabPane tabKey="1"
                    tab={<span><UserOutlined />{Translate("WarehouseList")}</span>} key="1">
                    <div className="site-card-border-less-wrapper">
                        <div className="d-flex">{header} </div>
                        <Table bordered size="small" columns={columns} dataSource={warehouses} />
                    </div>
                </Tabs.TabPane>


            </Tabs>
            <Modal
                title={Translate("Warehouse")}
                visible={flags.warehouseModalVisible}
                onCancel={() => setFlags({ ...flags, warehouseModalVisible: false, operation: "Add" })}
                onOk={() => { warehouseForm.submit() }}
                okText={Translate(flags.operation)}
                cancelText={Translate("Cancel_noun")}
                confirmLoading={loading}
            >
                <Form
                    form={warehouseForm}
                    layout={"horizontal"}
                    onFinish={addOrUpdateWarehouse}
                    {...formItemLayout}
                    size={"small"}
                    labelAlign={"left"}
                >
                    <Form.Item
                        name={"id"}
                        fieldKey={"id"}
                        hidden={true}
                        initialValue={0} >
                        <Input />
                    </Form.Item>
                    <Form.Item name={"idERP"} fieldKey={"idERP"} hidden={true}  > <Input /> </Form.Item>

                    <Form.Item
                        label={Translate("Description")}
                        name="description"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate("Description")} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("Code")}
                        name="code"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate("Code")} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("IsActive")}
                        name="isActive"
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Checkbox></Checkbox>
                    </Form.Item>

                </Form>
            </Modal>
        </>
    );
};

