import { SpecialCode } from "../models/models";
import { ApiResponse, request } from "./api";

export async function getList(): Promise<ApiResponse<SpecialCode[]>> {
    return await request<SpecialCode[]>(`specialCodes/getList`, "GET");
}
export async function add(model: SpecialCode): Promise<ApiResponse<SpecialCode>> {
    return await request<SpecialCode>("specialCodes/add", "POST", model);
}
export async function deleteSpecialCode(id: number): Promise<ApiResponse<null>> {
    return await request<null>(`specialCodes/delete/${id}`, "DELETE");
}
export async function update(model: SpecialCode): Promise<ApiResponse<SpecialCode>> {
    return await request<SpecialCode>("specialCodes/update", "PATCH", model);
}
export async function getListBySpecialCodeAreaId(SpecialCodeAreaId: number): Promise<ApiResponse<SpecialCode[]>> {
    return await request<SpecialCode[]>(`specialCodes/getListBySpecialCodeAreaId/${SpecialCodeAreaId}`, "GET");
}