import { useIntl } from 'react-intl';
import React, { useState, useEffect } from 'react';
import { MailOperationGroup, MailProvider, MailSendingConditions as Model_MailSendingConditions, MailTemplate } from '../../../../models/models';
import * as DbMailSendingConditions from '../../../../api_ts/mailSendingCondition';
import * as DbMailTemplate from '../../../../api_ts/mailTemplate';

import { Button, Tabs, Table, message, Modal, Form, InputNumber, Input, Tooltip, Select } from 'antd';
import { PlusSquareOutlined, UserOutlined, EditOutlined, DeleteOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { useStateWithCallbackLazy as useStateCallback } from 'use-state-with-callback';
import { MailProviders } from './MailProviders';
import { Translate } from '../../i18n/Translate';

interface Props {
    mailProviders: MailProvider[];
}
const { TabPane } = Tabs;

export const MailSendingConditions: React.FC<Props> = ({ mailProviders }) => {

    const intl = useIntl();
    const [mailSendingConditions, setMailSendingConditions] = useState<Model_MailSendingConditions[]>([]);
    const [mailTemplates, setMailTemplates] = useState<MailTemplate[]>([]);

    const [loading, setLoading] = useStateCallback(false);
    const [flags, setFlags] = useState({ mailSendingConditionsModalVisible: false, operationTitle: "" });
    const [mailSendingConditionsForm] = Form.useForm();

    useEffect(() => {
        getAllMailSendingConditions()
    }, [])


    const getAllMailSendingConditions = () => {
        return DbMailSendingConditions.getList()
            .then(response => {
                if (response.ok) {
                    setMailSendingConditions(response.data);
                }
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }

    const getAllMailTemplate = () => {
        return DbMailTemplate.getList()
            .then(response => {
                if (response.ok) {
                    setMailTemplates(response.data);
                }
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }

    const addOrUpdateMailSendingConditions = async (record: Model_MailSendingConditions) => {
        switch (flags.operationTitle) {
            case "Add": IsSuccessful(await DbMailSendingConditions.add(record)); break;
            case "Update": IsSuccessful(await DbMailSendingConditions.update(record)); break;
        }
    }


    const IsSuccessful = (action: any) => {
        setLoading(true, () => {
            if (action.ok) {
                getAllMailSendingConditions()
                    .then(() => {
                        mailSendingConditionsForm.resetFields()
                        setFlags({ ...flags, mailSendingConditionsModalVisible: false, operationTitle: "Add" })
                        setLoading(false, () => message.success(intl.formatMessage({ id: 'Operation.Success' })))
                    })
            } else {
                message.error(intl.formatMessage({ id: 'Operation.Error' }))
            }
        })
    }

    const fillMailSendingConditionsForm = (mailSendingConditions: Model_MailSendingConditions) => {
        mailSendingConditionsForm.setFieldsValue(mailSendingConditions);
    }

    const deleteMailSendingConditions = async (record: Model_MailSendingConditions) => {
        if ((await DbMailSendingConditions.deleteMailSendingConditions(record)).ok) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }))
            getAllMailSendingConditions();
        }
        else
            message.error(intl.formatMessage({ id: 'Operation.Error' }))
    }
    const columns = [
        {
            key: 'host',
            dataIndex: ["mailProvider", "host"],
            title: Translate("MailProvider"),
        },
        {
            key: 'mailOperationGroup',
            dataIndex: ["mailOperationGroup"],
            title: Translate("MailOperationGroup"),
            render: (value: number) => {
                return (Translate(MailOperationGroup[value]));
            }
        },
        {
            key: 'mailTemplate',
            dataIndex: ["mailTemplate", "description"],
            title: Translate("MailTemplate"),
        },
        {
            key: 'id',
            width: 100,
            render: (text: string, mailSendingCondition: Model_MailSendingConditions) =>
                <>
                    <Tooltip placement="top" color="blue" title={Translate("Edit")}>
                        <Button icon={<EditOutlined />} onClick={() => {
                            mailSendingConditionsForm.resetFields()
                            setFlags({ ...flags, mailSendingConditionsModalVisible: true, operationTitle: "Update" })
                            fillMailSendingConditionsForm(mailSendingCondition)
                        }
                        } />
                    </Tooltip>
                    <Tooltip placement="top" color="red" title={Translate("Delete")}>
                        <Button icon={<DeleteOutlined />} onClick={() => deleteMailSendingConditions(mailSendingCondition)} />
                    </Tooltip>
                </>
        },

    ];
    const header = (<Button type="primary" className="ml-auto mb-1" onClick={() => {
        getAllMailTemplate();
        mailSendingConditionsForm.resetFields()
        setFlags({ ...flags, mailSendingConditionsModalVisible: true, operationTitle: "Add" })
    }}
        icon={<PlusSquareOutlined style={{ verticalAlign: 'middle', fontSize: '20px' }} />} >
        <span style={{ verticalAlign: 'middle' }} >{Translate("Add")}</span>
    </Button>)


    const formItemLayout = { labelCol: { span: 7 }, wrapperCol: { span: 17 } };

    return (
        <>
            <div className="site-card-border-less-wrapper">
                <div className="d-flex">{header} </div>
                <Table bordered size="small" columns={columns} dataSource={mailSendingConditions} />
            </div>

            <Modal
                title={Translate("MailSendingConditions")}
                visible={flags.mailSendingConditionsModalVisible}
                onCancel={() => setFlags({ ...flags, mailSendingConditionsModalVisible: false, operationTitle: "Add" })}
                onOk={() => { mailSendingConditionsForm.submit() }}
                okText={Translate(flags.operationTitle)}
                cancelText={Translate("Cancel")}
                confirmLoading={loading}
            >
                <Form
                    form={mailSendingConditionsForm}
                    layout={"horizontal"}
                    onFinish={addOrUpdateMailSendingConditions}
                    {...formItemLayout}
                    size={"small"}
                    labelAlign={"left"}
                >
                    <Form.Item
                        label={Translate("MailProvider")}
                        name="mailProviderId"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Select style={{ width: '100%' }} placeholder={Translate('PleaseSelect')} >
                            {mailProviders.map(x => <Select.Option value={x.id} key={x.id}>{x.host}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={Translate("MailOperationGroup")}
                        name="mailOperationGroup"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Select style={{ width: '100%' }} placeholder={Translate('PleaseSelect')} >
                            {Object.keys(MailOperationGroup).filter(key => !/^\d+$/.test(key)).map(key =>
                                <Select.Option value={MailOperationGroup[key as any]}>{Translate(key)}</Select.Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={Translate("MailTemplate")}
                        name="mailTemplateId"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Select style={{ width: '100%' }} placeholder={Translate('PleaseSelect')} >
                            {mailTemplates.map(x => <Select.Option value={x.id} key={x.id}>{x.description}</Select.Option>)}
                        </Select>
                    </Form.Item>
                </Form >
            </Modal >
        </>
    )
}

