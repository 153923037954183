import { useIntl } from 'react-intl';
import { ProfileOutlined, PlusSquareOutlined, CheckSquareFilled, MinusSquareFilled } from '@ant-design/icons';
import * as React from 'react';
import { CurrencyType, PeriodParameter, Warehouse, SalesParameter, AmountType, CollectionBonusType, FirmParameter, E_InvoicePayer } from '../../../../models/models';
import * as dbParameters from '../../../../api_ts/parameters';
import * as dbWarehouse from '../../../../api_ts/warehouse';
import { Descriptions, Tabs, Modal, Card, Input, Button, message, Form, InputNumber, Select, Radio, Checkbox } from 'antd';
import { Translate } from '../../i18n/Translate';

export const Parameters: React.FC = () => {
    const intl = useIntl();
    const [parametersForm] = Form.useForm();
    const [warehouses, setWarehouses] = React.useState<Warehouse[]>([]);
    const [b2bWarehouses, setB2bWarehouses] = React.useState<Warehouse[]>([]);
    const [periodParameters, setPeriodParameters] = React.useState<PeriodParameter>();
    const [salesParameters, setSalesParameters] = React.useState<SalesParameter>();
    const [firmParameters, setFirmParameters] = React.useState<FirmParameter>();



    const [flags, setFlags] = React.useState({
        currentTab: "PeriodParameters",
        periodParametersModalVisible: false,
    });

    React.useEffect(() => {
        getAllPeriodParameters();
        getWarehousesByIsActive();
        getSalesParameters();
        getFirmParameters();
    }, []);

    React.useEffect(() => {
        setB2bWarehouses(warehouses.filter(x => x.b2bStatus))
    }, [warehouses]);

    const getWarehousesByIsActive = () => {
        dbWarehouse.getListByIsActive(true)
            .then(response => {
                if (response.ok)
                    setWarehouses(response.data);
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }

    const getSalesParameters = () => {
        dbParameters.getSalesParameters()
            .then(response => {
                if (response.ok) {
                    setSalesParameters(response.data);
                }
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }
    const getFirmParameters = () => {
        dbParameters.getFirmParameters()
            .then(response => {
                if (response.ok) {
                    setFirmParameters(response.data);
                }
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }

    const getAllPeriodParameters = () => {
        dbParameters.getPeriodParameters()
            .then(response => {
                if (response.ok) {
                    setPeriodParameters(response.data);
                }
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }

    const updateWarehousesB2bStatus = async (warehouseId) => {
        let result = warehouses.find(x => x.id == warehouseId)
        let success = (await dbWarehouse.updateB2bStatus(result as Warehouse)).ok;
        if (success)
            getWarehousesByIsActive();
        else
            console.log(intl.formatMessage({ id: 'Operation.Error' }));
    }

    const addOrUpdatePeriodParameters = async (form: any) => {
        form.orderWarehouseName = b2bWarehouses.find(x => x.id == form.orderWarehouseId)?.description;
        let success: boolean = (await dbParameters.addOrUpdatePeriodParameters(form)).ok;
        success = (await dbParameters.updateSalesParameters(form)).ok;
        success = (await dbParameters.updateFirmParameters(form)).ok;

        if (success) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            getAllPeriodParameters();
            getWarehousesByIsActive();
            getSalesParameters();
            getFirmParameters();
            setFlags({ ...flags, periodParametersModalVisible: false })
        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }

    const fillPeriodParametersForm = () => {
        parametersForm.setFieldsValue({
            b2bWarehouses: b2bWarehouses.map(x => x.id),
            ...periodParameters,
            ...salesParameters,
            ...firmParameters
        })
    }


    const formItemLayout = { labelCol: { span: 7 }, wrapperCol: { span: 17 } };
    const formItemLayoutSalesParameter = { labelCol: { span: 14 }, wrapperCol: { span: 10 } };
    return (
        <Tabs>
            <Tabs.TabPane key={flags.currentTab} tab={<span><ProfileOutlined />{Translate("Parameters")}</span>}>

                <Card
                    extra={
                        <Button size={'large'} type="primary" onClick={() => {
                            fillPeriodParametersForm();
                            setFlags({ ...flags, periodParametersModalVisible: true })
                        }
                        }
                            icon={<PlusSquareOutlined style={{ verticalAlign: 'middle', fontSize: '20px' }} />} >
                            <span style={{ verticalAlign: 'middle' }} >{Translate("Update")}</span>
                        </Button>}
                    title={Translate("Parameters")}
                    bordered={false} style={{ width: '100%' }}>
                    <Descriptions
                        size="small"
                        bordered
                        labelStyle={{ fontWeight: "bold" }}
                    >
                        <Descriptions.Item label="İletişim Telefon No" span={1}>{periodParameters?.phoneNumber}</Descriptions.Item>
                        <Descriptions.Item label="İletişim Eposta" span={1}>{periodParameters?.email}</Descriptions.Item>
                        <Descriptions.Item label="İletisim Adres">{periodParameters?.address}</Descriptions.Item>
                        <Descriptions.Item label="Iletisim Aciklama">{periodParameters?.description}</Descriptions.Item>
                        <Descriptions.Item label="Kurus Hane Sayisi">{periodParameters?.priceDecimalDigit}</Descriptions.Item>
                        <Descriptions.Item label="Varsayilan Dil">{periodParameters?.defaultLanguage}</Descriptions.Item>
                        <Descriptions.Item label="Varsayilan Doviz">{periodParameters?.defaultCurrency}</Descriptions.Item>
                        <Descriptions.Item label={Translate("OrderWarehouse")}>{salesParameters?.orderWarehouseName}</Descriptions.Item>
                        <Descriptions.Item label="Aktif Depolar">{b2bWarehouses.map((x, index) => { return < > {x.description} {index % 2 == 0 ? < br /> : "--"}</> })}
                        </Descriptions.Item>
                        <Descriptions.Item label={Translate("OrderOutOfStockItem")}>{salesParameters?.orderOutOfStockItem ?
                            <><CheckSquareFilled type="message" style={{ fontSize: '24px', color: 'green' }} />&nbsp;Aktif</> :
                            <><MinusSquareFilled style={{ fontSize: '24px', color: 'red' }} />&nbsp;Pasif</>}</Descriptions.Item>
                        <Descriptions.Item label={Translate("AutoOrderIntegration")}>{salesParameters?.autoOrderIntegration ? <> <CheckSquareFilled type="message" style={{ fontSize: '24px', color: 'green' }} />&nbsp;Aktif</> : <><MinusSquareFilled style={{ fontSize: '24px', color: 'red' }} />&nbsp;Pasif</>}</Descriptions.Item>
                        <Descriptions.Item label={Translate("ShowOutOfStockItem")}>{salesParameters?.showOutOfStockItem ? <> <CheckSquareFilled type="message" style={{ fontSize: '24px', color: 'green' }} />&nbsp;Aktif</> : <><MinusSquareFilled style={{ fontSize: '24px', color: 'red' }} />&nbsp;Pasif</>}</Descriptions.Item>
                        <Descriptions.Item label={Translate("ShowCurrency")}>{salesParameters?.showCurrency ? <> <CheckSquareFilled type="message" style={{ fontSize: '24px', color: 'green' }} />&nbsp;Aktif</> : <><MinusSquareFilled style={{ fontSize: '24px', color: 'red' }} />&nbsp;Pasif</>}</Descriptions.Item>
                        <Descriptions.Item label={Translate("ShowItemsWithoutPrice")}>{salesParameters?.showItemsWithoutPrice ? <> <CheckSquareFilled type="message" style={{ fontSize: '24px', color: 'green' }} />&nbsp;Aktif</> : <><MinusSquareFilled style={{ fontSize: '24px', color: 'red' }} />&nbsp;Pasif</>}</Descriptions.Item>
                        <Descriptions.Item label={Translate("EInvoice")}>{firmParameters?.e_InvoicePayer == E_InvoicePayer.Yes ? <> <CheckSquareFilled type="message" style={{ fontSize: '24px', color: 'green' }} />&nbsp;Aktif</> : <><MinusSquareFilled style={{ fontSize: '24px', color: 'red' }} />&nbsp;Pasif</>}</Descriptions.Item>

                        <Descriptions.Item label={Translate("ItemAmountType")}>{
                            AmountType[salesParameters?.amountType as AmountType]
                        }</Descriptions.Item>

                        <Descriptions.Item label={Translate("CollectionBonusType")}>{
                            Translate(CollectionBonusType[salesParameters?.collectionBonusType as CollectionBonusType])
                        }</Descriptions.Item>
                    </Descriptions>

                </Card>

                <Modal
                    title="Dönem Parametreler"
                    visible={flags.periodParametersModalVisible}
                    onCancel={() => setFlags({ ...flags, periodParametersModalVisible: false })}
                    onOk={() => { parametersForm.submit() }}
                // okText={Translate(flag.operation)}
                // confirmLoading={flag.loading}
                >

                    <Form form={parametersForm}
                        layout={"horizontal"}
                        onFinish={addOrUpdatePeriodParameters}
                        labelAlign={"left"}
                        {...formItemLayout}
                        size={"small"}
                    >
                        <Form.Item
                            name={"description"}
                            label={Translate("Description")}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={"address"}
                            label={Translate("Adress")}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={"email"}
                            label={Translate("Email")}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={"phoneNumber"}
                            label={Translate("Phone")}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={"defaultCurrency"}
                            rules={[{ required: true, message: Translate("RequiredField") }]}
                            label={Translate("CurrencyType")}
                        >
                            <Select placeholder={Translate("PleaseSelect")}>
                                {Object.keys(CurrencyType).filter(key => !/^\d+$/.test(key)).map(key =>
                                    <Select.Option value={CurrencyType[key as any]}>{key}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={"defaultLanguage"}
                            rules={[{ required: true, message: Translate("RequiredField") }]}
                            label={Translate("DefaultLanguage")}
                        >
                            <Select >
                                <Select.Option value="TURKISH">Türkçe</Select.Option>
                                <Select.Option value="ENGLISH">English</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="amountType"
                            rules={[{ required: true, message: Translate("RequiredField") }]}
                            label={Translate("ItemAmountType")}
                        >
                            <Select placeholder={Translate("PleaseSelect")}>
                                {Object.keys(AmountType).filter(key => !/^\d+$/.test(key)).map(key =>
                                    <Select.Option value={AmountType[key as any]}>{key}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="orderWarehouseId"
                            rules={[{ required: true, message: Translate("RequiredField") }]}
                            label={Translate("OrderWarehouse")}
                        >
                            <Select style={{ width: '100%' }} placeholder={Translate("PleaseSelect")}>
                                {b2bWarehouses.map(warehouse => (
                                    <Select.Option key={warehouse.id} value={warehouse.id}>{`${warehouse.description}`}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="b2bWarehouses"
                            rules={[{ required: true, message: Translate("RequiredField") }]}
                            label={Translate("B2BWarehouses")}
                        >
                            <Select
                                onSelect={updateWarehousesB2bStatus}
                                onDeselect={updateWarehousesB2bStatus}
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder={Translate("PleaseSelect")}
                            >
                                {warehouses.map(warehouse => (
                                    <Select.Option key={warehouse.id} value={warehouse.id}>{`${warehouse.description}`}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={"priceDecimalDigit"}
                            rules={[{ required: true, message: Translate("RequiredField") }]}
                            label={Translate("PriceDecimalDigit")}
                        >
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>

                        <Form.Item
                            name={"collectionBonusType"}
                            rules={[{ required: true, message: Translate("RequiredField") }]}
                            label={Translate("CollectionBonusType")}
                        >
                            <Select placeholder={Translate("PleaseSelect")}>
                                {Object.keys(CollectionBonusType).filter(key => !/^\d+$/.test(key)).map(key =>
                                    <Select.Option value={CollectionBonusType[key as any]}>{Translate(key)}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={Translate("EInvoice")}
                            name="e_InvoicePayer"
                            rules={[{ required: true, message: Translate("RequiredField") }]}
                            {...formItemLayoutSalesParameter}
                        >
                            <Radio.Group>
                                <Radio value={1}>Evet</Radio>
                                <Radio value={0}>Hayır</Radio>
                            </Radio.Group>
                        </Form.Item>

                        < Form.Item
                            name={"showOutOfStockItem"}
                            {...formItemLayoutSalesParameter}
                            rules={[{ required: true, message: Translate("RequiredField") }]}
                            label={Translate("ShowOutOfStockItem")}
                        >
                            <Radio.Group>
                                <Radio value={true}>Aktif</Radio>
                                <Radio value={false}>Pasif</Radio>
                            </Radio.Group>
                        </Form.Item>
                        < Form.Item
                            name={"autoOrderIntegration"}
                            {...formItemLayoutSalesParameter}
                            rules={[{ required: true, message: Translate("RequiredField") }]}
                            label={Translate("AutoOrderIntegration")}
                        >
                            <Radio.Group>
                                <Radio value={true}>Aktif</Radio>
                                <Radio value={false}>Pasif</Radio>
                            </Radio.Group>
                        </Form.Item>
                        < Form.Item
                            name={"showCurrency"}
                            {...formItemLayoutSalesParameter}
                            rules={[{ required: true, message: Translate("RequiredField") }]}
                            label={Translate("ShowCurrency")}
                        >
                            <Radio.Group>
                                <Radio value={true}>Aktif</Radio>
                                <Radio value={false}>Pasif</Radio>
                            </Radio.Group>
                        </Form.Item>
                        < Form.Item
                            name={"showItemsWithoutPrice"}
                            {...formItemLayoutSalesParameter}
                            rules={[{ required: true, message: Translate("RequiredField") }]}
                            label={Translate("ShowItemsWithoutPrice")}
                        >
                            <Radio.Group>
                                <Radio value={true}>Aktif</Radio>
                                <Radio value={false}>Pasif</Radio>
                            </Radio.Group>
                        </Form.Item>
                        < Form.Item
                            name={"orderOutOfStockItem"}
                            {...formItemLayoutSalesParameter}
                            rules={[{ required: true, message: Translate("RequiredField") }]}
                            label={Translate("OrderOutOfStockItem")}
                        >
                            <Radio.Group>
                                <Radio value={true}>Aktif</Radio>
                                <Radio value={false}>Pasif</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                </Modal>
            </Tabs.TabPane>
        </Tabs >

    );
};
