import { toast } from 'react-toastify';
import { ADD_TO_CART, UPDATE_CART, RESET_CART } from './cartActionTypes';
import * as dbCart from '../../api_js/cart';




export function updateCartSuccess(cart) {
    if (cart != null)
        return { type: UPDATE_CART, cart };
    else
        return { type: RESET_CART, cart };
}



export function addToCart(product, options = [], quantity = 1, successMessage) {

    return (dispatch) => dbCart.addToCart(product.id, quantity)
        .then(response => {
            if (response.ok) {
                dispatch(updateCartSuccess(response.data));
                toast.success(successMessage);
            } else {
                console.log(response.message);
            }
        })
        .catch(err => console.log(err));
}

export function removeFromCart(itemId, cartItemCount) {
    return (dispatch) => dbCart.removeFromCart(itemId, cartItemCount)
        .then(response => {
            if (response.ok) {
                dispatch(updateCartSuccess(response.data));
            } else {
                console.log(response.message);
            }
        })
        .catch(err => console.log(err));
}

export function updateCartItem(id, quantity) {
    return (dispatch) => dbCart.updateCartItem(id, quantity)
        .then(response => {
            if (response.ok) {
                dispatch(updateCartSuccess(response.data));
            } else {
                console.log(response.message);
            }
        })
        .catch(err => console.log(err));
}

export function getCart() {
    return (dispatch) => dbCart.getCart()
        .then(response => {
            if (response.ok) {
                dispatch(updateCartSuccess(response.data));
            } else {
                console.log(response.message);
            }
        })
        .catch(err => console.log(err));
}

export function updateOfferPricesOfCart(offerUnitPrice, cartItemId) {
    return (dispatch) => dbCart.updateOfferPricesOfCart(offerUnitPrice, cartItemId)
        .then(response => {
            if (response.ok) {
                return dispatch(updateCartSuccess(response.data));
            } else {
                console.log(response.message);
            }
        })
        .catch(err => console.log(err));
}



export function updatePaymentTypeOfCart(cartId, collectionTypeId) {
    return (dispatch) => dbCart.updatePaymentTypeOfCart(cartId, collectionTypeId)
        .then(response => {
            if (response.ok) {
                return dispatch(updateCartSuccess(response.data));
            } else {
                console.log(response.message);
            }
        })
        .catch(err => console.log(err));
}

export function updateDeliveryTypeOfCart(cartId, deliveryType) {
    return (dispatch) => dbCart.updateDeliveryTypeOfCart(cartId, deliveryType)
        .then(response => {
            if (response.ok) {
                return dispatch(updateCartSuccess(response.data));
            } else {
                console.log(response.message);
            }
        })
        .catch(err => console.log(err));
}

export function updateShippingAddressOfCart(cartId, deliveryAddressId) {
    return (dispatch) => dbCart.updateShippingAddressOfCart(cartId, deliveryAddressId)
        .then(response => {
            if (response.ok) {
                return dispatch(updateCartSuccess(response.data));
            } else {
                console.log(response.message);
            }
        })
        .catch(err => console.log(err));
}


export function updateBillingAddressOfCart(cartId, invoiceAdressId) {
    return (dispatch) => dbCart.updateBillingOfCart(cartId, invoiceAdressId)
        .then(response => {
            if (response.ok) {
                return dispatch(updateCartSuccess(response.data));
            } else {
                console.log(response.message);
            }
        })
        .catch(err => console.log(err));
}
