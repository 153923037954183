import React, { useEffect } from "react";
import * as dbContract from "../../api_js/contract";
import DOMPurify from 'dompurify';

export default function ShopPageSalesContract(props) {

    const [salesContract, setSalesContract] = React.useState();
    const { cartId } = props;

    useEffect(() => {
        getSalesContract()
    }, [])

    const getSalesContract = () => {
        dbContract.getSalesContractByCartId(cartId)
            .then(response => {
                if (response.ok) {
                    setSalesContract(response.data.salesContract_Value)
                } else {
                    console.log(response.message);
                }
            })
            .catch(err => console.log(err));
    }



    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(salesContract) }}></div>
        </>
    );
}



