import {
    OrderFilter,
    PagedResult,
    Order,
    OrderStatus,
    OrderLine,
    IOrderDetailDto,
    OrderDto,
} from "../models/models";
import { ApiResponse,request } from "./api";




export async function getListByFilter( filter: OrderFilter): Promise<ApiResponse<PagedResult<OrderDto>>> {
    return await request<PagedResult<OrderDto>>(`orders/getListByFilter`,"POST",filter )
    // Bu Kod HTTP Get istegini paramsa çeviriyor belki başka yerde laızm olur return await request<PagedResult<Order>>(`Orders/Search?${new URLSearchParams(filtre as any).toString()}`
}

export async function changeOrdersStatus(ordersId:  number[], orderStatus: OrderStatus): Promise<ApiResponse<null>> {
    return await request<null>(`orders/changeOrdersStatus?&orderStatus=${orderStatus}`, "PATCH",ordersId);
}

export async function erpTransfer(ordersId:  number[]): Promise<ApiResponse<null>> {
    return await request<null>(`orders/erpTransfer`, "POST",ordersId);
}

export async function getOrderDetail(id: number): Promise<ApiResponse<OrderLine[]>> {
    return await request<OrderLine[]>(`orders/getOrderDetailById/${id}`, "GET");
}


export async function getOrderDetailDtoByOrderId(orderId: number): Promise<ApiResponse<IOrderDetailDto>> {
    return await request<IOrderDetailDto>(`orders/getOrderDetailDtoByOrderId/${orderId}`, "GET");
}