import { request } from "./request";
export async function savePeriodParameterToLocalStorage() {
    const response = await request("parameters/periodParameters");
    if (response.ok)
        localStorage.setItem("s_paramsPeriod", JSON.stringify(response.data));
}

export async function getAllSalesParameters() {
    return await request('parameters/salesParameters');
}
export async function getAllPeriodParameters() {
    return await request('parameters/periodParameters');
}


export async function getSalesContract() {
    return await request('parameters/salesContract');
}

export async function getPreliminaryInfoForm() {
    return await request('parameters/preliminaryInfoForm');
}