import { message } from "antd";
import { string } from "prop-types";

export type ApiSuccessResponse<T> = {
    ok: true;
    data: T;
};

export type ApiErrorResponse = {
    ok: false;
    status: number;
    message: string;
};

export type ApiResponse<T> = ApiSuccessResponse<T> | ApiErrorResponse;

export type RequestMethod = "DELETE" | "GET" | "PATCH" | "POST" | "PUT";

/**
 * @param uri `/api/`den sonraki istek adresi
 */
export async function request<T>(
    uri: string,
    method?: RequestMethod,
    body?: any
): Promise<ApiResponse<T>> {
    let token = localStorage.getItem("token");
    const response = await fetch(`/api/${uri}`, {
        method: method || "GET",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + (token || ""),
        },
        body: body !=null || body !=undefined ||  body !="" ? JSON.stringify(body) : undefined,
    });
    //İşlem Başarılı ise 
    if (response.ok && response.status == 200) {
        const text = await response.text();
        if (text && text.length > 0) {
            const data = JSON.parse(text);
            return { ok: true, data: data as T };
        }
        return { ok: true, data: null! };

    } else { //İslem Başarısız ise

        switch (response.status) {
            case 401:
                localStorage.clear();
                window.history.pushState({},"",'/Backoffice')
                case 500:
                    const text = await response.text();
                    if (text && text.length) 
                        message.error(JSON.parse(text).message);
        }
        return {ok: false,status: response.status, message: response.statusText };
    }

}
