import { Tabs, DatePicker, Modal, Table, Input, Button, Tooltip, message, Form, Row, Col, InputNumber, Spin, Popconfirm } from 'antd';
import { useIntl } from 'react-intl';
import { ProfileOutlined, PlusSquareOutlined, SwapOutlined, DeleteOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import * as React from 'react';
import { FirmAndPeriod, Erp } from '../../../../models/models';
import * as dbFirmAndPeriod from '../../../../api_ts/firmAndPeriod';
import * as DbErp from '../../../../api_ts/erp';
import Moment from 'react-moment';
import TextArea from 'antd/lib/input/TextArea';
import { ApiResponse } from '../../../../api_ts/api';
import moment from 'antd/node_modules/moment';
import { useEffect, useState } from 'react';
import { Translate } from '../../i18n/Translate';

interface Flags { loading: boolean; periodModalVisible: boolean; erpModalVisible: boolean; operation: "None" | "Add" | "Update", }

export const Periods: React.FC = () => {

    const intl = useIntl();
    const [erpForm] = Form.useForm();
    const [periodForm] = Form.useForm();
    let [flags, setFlags] = useState<Flags>({ loading: false, periodModalVisible: false, erpModalVisible: false, operation: "None" });
    let [donemErp, setDonemErp] = useState<Erp>();
    let [periods, setPeriods] = useState<FirmAndPeriod[]>([]);


    useEffect(() => {
        getAllPeriods();
    }, []);


    const getAllPeriods = async () => {
        return dbFirmAndPeriod.getList()
            .then(response => {
                if (response.ok) {
                    setPeriods(response.data);
                }
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }

    const onFinishPeriodForm = (period: FirmAndPeriod) => {
        //Tarih Ayarlanması
        var date = periodForm.getFieldValue("date_range")
        period.startDate = date[0]
        period.finishDate = date[1];
        addOrUpdatePeriod(period);
    }


    const addOrUpdatePeriod = async (period: FirmAndPeriod) => {

        let success: boolean = false;
        if (period.id == 0) {
            success = (await dbFirmAndPeriod.add(period)).ok;
        } else {
            success = (await dbFirmAndPeriod.update(period)).ok;
        }
        if (success) {
            setFlags(({ ...flags, periodModalVisible: false }))
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            getAllPeriods();
        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }

    const deletePeriod = async (periodId: number) => {
        requestServer<FirmAndPeriod>(periodForm, await dbFirmAndPeriod.deleteFirmAndPeriod(periodId), getAllPeriods);
    }

    const fillPeriodForm = async (period: FirmAndPeriod) => {
        periodForm.resetFields();
        periodForm.setFieldsValue({ date_range: [moment(period.startDate), moment(period.finishDate)] });
        periodForm.setFieldsValue(period);
        setFlags(flags => ({ ...flags, periodModalVisible: true, operation: "Update" }));
    }

    //------------------------------ERP----------------------------------------------------------------------------
    const getErp = (donemId: number, callback?: () => void) => {
        DbErp.getById(donemId)
            .then(response => {
                if (response.ok) {
                    donemErp = response.data
                }
                else console.log(response.message);
            }).then(callback)
            .catch(err => console.log(err.message));
    }

    const periodErpIntegration = async (erp: Erp) => {
        requestServer<Erp>(erpForm, await DbErp.addOrUpdate(erp), getAllPeriods, "erpModalVisible");
    }

    const periodErp = async (donemId: number) => {
        getErp(donemId, () => {
            if (donemErp != undefined || donemErp != null) {
                erpForm.setFieldsValue(donemErp);
            } else {
                erpForm.setFieldsValue({ id: donemId })
            }
        });
    }

    //------------------------------GLOBAL----------------------------------------------------------------------------

    const requestServer = <T,>(
        form: any,
        requestMethod: ApiResponse<T>,
        getAll: () => Promise<void>,
        flagsModal?: "periodModalVisible" | "erpModalVisible") =>    //flags nesnesindeki property adları bunlar birbirine eşit olmalı
    {
        setFlags(flags => ({ ...flags, loading: true }));                                  //Loading Aç
        if (requestMethod.ok) {
            getAll().then(
                () => {
                    if (flagsModal) {                                                    //Silme İşleminde Modala gerek yok
                        flags[flagsModal] = false;
                        setFlags(flags);                                                  //Modalı Kapat
                        form.resetFields();                                             //Formu Temizle
                        message.success(intl.formatMessage({ id: 'Operation.Success' }));
                    }
                });
            setFlags(flags => ({ ...flags, loading: false }));                               //Loading Aç
        }
        else {
            message.success(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }

    //------------------------------------CUSTOM VALİDATOR ---------------------------------------------------------------------------




    const isDublicatePeriodValidator = (_: any, value: any): any => {
        if (flags.operation === "Add") {
            var periodList = periods.filter(x => x.periodNumber == periodForm.getFieldValue("periodNumber") && x.firmNumber == periodForm.getFieldValue("firmNumber"))
            if (periodList.length > 0) {
                return Promise.reject(new Error("Mükerrer Kayit"));
            } else {
                return Promise.resolve();
            }
        } else {
            return Promise.resolve();
        }
    }
    //---------------------------------------------------------------------------------------------------------------------------------------



    const erpColumns = [
        { title: Translate("ErpName"), dataIndex: 'erpName', },
        { title: Translate("FirmName"), dataIndex: 'port', },
        { title: Translate("FirmNumber"), dataIndex: 'firmNumber', },
        { title: Translate("PeriodNumber"), dataIndex: 'periodNumber', }
    ];

    const periodColumns = [
        { title: Translate("FirmNumber"), dataIndex: 'firmNumber', },
        { title: Translate("FirmName"), dataIndex: 'firmName', },
        { title: Translate("PeriodNumber"), dataIndex: 'periodNumber', },
        { title: Translate("PeriodName"), dataIndex: 'periodName', },
        {
            title: Translate("StartDate"), key: 'id',
            render: (text: string, period: FirmAndPeriod) => <Moment format="DD.MM.YYYY">{period.startDate}</Moment>
        },
        {
            title: Translate("EndDate"),
            key: 'id',
            render: (text: string, period: FirmAndPeriod) => <Moment format="DD.MM.YYYY">{period.finishDate}</Moment>
        },
        {
            key: 'id',
            width: "15%",
            render: (text: string, period: FirmAndPeriod) =>
                <>
                    <Tooltip placement="bottom" color="blue" title={Translate("Integration")}>
                        <Button icon={<SwapOutlined />} onClick={() => {
                            setFlags(flags => ({ ...flags, erpModalVisible: true }))
                            periodErp(period.id)
                        }} />
                    </Tooltip>
                    <Tooltip placement="bottom" color="blue" title={Translate("Edit")}>
                        <Button icon={<EditOutlined />} onClick={() => fillPeriodForm(period)} />
                    </Tooltip>
                    <Tooltip placement="bottom" color="red" title={Translate("Delete")}>
                        <Popconfirm
                            title="Silmek İstediğinize Emin Misiniz ?"
                            onConfirm={() => {
                                deletePeriod(period.id)
                            }}
                            okText={Translate("Yes")}
                            cancelText={Translate("No")}
                        >
                            <Button icon={<DeleteOutlined />}></Button>
                        </Popconfirm>
                    </Tooltip>
                </>
        },
    ];


    const header = (<Button type="primary" className="ml-auto mb-1" onClick={() => {
        periodForm.resetFields()
        setFlags({ ...flags, periodModalVisible: true, operation: "Add" })
    }}
        icon={<PlusSquareOutlined style={{ verticalAlign: 'middle', fontSize: '20px' }} />} >
        <span style={{ verticalAlign: 'middle' }} >{Translate("Add")}</span>
    </Button>)


    const formItemLayout = { labelCol: { span: 7 }, wrapperCol: { span: 18 } };
    return (
        <>
            <Tabs defaultActiveKey="1" tabBarStyle={{ marginBottom: "3.5px" }} >
                <Tabs.TabPane key="1" tab={<span><ProfileOutlined />{Translate("PeriodsList")}</span>}>

                    <div className="site-card-border-less-wrapper">
                        <div className="d-flex">{header} </div>
                        <Table size="small" bordered columns={periodColumns} dataSource={periods} />
                    </div>

                    <Modal
                        title="Donem Bilgisi"
                        visible={flags.periodModalVisible}
                        onCancel={() => setFlags(flags => ({ ...flags, periodModalVisible: false }))}
                        onOk={() => { periodForm.submit() }}
                        okText={Translate(flags.operation)}
                        confirmLoading={flags.loading}
                        cancelText={Translate("Cancel")}
                    >

                        <Form form={periodForm}
                            layout={"horizontal"}
                            onFinish={onFinishPeriodForm}
                            {...formItemLayout}
                            size={"small"}
                            labelAlign={'left'}
                        >
                            <Form.Item

                                name={"id"}
                                fieldKey={"id"}
                                hidden={true}
                                initialValue={0} >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label={Translate("FirmName")}
                                name="firmName"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input placeholder={Translate('FirmName')} />
                            </Form.Item>
                            <Form.Item
                                label={Translate("FirmNumber")}
                                name="firmNumber"
                                dependencies={['periodNumber']}
                                rules={[
                                    { required: true, message: Translate("RequiredField") },
                                    { validator: isDublicatePeriodValidator }
                                ]}

                            >
                                <InputNumber disabled={flags.operation == "Update"} style={{ width: '100%' }} placeholder={Translate('FirmNumber')} />
                            </Form.Item>

                            <Form.Item
                                label={Translate("PeriodNumber")}
                                name="periodNumber"
                                dependencies={['firmNumber']}
                                rules={[
                                    { required: true, message: Translate("RequiredField") },
                                    { validator: isDublicatePeriodValidator }
                                ]}
                            >

                                <InputNumber disabled={flags.operation == "Update"} min={0} max={999} value={0} style={{ width: '100%' }} placeholder={Translate('PeriodNumber')} />
                            </Form.Item>
                            <Form.Item
                                label={Translate("TaxNumber")}
                                name="taxNo"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <InputNumber style={{ width: '100%' }} placeholder={Translate('TaxNumber')} />
                            </Form.Item>

                            <Form.Item
                                label={Translate("TaxOffice")}
                                name="taxOffice"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input placeholder={Translate('TaxOffice')} />
                            </Form.Item>

                            <Form.Item
                                label={Translate("City")}
                                name="city"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input placeholder={Translate('City')} />
                            </Form.Item>

                            <Form.Item
                                label={Translate("Town")}
                                name="town"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input placeholder={Translate('Town')} />
                            </Form.Item>

                            <Form.Item
                                label={Translate("Address")}
                                name="adress"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <TextArea rows={2} placeholder={Translate('Address')} />
                            </Form.Item>



                            <Form.Item
                                label={Translate("PeriodName")}
                                name="periodName"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input placeholder={Translate('PeriodName')} />
                            </Form.Item>


                            <Form.Item
                                name="date_range"
                                label="Başlangıç ve Bitiş"
                                rules={[{ required: true, message: Translate("RequiredField") }]}>
                                <DatePicker.RangePicker style={{ width: '100%' }} placeholder={["Başlangıç Tarih", "Bitiş Tarih"]} format="DD.MM.YYYY" />
                            </Form.Item>
                        </Form>
                    </Modal>



                    <Modal
                        title="Entegrasyon Bilgisi"
                        visible={flags.erpModalVisible}
                        onCancel={() => {
                            erpForm.resetFields();
                            setFlags(flags => ({ ...flags, erpModalVisible: false }));
                        }}
                        onOk={() => { erpForm.submit() }}
                        okText={Translate("Integration")}
                        cancelText={Translate("Cancel")}
                    >
                        <Form
                            form={erpForm}
                            layout={"vertical"}
                            onFinish={periodErpIntegration}
                        >
                            <Form.Item
                                name={"id"}
                                fieldKey={"id"}
                                hidden={true}
                                initialValue={0}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={Translate("ProjectName")}
                                name="erpName"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input placeholder={Translate('ProjectName')} prefix={<UserOutlined />} />
                            </Form.Item>
                            <Form.Item
                                label={Translate("Server")}
                                name="port"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input placeholder={Translate('Server')} prefix={<UserOutlined />} />
                            </Form.Item>
                            <Row>
                                <Col span={8}>
                                    <Form.Item
                                        label={Translate("FirmNumber")}
                                        name="firmNumber"
                                        initialValue={0}
                                        rules={[
                                            { required: true, message: Translate("RequiredField") }
                                        ]}
                                    >
                                        <InputNumber min={0} max={9999} placeholder={Translate('FirmNumber')} />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={8}>

                                    <Form.Item
                                        label={Translate("PeriodNumber")}
                                        name="periodNumber"
                                        initialValue={0}
                                        rules={[{ required: true, message: Translate("RequiredField") }]}
                                    >
                                        <InputNumber min={0} max={9999} value={0} placeholder={Translate('PeriodNumber')} />
                                    </Form.Item>
                                </Col>
                            </Row >
                        </Form>
                    </Modal>

                </Tabs.TabPane>
            </Tabs>

        </>)
}


