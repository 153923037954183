import { ItemWarehouse } from "../models/models";
import { ApiResponse, request } from "./api";

export function amountOfItemInWarehouse(malzemeId: number): Promise<ApiResponse<ItemWarehouse[]>> {
    return request<ItemWarehouse[]>(`items/${malzemeId}/Depo`);
} 

export function malzemeMiktarKaydet(model: ItemWarehouse): Promise<ApiResponse<null>> {
    return request<null>(`items/Depo`, "PATCH", model);
}
