import * as React from 'react';
import { Tabs, message, Table } from 'antd';
import { ProfileOutlined } from '@ant-design/icons';
import { BackOfficeCartDetailDTO, BackOfficeCartItemDTO } from '../../../../models/models'
import * as DbMalzeme from '../../../../api_ts/item';
import * as DbSepet from '../../../../api_ts/cart';
import { useIntl } from 'react-intl';
import { Translate } from '../../i18n/Translate';


interface Props {
    cartId: number;
}

export const CartDetail: React.FC<Props> = ({ cartId }) => {

    const intl = useIntl();
    const [cartDetail, setCartDetail] = React.useState<BackOfficeCartDetailDTO>();

    React.useEffect(() => {
        getCartDetail();
    }, [cartId]);

    const getCartDetail = () => {
        DbSepet.getCartDetail(cartId)
            .then(response => {
                if (response.ok) {
                    setCartDetail(response.data);
                }
                else
                    console.log(response.message)
            })
            .catch(err => console.log(err.message));
    }


    const columns = [
        {
            title: Translate("Code"),
            dataIndex: 'code',
        },
        {
            title: Translate("Category"),
            dataIndex: 'categoryName',
        },
        {
            title: Translate("Description"),
            dataIndex: 'description',
        },
        {
            title: Translate("UnitPrice"),
            dataIndex: 'unitPrice',
        },
        {
            title: Translate("Quantity"),
            dataIndex: 'quantity',
        },
        {
            title: Translate("VAT"),
            dataIndex: 'vat',
            render: (value: string, cart: BackOfficeCartItemDTO) => {
                return <span>&#37;{value}</span>
            }
        }
    ];

    return (
        <>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab={<span><ProfileOutlined />{Translate("ItemsList")}</span>} key="1">
                    <div className="site-card-border-less-wrapper">
                        <Table columns={columns} dataSource={cartDetail?.cartItems} />
                    </div>
                    <ul>

                        {/* <li>Alt Toplam: {cartDetail?.altToplamTutar}</li>
                        {clients?.ekstraSatirlar.map(x => (
                            <li>{x.baslik}: {x.tutar}</li>
                        ))} */
                        }
                        <li>Toplam Adet: {cartDetail?.totalQuantity}</li>
                        <li>Toplam Tutar: {cartDetail?.totalPrice}</li>
                    </ul>
                </Tabs.TabPane>
            </Tabs>
        </>
    );
};
