// reducers
import wizardReducer from './general';
import { combineReducers } from 'redux';
import cartReducer from './cart';
import compareReducer from './compare';
import currencyReducer from './currency';
import localeReducer from './locale';
import mobileMenuReducer from './mobile-menu';
import quickviewReducer from './quickview';
import sidebarReducer from './sidebar';
import version from './version';
import wishlistReducer from './wishlist';
import responsiveReducer from './responsive';
import salesParametersReducer from './parameter';
import localeAntDesignReducer from './locale/localeAntDesignReducer';
import clientReducer from './client';

export default combineReducers({
    version: (state = version) => state,
    cart: cartReducer,
    compare: compareReducer,
    currency: currencyReducer,
    locale: localeReducer,
    locale_ant: localeAntDesignReducer,
    mobileMenu: mobileMenuReducer,
    quickview: quickviewReducer,
    sidebar: sidebarReducer,
    wishlist: wishlistReducer,
    responsive: responsiveReducer,
    parameter: salesParametersReducer,
    wizard: wizardReducer,
    client: clientReducer,
});
