import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { Table, Button, Input, message } from 'antd';
import theme from '../../data/theme';
import PageHeader from '../shared/PageHeader';
import * as dbClient from '../../api_js/client';
import * as authenticate from '../../api_js/authenticate';
import { withRouter } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import $ from "jquery"

function AccountPageChooseClient(props) {
    const breadcrumb = [];

    const intl = useIntl();
    const user = authenticate.getSalesmanInfo();
    const mobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const [clients, setClients] = React.useState([]);

    const logout = async () => {
        authenticate.logoutUserB2B();
        return props.history.push("/account/login")
    }

    const getClientsBySalesmanId = () => {
        dbClient.getListBySalesmanId(user.id)
            .then(response => {
                if (response.ok) {
                    setClients(response.data);
                } else {
                    console.log(response.message);
                }
            })
            .catch(err => console.log(err));
    }


    React.useEffect(() => {
        getClientsBySalesmanId()
    }, []);



    const chooseClient = async (clientId) => {
        const chooseClientDto = {
            salesmanId: user.id,
            clientId,
            firmNumber: user.firmNumber,
            periodNumber: user.periodNumber
        };

        const response = await authenticate.loginSalesman(chooseClientDto);
        if (response.ok) {
            authenticate.loginUI(response.data)
            return props.history.push("/shop")
        } else {
            message.error('Kullanıcı giriş başarısız');
        }
    }

    function toogleClientDetail(clientDetail) {
        $("#apcc-mobil-client-detail").html("<ul style='line-height:180%'><li> Kod : " + clientDetail.code + "</li> <li> Acıklama : " + clientDetail.description + "</li><li> Acıklama-2 :" + clientDetail.description2 + "</li></ul>")
        $("#apcc-mobil-client-detail").fadeToggle({}, 1000)
    }

    const columns = [
        {
            title: <FormattedMessage id="accountPage.Code" defaultMessage="Code" />,
            dataIndex: 'code',
            width: '25%',
            filterDropdown: ({ confirm, setSelectedKeys, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        onPressEnter={(e) => confirm()}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        style={{ width: 188, marginBottom: 8, display: 'block' }} />
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => confirm()}
                        style={{ width: 90 }}>
                        <FormattedMessage id="accountPage.Search" defaultMessage="Search" />
                    </Button>
                    <Button size="small" onClick={() => clearFilters()} style={{ width: 90 }}>
                        <FormattedMessage id="accountPage.Reset" defaultMessage="Reset" />
                    </Button>
                </div>
            ),
            onFilter: (text, record) => record.kod.toLocaleLowerCase().trim().includes(text.toLocaleLowerCase().trim()),
        },
        {
            title: <FormattedMessage id="accountPage.Explication" defaultMessage="Code" />,
            dataIndex: 'description',
            width: '25%',
            filterDropdown: ({ confirm, setSelectedKeys, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        onPressEnter={(e) => confirm()}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        style={{ width: 188, marginBottom: 8, display: 'block' }} />
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => confirm()}
                        style={{ width: 90 }}>
                        <FormattedMessage id="accountPage.Search" defaultMessage="Search" />
                    </Button>
                    <Button size="small" onClick={() => clearFilters()} style={{ width: 90 }}>
                        <FormattedMessage id="accountPage.Reset" defaultMessage="Reset" />
                    </Button>
                </div>
            ),
            onFilter: (text, record) => record.description.toLocaleLowerCase().trim().includes(text.toLocaleLowerCase().trim()),
        },
        {
            hidden: mobile,
            title: <FormattedMessage id="accountPage.Explication" defaultMessage="Code" />,
            dataIndex: 'description2',
            width: '25%',
            filterDropdown: ({ confirm, setSelectedKeys, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        onPressEnter={(e) => confirm()}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        style={{ width: 188, marginBottom: 8, display: 'block' }} />
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => confirm()}
                        style={{ width: 90 }}>
                        <FormattedMessage id="accountPage.Search" defaultMessage="Search" />
                    </Button>
                    <Button size="small" onClick={() => clearFilters()} style={{ width: 90 }}>
                        <FormattedMessage id="accountPage.Reset" defaultMessage="Reset" />
                    </Button>
                </div>
            ),
            onFilter: (text, record) => record.description2.toLocaleLowerCase().trim().includes(text.toLocaleLowerCase().trim()),
        },
        {
            title: '', dataIndex: 'id', width: '1%', render: (text, record) => (

                <input type="button" className="btn btn-sm btn-primary" value="Seç" onClick={() => chooseClient(record.id, record.description)} />
            )
        },
    ].filter(column => !column.hidden)


    const chooseClientHtml = (
        <React.Fragment>
            <Helmet>
                <title>{`${theme.name}`}</title>
            </Helmet>
            <PageHeader header={<FormattedMessage id="accountPage.Title" defaultMessage="Cari Hesap Seç" />} breadcrumb={breadcrumb} />
            <div className="block">
                <div className="container">
                    {/* <div className="row justify-content-center">
                <div className="col-md-12 d-flex">
                    <div className="card flex-grow-1 mb-md-0">
                        <div className="card-body"> */}
                    <Table onRow={(record, rowIndex) => {
                        return {
                            onClick: event => {
                                toogleClientDetail(record)
                            } // click row  
                        };
                    }} size="small" columns={columns} dataSource={clients} />
                    {/* </div>
                    </div>
                </div> 
            </div>*/}
                    <div className="row mt-2">
                        <div className="col-md-12 d-flex justify-content-end">
                            <input
                                type="button"
                                className="btn btn-sm btn-primary"
                                value={intl.formatMessage({ id: 'header.logout' })}
                                onClick={logout}
                            />
                        </div>
                    </div>
                </div>
                {mobile && <div id="apcc-mobil-client-detail" style={{ display: "none" }} className="apcc-mobil-client-detail p-5">
                </div>
                }
            </div>
        </React.Fragment>
    )


    return (
        chooseClientHtml
    );
}
export default withRouter(AccountPageChooseClient)


