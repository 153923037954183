import './ItemPrices.css';
import * as React from 'react';
import Moment from 'react-moment';
import { useIntl } from 'react-intl';
import moment from 'antd/node_modules/moment';
import * as DbBirim from '../../../../../api_ts/unit';
import { formatFiyat } from '../../../utils/number';
import * as DbMalzemefiyat from '../../../../../api_ts/itemPrice';
import { ItemPrice, CurrencyUnit, CurrencyType, Unit } from "../../../../../models/models";
import { Tabs, DatePicker, Table, Card, Button, Tooltip, message, InputNumber, Select, Checkbox, Form, Input } from 'antd';
import { FileDoneOutlined, FileAddTwoTone, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Translate } from '../../../i18n/Translate';
import Currency from '../../../components/Currency';

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;

interface Props {
    malzemeId: number;
}

export const ItemPrices: React.FC<Props> = ({ malzemeId }) => {

    const intl = useIntl();
    const [itemPriceForm] = Form.useForm();
    const [itemPrices, setItemPrices] = React.useState<ItemPrice[]>([]);
    const [units, setUnits] = React.useState<Unit[]>([]);
    const [flags, setFlags] = React.useState({ activeTabKey: "1", operation: "AddPrice", title: "", groupCodeModalVisible: false, loading: false });

    React.useEffect(() => { getAllItemPrices() }, [malzemeId]);


    const handleEditButtonClick = async (id: number) => {
        await getItemPriceById(id).then((itemPrice) => {
            itemPriceForm.resetFields();
            setFlags({ ...flags, activeTabKey: "2", operation: "EditPrice" })
            itemPriceForm.setFieldsValue({ startAndEndDates: [moment((itemPrice as ItemPrice).startDate), moment((itemPrice as ItemPrice).endDate)] });
            itemPriceForm.setFieldsValue(itemPrice)
        });
    }

    const deleteItemPrice = async (value: number) => {
        const response = await DbMalzemefiyat.deleteItemPrice(value);
        if (response.ok) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            getAllItemPrices();
        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }



    const addOrUpdateItemPrice = async (itemPrice: ItemPrice) => {
        var date = itemPriceForm.getFieldValue("startAndEndDates")
        itemPrice.startDate = date[0]; itemPrice.endDate = date[1];
        itemPrice.itemId = malzemeId;

        let success: boolean = false;
        if (itemPrice.id === 0) {
            success = (await DbMalzemefiyat.add(itemPrice)).ok;
        } else {
            success = (await DbMalzemefiyat.update(itemPrice)).ok;
        }

        if (success) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            getAllItemPrices();
            resetOperation()
        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }



    const getItemPriceById = async (itemPriceId: number) => {
        return DbMalzemefiyat.getItemPriceById_API(itemPriceId)
            .then(response => {
                if (response.ok) {
                    return response.data;
                }
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }


    const getAllItemPrices = async () => {
        await DbMalzemefiyat.getListByItemId(malzemeId)
            .then(response => {
                if (response.ok) {
                    console.log(response.data);
                    setItemPrices(response.data);
                }
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));

        DbBirim.getList()
            .then(response => {
                if (response.ok)
                    setUnits(response.data);
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }

    const resetOperation = () => {
        itemPriceForm.resetFields();
        setFlags({ ...flags, activeTabKey: "1", operation: "AddPrice" })
    }

    const column = [
        {
            title: Translate("CurrencyUnit"),
            dataIndex: 'currencyUnit',
            key: 'currencyUnit',
            render: (text: string, itemPrice: ItemPrice) => {
                return (Translate(CurrencyUnit[itemPrice.currencyUnit]));
            }
        },
        {
            title: Translate("UnitPrice"),
            dataIndex: 'unitPrice',
            align: 'right' as const,
            key: 'unitPrice',
            render: (_: string, itemPrice: ItemPrice) => <Currency value={itemPrice.unitPrice} />
        },
        {
            title: Translate("CurrencyType"),
            key: 'currencyType',
            dataIndex: 'currencyType',
            render: (text: string, itemPrice: ItemPrice) => {
                return (Translate(CurrencyType[itemPrice.currencyType]));
            }
        },
        {
            title: Translate("CurrencyUnitPrice"),
            dataIndex: 'currencyUnitPrice',
            key: 'currencyUnitPrice',
            render: (_: string, itemPrice: ItemPrice) => <Currency value={itemPrice.currencyUnitPrice} />
        },
        {
            title: Translate("StartDate"),
            key: 'startDate',
            dataIndex: 'startDate',
            render: (text: string, itemPrice: ItemPrice) => <Moment format="DD.MM.YYYY HH:mm">{itemPrice.startDate}</Moment>
        },
        {
            title: Translate("EndDate"),
            key: 'endDate',
            dataIndex: 'endDate',
            render: (text: string, itemPrice: ItemPrice) => <Moment format="DD.MM.YYYY HH:mm">{itemPrice.endDate}</Moment>
        },
        {
            key: 'id',
            width: '12%',
            render: (text: string, itemPrice: ItemPrice) =>
                <>
                    <Tooltip placement="top" color="blue" title={Translate("Edit")}>
                        <Button icon={<EditOutlined />} onClick={() => handleEditButtonClick(itemPrice.id)} />
                    </Tooltip>
                    <Tooltip placement="top" color="red" title={Translate("Delete")}>
                        <Button icon={<DeleteOutlined />} onClick={() => deleteItemPrice(itemPrice.id)} />
                    </Tooltip>
                </>
        },
    ];


    return (
        <>

            <Tabs defaultActiveKey="1" onTabClick={(e) => setFlags({ ...flags, activeTabKey: e })} activeKey={flags.activeTabKey} >
                <Tabs.TabPane tab={<span><FileDoneOutlined />{Translate("ItemPriceCards")}</span>} key="1">
                    <Table columns={column} dataSource={itemPrices} pagination={{ pageSize: 7 }} scroll={{ y: 700 }} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span><FileAddTwoTone />{Translate(flags.operation)}</span>} key="2">
                    <Card
                        bordered={false}
                        size={'small'}
                        style={{ width: "50%" }}
                    >
                        <Form
                            form={itemPriceForm}
                            layout={"horizontal"}
                            id={"itemPriceForm"}
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 17 }}
                            onFinish={addOrUpdateItemPrice}
                            size={"small"}
                            labelAlign={"left"}
                        >
                            <Form.Item
                                name={"id"}
                                fieldKey={"id"}
                                hidden={true}
                                initialValue={0}
                            >
                                <Form.Item name={"idERP"} fieldKey={"idERP"} hidden={true}  > <Input /> </Form.Item>
                            </Form.Item>
                            <Form.Item
                                label={Translate("Unit")}
                                name="unitId"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Select style={{ width: '100%' }} placeholder={Translate('PleaseSelect')} >
                                    {units.map(x => <Select.Option value={x.id} key={x.id}>{x.code}</Select.Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={Translate("CurrencyUnit")}
                                name="currencyUnit"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Select style={{ width: '100%' }} placeholder={Translate('PleaseSelect')} >
                                    {Object.keys(CurrencyUnit).filter(key => !/^\d+$/.test(key)).map(key => <Option value={CurrencyUnit[key as any]}>{key}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={Translate("CurrencyType")}
                                name="currencyType"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Select style={{ width: '100%' }} placeholder={Translate('PleaseSelect')} >
                                    {Object.keys(CurrencyType).filter(key => !/^\d+$/.test(key)).map(key => <Option value={CurrencyType[key as any]}>{key}</Option>)}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label={Translate("UnitPrice")}
                                name="unitPrice"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <InputNumber placeholder={Translate('UnitPrice')} />
                            </Form.Item>
                            <Form.Item
                                label={Translate("CurrencyUnitPrice")}
                                name="currencyUnitPrice"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <InputNumber placeholder={Translate('CurrencyUnitPrice')} />
                            </Form.Item>
                            <Form.Item
                                label={Translate("Date")}
                                name="startAndEndDates"
                                rules={[{ required: true, message: Translate("RequiredField") }]}

                            >
                                <RangePicker showTime format="DD.MM.YYYY HH:mm:ss" />
                            </Form.Item>
                            <Form.Item
                                label={Translate("TaxIncluded")}
                                name="isVATIncluded"
                            >
                                <Checkbox disabled={true}></Checkbox>
                            </Form.Item>

                            <Form.Item
                                label={Translate("IsActive")}
                                name="isActive"
                                initialValue={true}
                                valuePropName="checked"
                            >
                                <Checkbox></Checkbox>
                            </Form.Item>


                            <Form.Item wrapperCol={{ offset: 7, span: 17 }}>
                                <Button type="primary" htmlType="submit">
                                    {Translate("Save")}
                                </Button>
                                <Button htmlType="button" onClick={resetOperation}>
                                    {Translate("Cancel")}
                                </Button>
                            </Form.Item>
                        </Form>

                    </Card>
                </Tabs.TabPane >
            </Tabs >
        </>
    );
};
