import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive'
import Currency from "../shared/Currency";
import moment from 'moment'
import { Table, Form, DatePicker, Input, Row, Col, Button } from 'antd';
import { getAccountSummaryByFilter_API } from '../../api_js/client';
import React from 'react'

export default function AccountPageClientAccountSummary() {
    const intl = useIntl();
    const dateFormat = "DD.MM.YYYY";
    // const dateNow = moment();
    // const lastMonth = moment().add(-1, 'M'); 
    const [accountSummaries, setAccountSummaries] = React.useState([]);
    const [accountSummaryFilter, setAccountSummaryFilter] = React.useState({ startDate: null, endDate: null });

    const mobile = useMediaQuery({ query: '(max-width:425px)' })
    const tablet = useMediaQuery({ query: '(max-width:768px)' })

    React.useEffect(() => {
        getAccountSummaryByFilter();
    }, [accountSummaryFilter]);

    const getAccountSummaryByFilter = () => {
        const filter = YYYY_MM_DD(accountSummaryFilter)
        getAccountSummaryByFilter_API(filter)
            .then((response) => {
                if (response.ok) {
                    setAccountSummaries(response.data)
                } else console.log(response.message);
            })
            .catch((err) => console.log(err.message));
    }

    const searchToAccountSummary = (filter) => {
        setAccountSummaryFilter(filter)
    }

    //(bu method tarihi YYYY_MM_DD formatına çevirir Mssql ,C# vs sunucular en,tr culture farkı gözetmez bu format ile bu sayade kolaylık olur)
    const YYYY_MM_DD = (value) => {
        const filter = {
            startDate: value.startDate != null ? moment(value.startDate).format("YYYY-MM-DD") : "",
            endDate: value.endDate != null ? moment(value.endDate).format("YYYY-MM-DD") : ""
        }
        return filter;
    }

    const filterBar = (
        <Form
            layout="horizontal"
            size="small"
            onFinish={searchToAccountSummary}
        >
            <Row>
                <Col lg={12} md={13} sm={13} xs={mobile ? 24 : 15}>
                    <Form.Item
                        name="startDate"
                        style={{ display: 'inline-block', width: 'calc(50%)' }}
                        // rules={[{ required: true, message: intl.formatMessage({ id: "requiredField" })}]}
                        initialValue={accountSummaryFilter.startDate}
                    >
                        <DatePicker
                            size="middle"
                            style={{ width: "98%" }}
                            placeholder={intl.formatMessage({ id: "accountPage.StartDate" })}
                            defaultValue={accountSummaryFilter.startDate}
                            format={dateFormat}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{ marginBottom: 0 }}
                        name="endDate"
                        // rules={[{ required: true, message: intl.formatMessage({ id: "requiredField" })}]}
                        style={{ display: 'inline-block', width: 'calc(50%)' }}
                        initialValue={accountSummaryFilter.endDate}
                    >
                        <DatePicker
                            size="middle"
                            style={{ width: "98%" }}
                            defaultValue={accountSummaryFilter.endDate}
                            format={dateFormat}
                            placeholder={intl.formatMessage({ id: "accountPage.EndDate" })} />
                    </Form.Item>
                </Col>
                <Col lg={12} md={11} sm={11} xs={mobile ? 24 : 9}>
                    <Form.Item
                        name="searchValue"
                        initialValue=""
                    >
                        <Button size="middle" style={{ width: "100%", background: "#cc3333", color: "#fff" }} htmlType="submit">{intl.formatMessage({ id: "search" })}</Button>


                    </Form.Item>

                </Col>
            </Row>
        </Form>)

    const mobileTable = (
        <div className="card">
            <div className="card-header">
                <h5>HESAP ÖZETİ</h5>
            </div>
            <table className="account-summary-mobil-table">
                {
                    accountSummaries.map(x =>
                        <>
                            <tbody >
                                <tr  >
                                    <td className="text-center pt-2" rowSpan="4">{x.isleM_DOVIZ_TIPI}</td>
                                </tr>
                                <tr  >
                                    <td className="pt-2" >Borc:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{<Currency value={x.borc} />}</td>
                                </tr>
                                <tr  >
                                    <td>Alacak:&nbsp;&nbsp;{<Currency value={x.alacak} />}</td>
                                </tr  >
                                <tr  >
                                    <td>Bakiye:&nbsp;&nbsp;{<Currency value={x.bakiye} />}{x.bakiyE_TIPI}</td>
                                </tr  >
                            </tbody>
                            <tr class="tr-separator " style={{ height: "0.5rem", borderBottom: "1px solid #6e777f" }} colspan="2">
                            </tr>
                        </>
                    )
                }
            </table>
            <div className="card-divider" />
            <div className="card-footer">
            </div>
        </div>
    )



    const desktopTable = (
        <div className="card">
            <Helmet>
                <title>{`Hesap Özeti`}</title>
            </Helmet>

            <div className="card-header">
                <h5>HESAP ÖZETİ</h5>
            </div>
            <div className="card-divider" />
            <div className="card-table">
                <div className="table-responsive-sm">
                    <table>
                        <thead>
                            <tr>
                                <th>DÖVİZ TÜRÜ</th>
                                <th>BORC</th>
                                <th>ALACAK</th>
                                <th>BAKIYE</th>
                                {/* <th>YEREL BAKIYE</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                accountSummaries.map(x =>
                                    <tr>
                                        <td>{x.isleM_DOVIZ_TIPI}</td>
                                        <td>{<Currency value={x.borc} />}</td>
                                        <td>{<Currency value={x.alacak} />}</td>
                                        <td>{<Currency value={x.bakiye} />}{x.bakiyE_TIPI}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card-divider" />
            <div className="card-footer">

            </div>
        </div>
    )


    return (
        <>
            {filterBar}
            {tablet ? mobileTable : desktopTable}
        </>
    );

}

