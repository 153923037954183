import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import Currency from "../shared/Currency";
import * as dbOrder from "../../api_js/order";
import * as authenticate from "../../api_js/authenticate";
import * as dbNetahsilat from "../../api_js/netahsilat";
import { getCart } from "../../store/cart";
import { getAllSalesParameters } from "../../store/parameter";
import MediaQuery from 'react-responsive'
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Checkbox, Modal } from 'antd';
import { CollectionBonusType, PaymentMethod } from "../../../../models/models";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from "jquery"
import { OperationType } from "../../enum/enumB2B";
import "../../css/ShopPageCartPriceSummary.css";
import ShopPageSalesContract from "./ShopPageSalesContract";
import ShopPagePreliminaryInfoForm from "./ShopPagePreliminaryInfoForm";
import { useMediaQuery } from 'react-responsive'

function ShopPageCartPriceSummary(props) {


    const history = useHistory();
    const { cart, btnInfo, getCart, salesParameter } = props;
    const [renderBtn, setRenderBtn] = React.useState();
    const [dangerStyle, setDangerStyle] = React.useState(false);
    const mobile = useMediaQuery({ query: '(max-width: 1201)' })

    const [isCheckedContract, setIsCheckedContract] = React.useState(false);
    const [salesContractModal, setSalesContractModal] = React.useState(false);
    const [preliminiaryInfoFormModal, setPreliminiaryInfoFormModal] = React.useState(false);

    const [loading, setLoading] = React.useState(false);

    const user = authenticate.getUserInfo();

    useEffect(() => {
        setRenderBtn(renderButton())
    }, [])

    useEffect(() => {
        setRenderBtn(renderButton())
    }, [btnInfo, isCheckedContract])


    const createOrder = async () => {
        return await dbOrder.create()
            .then(response => {
                if (response.ok) {
                    return response.data
                } else {
                    console.log(response.message);
                }
            })
            .catch(err => console.log(err));
    }

    const payOrderWithCreditCard = async () => {
        dbNetahsilat.payOrderWithCreditCard()
            .then(response => {
                if (response.ok) {
                    orderPaymentForm(response.data)
                } else {
                    console.log(response.message);
                }
            })
            .catch(err => console.log(err));
    }




    function toogleCartSummary() {
        $("#spcps-mobil-cart-summary").fadeToggle({}, 1000)
    }





    const orderPaymentForm = (response) => {
        let form = $(`<form action=${response.form.netahsilatUrl} method='post' id='paymentpage' name='paymentpage' enctype='application/x-www-form-urlencoded'></form>`);

        let dataInput = $("<input/>", { type: 'hidden', id: 'DATA', name: 'Data', value: response.data })

        let hashInput = $(`<input  type='hidden' id='HASH' name='HASH' value="${response.hash}"/>`)

        form.append(dataInput);
        form.append(hashInput);
        $("#yonlendir").append(form);
        document.forms['paymentpage'].submit();
    }

    const payOrderByPaymentType = () => {

        switch (cart.paymentMethod) {
            case PaymentMethod.CreditCard: payOrderWithCreditCard();
                break;
            case PaymentMethod.Bill: console.log("SENET");
                createOrder().then(order => history.push(`shop/order/success?id=${order.id}`)).then(() => getCart());
                break;
            case PaymentMethod.Cash: console.log("NAKİT")
                createOrder().then(order => history.push(`/shop/order/success?id=${order.id}`)).then(() => getCart());
                break;
            case PaymentMethod.ClientAccount: console.log("CARİ HESAP")
                createOrder().then(order => history.push(`/shop/order/success?id=${order.id}`)).then(() => getCart());

                break;
            case PaymentMethod.Remittance: console.log("HAVALE")
                createOrder().then(order => history.push(`/shop/order/success?id=${order.id}`)).then(() => getCart());

                break;
            case PaymentMethod.Cheque: console.log("ÇEK")
                createOrder().then(order => history.push(`/shop/order/success?id=${order.id}`)).then(() => getCart());
                break;
            default:
                break;
        }



    }




    const renderButton = () => {
        switch (btnInfo.operationType) {
            case OperationType.Link:
                return (<Link
                    className={`btn btn-primary ${!btnInfo.validation && "disabled"}`}
                    to={btnInfo.to}><FormattedMessage id={btnInfo.btnName} />
                </Link>)
            case OperationType.PaymentPage:
                return (
                    <Link
                        // !btnInfo.validation &&
                        className={`btn btn-primary ${!(btnInfo.validation) && "disabled"} ${loading && "btn-loading"}`}
                        onClick={() => {
                            if (isCheckedContract) {
                                setLoading(true);
                                payOrderByPaymentType()
                            } else {
                                $("#spcps-mobil-cart-summary").fadeIn()
                                setDangerStyle(true);
                            }

                        }}><FormattedMessage id={btnInfo.btnName} />
                    </Link>
                )
        }
    }

    const contracts = (
        btnInfo.operationType == OperationType.PaymentPage &&
        <div className={dangerStyle && "danger-console.log"}>
            <Checkbox id={"contactCheckBox"} onChange={(e) => {
                setIsCheckedContract(e.target.checked)
                setDangerStyle(false);
            }} className="spcps-contracts">
                <a href="javascript:void(0)" onClick={() => setPreliminiaryInfoFormModal(true)}>Ön bilgilendirme formunu</a>  ve
                <a href="javascript:void(0)" onClick={() => setSalesContractModal(true)}> Mesafeli satış sözleşmesini</a> onaylıyorum.
            </Checkbox>
        </div>
    )



    const cartSummary = (
        <>
            <table className="cart__totals mb-1">
                {salesParameter.collectionBonusType != CollectionBonusType.Discount && cart.bonus != 0 &&
                    <tr >
                        <td>
                            <FormattedMessage id="bonusPoint" />
                        </td>
                        <td>
                            {cart.bonus}
                        </td>
                    </tr>
                }
                <tr>
                    <td>
                        <FormattedMessage id="subTotal" />
                    </td>
                    <td>
                        <Currency value={cart.subtotal} />
                    </td>
                </tr>
                {cart.paymentTypeDiscount != 0 &&
                    <tr>
                        <td>
                            <FormattedMessage id="discountPrice" />
                        </td>
                        <td>
                            <Currency value={cart.paymentTypeDiscount} />
                        </td>
                    </tr>
                }
                <tr>
                    <td>
                        <FormattedMessage id="VATIncludedPrice" />
                    </td>
                    <td>
                        <Currency value={cart.priceWithVAT} />
                    </td>
                </tr>
                <tfoot className="cart__totals-footer">
                    <tr>
                        <th>
                            <FormattedMessage id="total" />
                        </th>
                        <td>
                            <Currency value={cart.netAmount} />
                        </td>
                    </tr>
                </tfoot>
            </table>
        </>
    )



    return (
        <>
            <div hidden={true} id="yonlendir">
            </div>
            <MediaQuery minWidth={1201}>
                <div className="spcps-sidebar-container">
                    <div className="spcps-sidebar-sticky">
                        <div className="spcps-sidebar">
                            <div className="card spcps-card">
                                <div className="card-body spcps-card-body"     >
                                    <span className="header-title">
                                        <FormattedMessage id="cartSummary" />
                                    </span>
                                    {cartSummary}
                                    {contracts}
                                    <div className="separate-10"></div>
                                    <div className="text-center">
                                        {renderBtn}
                                    </div>
                                    {/* <div
                className="btn btn-primary btn-xl btn-block cart__checkout-button">
                <FormattedMessage id="proceedToCheckout" />
            </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>

            <MediaQuery maxWidth={1200}>
                <div id="spcps-mobil-cart-summary" style={{ display: "none" }} className="spcps-mobil-cart-summary p-4">
                    {contracts}
                    {cartSummary}
                </div>
                <div className="spcps-footer">
                    <Row>
                        <Col >
                            <div className="spcps-footer-price">
                                <table onClick={toogleCartSummary} cellPadding={10}>
                                    <tr><td style={{ fontSize: "17px" }} colSpan={2}>Sepet Özeti</td></tr>
                                    <tr>
                                        <td ><span style={{ fontSize: "17px" }} ><Currency value={cart.netAmount} /></span></td>
                                        <td ><i class="fas fa-chevron-up"></i></td>
                                    </tr>
                                </table>
                                <div></div>
                            </div>
                        </Col>
                        <MediaQuery minWidth={400} children={<Col style={{ margin: "auto 18px" }} className="text-right">{renderBtn}</Col>} />
                        <MediaQuery maxWidth={400} children={<Col xs="7" style={{ margin: "auto 4px auto 0px" }} className="text-right">{renderBtn}</Col>} />
                    </Row>
                </div>
            </MediaQuery>

            <Modal
                className="contract-modal"
                title={<><div><td className="pr-3" onClick={() => {
                    setSalesContractModal(false)
                }}> <FontAwesomeIcon style={{ color: "#cc3333" }} size={"2x"} icon={"chevron-circle-left"} /></td>
                    <td className="align-middle">
                        <FormattedMessage id="salesContract" />
                    </td>
                </div>
                </>}
                style={{ top: 0 }}
                bodyStyle={{ padding: "55px 20px" }}
                footer={null}
                visible={salesContractModal}
                closable={false}
            >

                <ShopPageSalesContract cartId={cart.cartId} />
            </Modal>

            <Modal
                className="contract-modal"
                title={<><div><td className="pr-3" onClick={() => {
                    setPreliminiaryInfoFormModal(false)
                }}> <FontAwesomeIcon style={{ color: "#cc3333" }} size={"2x"} icon={"chevron-circle-left"} /></td>
                    <td className="align-middle">
                        <FormattedMessage id="preliminaryInfoForm" />
                    </td>
                </div>
                </>}
                style={{ top: 0 }}
                bodyStyle={{ padding: "55px 20px" }}
                footer={null}
                visible={preliminiaryInfoFormModal}
                closable={false}
            >

                <ShopPagePreliminaryInfoForm cartId={cart.cartId} />
            </Modal>

        </>
    );
}


const mapStateToProps = (state) => ({
    cart: state.cart,
    salesParameter: state.parameter,
});

const mapDispatchToProps = {
    getCart,
    getAllSalesParameters
};


export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCartPriceSummary);
