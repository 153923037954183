import * as React from 'react';
import { useIntl } from 'react-intl';
import { Client, E_InvoicePayer } from '../../../../models/models';
import * as DbClient from '../../../../api_ts/client';
import { ClientUsers } from './ClientUsers';
import { ClientAddresses } from './ClientAddresses';
import { tableColumnTextFilterConfig } from '../../components/AntHelpers/TableHelpers';
import { Translate } from '../../i18n/Translate';
import { Tabs, Modal, Table, Input, Button, Tooltip, message, Card, Form, InputNumber, Popconfirm, Select, Checkbox, Radio } from 'antd';
import { ProfileOutlined, PlusSquareOutlined, UsergroupAddOutlined, BookOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

export const Clients: React.FC = () => {
    const intl = useIntl();
    const [clients, setClients] = React.useState<Client[]>([]);
    const [clientForm] = Form.useForm();
    const formItemLayout = { labelCol: { span: 7 }, wrapperCol: { span: 17 } };
    const [flags, setFlags] = React.useState({ loading: false, clientModalVisible: false, operation: "None" });
    const [clientId, setClientId] = React.useState<number>(0);
    const [clientUsersVisible, setClientUsersVisible] = React.useState(false);
    const [clientAdressVisible, setClientAdressVisible] = React.useState(false);

    const clientUserDetail = (client: Client) => {
        setClientId(client.id);
        setClientUsersVisible(true);
    }

    const clientAdressDetail = (client: Client) => {
        setClientId(client.id);
        setClientAdressVisible(true);
    }

    const deleteClient = async (e: number) => {
        const response = await DbClient.deleteClient(e);
        if (response.ok) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            getAllClients();
        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }
    const fillClientForm = async (client: Client) => {
        clientForm.resetFields();
        setFlags(({ ...flags, clientModalVisible: true, operation: "Update" }));
        clientForm.setFieldsValue(client);
    }

    const columns = [
        {
            title: Translate("Code"),
            dataIndex: 'code',
            ...tableColumnTextFilterConfig<Client>(),
            onFilter: (value: any, item: Client) => item.code.toString().toLowerCase().includes(value.toString().toLowerCase())
        },
        {
            title: Translate("Description"),
            dataIndex: 'description',
            ...tableColumnTextFilterConfig<Client>(),
            onFilter: (value: any, item: Client) => item.description.toString().toLowerCase().includes(value.toString().toLowerCase())
        },
        {
            title: Translate("Description-2"),
            dataIndex: 'description2',
            ...tableColumnTextFilterConfig<Client>(),
            onFilter: (value: any, item: Client) => item.description2.toString().toLowerCase().includes(value.toString().toLowerCase())
        },
        {
            title: Translate("EInvoice"),
            dataIndex: 'e_InvoicePayer',
            filters: [{ text: 'Evet', value: E_InvoicePayer.Yes }, { text: 'Hayır', value: E_InvoicePayer.No }],
            onFilter: (value: any, record: Client) => record.e_InvoicePayer == value,
            render: (text: string, item: Client) => {
                return (Translate(E_InvoicePayer[item.e_InvoicePayer]));
            }
        },
        {
            title: Translate("Status"),
            dataIndex: 'isActive',
            render: (text: string, record: Client) => record.isActive ? "Aktif" : "Pasif",
            filters: [{ text: 'Aktif', value: true }, { text: 'Pasif', value: false }],
            onFilter: (value: any, record: Client) => record.isActive == value
        },

        {
            key: 'id',
            width: 200,
            render: (text: string, record: Client) =>
                <>
                    <Tooltip placement="top" color="blue" title={Translate("ClientUsers")}>
                        <Button icon={<UsergroupAddOutlined />} onClick={() => clientUserDetail(record)} />
                    </Tooltip>
                    <Tooltip placement="top" color="blue" title={Translate("ClientAddresses")}>
                        <Button icon={<BookOutlined />} onClick={() => clientAdressDetail(record)} />
                    </Tooltip>
                    <Tooltip placement="top" color="blue" title={Translate("Edit")}>
                        <Button icon={<EditOutlined />} onClick={() => fillClientForm(record)} />
                    </Tooltip>
                    <Tooltip placement="top" color="red" title={Translate("Delete")}>
                        <Button icon={<DeleteOutlined />} onClick={() => deleteClient(record.id)} danger />
                    </Tooltip>
                </>
        },
    ];

    const addOrUpdateClient = async (client: Client) => {
        let success: boolean = false;
        if (client.id === 0) {
            success = (await DbClient.add(client)).ok;
        } else {
            success = (await DbClient.update(client)).ok;
        }
        if (success) {
            setFlags(({ ...flags, clientModalVisible: false }))
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            getAllClients();
        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }

    const getAllClients = () => {
        DbClient.getList()
            .then(response => {
                if (response.ok)
                    setClients(response.data);
                else
                    console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }

    React.useEffect(getAllClients, []);

    const header = (<Button type="primary" className="ml-auto mb-1" onClick={() => {
        if (flags.operation == "Update") {
            clientForm.resetFields();
        }
        setFlags({ ...flags, clientModalVisible: true, operation: "Add" })
    }}
        icon={<PlusSquareOutlined style={{ verticalAlign: 'middle', fontSize: '20px' }} />} >
        <span style={{ verticalAlign: 'middle' }} >{Translate("Add")}</span>
    </Button>)

    return (
        <>
            <Tabs
                defaultActiveKey="1"
                tabBarStyle={{ marginBottom: "3.5px" }}
            >
                <Tabs.TabPane tab={<span><ProfileOutlined />{Translate("Clients")}</span>} key="1">
                    <div className="site-card-border-less-wrapper">
                        <div className="d-flex">{header} </div>
                        <Table bordered size="small" columns={columns} dataSource={clients} />
                    </div>
                </Tabs.TabPane>
            </Tabs>

            <Modal
                title={Translate("Client")}
                visible={flags.clientModalVisible}
                onCancel={() => setFlags(({ ...flags, clientModalVisible: false }))}
                onOk={() => { clientForm.submit() }}
                okText={Translate(flags.operation)}
                confirmLoading={flags.loading}
            >

                <Form
                    form={clientForm}
                    id={"clientForm"}
                    layout={"horizontal"}
                    onFinish={addOrUpdateClient}
                    {...formItemLayout}
                    size={"small"}
                    labelAlign={"left"}
                >
                    <Form.Item
                        name={"id"}
                        fieldKey={"id"}
                        hidden={true}
                        initialValue={0} >
                        <Input />
                    </Form.Item>
                    <Form.Item name={"idERP"} fieldKey={"idERP"} hidden={true}  > <Input /> </Form.Item>
                    <Form.Item
                        label={Translate("Code")}
                        name="code"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate("Code")} />
                    </Form.Item>

                    <Form.Item
                        label={Translate("Description")}
                        name="description"
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Input placeholder={Translate("Description")} />
                    </Form.Item>
                    <Form.Item
                        label={Translate("Description2")}
                        name="description2"
                    >
                        <Input placeholder={Translate("Description2")} />
                    </Form.Item>
                    < Form.Item
                        name={"e_InvoicePayer"}
                        label={Translate("EInvoice")}
                        rules={[{ required: true, message: Translate("RequiredField") }]}
                    >
                        <Radio.Group>
                            <Radio value={1}>{Translate("Yes")}</Radio>
                            <Radio value={0}>{Translate("No")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {/* < Form.Item
                        name={"e_IrsaliyeMukellef"}
                        label={Translate("E_IrsaliyeMukellef")}
                        rules={[{ required: true, message: Translate("RequiredField") }]}

                    >
                        <Radio.Group>
                            <Radio value={true}>Evet</Radio>
                            <Radio value={false}>Hayır</Radio>
                        </Radio.Group>
                    </Form.Item> */}
                    <Form.Item
                        label={Translate("IsActive")}
                        name="isActive"
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Checkbox></Checkbox>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                centered
                visible={clientUsersVisible}
                footer={null}
                cancelText={Translate("Close")}
                onCancel={() => setClientUsersVisible(false)} width={1200}>
                <ClientUsers clientId={clientId} />
            </Modal>
            <Modal
                centered
                visible={clientAdressVisible}
                footer={null}
                cancelText={Translate("Close")}
                onCancel={() => setClientAdressVisible(false)} width={1200}>
                <ClientAddresses clientId={clientId} />
            </Modal>
        </>
    );
};
