import { getUserInfo } from "../api_ts/user";
import { CurrencyType } from "../models/models"


export function currencySymbol(currencyType) {
    switch (currencyType) {
        case CurrencyType.tl: return "₺";
        case CurrencyType.TL: return "₺";
        case CurrencyType.USD: return "$";
        case CurrencyType.EURO: return "€";
    }
}


String.prototype.replaceAt = function (index, replacement) {
    return this.substr(0, index) + replacement + this.substr(index + replacement.length);
}

export function getUriParams(value) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(value)
}

//"{data:123}" =>  {data:123} şeklinde dönüştürür.    yada 1234  => 23 şekline dönüştürür.bir string degerin ilk ve son harfini siler.
export function trimFirstAndLastLetter(str) {
    return str.slice(1, -1);
}


// Bir nesnenin JsonString olup olmadıgını kontrol eder
export function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


//Bir String Degerin ilk Harfini Büyük Harf Yapar
export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

//Dizinin İlk elemanını alır
export function first(p) {
    for (let i in p) return p[i];
}

