import { request } from "./request";

export async function getSalesContractByCartId(cartId) {
    return await request(`contracts/getSalesContractByCartId?cartId=${cartId}`, "GET");
}
export async function getPreliminaryInfoFormByCartId(cartId) {
    return await request(`contracts/getPreliminaryInfoFormByCartId?cartId=${cartId}`, "GET");
}


