// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';
import {
    BrowserRouter,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { ScrollContext } from 'react-router-scroll-4';
import { ConfigProvider } from 'antd';
import tr_TR from 'antd/lib/locale/tr_TR';

// application
import languages from '../i18n';
import { localeChange } from '../store/locale';
// pages
import Layout from './Layout';
import HomePageOne from './home/HomePageOne';
import HomePageTwo from './home/HomePageTwo';
import AccountPageLogin from './account/AccountPageLogin';
import * as authenticate from '../api_js/authenticate';
import AccountPageChooseClient from './account/AccountPageChooseClient';


class Root extends Component {
    componentDidMount() {
        // preloader
        setTimeout(() => {
            const preloader = document.querySelector('.site-preloader');

            if (!preloader) return;

            preloader.addEventListener('transitionend', (event) => {
                if (event.propertyName === 'opacity') {
                    preloader.parentNode.removeChild(preloader);
                }
            });
            preloader.classList.add('site-preloader__fade');
        }, 500);

        // this is for demo only, you can delete it
        const { localeChange: changeLocale } = this.props;
        const direction = new URLSearchParams(window.location.search).get('dir');

        if (direction !== null) {
            changeLocale(direction === 'rtl' ? 'ar' : 'en');
        }

    }

    shouldUpdateScroll = (prevRouterProps, { location }) => (
        prevRouterProps && location.pathname !== prevRouterProps.location.pathname
    );

    render() {
        const { locale, ant_locale } = this.props;
        const { messages, direction } = languages[locale];
        return (
            <IntlProvider locale={locale} messages={messages}>
                <ConfigProvider locale={tr_TR}>      {/*ConfigProvider Ants Designer Dil ayarlaması için kullanılır (Language)   */}
                    <BrowserRouter basename={process.env.PUBLIC_URL}>
                        <HelmetProvider>
                            <Helmet htmlAttributes={{ lang: locale, dir: direction }} />
                            <ScrollContext shouldUpdateScroll={this.shouldUpdateScroll}>
                                <Switch>
                                    <Route
                                        path="/account/login"
                                        component={AccountPageLogin}
                                    />
                                    <Route
                                        path="/salesman/choose-client"
                                        render={(props) => (
                                            authenticate.firstStepLoginSalesmanStatus() || authenticate.finallyStepLoginSalesmanStatus()
                                                ? <AccountPageChooseClient /> : <Redirect to="/account/login" />
                                        )}

                                    />
                                    <Route
                                        path="/home-two"
                                        render={(props) => (
                                            authenticate.isUserLogin() ?
                                                < Layout {...props} headerLayout="compact" homeComponent={HomePageTwo} />
                                                : <Redirect to="/account/login" />
                                        )}
                                    />
                                    <Route
                                        path="/"
                                        render={(props) => (
                                            authenticate.isUserLogin() ?
                                                < Layout {...props} headerLayout="compact" homeComponent={HomePageTwo} />
                                                : <Redirect to="/account/login" />
                                        )}
                                    />
                                    <Redirect to="/" />
                                </Switch>
                            </ScrollContext>
                        </HelmetProvider>
                    </BrowserRouter>
                </ConfigProvider>
            </IntlProvider>
        );
    }
}

Root.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    ant_locale: state.ant_locale
});

const mapDispatchToProps = {
    localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
