import * as React from 'react';
import { ClientAddressType, ClientAddress } from '../../../../models/models';
import * as dbClientAddress from '../../../../api_ts/clientAdress';
import { useIntl } from 'react-intl';
import { Translate } from '../../i18n/Translate';
import { Tabs, Table, Input, Button, Tooltip, message, Card, Form } from 'antd';
import { EditOutlined, MailOutlined, UserOutlined, DeleteOutlined, FileDoneOutlined, FileAddTwoTone, LockOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';


interface Props {
    clientId: number;
}
export const ClientAddresses: React.FC<Props> = ({ clientId }) => {

    const intl = useIntl();
    const [clientsAddress, setClientsAddress] = React.useState<ClientAddress[]>([]);
    const [clientsAddressForm] = Form.useForm();
    const formItemLayout = { labelCol: { span: 7 }, wrapperCol: { span: 17 } };
    const [flags, setFlags] = React.useState({ activeTabKey: "1", operation: "Add", title: "", loading: false });

    React.useEffect(() => {
        getAllClientAddresss()
        resetOperation()
    }, [clientId]);


    const resetOperation = () => {
        clientsAddressForm.resetFields();
        setFlags({ ...flags, activeTabKey: "1", operation: "Add" })
    }

    const deleteClientAddress = async (e: number) => {
        const response = await dbClientAddress.deleteClientAddress(e);
        if (response.ok) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            getAllClientAddresss();
            resetOperation()
        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }
    const fillClientAddressForm = async (clientAddress: ClientAddress) => {
        clientsAddressForm.setFieldsValue(clientAddress);
        setFlags({ ...flags, activeTabKey: "2", operation: "Update" })

    }

    const addOrUpdateClientAddress = async (clientAddress: ClientAddress) => {
        let success: boolean = false;
        if (clientAddress.id === 0) {
            success = (await dbClientAddress.add(clientAddress)).ok;
        } else {
            success = (await dbClientAddress.update(clientAddress)).ok;
        }
        if (success) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            getAllClientAddresss();
            resetOperation()
        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }

    const getAllClientAddresss = () => {
        dbClientAddress.getListByClientId(clientId)
            .then(response => {
                if (response.ok) {
                    setClientsAddress(response.data);
                }
                else
                    console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }



    const columns = [
        {
            width: "100px",
            title: Translate("AddressDefinition"),
            dataIndex: 'adressType',
            key: 'id',
            render: (text: string, record: ClientAddress) => {
                return (Translate(ClientAddressType[record.addressType]));
            }
        },
        {
            title: Translate("Address"),
            dataIndex: 'address',
        },
        {
            title: Translate("Address2"),
            dataIndex: 'address2',
        },
        {
            title: Translate("City"),
            dataIndex: 'city',
        },
        {
            title: Translate("Town"),
            dataIndex: 'town',
        },
        {
            title: Translate("TaxOffice"),
            dataIndex: 'taxOffice',
        },
        {
            title: Translate("TaxNumber"),
            dataIndex: 'taxNr',
        },
        {
            key: 'id',
            width: 100,
            render: (text: string, record: ClientAddress) =>
                <>
                    <Tooltip placement="top" color="blue" title={Translate("Edit")}>
                        <Button icon={<EditOutlined />} onClick={() => fillClientAddressForm(record)} />
                    </Tooltip>
                    <Tooltip placement="top" color="red" title={Translate("Delete")}>
                        <Button icon={<DeleteOutlined />} onClick={() => deleteClientAddress(record.id)} danger />
                    </Tooltip>
                </>
        },
    ];

    return (
        <>
            <Tabs defaultActiveKey="1" onTabClick={(e) => setFlags({ ...flags, activeTabKey: e })} activeKey={flags.activeTabKey} >
                <Tabs.TabPane tab={<span><FileDoneOutlined />{Translate("ClientAddresses")}</span>} key="1">
                    <Table
                        bordered
                        size="small"
                        columns={columns}
                        dataSource={clientsAddress}
                        pagination={{ pageSize: 7 }} scroll={{ y: 700 }} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span><FileAddTwoTone />{Translate(flags.operation)}</span>} key="2">
                    <Card
                        bordered={false}
                        size={'small'}
                        style={{ width: "50%" }}
                    >
                        <Form
                            form={clientsAddressForm}
                            id={"clientForm"}
                            layout={"horizontal"}
                            onFinish={addOrUpdateClientAddress}
                            {...formItemLayout}
                            size={"small"}
                            labelAlign={"left"}
                        >
                            <Form.Item
                                name={"id"}
                                hidden={true}
                                initialValue={0} >
                                <Input />
                            </Form.Item>
                            <Form.Item name={"idERP"} fieldKey={"idERP"} hidden={true}  > <Input /> </Form.Item>
                            <Form.Item
                                name={"clientId"}
                                hidden={true}
                                initialValue={clientId} >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={Translate("Address")}
                                name="address"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <TextArea placeholder={Translate("Address")} />
                            </Form.Item>
                            <Form.Item
                                label={Translate("Address2")}
                                name="address2"
                            >
                                <TextArea placeholder={Translate("Address2")} />
                            </Form.Item>
                            <Form.Item
                                label={Translate("City")}
                                name="city"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input placeholder={Translate("City")} />
                            </Form.Item>
                            <Form.Item
                                label={Translate("Town")}
                                name="town"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input placeholder={Translate("Town")} />
                            </Form.Item>
                            < Form.Item
                                name={"taxOffice"}
                                label={Translate("TaxOffice")}
                                rules={[{ required: true, message: Translate("RequiredField") }]}

                            >
                                <Input placeholder={Translate("TaxOffice")} />
                            </Form.Item>
                            <Form.Item
                                label={Translate("TaxNumber")}
                                name="taxNr"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input placeholder={Translate("TaxNumber")} />
                            </Form.Item>

                            <Form.Item {...{ wrapperCol: { offset: 7, span: 17 } }}>
                                <Button type="primary" className="mr-1" htmlType="submit">
                                    {Translate("Save")}
                                </Button>
                                <Button htmlType="button" onClick={resetOperation}>
                                    {Translate("Cancel")}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>


                    {/* <div className="form-group" style={{ marginBottom: '20px' }}>
                        <label>{Translate("YetkiGrubu")}</label>
                        {' '}
                        <Select
                            style={{ width: 200 }}
                            value={clientUser.yetkiGrubu}
                            onChange={value => setClientUser({ ...clientUser, yetkiGrubu: value })}
                        >
                            {Object.keys(CariKullaniciYetkiGrubu).filter(x => !/^\d+$/.test(x)).map(x => (
                                <Select.Option value={CariKullaniciYetkiGrubu[x as any]}>{x}</Select.Option>
                            ))}
                        </Select>
                    </div> */}

                </Tabs.TabPane>
            </Tabs>
        </>
    );
};
