import { VirtualPosParameter } from "../models/models";
import { ApiResponse, request } from "./api";

export async function addOrUpdateVirtualPosParameter(model): Promise<ApiResponse<VirtualPosParameter>> {
    return await request<VirtualPosParameter>("parameters/addOrUpdateVirtualPosParameter","POST",model);
}

export async function getVirtualPosParameter(): Promise<ApiResponse<VirtualPosParameter>> {
    return await request<VirtualPosParameter>("parameters/getVirtualPosParameter","GET");
}

export async function deleteVirtualPosParameter(virtualPos): Promise<ApiResponse<null>> {
    return await request<null>("parameters/deleteVirtualPosParameter","POST",virtualPos);
}