import * as React from 'react';
import { Tag, Table, Button, Modal, Tabs, Tooltip } from 'antd';
import { ProfileOutlined, OneToOneOutlined } from '@ant-design/icons';
import { BackOfficeCartDTO } from '../../../../models/models';
import * as DbSepet from '../../../../api_ts/cart';
import { CartDetail } from './CartDetail'
import Moment from 'react-moment';
import { tableColumnTextFilterConfig } from '../../components/AntHelpers/TableHelpers';
import { useIntl } from 'react-intl';
import { Translate } from '../../i18n/Translate';

const { TabPane } = Tabs;

export const Carts: React.FC = () => {
    const intl = useIntl();
    const [carts, setCarts] = React.useState<BackOfficeCartDTO[]>([]);
    const [flag, setFlag] = React.useState({ loading: false, cartDetailModalVisible: false });
    const [cartId, setCartId] = React.useState<number>(0);

    const handleCartDetail = async (itemCartId: number) => {
        setFlag(flag => ({ ...flag, cartDetailModalVisible: true }));
        setCartId(itemCartId);
    }

    const getAllCart = () => {
        DbSepet.getAllClientCarts()
            .then(response => {
                if (response.ok) {
                    setCarts(response.data);
                }
                else
                    console.log(response.message)
            })
            .catch(err => console.log(err.message));
    }

    React.useEffect(getAllCart, []);

    const columns = [
        {
            title: Translate("ClientCode"),
            dataIndex: "clientCode",                     //  dataIndex: ['client', 'kod'] modelin içerisinde deger almak için
            ...tableColumnTextFilterConfig<BackOfficeCartDTO>(),
            onFilter: (value: any, cart: BackOfficeCartDTO) => cart.clientCode.toString().toLowerCase().includes(value.toString().toLowerCase())
        },
        {
            title: Translate("Client"),
            dataIndex: "clientName",
            ...tableColumnTextFilterConfig<BackOfficeCartDTO>(),
            onFilter: (value: any, cart: BackOfficeCartDTO) => cart.clientName.toString().toLowerCase().includes(value.toString().toLowerCase())
        },
        {
            title: Translate("Date"),
            dataIndex: 'createDate',
            render: (text: string, cart: BackOfficeCartDTO) => <Moment format="DD.MM.YYYY HH:mm">{cart.createDate}</Moment>,
        },
        {
            title: Translate("Username"),
            dataIndex: "user",
            ...tableColumnTextFilterConfig<BackOfficeCartDTO>(),
            onFilter: (value: any, cart: BackOfficeCartDTO) => {
                if (cart.clientUserFullName === "") {  //TODO type Script'de String.Empty oluşturulacak . TsCore adında bir dosya yap global orada yardımcı methodları tut
                    return cart.salesmanFullName.toLowerCase().includes(value.toString().toLowerCase())
                } else {
                    return cart.clientUserFullName.toLowerCase().includes(value.toString().toLowerCase())
                }
            },
            render: (text: string, cart: BackOfficeCartDTO) => {
                if (cart.clientUserFullName === "") {
                    return <span><Tooltip placement="top" color="green" title={Translate("Salesman")}><Tag color={'green'}> {'SE'}</Tag>{`${cart.salesmanFullName}`}</Tooltip>
                    </span>
                } else if (cart.salesmanFullName === "") {
                    return <span><Tooltip placement="top" color="green" title={Translate("Salesman")}><Tag color={'blue'}> {'CE'}</Tag>{`${cart.clientUserFullName}`}</Tooltip>
                    </span>
                }
            }
        },
        {
            title: Translate("Operations"),
            key: 'id',
            width: 100,
            render: (text: string, cart: BackOfficeCartDTO) =>
                <>
                    <Tooltip placement="top" color="blue" title={Translate("ItemsList")}>
                        <Button icon={<OneToOneOutlined />} onClick={() => handleCartDetail(cart.id)} />
                    </Tooltip>
                </>
        },
    ];


    return (
        <>
            <Tabs defaultActiveKey="1">
                <TabPane tab={<span><ProfileOutlined />{Translate("Carts")}</span>} key="1">
                    <div className="site-card-border-less-wrapper">
                        <Table columns={columns} dataSource={carts} />
                    </div>
                </TabPane>
            </Tabs>
            <Modal
                centered
                visible={flag.cartDetailModalVisible}
                okButtonProps={{ style: { display: 'none' } }}
                cancelText={Translate('Close')}
                onCancel={() => setFlag(flag => ({ ...flag, cartDetailModalVisible: false }))} width={1000}>
                <CartDetail cartId={cartId} />
            </Modal>
        </>
    );
};
