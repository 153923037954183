import React from 'react';
import { useIntl } from 'react-intl';
import * as DbClient from '../../../../api_ts/client';
import * as dbSalesman from '../../../../api_ts/salesman';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Client, ISalesman } from '../../../../models/models';
import { Button, Tabs, Table, message, Tooltip, Divider, Checkbox } from 'antd';
import { AppstoreAddOutlined, ProfileOutlined, FileAddOutlined, DeleteOutlined } from '@ant-design/icons';
import { tableColumnTextFilterConfig } from '../../components/AntHelpers/TableHelpers';
import { Translate } from '../../i18n/Translate';

const { TabPane } = Tabs;

interface Props {
    salesman: ISalesman;
    changeAllClientsPermission: (e: CheckboxChangeEvent) => Promise<void>
}

export const SalesmanClients: React.FC<Props> = ({ salesman, changeAllClientsPermission }) => {
    const intl = useIntl();
    const [salesmanClients, setSalesmanClients] = React.useState<Client[]>([]);
    const [clients, setClients] = React.useState<Client[]>([]);


    React.useEffect(() => {
        getClientsBySalesmanId()
    }, [salesman]);

    const assignClientToSalesman = async (clientId: number) => {
        debugger;
        const response = await dbSalesman.assignClientToSalesman(salesman.id, clientId);
        if (response.ok) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }

    const deleteClientToSalesman = async (clientId: number) => {

        const response = await dbSalesman.deleteClientToSalesman(salesman.id, clientId);
        if (response.ok) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            getClientsBySalesmanId();
        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }


    const columns = [
        {
            title: Translate("Code"),
            dataIndex: 'code',
            render: (text: string) => text,
            ...tableColumnTextFilterConfig<Client>(),
            onFilter: (value: any, cari: Client) => cari.code.toString().toLowerCase().includes(value.toString().toLowerCase())
        },
        {
            title: Translate("Aciklama"),
            dataIndex: 'description',
            render: (text: string) => text,
            ...tableColumnTextFilterConfig<Client>(),
            onFilter: (value: any, cari: Client) => cari.description.toString().toLowerCase().includes(value.toString().toLowerCase())
        },
        {
            title: Translate("Aciklama"),
            dataIndex: 'description2',
            render: (text: string) => text,
            ...tableColumnTextFilterConfig<Client>(),
            onFilter: (value: any, cari: Client) => cari.description2.toString().toLowerCase().includes(value.toString().toLowerCase())
        },
        {
            key: 'id',
            render: (text: string, cari: Client) =>
                <Tooltip placement="top" color="red" title={Translate("Delete")}>
                    <Button icon={<DeleteOutlined />} onClick={() => deleteClientToSalesman(cari.id)} />
                </Tooltip>
        },
    ];

    const column = [
        {
            width: "15%",
            title: Translate("Code"),
            dataIndex: 'code',
            ...tableColumnTextFilterConfig<Client>(),
            onFilter: (value: any, item: Client) => item.code.toString().toLowerCase().includes(value.toString().toLowerCase())
        },
        {
            width: "50%",
            title: Translate("Aciklama"),
            dataIndex: 'description',
            ...tableColumnTextFilterConfig<Client>(),
            onFilter: (value: any, item: Client) => item.description.toString().toLowerCase().includes(value.toString().toLowerCase())
        },
        {
            width: "30%",
            title: Translate("Aciklama-2"),
            dataIndex: 'descriptioon2',
            ...tableColumnTextFilterConfig<Client>(),
            onFilter: (value: any, item: Client) => item.description2.toString().toLowerCase().includes(value.toString().toLowerCase())
        },
        {
            key: 'id',
            width: "5%",
            render: (text: string, cari: Client) =>
                <Tooltip placement="top" color="blue" title={Translate("AddClient")}>
                    <Button icon={<AppstoreAddOutlined />} onClick={() => assignClientToSalesman(cari.id)} />
                </Tooltip>
        },
    ];

    const getClientsBySalesmanId = () => {
        dbSalesman.getClientsBySalesmanId(salesman.id)
            .then(response => {
                if (response.ok)
                    setSalesmanClients(response.data);
                else alert(response.message);
            })
            .catch(err => alert(err.message));
    }

    const getAllClients = () => {
        return DbClient.getList()
            .then(response => {
                if (response.ok)
                    setClients(response.data);
                else alert(response.message);
            })
            .catch(err => alert(err.message));
    }


    // React.useEffect(getAllClient, [salesmanId]);
    const tabChange = (activityKey: string) => {
        switch (activityKey) {
            case "Tab1": getClientsBySalesmanId(); break;
            case "Tab2": getAllClients(); break;
            default:
                break;
        }
    }

    return (
        <>

            <Tabs defaultActiveKey="1" onChange={tabChange} >
                <TabPane tab={<span><ProfileOutlined />{Translate("SalesmanClients")}</span>} key="Tab1">
                    <Checkbox
                        checked={salesman?.allClientAuth ?? false}
                        onChange={(e) => {
                            console.log(e)
                            debugger;
                            changeAllClientsPermission(e)
                        }}
                    >
                        {Translate("AllClientAuth")}
                    </Checkbox>
                    {!salesman.allClientAuth &&
                        <>
                            <Divider />
                            <Table bordered size="small" columns={columns} dataSource={salesmanClients} />
                        </>
                    }
                </TabPane>
                <TabPane tab={<span><FileAddOutlined />{Translate("AssignClientToSalesman")}</span>} key="Tab2">

                    <Table bordered size="small" columns={column} dataSource={clients} />

                </TabPane>
            </Tabs>

        </>
    );
};
