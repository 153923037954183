import * as React from 'react';
import { Tabs, Card, Switch, Checkbox, Button, message, Form, Row, Col, Space, Select, InputNumber, Spin, Layout, Descriptions, } from 'antd';
import { DatabaseOutlined, SwapOutlined } from '@ant-design/icons';
import * as DbEntegrasyon from '../../../../api_ts/IntegrationInfo';
import * as DbLogoMalzemeFiyat from '../../../../api_ts/logoItemPrice';
import * as dbErp from '../../../../api_ts/erp';
import { Erp, IntegrationHistory, IntegrationFilter, LG_FFF_SPECODES, PRCLIST_PTYPE } from '../../../../models/models';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { Translate } from '../../i18n/Translate';



const { Content } = Layout;
const { Option } = Select;
interface Flag { loading?: boolean, isDefinedPeriod?: boolean }
interface SwitchKey { item: boolean, itemPriceCard: boolean, currents: boolean };
interface FormFilter {
    isActiveItem: boolean,
    isActiveItemPriceCard: boolean,
    isActiveCurrent: boolean,
    itemGroupColumnName: string,
    itemPriceGroupCode: string[],
    e_JobItem: number,
    e_JobItemPriceCard: number,
    e_JobCurrent: number,
    startPriorityNr: number,
    endPriorityNr: number,
    startOrderNr: number,
    endOrderNr: number,
    salesmanGroupCode: string
};
export const LogoIntegration: React.FC = () => {
    const intl = useIntl();
    const [integrationForm] = Form.useForm();
    const [flag, setFlag] = React.useState<Flag>({ loading: false, isDefinedPeriod: false });
    const [itemGroupCodes, setItemGroupCodes] = React.useState<string[]>([]);
    const [salesmanGroupCodes, setSalesmanGroupCodes] = React.useState<string[]>([]);
    const [itemPriceGroupCodes, setItemPriceGroupCodes] = React.useState<LG_FFF_SPECODES[]>([]);
    const [switchKey, setSwitchKey] = React.useState<SwitchKey>({ item: true, itemPriceCard: true, currents: true });
    const [donemErp, setDonemErp] = React.useState<Erp>({});


    useEffect(() => {
        getErp();
        getParametreEntegrasyonHistory();
        fillGroupCodes();
    }, []);

    const getParametreEntegrasyonHistory = () => {
        DbEntegrasyon.getEntegrasyonHistory()
            .then(response => {
                if (response.ok) {
                    history(response.data)
                }
                else {
                    console.log(response.message)
                }
            }).catch(err => console.log(err.message))
    }

    const history = (data: IntegrationHistory) => {
        if (data.itemPriceGroupCode == "" || data.itemPriceGroupCode == null) {
            data.itemPriceGroupCode = undefined;
        } else {
            data.itemPriceGroupCode = (data.itemPriceGroupCode as string).split(",");
        }
        integrationForm.setFieldsValue(data);
        setSwitchKey({ item: data.isSubmitItem, itemPriceCard: data.isSubmitItemPrice, currents: data.isSubmitCurrent })
    }

    const getErp = () => {
        dbErp.getByFirmAndPeriodNumber()
            .then(response => {
                if (response.ok) {
                    if (response.data != null) {
                        setDonemErp(response.data);
                        setFlag(flag => ({ ...flag, isDefinedPeriod: true }));
                        getItemPriceGroupCode(response.data);
                    } else {
                        setFlag(flag => ({ ...flag, isDefinedPeriod: false }));
                    }
                }
                else {
                    console.log(response.message);
                }
            })
            .catch(err => console.log(err.message));
    }

    const getItemPriceGroupCode = (erp: Erp) => {
        DbLogoMalzemeFiyat.logoMalzemeFiyatGrupKodGetir(erp)
            .then(response => {
                if (response.ok) {
                    setItemPriceGroupCodes(response.data);
                }
                else
                    console.log(response.message);
            }).catch(err => console.log(err.message))
    }

    const changeSwitchKey = (switchKey: SwitchKey, fields: string[]) => {
        setSwitchKey(switchKey)
    }

    const selectAllMultiSelect = (e: any) => {
        if (e.target.checked) {
            integrationForm.setFieldsValue({
                itemPriceGroupCode: itemPriceGroupCodes.map(x => x.specode)
            })
        } else {
            integrationForm.setFieldsValue({
                itemPriceGroupCode: []
            })
        }
    }

    const fillGroupCodes = () => {
        var itemGroupCode: string[] = ["Ozel Kod 1", "Ozel Kod 2", "Ozel Kod 3", "Ozel Kod 4", "Ozel Kod 5", "Grup Kod"];
        var salesmanGroupCode: string[] = ["Ozel Kod 1", "Ozel Kod 2", "Ozel Kod 3", "Ozel Kod 4", "Ozel Kod 5"];
        setItemGroupCodes(itemGroupCode)
        setSalesmanGroupCodes(salesmanGroupCode);
    }

    const submitFirmForm = () => {
        if (flag.isDefinedPeriod) {
            integrationForm.submit();
        } else {
            message.warning("Lütfen Erp Bilgisi Giriniz");
        }
    }

    const submitFilter = (form: FormFilter) => {
        if ((switchKey.item || switchKey.itemPriceCard || switchKey.currents) == false) return;
        setFlag(flag => ({ ...flag, loading: true }));
        const integrationFilter: IntegrationFilter = {
            itemFilter: {
                itemGroupColumnName: form.itemGroupColumnName,
                EXTACCESSFLAGS: form.e_JobItem ? 1 : 0,  //ToDo:Buradaki Sıfır Hem Erişebilir ve hemde Erişilemeyen için yapılmıştır Düzeltilecek
                submit: switchKey.item
            },
            itemPriceCardFilter: {
                groupCodes: form.itemPriceGroupCode?.map((x: string) => x), //All degeri Tümü güncelle demektir.
                startPriortyNr: form.startPriorityNr,
                endPriortyNr: form.endPriorityNr,
                startOrderNr: form.startOrderNr,
                endOrderNr: form.endOrderNr,
                EXTACCESSFLAGS: form.e_JobItemPriceCard ? 1 : 0,
                submit: switchKey.itemPriceCard,
                PRCLIST_PTYPE: PRCLIST_PTYPE.SatisFiyatKart
            },
            currentFilter: {
                EXTACCESSFLAGS: form.e_JobCurrent ? 1 : 0,
                submit: switchKey.currents,
                salesmanGroupCode: form.salesmanGroupCode
            },
        }
        logoIntegration(integrationFilter);

    }

    const logoIntegration = (filter: IntegrationFilter) => {
        DbEntegrasyon.logoIntegration(donemErp, filter)
            .then(response => {
                if (response.ok) {
                    message.success(intl.formatMessage({ id: 'Operation.Success' }));
                    // downloadTxtFile();
                }
                else {
                    console.log(response.message);
                }
                setFlag(flag => ({ ...flag, loading: false }));

            }).catch(err => console.log(err.message))
    }

    const downloadTxtFile = () => {

        // fetch('../../../../modules/BackOffice/views/LgIntegration/abc.txt')
        //     .then(response => response.text())
        //     .then(text => console.log(text))

        // var element = document.createElement('a');
        // element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent("file:///D:/abc.txt"));
        // element.setAttribute('download', "y");
        // element.style.display = 'none';
        // document.body.appendChild(element);

        // element.click();

    }


    return (
        <div>
            <Tabs>
                <Tabs.TabPane key="1" tab={<span><DatabaseOutlined />{Translate("Integration")}</span>}>
                    <Spin tip={Translate('Loading')} size={'large'} spinning={flag.loading}>
                        <Content>
                            <Row style={{ alignItems: "center", justifyContent: "center" }}>
                                <Col span={5}>
                                    <Descriptions style={{ width: '300px' }} layout={"horizontal"} title="Erp Bilgisi">
                                        <Descriptions.Item label={Translate("FirmNo")}>{donemErp?.firmNumber}</Descriptions.Item>
                                        <Descriptions.Item label={Translate("PeriodNo")}>{donemErp?.periodNumber}</Descriptions.Item>
                                    </Descriptions>
                                </Col>
                                <Col style={{ textAlign: "end" }} offset={14} span={5}>
                                    <Button size={'large'} type="primary" onClick={submitFirmForm} icon={<SwapOutlined style={{ verticalAlign: 'middle', fontSize: '20px' }} />} >
                                        <span style={{ verticalAlign: 'middle' }} >{Translate("Update")}</span>
                                    </Button>
                                </Col>
                            </Row>
                        </Content>
                        <Content >
                            <Space direction={"vertical"} size={"middle"} style={{ width: "100%" }}  >
                                <Form
                                    layout="vertical"
                                    form={integrationForm}
                                    onFinish={submitFilter}
                                >
                                    <Row>
                                        <Col span={8}>
                                            <Card title={<span><Switch checked={switchKey.item} onChange={() => changeSwitchKey({ ...switchKey, item: !switchKey.item }, [
                                                "itemGroupColumnName", "e_JobItem"])} /> {Translate("Items")}</span>}  >
                                                <Form.Item
                                                    name={"itemGroupColumnName"}
                                                    fieldKey={"itemGroupColumnName"}
                                                    rules={[{ required: switchKey.item, message: Translate("RequiredField") }]}
                                                    label={Translate("CategoryGroup")}
                                                >
                                                    <Select

                                                        placeholder={Translate("PleaseSelect")}
                                                        disabled={!switchKey.item}
                                                    >
                                                        {itemGroupCodes.map((group, index) => (
                                                            <Option key={index} value={group}>{`${group}`}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    name={"e_JobItem"}
                                                    fieldKey={"e_JobItem"}
                                                    valuePropName="checked"
                                                    initialValue={false}
                                                >
                                                    <Checkbox disabled={!switchKey.item}>{Translate("E-Job-Access")}</Checkbox>
                                                </Form.Item>
                                            </Card>
                                        </Col>
                                        <Col span={8}>
                                            <Card title={<span><Switch checked={switchKey.itemPriceCard} onChange={() => changeSwitchKey({ ...switchKey, itemPriceCard: !switchKey.itemPriceCard }, ["isActiveItemPriceCard", "itemPriceGroupCode", "e_JobItemPriceCard", "startPriorityNr", "endPriorityNr", "startOrderNr", "endOrderNr"])} /> {Translate("ItemPriceCards")}</span>}  >

                                                <Form.Item
                                                    name={"itemPriceGroupCode"}
                                                    fieldKey={"itemPriceGroupCode"}
                                                    label={<span><Space>{Translate("GroupCode")}<Checkbox name={"AllMultiSelect"} onChange={selectAllMultiSelect} disabled={!switchKey.itemPriceCard} /></Space></span>}
                                                    rules={[{ required: switchKey.itemPriceCard, message: Translate("RequiredField") }]}
                                                >
                                                    <Select
                                                        mode="multiple"
                                                        style={{ width: '100%' }}
                                                        placeholder={Translate("PleaseSelect")}
                                                        disabled={!switchKey.itemPriceCard}
                                                    >
                                                        {itemPriceGroupCodes && itemPriceGroupCodes.map(groupCode => (
                                                            <Option key={groupCode.logicalref} value={groupCode.specode}>{`${groupCode.specode} -- ${groupCode.definitioN_}`}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <label>{Translate("PriorityRange")}</label>
                                                <   Row>
                                                    <Col span={5}>
                                                        <Form.Item
                                                            name="startPriorityNr"
                                                            initialValue={0}
                                                            rules={[{ required: switchKey.itemPriceCard, message: Translate("RequiredField") }]}

                                                        >
                                                            <InputNumber disabled={!switchKey.itemPriceCard} min={0} max={999} placeholder={Translate('Start')} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col offset={1} span={5}>
                                                        <Form.Item
                                                            initialValue={999}
                                                            name="endPriorityNr"
                                                            rules={[{ required: switchKey.itemPriceCard, message: Translate("RequiredField") }]}
                                                        >
                                                            <InputNumber disabled={!switchKey.itemPriceCard} min={0} max={999} placeholder={Translate('End')} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row >
                                                <label>{Translate("SequenceNumber")}</label>
                                                <Row>
                                                    <Col span={5}>
                                                        <Form.Item
                                                            name="startOrderNr"
                                                            initialValue={0}
                                                            rules={[{ required: switchKey.itemPriceCard, message: Translate("RequiredField") }]}
                                                        >
                                                            <InputNumber disabled={!switchKey.itemPriceCard} min={0} max={999} placeholder={Translate('Start')} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col offset={1} span={5}>

                                                        <Form.Item
                                                            name="endOrderNr"
                                                            initialValue={999}
                                                            rules={[{ required: switchKey.itemPriceCard, message: Translate("RequiredField") }]}
                                                        >
                                                            <InputNumber disabled={!switchKey.itemPriceCard} min={0} max={999} placeholder={Translate('End')} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row >
                                                <Form.Item
                                                    name={"e_JobItemPriceCard"}
                                                    fieldKey={"e_JobItemPriceCard"}
                                                    valuePropName="checked"
                                                    initialValue={false}
                                                >
                                                    <Checkbox disabled={!switchKey.itemPriceCard}>{Translate("E-Job-Access")}</Checkbox>
                                                </Form.Item>
                                            </Card>
                                        </Col >
                                        <Col span={8}>
                                            <Card title={<span><Switch checked={switchKey.currents} onChange={() => changeSwitchKey({ ...switchKey, currents: !switchKey.currents }, ["salesmanGroupCode", "e_JobCurrent"])} /> {Translate("Clients")}</span>}  >

                                                <Form.Item
                                                    name={"salesmanGroupCode"}
                                                    fieldKey={"salesmanGroupCode"}
                                                    rules={[{ required: switchKey.currents, message: Translate("RequiredField") }]}
                                                    label={Translate("SalesmanGroup")}
                                                >
                                                    <Select
                                                        placeholder={Translate("PleaseSelect")}
                                                        disabled={!switchKey.currents}
                                                    >
                                                        {salesmanGroupCodes.map((group, index) => (
                                                            <Option key={index} value={group}>{`${group}`}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    name={"e_JobCurrent"}
                                                    fieldKey={"e_JobCurrent"}
                                                    valuePropName="checked"
                                                    initialValue={false}
                                                >
                                                    <Checkbox disabled={!switchKey.currents}>{Translate("E-Job-Access")}</Checkbox>
                                                </Form.Item>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Form>
                            </  Space>
                        </Content>
                    </Spin>
                </Tabs.TabPane>
            </Tabs >

        </div >
    );

}
