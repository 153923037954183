import { LOCALE_CHANGE } from "./localeActionTypes";
import * as DbDonem from "../../../../api_ts/firmAndPeriod";


const defaultLanguage = DbDonem.parametreDonemAlLocalStorage().defaultLanguage;


let initialState = "tr";

if (defaultLanguage == "ENGLISH") initialState = "en";

export default function localeReducer(state = initialState, action) {
    if (action.type === LOCALE_CHANGE) {
        return action.locale;
    }
    return state;
}


