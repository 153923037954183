import { Tabs, Table, Card, Input, Button, Tooltip, message, Form, Popconfirm, Select, Modal } from 'antd';
import { PlusSquareOutlined, UserOutlined, DeleteOutlined } from '@ant-design/icons';
import * as DbGrupKodu from '../../../../api_ts/groupCode';
import * as Icons from '@ant-design/icons';
import * as React from 'react';
import { GroupCode } from '../../../../models/models';
import { useIntl } from 'react-intl';
import { tableColumnTextFilterConfig } from '../../components/AntHelpers/TableHelpers';
import { Translate } from '../../i18n/Translate';


export const GroupCodes: React.FC = () => {

    const intl = useIntl();
    const [groupCodeForm] = Form.useForm();
    const [groupCodes, setGroupCodes] = React.useState<GroupCode[]>([]);
    const [flags, setFlags] = React.useState({ operation: "", title: "", groupCodeModalVisible: false, loading: false });

    React.useEffect(() => { getAllGroupCodes() }, []);

    const getAllGroupCodes = async () => {
        return DbGrupKodu.getList()
            .then(response => {
                if (response.ok)
                    setGroupCodes(response.data);
                else
                    console.log(response.message)
            })
            .catch(err => console.log(err.message));
    }

    const deleteGroupCode = async (groupCodeId: number) => {
        let list = groupCodes.filter(x => x.parentGroupCodeId == groupCodeId)
        if (list.length == 0) {
            const response = await DbGrupKodu.deleteGroupCode(groupCodeId);
            if (response.ok) {
                message.success(intl.formatMessage({ id: 'Operation.Success' }));
                getAllGroupCodes();
            } else {
                message.error(intl.formatMessage({ id: 'Operation.Error' }));
            }
        } else {
            message.warning("Üst Grup Kodu Silinemez !! Bagimli Kayitlari Siliniz");
        }
    }

    const reset = () => {
        setFlags({ ...flags, groupCodeModalVisible: false, loading: false })
        groupCodeForm.resetFields();
        getAllGroupCodes();
    }

    const addOrUpdateGroupCode = async (grupKod: GroupCode) => { //Todo Kod Düzeltilebilir 

        if (grupKod.id == 0) {
            if ((await DbGrupKodu.add(grupKod)).ok) {
                message.success(intl.formatMessage({ id: 'Operation.Success' }));
                reset();
            } else {
                message.error(intl.formatMessage({ id: 'Operation.Error' }));
            }
        } else {
            if ((await DbGrupKodu.update(grupKod)).ok) {
                message.success(intl.formatMessage({ id: 'Operation.Success' }));
                reset();
            } else {
                message.error(intl.formatMessage({ id: 'Operation.Error' }));
            }
        }
    }

    const fillGroupCodeForm = (grupKod: GroupCode) => {
        groupCodeForm.setFieldsValue(grupKod);
        setFlags({
            ...flags, operation: "Update", groupCodeModalVisible: true, title: 'Update'
        });
    }



    const columns = [
        {
            title: Translate("Code"),
            width: '40%',
            dataIndex: 'code',
            ...tableColumnTextFilterConfig<GroupCode>(),
            onFilter: (value: any, item: GroupCode) => item.code.toString().toLowerCase().includes(value.toString().toLowerCase())

        },
        {
            title: Translate("GroupCodeName"),
            width: '40%',
            dataIndex: 'description',
            ...tableColumnTextFilterConfig<GroupCode>(),
            onFilter: (value: any, item: GroupCode) => item.description.toString().toLowerCase().includes(value.toString().toLowerCase())
        },
        {
            title: Translate("Operations"),
            key: 'id',
            width: '20%',
            render: (text: string, groupCode: GroupCode) =>
                <>
                    <Tooltip placement="top" color="blue" title={Translate("Edit")}>
                        <Button className="units_button" icon={<Icons.EditOutlined />} onClick={() => fillGroupCodeForm(groupCode)} />
                    </Tooltip>
                    <Tooltip placement="top" color="red" title={Translate("Delete")}>
                        <Popconfirm
                            title="Silmek İstediğinize Emin Misiniz ?"
                            onConfirm={() => { deleteGroupCode(groupCode.id) }}
                            okText={Translate("Yes")}
                            cancelText={Translate("No")}
                        >
                            <Button className="units_button" icon={<DeleteOutlined />}></Button>
                        </Popconfirm>
                    </Tooltip>
                </>
        },
    ];

    const isDublicateGroupCodeValidator = (_: any, value: string): any => {
        let groupCodeList = groupCodes.filter(x => x.code.toLocaleLowerCase() == value.toLocaleLowerCase())
        if (groupCodeList.length > 0 && groupCodeList[0]["id"] != groupCodeForm.getFieldValue("id")) {
            return Promise.reject(new Error("Mükerrer Kayit"));
        } else {
            return Promise.resolve();
        }
    }

    const header = (<Button type="primary" className="ml-auto mb-1" onClick={() => {
        groupCodeForm.resetFields()
        setFlags({ ...flags, groupCodeModalVisible: true, operation: "Add" })
    }}
        icon={<PlusSquareOutlined style={{ verticalAlign: 'middle', fontSize: '20px' }} />} >
        <span style={{ verticalAlign: 'middle' }} >{Translate("Add")}</span>
    </Button>)

    return (<>
        <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab={<span><Icons.ProfileOutlined />{Translate("GroupCode")}</span>} key="1">
                <div className="site-card-border-less-wrapper">
                    <div className="d-flex">{header} </div>
                    <Table bordered size="small" columns={columns} dataSource={groupCodes} />
                </div>
            </Tabs.TabPane>
        </Tabs >


        <Modal
            title={Translate(flags.title)}
            visible={flags.groupCodeModalVisible}
            onCancel={reset}
            onOk={() => { groupCodeForm.submit() }}

        >
            <Form
                form={groupCodeForm}
                layout={"vertical"}
                onFinish={addOrUpdateGroupCode}
            >
                <Form.Item
                    name={"id"}
                    fieldKey={"id"}
                    hidden={true}
                    initialValue={0}
                >
                </Form.Item>
                <Form.Item
                    label={Translate("Code")}
                    name="code"
                    rules={[
                        { required: true, message: Translate("RequiredField") },
                        { validator: isDublicateGroupCodeValidator }
                    ]}

                >
                    <Input placeholder={Translate('Code')} prefix={<UserOutlined />} />
                </Form.Item>
                <Form.Item
                    label={Translate("GroupCodeName")}
                    name="description"
                    rules={[
                        { required: true, message: Translate("RequiredField") },
                    ]}

                >
                    <Input placeholder={Translate('GroupCodeName')} prefix={<UserOutlined />} />
                </Form.Item>
                <Form.Item
                    label={Translate("ParentGroupCode")}
                    name="parentGroupCodeId"
                >
                    <Select style={{ width: '100%' }} placeholder={Translate('ParentGroupCode')} >
                        {groupCodes.map(x => <Select.Option value={x.id} key={x.id}>{x.description}</Select.Option>)}
                    </Select>
                </Form.Item>
            </Form>
        </Modal >

    </>);
};
