import { Warehouse } from "../models/models";
import { ApiResponse, request } from "./api";

export async function getList(): Promise<ApiResponse<Warehouse[]>> {
    return await request<Warehouse[]>("warehouses/getList");
}
export async function add(model: Warehouse): Promise<ApiResponse<Warehouse>> {
    return await request<Warehouse>(`warehouses/add`, "POST", model);
}
export async function deleteWarehouse(id: number): Promise<ApiResponse<Warehouse>> {
    return await request<Warehouse>(`warehouses/${id}`, "DELETE");
}
export async function update(model: Warehouse): Promise<ApiResponse<Warehouse>> {
    return await request<Warehouse>("warehouses/update", "POST", model);
}
export async function getListByIsActive(status: boolean): Promise<ApiResponse<Warehouse[]>> {
    return await request<Warehouse[]>(`warehouses/getListByStatus?isActive=${status}`, "GET");
}
export async function updateB2bStatus(models:Warehouse): Promise<ApiResponse<null>> {
    return await request<null>("warehouses/updateB2bStatus", "POST", models);
}
export async function updateRangeB2bStatus(models: Warehouse[]): Promise<ApiResponse<null>> {
    return await request<null>("warehouses/updateRangeB2bStatus", "POST", models);
}
