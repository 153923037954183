import { getAllSalesParameters as getAllSalesParameters_API } from '../../api_js/parameters';
import { GET_SALES_PARAMETERS } from './parameterActionTypes';


export const salesParameter = (salesParameter) => ({
    type: GET_SALES_PARAMETERS,
    salesParameter,
});


export const getAllSalesParameters = () => {
    return (dispatch) => getAllSalesParameters_API()
        .then(response => {
            if (response.ok) {
                dispatch(salesParameter(response.data));
            } else {
                console.log(response.message);
            }
        })
        .catch(err => console.log(err));
}