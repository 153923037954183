import { UnitSet } from "../models/models";
import { ApiResponse, request } from "./api";

export async function getList(): Promise<ApiResponse<UnitSet[]>> {
    return await request<UnitSet[]>("unitSets/getList");
}
export async function add( unitSet: UnitSet): Promise<ApiResponse<UnitSet>> {
    return await request<UnitSet>(`unitSets/add`, "POST", unitSet);
}

export async function update(unitSet: UnitSet): Promise<ApiResponse<UnitSet>> {
    return await request<UnitSet>(`unitSets/update`, "PATCH", unitSet);
}
export async function deleteUnitSet(id: number): Promise<ApiResponse<null>> {
    return await request<null>(`unitSets/delete/${id}`, "DELETE");
}

