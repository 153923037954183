import {  ImageFile, Item } from "../models/models";
import { ApiResponse, request } from "./api";

export async function getListWithWarehouse(): Promise<ApiResponse<any[]>> {
    return await request<any[]>("items/getListWithWarehouse");
}

export async function add(model: Item): Promise<ApiResponse<Item>> {
    return await request<Item>("items/add", "POST", model);
}

export async function update(model: Item): Promise<ApiResponse<null>> {
    return await request<null>(`items/update`, "POST", model);
}

export async function deleteItem(id: number): Promise<ApiResponse<null>> {
    return await request<null>(`items/delete/${id}`, "DELETE");
}



export async function malzemeResimleriniKaydet(malzemeId: number,file: File): Promise<Response> {
    const data = new FormData();
    data.append("file", file);

    return await fetch(`/api/items/${malzemeId}/resim`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: data,
    });
}
export async function getItemImages(malzemeId: number): Promise<ApiResponse<ImageFile[]>> {
    const uri = `items/${malzemeId}/resim`;
    return request<ImageFile[]>(uri);
}
export async function deleteItemImage(malzemeId: number,resimId: number): Promise<ApiResponse<null>> {
    return await request<null>( `items/${malzemeId}/resim/${resimId}`, "DELETE"
    );
}