import { FormattedMessage, useIntl } from 'react-intl';
import AsyncAction from './AsyncAction';
import classNames from 'classnames';
import React from 'react'
import { connect } from 'react-redux';
import { addToCart } from '../../store/cart';

 function AddToCartButton(props) {
        const intl = useIntl();
        const {
            product,
            quantity,
            addToCart,
            className,
            salesParameter,
            disabled
        } = props;

        //TODO Product Quantity'yi tek Bir properti'de halledebilirdiniz iki defa sorgu yapıyorsun
        const message = intl.formatMessage({ id: 'shop.addedToCart' },{ product: product.name })

       const  stoctControl=()=>{
            if(salesParameter.orderOutOfStockItem) return true;
            if(product.quantity > 0) return true
            if(salesParameter.orderOutOfStockItem == false  && product.quantity <= 0) return false;
        }

        return (
            <AsyncAction
            action={() => addToCart(product, [], quantity,message)}
               render={({ run, loading }) => (
                       <React.Fragment>
                           <button
                               type="button"
                               disabled={disabled}
                               onClick={stoctControl()  && run}
                               className={classNames(`btn btn-primary ${className} product-card__addtocart`, {
                                   'btn-loading': loading,
                               })}
                           >
                               <FormattedMessage id={stoctControl()? "shop.addToCart":"outOfStock"} />
                           </button>
                       </React.Fragment>
                   )}          
               />
        )
    }

const mapStateToProps = (state) => ({
    salesParameter: state.parameter,
}); 

const mapDispatchToProps = {
    addToCart
};

export default connect(mapStateToProps,mapDispatchToProps)(AddToCartButton);
