import * as React from 'react';
import { useIntl } from 'react-intl';
import { Button, message, InputNumber, Table } from 'antd';
import { ItemWarehouse } from '../../../../../models/models';
import * as DbItemAmount from '../../../../../api_ts/itemWarehouse';
import './ItemAmounts.css';
import { Translate } from '../../../i18n/Translate';

interface Props {
    malzemeId: number;
    onChange?: (malzemeDepolar: ItemWarehouse[]) => void;
}

export const ItemAmounts: React.FC<Props> = ({ malzemeId, onChange }) => {
    const intl = useIntl();
    const [malzemeDepolar, setMalzemeDepolar] = React.useState<ItemWarehouse[]>([]);

    const GetAllItemAmounts = () => {
        DbItemAmount.amountOfItemInWarehouse(malzemeId)
            .then(response => {
                if (response.ok) {
                    setMalzemeDepolar(response.data);
                } else {
                    console.log(response.message)
                }
            })
            .catch(err => message.error(err));
    }

    // const updateItemAmounts = () => {
    //     const promises = malzemeDepolar.map(x => DbItemAmount.malzemeMiktarKaydet(x));
    //     Promise.all(promises)
    //         .then(x => {
    //             if (onChange) onChange(malzemeDepolar);
    //             message.success(intl.formatMessage({ id: 'Operation.Success' }));
    //         })
    //         .catch(err => message.error(intl.formatMessage({ id: 'Operation.Error' })));
    // }

    const itemAmountColumns = [
        {
            title: Translate("WarehouseCode"),
            dataIndex: ["warehouse", "code"]
        },
        {
            title: Translate("WarehouseDescription"),
            dataIndex: ["warehouse", "description"]
        },
        {
            title: Translate("UnitCode"),
            dataIndex: ["unit", "code"]
        },
        {
            title: Translate("UnitDescription"),
            dataIndex: ["unit", "description"]
        },
        {
            title: Translate("MiktarFiili"),
            dataIndex: 'miktarFiili'
        },
        {
            title: Translate("MiktarSevkedilebilir"),
            dataIndex: 'miktarSevkedilebilir'
        },
        {
            title: Translate("MiktarGercek"),
            dataIndex: 'miktarGercek'
        },
        {
            title: Translate("Status"),
            dataIndex: ["depo", "isActive"],
            render: (text: string, record: ItemWarehouse) => record.warehouse.b2bStatus ? "B2B Ambar" : "Diger Ambarlar",
        },
    ];
    React.useEffect(GetAllItemAmounts, [malzemeId]);

    return (
        <>
            <Table
                dataSource={malzemeDepolar}
                columns={itemAmountColumns}
                rowClassName={(record) => record.warehouse.b2bStatus ? 'table-row-light' : 'table-row-dark'}
            >
            </Table>

        </>
    );
}





/* <table style={{ width: '100%', border: 1 }}>
                <tr>
                    <th>{Translate("StoreCode")}</th>
                    <th>{Translate("StoreDescription")}</th>
                    <th>{Translate("UnitCode")}</th>
                    <th>{Translate("UnitDescription")}</th>
                    <th>{Translate("MiktarFiili")}</th>
                    <th>{Translate("MiktarSevkedilebilir")}</th>
                    <th>{Translate("MiktarGercek")}</th>
                </tr>
                {malzemeDepolar.map((x, i) => (
                    <tr key={i} style={{ background: "red" }}>
                        <td>{x.depo.kod}</td>
                        <td>{x.depo.description}</td>
                        <td>{x.birim.kod}</td>
                        <td>{x.birim.description}</td>
                        <td>
                            {x.miktarFiili}
                            { <InputNumber disabled={true} value={x.miktarFiili} onChange={e => {
                                const newValues = [...malzemeDepolar];
                                newValues[i] = { ...newValues[i], miktarFiili: parseFloat(e?.toString() || "0") };
                                setMalzemeDepolar(newValues);
                            }} /> }
                           // </td>
                           // <td>
                                {x.miktarSevkedilebilir}
                                {/* <InputNumber disabled={true} value={x.miktarSevkedilebilir} onChange={e => {
                                    const newValues = [...malzemeDepolar];
                                    newValues[i] = { ...newValues[i], miktarSevkedilebilir: parseFloat(e?.toString() || "0") };
                                    setMalzemeDepolar(newValues);
                                }} /> }
                            </td>
                            <td>
                                {x.miktarGercek}
                                {/* <InputNumber disabled={true} value={x.miktarGercek} onChange={e => {
                                    const newValues = [...malzemeDepolar];
                                    newValues[i] = { ...newValues[i], miktarGercek: parseFloat(e?.toString() || "0") };
                                    setMalzemeDepolar(newValues);
                                }}
                                /> }
                            </td>
                        </tr>
                    ))}
                </table>
                {/* <div style={{ textAlign: 'right' }}>
                    <Button style={{ marginTop: 15 }} onClick={updateItemAmounts}>{Translate("Save")}</Button>
                </div> */