import { connect } from "react-redux";

import React from "react";

function Wizard(props) {
    return (
            <div class="one-case">
                <div class="step-indicator">
                    {props.captions.map((caption, index) => (
                        <li class={props.step >= index ? "complete" : ""}>
                            <div class="step">
                                <i style={{fontSize:"24px"}} class="fa fa-check-circle"></i>
                            </div>
                            <div class="caption hidden-xs hidden-sm">
                                {caption}
                            </div>
                        </li>
                    ))}
                </div>
            </div>
    );
}

const mapStateToProps = (state) => ({
    step: state.wizard,
});

export default connect(mapStateToProps)(Wizard);
