import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { connect } from "react-redux";
import { getById } from "../../store/client";

export function ClientHeader(props) {

    const mobile = useMediaQuery({ query: '(max-width:425px)' })

    useEffect(() => {
        const { getById } = props
        getById(localStorage.getItem("s_clientId"))
    }, [])


    return (
        <p style={{
            textAlign: 'center',
            margin: '7px 0 7px 0',
            fontSize: mobile ? '14px' : '20px',
            border: "2px solid #f0f0f0",
            borderRadius: "10px",
            background: "#f0f0f0",
            ...props.style
        }



        }>{props.client.description}</p>
    )
}
const mapStateToProps = (state) => ({ client: state.client, });

const mapDispatchToProps = { getById };


export default connect(mapStateToProps, mapDispatchToProps)(ClientHeader);
