import * as React from 'react';
import { Button, message } from 'antd';
import { ImageFile } from '../../../../models/models';
import { useIntl } from 'react-intl';
import * as dbItem from '../../../../api_ts/item';
import { Translate } from '../../i18n/Translate';

interface Props {
    malzemeId: number;
}

export const Images: React.FC<Props> = ({ malzemeId }) => {
    const intl = useIntl();
    const [file, setFile] = React.useState<File | undefined>();
    const [images, setImages] = React.useState<ImageFile[]>([]);

    const uploadFile = () => {
        if (!file) return;

        dbItem.malzemeResimleriniKaydet(malzemeId, file)
            .then(response => {
                if (response.ok) {
                    yenile();
                    message.success(intl.formatMessage({ id: 'Operation.Success' }));
                }
            })
            .catch(err => console.log(err));
    }

    const yenile = () => {
        dbItem.getItemImages(malzemeId)
            .then(response => {
                if (response.ok) {
                    setImages(response.data);
                } else {
                    console.log(response.message);
                }
            })
            .catch(err => console.log(err));
    }

    React.useEffect(yenile, [malzemeId]);

    return (
        <div>
            {images.map(image => (
                <div style={{ width: 130, padding: 15, textAlign: 'center', display: 'inline-block' }}>
                    <a href={image.url} target="_blank">
                        <img style={{ width: 100, height: 100 }} src={image.url} alt="" />
                    </a>
                    <Button
                        style={{ marginTop: 15 }}
                        type="ghost"
                        danger
                        onClick={() => {
                            dbItem.deleteItemImage(malzemeId, image.id)
                                .then(response => yenile())
                                .catch(err => message.error(err));
                        }}
                    >
                        {Translate("Delete")}
                    </Button>
                </div>
            ))}
            <div>
                <input
                    type="file"
                    onChange={(e) => setFile(e.target.files ? e.target.files[0] : undefined)}
                />
            </div>
            <div style={{ marginTop: 15 }}>
                <Button onClick={uploadFile}>{Translate("Save")}</Button>
            </div>
        </div>
    );
};
