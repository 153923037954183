import * as React from 'react';
import { useIntl } from 'react-intl';
import { Input, Tabs, Form, Select, Tooltip, Button, message, Card, Table, Checkbox } from 'antd';
import * as Icons from '@ant-design/icons';
import { User, OperationClaim } from '../../../../models/models';
import * as dbUser from '../../../../api_ts/user';
import * as dbOperationClaim from '../../../../api_ts/operationClaim';

import { Translate } from '../../i18n/Translate';


export const Users: React.FC = () => {
    const intl = useIntl();

    const [users, setUsers] = React.useState<User[]>([]);
    const [operationClaims, setOperationClaims] = React.useState<OperationClaim[]>([]);
    const [selectedTabKey, setSelectedTabKey] = React.useState<string>("1");
    const [userForm] = Form.useForm();
    const formItemLayout = { labelCol: { span: 7 }, wrapperCol: { span: 17 } };
    const [flags, setFlags] = React.useState({ operation: "Add" });

    const columns = [
        {
            title: Translate("Name"),
            dataIndex: 'firstName',
        },
        {
            title: Translate("LastName"),
            dataIndex: 'lastName',
        },
        {
            title: Translate("Username"),
            dataIndex: 'username',
        },
        {
            title: Translate("Password"),
            dataIndex: 'password',
        },
        {
            title: Translate("E-mail"),
            dataIndex: 'email',
        },
        {
            title: Translate("Phone"),
            dataIndex: 'phoneNumber',
        },
        {
            title: Translate("Auth"),
            render: (text: string, record: User) => operationClaims.find(x => x.id == record.userOperationClaims[0].operationClaimId)?.name
        },
        {
            title: Translate("IsActive"),
            dataIndex: 'isActive',
            render: (text: string, record: User) => record.isActive ? "Aktif" : "Pasif",
        },
        {
            key: 'id',
            render: (text: string, record: User) =>
                <>
                    <Tooltip placement="top" color="blue" title={Translate("Edit")}>
                        <Button icon={<Icons.EditOutlined />} onClick={() => handleEditUserClick(record)} />
                    </Tooltip>
                    <Tooltip placement="top" color="blue" title={Translate("Delete")}>
                        <Button icon={<Icons.DeleteOutlined />} onClick={() => kullaniciSil(record.id)} />
                    </Tooltip>
                </>
        },
    ];

    function yenile() {
        dbUser.getUsersWithClaims()
            .then(response => {
                if (response.ok) setUsers(response.data);
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }



    const getOperationClaims = () => {
        dbOperationClaim.getList()
            .then(response => {
                if (response.ok)
                    setOperationClaims(response.data);
                else console.log(response.message);
            })
            .catch(err => console.log(err.message));
    }


    React.useEffect(() => {
        yenile();
        getOperationClaims();
    }, []);

    const addOrUpdate = async (user: any) => {
        let success: boolean = false;
        user.userOperationClaims = [{ id: user.userOperationClaimId, userid: user.id, operationClaimId: user.auth }];
        if (user.id > 0) {
            success = (await dbUser.update(user)).ok;
        } else {
            success = (await dbUser.add(user)).ok;
        }

        if (success) {
            message.success(intl.formatMessage({ id: 'Operation.Success' }));
            yenile();
            setSelectedTabKey("1");
            userForm.resetFields();
            setFlags({ ...flags, operation: "Add" })

        } else {
            message.error(intl.formatMessage({ id: 'Operation.Error' }));
        }
    }


    const kullaniciSil = async (e: number) => {
        const response = await dbUser.deleteUser(e);
        if (response.ok) {
            message.success('İşlem tamamland');
            yenile();
        }
        else {
            message.error('Kayıt silme sırasında hata oluştu', 2);
        }
    }

    const handleEditUserClick = function (user: User) {
        userForm.setFieldsValue(user)
        userForm.setFieldsValue({ auth: user.userOperationClaims[0].operationClaimId, userOperationClaimId: user.userOperationClaims[0].id })
        setFlags({ ...flags, operation: "Update" })
        setSelectedTabKey("2");
    }


    return (
        <Tabs activeKey={selectedTabKey} onTabClick={key => setSelectedTabKey(key)}>
            <Tabs.TabPane tab={<span><Icons.UserOutlined />{Translate("UsersList")}</span>} key="1">
                <div className="site-card-border-less-wrapper">
                    <Card title={Translate("UsersList")} bordered={false} style={{ width: '95%' }}>
                        <Table columns={columns} dataSource={users} />
                    </Card>
                </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={<span><Icons.UserAddOutlined />{Translate(flags.operation)}</span>} key="2">
                <div className="site-card-border-less-wrapper">
                    <Form
                        form={userForm}
                        layout={"horizontal"}
                        onFinish={addOrUpdate}
                        size={"small"}
                        {...formItemLayout}
                        labelAlign={"left"}
                    >
                        <Card key={'card'} title={Translate("AddUser")} bordered={false} style={{ width: '50%' }}>

                            <Form.Item name={"id"} hidden={true} initialValue={0} ><Input /></Form.Item>
                            <Form.Item
                                label={Translate("Name")}
                                name="firstName"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input placeholder={Translate("Name")} />
                            </Form.Item>
                            <Form.Item
                                label={Translate("Surname")}
                                name="lastName"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input placeholder={Translate("Surname")} />
                            </Form.Item>
                            <Form.Item
                                label={Translate("Username")}
                                name="username"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input placeholder={Translate("Username")} />
                            </Form.Item>

                            <Form.Item
                                label={Translate("Phone")}
                                name="phoneNumber"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input placeholder={Translate("Phone")} prefix={<Icons.PhoneOutlined />} />
                            </Form.Item>

                            <Form.Item
                                label={Translate("E-mail")}
                                name="email"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input type={""} placeholder={Translate("E-mail")} prefix={<Icons.MailOutlined />} />
                            </Form.Item>

                            <Form.Item
                                label={Translate("Password")}
                                name="password"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input type="password" placeholder={Translate("Password")} prefix={<Icons.LockOutlined />} />
                            </Form.Item>


                            {/* <Form.Item
                                label={Translate("Password")}
                                name="password"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input type="password" placeholder={Translate("E-mail")} prefix={<Icons.MailOutlined />} />
                            </Form.Item>

                            <Form.Item
                                label={Translate("Password")}
                                name="password"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Input type="password" placeholder={Translate("Password")} prefix={<Icons.MailOutlined />} />
                            </Form.Item> */}

                            <Form.Item name={"userOperationClaimId"} hidden={true} initialValue={0} ><Input /></Form.Item>
                            <Form.Item
                                label={Translate("Auth")}
                                name="auth"
                                rules={[{ required: true, message: Translate("RequiredField") }]}
                            >
                                <Select
                                    placeholder={Translate('Auth')}
                                    style={{ width: 200 }}
                                >
                                    {
                                        operationClaims.map(x => <Select.Option value={x.id}>{x.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>


                            <Form.Item
                                label={Translate("IsActive")}
                                name="isActive"
                                valuePropName="checked"
                                initialValue={true}
                            >
                                <Checkbox  >
                                    {Translate("IsActive")}
                                </Checkbox>
                            </Form.Item>
                            <Button type="primary" htmlType="submit">{Translate("Save")}</Button>
                            <Button type="ghost" onClick={() => {
                                setSelectedTabKey("1");
                                setFlags({ ...flags, operation: "Add" })
                                userForm.resetFields();
                                yenile()
                            }}>{Translate("Cancel")}</Button>
                        </Card>
                    </Form>
                </div>
            </Tabs.TabPane>
        </Tabs >
    );
};
