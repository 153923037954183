import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Tabs, Form, message, Input, Select, InputNumber } from 'antd';
import theme from '../../data/theme';
import * as authenticate from '../../api_js/authenticate';
import * as dbFirmAndPeriod from '../../../../api_ts/firmAndPeriod';
import { withRouter } from 'react-router-dom'
import { UserType } from '../../../../modules/B2B/enum/enumB2B';
import AccountPageChooseClient from './AccountPageChooseClient';

import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import '../../../B2B/css/AccountPageLogin.css';

const { TabPane } = Tabs;
const { Option } = Select;



function AccountPageLogin(props) {

    const [loginForm] = Form.useForm();
    const [isLoginUI, setLoginUI] = React.useState(false);
    const [userTypeTabs, setUserTypeTabs] = React.useState(UserType.Salesman);
    const [firmsAndPeriods, setFirmsAndPeriods] = React.useState([]);
    const formItemLayout = { labelCol: { span: 6 }, wrapperCol: { span: 24 } };

    const intl = useIntl();

    const breadcrumb = [];

    React.useEffect(() => {
        setLoginUI(authenticate.isUserLogin() || authenticate.firstStepLoginSalesmanStatus());
        dbFirmAndPeriod.getList()
            .then(response => {
                if (response.ok) {
                    setFirmsAndPeriods(response.data);
                } else {
                    console.log(response.message);
                }
            })
            .catch(err => console.log(err.message));
    }, []);


    const userLogin = async (user) => {
        user.periodNumber = firmsAndPeriods.find(x => x.firmNumber == user.firmNumber).periodNumber;
        let response = null;
        switch (user.userType) {
            case 0:
                response = await authenticate.loginFirstStepForSalesman(user);
                if (response.ok) {
                    authenticate.loginUI(response.data)
                    debugger;
                    if (authenticate.firstStepLoginSalesmanStatus())
                        return props.history.push("/account/choose-client")
                    else
                        message.error('Kullanıcı giriş başarısız');
                } else {
                    message.error('Kullanıcı giriş başarısız Kullanıcı Adı ve Password Kontrol Ediniz');
                }
                break;
            case 1:
                response = await authenticate.loginClient(user);
                if (response.ok) {
                    authenticate.loginUI(response.data)
                    if (authenticate.isUserLogin()) {
                        return props.history.push("/shop")
                    }
                    else
                        message.error('Kullanıcı giriş başarısız');
                    break;
                } else {
                    message.error('Kullanıcı giriş başarısız Kullanıcı Adı ve Password Kontrol Ediniz');
                }
        }
    }

    //STEP 2 GİRİŞ YAPAN KULLANICI NASIL YÖNLENECEK
    const afterToLogin = () => {
        debugger;
        switch (authenticate.getUserInfo().userType) {
            case 0:
                console.log("Satış elemanı Yönlendiriliyor")
                return <Redirect to="/salesman/choose-client" />;
            case 1:
                console.log("Satış elemanı Yönlendiriliyor")
                return <Redirect to="/shop" />
        }
    }

    const accountPageLoginToHtml = (
        <React.Fragment>
            <Helmet>
                <title>{`Giriş — ${theme.name}`}</title>
            </Helmet>
            {/* <PageHeader header="Giriş Yap" breadcrumb={breadcrumb} /> */}
            <div className="site-header__middle container">
                <div className="site-header__logo">
                    <Link to="/"><img src="/images/resmak106x26.jpg" alt="" /></Link>
                </div>
            </div>
            <div className="block" style={{ marginTop: 50 }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <Tabs defaultActiveKey={0} onChange={(value) => {
                                        setUserTypeTabs(value)
                                    }}>
                                        <TabPane tab={<FormattedMessage id="accountPage.SalaesmanUser" />} key={0}>
                                            <h3 className="card-title"><FormattedMessage id="accountPage.loginSalaesman" /></h3>

                                            <Form
                                                name="basic"
                                                {...formItemLayout}
                                                layout='vertical'
                                                initialValues={{ remember: true }}
                                                onFinish={userLogin}
                                                autoComplete="on"
                                            >
                                                <Form.Item
                                                    label={intl.formatMessage({ id: "username" })}
                                                    name="username"
                                                    rules={[{ required: true, message: intl.formatMessage({ id: "requiredField" }) }]}

                                                    requiredMark={true}
                                                    style={{ marginBottom: "5px" }}
                                                >
                                                    <Input />
                                                </Form.Item>

                                                <Form.Item
                                                    label={intl.formatMessage({ id: "accountPage.password" })}
                                                    name="password"
                                                    rules={[{ required: true, message: intl.formatMessage({ id: "requiredField" }) }]}
                                                    style={{ marginBottom: "5px", width: "100%" }}
                                                >
                                                    <Input.Password />
                                                </Form.Item>
                                                < Form.Item
                                                    label={intl.formatMessage({ id: "firms" })}
                                                    name={"firmNumber"}
                                                    rules={[{ required: true, message: intl.formatMessage({ id: "requiredField" }) }]}
                                                    style={{ marginBottom: "10px" }}
                                                //initialValue={firmsAndPeriods[0]?.firmNumber ?? 0} 
                                                >
                                                    <Select
                                                        placeholder={"Firma"}
                                                        size={"large"}
                                                    >
                                                        {firmsAndPeriods.map(x => <Option value={x.firmNumber}>{x.firmNumber}{": "}{x.firmName}</Option>)}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item name="periodNumber" hidden={true}> <Input /> </Form.Item>
                                                <Form.Item name="userType" initialValue={UserType.Salesman} hidden={true}> <InputNumber /> </Form.Item>
                                                <Form.Item >
                                                    <button class="btn btn-primary mt-2 mt-md-3 mt-lg-4">Giriş Yap</button>
                                                </Form.Item>
                                            </Form>
                                        </TabPane>
                                        <TabPane tab={<FormattedMessage id="accountPage.CurrentUser" />} key={1}>
                                            <h3 className="card-title"><FormattedMessage id="accountPage.loginCurrentUser" /></h3>
                                            <Form
                                                name="basic"
                                                {...formItemLayout}
                                                layout='vertical'
                                                initialValues={{ remember: true }}
                                                onFinish={userLogin}
                                                autoComplete="on"
                                            >
                                                <Form.Item
                                                    label={intl.formatMessage({ id: "username" })}
                                                    name="username"
                                                    rules={[{ required: true, message: intl.formatMessage({ id: "requiredField" }) }]}

                                                    requiredMark={true}
                                                    style={{ marginBottom: "5px" }}
                                                >
                                                    <Input />
                                                </Form.Item>

                                                <Form.Item
                                                    label={intl.formatMessage({ id: "accountPage.password" })}
                                                    name="password"
                                                    rules={[{ required: true, message: intl.formatMessage({ id: "requiredField" }) }]}
                                                    style={{ marginBottom: "5px", width: "100%" }}
                                                >
                                                    <Input.Password />
                                                </Form.Item>
                                                < Form.Item
                                                    label={intl.formatMessage({ id: "firms" })}
                                                    name={"firmNumber"}
                                                    rules={[{ required: true, message: intl.formatMessage({ id: "requiredField" }) }]}
                                                    style={{ marginBottom: "10px" }}
                                                //initialValue={firmsAndPeriods[0]?.firmNumber ?? 0} 
                                                >
                                                    <Select
                                                        placeholder={"Firma"}
                                                        size={"large"}>
                                                        {firmsAndPeriods.map(x => <Option value={x.firmNumber}>{x.firmNumber}{": "}{x.firmName}</Option>)}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item name="periodNumber" hidden={true}> <Input /> </Form.Item>
                                                <Form.Item name="userType" initialValue={UserType.ClientUser} hidden={true}> <InputNumber /> </Form.Item>

                                                <Form.Item >
                                                    <button onClick={() => loginForm.submit()} class="btn btn-primary mt-2 mt-md-3 mt-lg-4">Giriş Yap</button>
                                                </Form.Item>
                                            </Form>
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

    return (
        isLoginUI ? afterToLogin() : accountPageLoginToHtml
    );
}

export default withRouter(AccountPageLogin)